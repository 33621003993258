label.error {
    bottom: -1px;
    color: #ff0000;
    font-size: 12px;
    position: static;
    margin-bottom: 0;
}
.form-group {
    margin-bottom: 10px;
}
html body .row {
    margin-left: -10px;
    margin-right: -10px;
}
.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    padding-left: 10px;
    padding-right: 10px;
}
.full-login-screen {
    display: table;
    padding: 50px 0;
    height: 100vh;
    .login-form {
        margin: 0;
    }
}

.login-view-block {
    background-image: url("../../../img/admin/login/bg.jpg");
    background-size: cover;
    min-height: 100vh;
}

.login-description {
    text-align: right;
    h2 {
        margin: 0 0 9px;
        // width: 84%;
        width: 100%;
    }
    h3 {
        margin: 0;
    }
}

.login-view-block .input-group {
    width: 100%;    
    .input-group-addon {
        background: rgba(0, 0, 0, 0);
        border: 1px solid #004b8b;
        border-right: none;
        padding: 0;
        min-width: 40px;

        border: 0;
        padding: 0;
        position: absolute;
        left: 0;
        z-index: 4;
        top: 12px;
        i {
            color: #004b8b;
        }
    }
    input {
        background: rgba(0, 0, 0, 0);
        border: 0;
        border-bottom: 1px solid #ccc;
        width: 100%;
        padding: 8px 24px 6px 40px;
        color: #333;
        &:-webkit-autofill {
            transition: background-color 5000s ease-in-out 0s;
        }
        &:hover{
            border-bottom:  2px solid #004b8b;
        }
        &::placeholder {
            color: #999;
        }
        &::-moz-placeholder {
            color: #999;
        }
        &::-webkit-input-placeholder {
            color: #999;
        }
        &:-ms-input-placeholder {
            color: #999;
        }
    }
}

.login-description {
    // border-left: 4px solid #fff;
    color: #fff;
    padding-right: 10px;
    padding-left: 50px;
    border-right: 4px solid #fff;
    span {
        color: #fff;
    }
}

.content-log {
    background: #fcfcfc;
    border-radius: 7px;
    box-shadow: 0 0 7px 5px rgba(0, 0, 0, 0.20);
    // margin: 0 auto;
    margin-left: auto;
    padding: 20px 30px 30px;
    width: 450px;
}

.leftbar-login, .rightbar-login {
    display: table-cell;
    float: none;
    vertical-align: middle;
}

.login-logo {
    margin-bottom: 26px;
    text-align: right;
    img{
        height: 72px;
    }
}

.btn.login-bn {
    background-color: #27a7ce;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    width: 100%;
}

.rightbar-login .rememberme {
    margin-bottom: 16px;
    color: #004b8b;
    margin-top: 11px;
}

.login-logo.logoicon {
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 50% !important;
    box-shadow: 0 0 7px 5px rgba(0, 0, 0, 0.20);
    height: 85px;
    margin: -56px auto 32px;
    padding: 14px 18px;
    text-align: center;
    width: 85px;
    img {
        width: 50px;
        height: auto;
    }
}

.forget-pass {
    color: #004b8b;
    display: block;
    float: right;
    font-size: 15px;
    margin: 12px 0 16px 0;
    padding: 0;
    text-align: right;
    text-transform: capitalize;
}
.logout-alert {
    background: #fff;
    color: #23a34a;
    text-align: center;
    padding: 10px;
    border: 1px solid #004b8b;
}

.form-title {
    color: #004b8b;
    padding-bottom: 10px;
    text-align: center;
}
.field {
    width: 100%;
    margin-bottom: 20px;
    .urlBox {
        float: left;
        width: 60%;
        padding: 0 0;
        input {
            background: transparent;
            // border: 1px solid #004b8b;
            border-radius: 5px;
            box-sizing: border-box;
            font-size: 14px;
            height: 34px;
            // color: #004b8b;
            // padding:6px 10px;
            width: 100%;
            // text-align: right;
            &:focus {
                outline:none;
            }

        }
         ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
              color: #fff;
            }
            ::-moz-placeholder { /* Firefox 19+ */
              color: #fff;
            }
            :-ms-input-placeholder { /* IE 10+ */
              color: #fff;
            }
            :-moz-placeholder { /* Firefox 18- */
              color: #fff;
            }
            .js-error-message {
                margin: 0;
                font-size: 12px;
                width: 100%;
                position: absolute;
                color: #ea5b5b;
                left: 0;
            }
    } 
    .wz-text {
        margin-top: 8px;
        font-weight: 600;
        color: #004b8b;
        padding-left: 10px; 
    }
    .company-text {
        display: block;
        // text-align: center;
        margin: 0;
        border-bottom: 1px solid #ccc;
        width: 100%;
        padding: 8px 24px 6px 40px;
        .input-group-addon{
            width: auto;
            left: 11px;
            position: absolute;
            top: 6px;
            background: transparent;
            border: 0;
            i{
                color: #004b8b;
            }
        }
    }
}

.rightbar-login form .input-icon input {
    font-size: 14px;
}

.login-btn-submit:hover {
    box-shadow: 0 0 7px 5px rgba(0, 0, 0, 0.15) !important;
}

.rememberme {
    &.mt-checkbox {
        > span::after {
            border-color: #004b8b;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
        }
        &.mt-checkbox-outline {
            &:hover > input {
                &:checked:not([disabled]) ~ span, &:not([disabled]) ~ span {
                    border: 1px solid #004b8b;
                }
            }
            > {
                input {
                    &:checked ~ span, &:focus ~ span {
                        border: 1px solid #004b8b;
                    }
                }
                span {
                    border: 1px solid #fff;
                }
            }
        }
        &.mt-radio-outline {
            &:hover > input {
                &:checked:not([disabled]) ~ span, &:not([disabled]) ~ span {
                    border: 1px solid #fff;
                }
            }
            > input:checked ~ span {
                border: 1px solid #fff;
            }
        }
    }
    &.mt-radio.mt-checkbox-outline:hover > input:checked:not([disabled]) ~ span {
        border: 1px solid #fff;
    }
    &.mt-checkbox.mt-checkbox-outline > span {
        border: 1px solid #004b8b;
        top: -1px;
    }
}


.btn-5a::before {
    left: 0;
    top: -100%;
}

.btn-5 {
    backface-visibility: hidden;
    background: #004b8b !important;
    color: #fff !important;
    font-size: 16px;
    height: 38px;
    line-height: 24px;
    min-width: 180px;
    overflow: hidden;
    border-radius: 5px !important;
}

.btn.btn-info {
    background: #004b8b;
    border-color: #004b8b;
    border-radius: 0px !important;
}

.btn-del {
    &::after {
        content: "";
        position: absolute;
        transition: all 0.3s ease 0s;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -ms-transition: all 0.3s ease 0s;
        z-index: -1;
    }
    background: rgba(0, 0, 0, 0) ;
    border: medium none;
    color: inherit;
    cursor: pointer;
    display: inline-block;
    font-family: inherit;
    font-size: inherit;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 0;
    outline: medium none;
    padding: 12px 0;
    position: relative;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    width: 100%;
}

.btn-5a {
    
    &::before {
        left: 0;
        top: -100%;
    }
    &:hover::before {
        top: 0;
    }
}

.btn-5::before {
    font-size: 180%;
    height: 100%;
    line-height: 1.5;
    position: absolute;
    transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    width: 100%;
}

.btn-5 span {
    backface-visibility: hidden;
    display: inline-block;
    height: 100%;
    transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    width: 100%;
}

.btn-5a:hover span {
    transform: translateY(300%);
    -webkit-transform: translateY(300%);
    -moz-transform: translateY(300%);
    -ms-transform: translateY(300%);
}

.fa-lock::before {
    content: "";
    font-family: FontAwesome;
}

.fa-angle-double-right::before {
    content: "\f023";
    font-family: FontAwesome;
}

.text-white {
    color: #004b8b;
    text-transform: uppercase;
    font-weight: 600;
}

.company-name {
    -webkit-text-fill-color: #004b8b;
    bottom: 5px;
    font-size: 13px;
    position: absolute;
    right: 10px;
}

.forget-form .btn {
    background: #004b8b;
    color: #ffffff;
    text-transform: uppercase;
    border-radius: 5px !important;
    width: 100%;
}
.forget-form .back_to_login{
     background: transparent;
     color: #004b8b;
     text-transform: none;
}

/****** Select company Popup *******/

#select-company-modal {
    .modal-dialog {
        max-width: 96%;
        width: 800px;
        .mt-element-card.mt-card-round{
            .mt-card-item{
                overflow: hidden;
                transition: all 300ms ease 0s;
                -webkit-transition: all 300ms ease 0s;
                -moz-transition: all 300ms ease 0s;
                &::before{
                    background-color: #eeefef;
                    content: "";
                    height: 50%;
                    left: 0;
                    position: absolute;
                    transition: all 300ms ease 0s;
                    -webkit-transition: all 300ms ease 0s;
                    -moz-transition: all 300ms ease 0s;
                    top: -50%;
                    width: 100%;
                }
                &::after{
                    background-color: #eeefef;
                    bottom: -50%;
                    content: "";
                    height: 50%;
                    left: 0;
                    position: absolute;
                    transition: all 300ms ease 0s;
                    -webkit-transition: all 300ms ease 0s;
                    -moz-transition: all 300ms ease 0s;
                    width: 100%;
                }
                &:hover{
                    box-shadow: 0 12px 10px -15px #000000;
                    &::before{
                        top: 0;
                    }
                    &::after{
                        bottom: 0;
                    }
                    .mt-overlay {
                        opacity: 1;
                        transform: translateZ(0px);
                    }
                    img {
                        transform:translate(-50%, -50%) scale(1.2) translateZ(0px);
                        -webkit-transform:translate(-50%, -50%) scale(1.2) translateZ(0px);
                        -moz-transform:translate(-50%, -50%) scale(1.2) translateZ(0px);
                    }
                    .mt-info {
                        opacity: 1;
                        transition-delay: 0.2s;
                    }
                }
            }
        }
    }
    .modal-content {
        background: rgba(0, 0, 0, 0);
        border: medium none;
        box-shadow: none;
        .company-box {
            background: #fff;
            padding: 15px 30px 0;
        }
        .box-tlt {
            color: #3598dc;
            font-size: 18px;
            font-weight: bold;
            margin: 5px 0 20px;
            text-align: center;
        }
    }
    .mt-element-overlay {
        .mt-overlay-1 {
            background-color: #eeefef;
            float: none;
            cursor: pointer;
            margin: auto;
            height: 150px;
            position: relative;
            width: 150px;
            z-index: 1;
            img{
                left: 50%;
                margin: auto;
                max-height: 100%;
                max-width: 100%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                width: auto;
            }
        }
        .mt-card-content{
            position: relative;
            z-index: 1;
        }
    }
}

/****** Select company Popup *******/
label.label-helper {
    font-weight: 600;
    font-size: 14px;
    color: #333;
}

@media (max-width: 991px) {

    .full-login-screen {
        display: block;
        height: auto;
    }

    .leftbar-login {
        display: block;
        padding-top: 25px;
        text-align: center;
    }

    .login-description {
        border-left: none;
        padding-left: 0px;
        h2, h3 {
            width: 80%;
            margin-left: auto;
        }
    }

    .rightbar-login {
        display: block;
        margin-top: 80px;
        margin-bottom: 30px;
    }

    .content-log {
        max-width: 100%;
    }

}

@media (max-width: 767px) {
    .login-logo{
        img{
            margin: 0px auto;   
            display: block;
        }
    }
    .login-description{
        padding-right: unset;
        border-right: unset;
        text-align: center;
        h2, h3{
            margin-left: unset;
            width: 100%;
        }
    } 
    .full-login-screen {
        padding: 30px 0 15px 0;
    }

    .leftbar-login {
        padding: 0;
    }

    .rightbar-login {
        padding: 0;
        margin-bottom: 0;
    }

    .content-log {
        // background-color: rgba(255, 255, 255, 0.3);
        padding: 20px 15px 30px;
        margin: 0px auto;
    }
    .login-view-block{
        background-position: bottom right;
    }

}

@media (max-width: 480px) {

    /****** Login *********/

    .rightbar-login {
        .rememberme {
            display: block;
            font-size: 13px;
        }
    }

    .form-title {
        font-size: 20px;
    }

    .forget-pass {
        float: left;
        margin: 0 0 10px 0;
        font-size: 13px;
    }

    .login-description {
        h2 {
            font-size: 20px;
        }
        h3 {
            font-size: 14px;
        }
    }

    /****** Login *********/

}

@media (max-width: 414px) {

    /******** Login **********/
    .login-content {
        .g-recaptcha {
            transform: scale(0.79);
            transform-origin: left top;
        }
    }

    .capchaBox {
        margin-bottom: 0;
    }

    /******** Login **********/

}
@media (max-width: 360px) {
    .field{
        .wz-text{
            padding-left: 5px;
        }
        .urlBox{
            input{
                font-size: 13px;
            }
        } 
        .company-text{
            padding: 8px 24px 6px 40px;
        }
    } 
}
@media (max-width: 340px) {
    .content-log {
        padding: 20px 10px 30px;
    }
    .field{
        .wz-text{
            font-size: 13px;
        }
    } 
}

