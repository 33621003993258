@charset "UTF-8";

.datepicker {
  .active {
    background-image: none !important;
    filter: none !important;

    &:hover {
      background-image: none !important;
      filter: none !important;
    }
  }

  .selected,
  .today {
    background-image: none !important;
    filter: none !important;
  }
}

.modal-open {
  .colorpicker,
  .datepicker,
  .daterangepicker {
    z-index: 10055 !important;
  }
}

.colorpicker.dropdown-menu,
.daterangepicker.dropdown-menu,
.datetimepicker.dropdown-menu {
  padding: 5px;
}

.input-group.color .input-group-btn i {
  position: absolute;
  display: block;
  cursor: pointer;
  width: 20px;
  height: 20px;
  right: 6px;
}

.datepaginator a {
  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: 300;
}

.datepicker table {
  td,
  th {
    font-family: "Open Sans" !important;
  }
}

.datetimepicker table {
  td,
  th {
    font-family: "Open Sans" !important;
  }
}

#dp-calendar {
  right: 4px !important;
}

.datepaginator {
  .fa-angle-right:before {
    content: "";
  }

  .fa-angle-left:before {
    content: "";
  }
}

.datepicker {
  .fa-angle-left:before {
    content: "";
  }

  &.dropdown-menu {
    padding: 5px;
    box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
    border: 1px solid #efefef;
  }

  .selected {
    background-color: #909090 !important;
  }

  .active {
    background-color: #4b8df8 !important;

    &:hover {
      background-color: #2678FC !important;
    }
  }

  .input-daterange input {
    text-align: left;
  }

  table {
    td {
      color: #000;
      font-weight: 300 !important;
    }

    th {
      color: #333;
      font-weight: 400 !important;
    }
  }

  .fa-angle-right:before {
    content: "";
  }
}

.daterangepicker {
  .fa-angle-right:before {
    content: "";
  }
  margin-top: 4px;

  .input-mini {
    width: 100% !important;
    outline: 0 !important;
  }

  td {
    text-shadow: none;

    &.active {
      background-color: #4b8df8;
      background-image: none;
      filter: none;
    }
  }
}

.datetimepicker .active {
  filter: none !important;
  background-image: none !important;

  &:hover {
    filter: none !important;
    background-image: none !important;
  }
}

.daterangepicker {
  th {
    font-weight: 400;
    font-size: 14px;
  }

  .ranges {
    input[type=text] {
      width: 70px !important;
      font-size: 11px;
      vertical-align: middle;
    }

    label {
      font-weight: 300;
      display: block;
    }
    width: 170px;

    ul > li.active {
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
      border-radius: 4px;
    }

    .btn {
      margin-top: 10px;
    }

    li {
      color: #333;

      &.active,
      &:hover {
        background: #4b8df8 !important;
        border: 1px solid #4b8df8 !important;
        color: #fff;
      }
    }
  }

  .range_inputs input {
    margin-bottom: 0 !important;
  }

  .fa-angle-left:before {
    content: "";
  }
}

.datetimepicker {
  .fa-angle-left:before {
    content: "";
  }

  table {
    td {
      color: #000;
      font-weight: 300 !important;
    }

    th {
      font-weight: 400 !important;
    }
  }

  .active {
    background-color: #4b8df8 !important;

    &:hover {
      background-color: #2678FC !important;
    }
  }

  .fa-angle-right:before {
    content: "";
  }
}

.modal-open .datetimepicker {
  z-index: 10055;
}

.editable-input {
  table {
    border: 0 !important;

    td,
    th,
    tr {
      border: 0 !important;
    }
  }

  .combodate select {
    margin-bottom: 5px;
  }
}

.fileinput {
  margin-bottom: 0;

  .close {
    float: none;
  }

  .input-group {
    white-space: nowrap;
    overflow: hidden;
  }
}

.md-input {
  padding: 5px !important;
  border-bottom: 0 !important;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -ms-border-radius: 0 0 4px 4px;
  -o-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}

.md-editor {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;

  .btn-toolbar {
    margin-left: 0;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    -ms-border-radius: 4px 4px 0 0;
    -o-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;

    .btn-group {
      .btn-sm.btn {
        padding-bottom: 3px;
      }

      .btn-primary {
        padding-top: 5px;
        padding-bottom: 7px;
      }
    }
  }

  &.active {
    border: 1px solid #999;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }
}

.has-error .md-editor {
  border: 1px solid #fbe1e3 !important;
}

.has-success .md-editor {
  border: 1px solid #abe7ed !important;
}

.has-warning .md-editor {
  border: 1px solid #f9e491 !important;
}

.modal-open.page-overflow {
  .modal-scrollable {
    overflow-y: auto !important;
  }

  .page-container {
    overflow-y: auto !important;

    .navbar-fixed-bottom,
    .navbar-fixed-top {
      overflow-y: auto !important;
    }
  }
}

.modal-scrollable {
  overflow: hidden !important;
  z-index: 10051;
  overflow-y: scroll !important;
}

.modal-backdrop {
  position: fixed;
  bottom: 0;
}

.bootstrap-select .btn {
  border-color: #c2cad8;
}

.has-error .bootstrap-select .btn {
  border-color: #fbe1e3;
}

.has-success .bootstrap-select .btn {
  border-color: #abe7ed;
}

.has-warning .bootstrap-select .btn {
  border-color: #f9e491;
}

.bootstrap-select {
  &.open {
    &.dropup .btn,
    .btn {
      border-color: #93a1bb;
    }
  }

  .btn:focus {
    outline: 0 !important;
    outline-offset: 0;
  }

  &.btn-group .dropdown-menu {
    margin-top: 1px;

    > li > dt > .text {
      font-weight: 600;
      font-family: 'Open Sans';
      font-size: 14px;
    }

    .text-muted {
      color: #999 !important;
    }
  }

  .caret {
    border: 0;
    width: auto;
    height: auto;
    margin-top: (-10px) !important;

    &:before {
      content: "\f107";
      display: inline-block;
      border: 0;
      font-family: FontAwesome;
      font-style: normal;
      font-weight: 400;
    }
  }
}

.form-wizard .steps > li > a.step > {
  .desc,
  .number {
    display: inline-block;
    font-size: 16px;
    font-weight: 300;
  }
}

.bootstrap-select {
  .selected i {
    color: #aaa;
  }

  .dropdown-menu {
    z-index: 1000 !important;
  }
}

.modal-open {
  .bootstrap-select .dropdown-menu {
    z-index: 10050 !important;
  }

  .bootstrap-timepicker-widget,
  .clockface {
    z-index: 10055 !important;
  }
}

.bootstrap-switch {
  border-color: #c2cad8;

  &.bootstrap-switch-focused {
    box-shadow: none;
    border-color: #c2cad8;
  }

  .bootstrap-switch-handle-off.bootstrap-switch-primary,
  .bootstrap-switch-handle-on.bootstrap-switch-primary {
    color: #fff;
    background: #337ab7;
  }

  .bootstrap-switch-handle-off.bootstrap-switch-info,
  .bootstrap-switch-handle-on.bootstrap-switch-info {
    color: #fff;
    background: #659be0;
  }

  .bootstrap-switch-handle-off.bootstrap-switch-success,
  .bootstrap-switch-handle-on.bootstrap-switch-success {
    color: #fff;
    background: #36c6d3;
  }

  .bootstrap-switch-handle-off.bootstrap-switch-warning,
  .bootstrap-switch-handle-on.bootstrap-switch-warning {
    color: #fff;
    background: #F1C40F;
  }

  .bootstrap-switch-handle-off.bootstrap-switch-danger,
  .bootstrap-switch-handle-on.bootstrap-switch-danger {
    color: #fff;
    background: #ed6b75;
  }

  .bootstrap-switch-handle-off.bootstrap-switch-default,
  .bootstrap-switch-handle-on.bootstrap-switch-default {
    color: #444;
    background: #e8eaef;
  }
}

.portlet:not(.light) > .portlet-title > .actions > .bootstrap-switch {
  border-color: transparent;
  margin-top: 0;
}

.bootstrap-timepicker-widget {
  table td a {
    padding: 4px 0;
  }

  input {
    outline: 0 !important;
    border: 0;

    &:focus {
      outline: 0 !important;
      border: 0;
    }
  }

  &.timepicker-orient-bottom {
    &:after,
    &:before {
      top: auto;
    }
  }
}

.toast {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  background-color: #030303;
}

.toast-success {
  background-color: #51a351;
}

.toast-error {
  background-color: #bd362f;
}

.toast-info {
  background-color: #2f96b4;
}

.toast-warning {
  background-color: #f89406;
}

.toast .toast-close-button {
  display: inline-block;
  margin-top: 0;
  margin-right: 0;
  text-indent: -100000px;
  width: 11px;
  height: 16px;
  background-repeat: no-repeat !important;
  background-image: url("../img/portlet-remove-icon-white.png") !important;
}

.form-wizard .steps {
  background-color: #fff;
  background-image: none;
  border: 0;
  box-shadow: none;
  filter: none;

  > li > a.step {
    background-color: #fff;
    background-image: none;
    border: 0;
    box-shadow: none;
    filter: none;
  }
}

.toast-top-center {
  top: 12px;
  margin: 0 auto 0 -150px;
  left: 50%;

  .toast {
    margin: 0 !important;
  }
}

.toast-bottom-center {
  bottom: 12px;
  margin: 0 auto 0 -150px;
  left: 50%;

  .toast {
    margin: 0 !important;
  }
}

.form-wizard {
  .progress {
    margin-bottom: 30px;
  }

  .steps {
    padding: 10px 0;
    margin-bottom: 15px;

    > li {
      > a.step {
        &:hover {
          background: 0 0;
        }

        > {
          .number {
            background-color: #eee;
            text-align: center !important;
            padding: 11px 15px 13px;
            margin-right: 10px;
            height: 45px;
            width: 45px;
            -webkit-border-radius: 50% !important;
            -moz-border-radius: 50% !important;
            border-radius: 50% !important;
          }

          .desc > i {
            display: none;
          }
        }
      }

      &.active > a.step {
        .number {
          background-color: #36c6d3;
          color: #fff;
        }

        .desc {
          color: #333;
        }
      }

      &.done > a.step {
        .number {
          background-color: #F1C40F;
          color: #fff;
        }

        .desc {
          color: #333;

          i {
            font-size: 12px;
            font-weight: 400;
            color: #999;
            display: inline-block;
          }
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .form-wizard .step .desc {
    margin-top: 10px;
    display: block;
  }
}
@media (max-width: 768px) {
  .form-wizard .steps > li > a {
    text-align: left;
  }
}
/*!
* Tab drop for Bootstrap
*/

.tabbable-tabdrop {
  .nav-pills,
  .nav-tabs {
    position: relative;
  }

  .nav-pills .caret,
  .nav-tabs .caret {
    display: none;
  }

  .nav-pills .tabdrop .dropdown-toggle i,
  .nav-tabs .tabdrop .dropdown-toggle i {
    font-size: 14px;
  }

  .dropdown-menu {
    &:after {
      right: 10px;
      left: auto;
    }

    &:before {
      right: 9px;
      left: auto;
    }
  }
}

.wysihtml5-sandbox {
  width: 100% !important;
}

.wysihtml5-toolbar li {
  margin: 0;
  height: 29px;

  .dropdown-menu {
    margin-top: 5px;
  }
}

.has-error .wysihtml5-sandbox {
  border: 1px solid #fbe1e3 !important;
}

.has-success .wysihtml5-sandbox {
  border: 1px solid #abe7ed !important;
}

.has-warning .wysihtml5-sandbox {
  border: 1px solid #f9e491 !important;
}

.note-editor {
  border: 1px solid #c2cad8;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;

  .note-toolbar {
    border-bottom: 1px solid #c2cad8;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    -ms-border-radius: 4px 4px 0 0;
    -o-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
  }

  .note-statusbar {
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    -ms-border-radius: 0 0 4px 4px;
    -o-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;

    .note-resizebar {
      border-top: 1px solid #c2cad8;
    }
  }

  &.note-frame.fullscreen {
    z-index: 10050;
    width: 100% !important;
  }

  .dropdown-menu {
    &:before {
      left: 9px;
      right: auto;
    }

    &:after {
      left: 10px;
      right: auto;
    }
  }
}

.note-link-dialog .checkbox {
  margin-left: 20px;
}

.has-error .note-editor {
  border: 1px solid #fbe1e3 !important;
}

.has-success .note-editor {
  border: 1px solid #abe7ed !important;
}

.has-warning .note-editor {
  border: 1px solid #f9e491 !important;
}

.gtreetable {
  .dropdown-menu {
    margin-top: 0;

    &:after,
    &:before {
      display: none !important;
    }
  }

  .node-action .form-control {
    position: relative;
    top: 2px;
    display: inline-block;
  }

  .node-icon-ce,
  .node-icon-handle,
  .node-icon-selected,
  .node-icon-type {
    opacity: 0.6;
    filter: alpha(opacity = 60);
  }
}

.bootstrap-tagsinput {
  display: block;
  padding: 4px 4px 6px;
  font-size: 14px;
  font-weight: 400;
  min-height: 34px;
  color: #555;
  background-color: #fff;
  border: 1px solid #c2cad8;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
}

.CSSAnimationChart a,
.mapChart a {
  color: #bbb !important;
  font-size: 12px !important;
}

.bootstrap-tagsinput .tag {
  [data-role=remove] {
    box-shadow: none;
    font-family: arial;

    &:after {
      font-family: arial;
    }

    &:hover {
      text-decoration: none;
      box-shadow: none;
    }
  }
  display: inline-block;
  margin-top: 2px;
  padding: 4px 6px;

  input {
    width: 5em !important;
  }
}

.bootstrap-tagsinput-width-auto .bootstrap-tagsinput {
  display: inline-block;
}

.mt-bootstrap-tables .mt-btm-transform .bars {
  height: auto;
}

.CSSAnimationChart,
.mapChart {
  width: 100%;
  height: 500px;
}

.fixed-table-toolbar .columns label {
  margin: 10px;
  padding: 0 0 0 30px;
}

.fixed-table-header th.bs-checkbox {
  outline: 0;

  .th-inner {
    overflow: visible;

    .mt-checkbox {
      right: -5px;
    }
  }
}

.bootbox .bootbox-close-button {
  margin-top: 0 !important;
}

.amcharts-graph-g2 .amcharts-graph-stroke {
  stroke-dasharray: 3px 3px;
  stroke-linejoin: round;
  stroke-linecap: round;
  -webkit-animation: am-moving-dashes 1s linear infinite;
  animation: am-moving-dashes 1s linear infinite;
}
@-webkit-keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -31px;
  }
}
@keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -31px;
  }
}

.lastBullet {
  -webkit-animation: am-pulsating 1s ease-out infinite;
  animation: am-pulsating 1s ease-out infinite;
}
@-webkit-keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0;
  }

  100% {
    stroke-opacity: 0;
    stroke-width: 50px;
  }
}
@keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0;
  }

  100% {
    stroke-opacity: 0;
    stroke-width: 50px;
  }
}

.amcharts-graph-column-front {
  -webkit-transition: all 0.3s 0.3s ease-out;
  transition: all 0.3s 0.3s ease-out;

  &:hover {
    fill: #496375;
    stroke: #496375;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
}

.amcharts-graph-g3 {
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-dasharray: 500%;
  stroke-dasharray: 0\9;
  stroke-dashoffset: 0\9;
  -webkit-animation: am-draw 40s;
  animation: am-draw 40s;
}
@-webkit-keyframes am-draw {
  0% {
    stroke-dashoffset: 500%;
  }

  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes am-draw {
  0% {
    stroke-dashoffset: 500%;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

.animated-pie-chart,
.columnLine {
  width: 100%;
  height: 350px;
  font-size: 11px;
}

.amcharts-graph-graph2 .amcharts-graph-stroke {
  stroke-dasharray: 4px 5px;
  stroke-linejoin: round;
  stroke-linecap: round;
  -webkit-animation: am-moving-dashes 1s linear infinite;
  animation: am-moving-dashes 1s linear infinite;
}
@-webkit-keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -28px;
  }
}
@keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -28px;
  }
}

.cke {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;

  .cke-top {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    -ms-border-radius: 4px 4px 0 0;
    -o-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
  }

  .cke-bottom {
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    -ms-border-radius: 0 0 4px 4px;
    -o-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
  }
}

.cke_bottom,
.cke_dialog,
.cke_dialog_footer,
.cke_dialog_title,
.cke_inner,
.cke_reset,
.cke_top {
  background-image: none !important;
  filter: none;
  border-top: 0;
  border-bottom: 0;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  text-shadow: none;
}

.cke_dialog_tab,
.cke_dialog_ui_button,
.cke_dialog_ui_input_text {
  filter: none;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  background-image: none !important;
}

.cke_dialog_tab,
.cke_dialog_ui_button {
  box-shadow: none !important;
  text-shadow: none !important;
}

.cke_dialog_tab:hover,
.cke_dialog_ui_button:hover {
  text-decoration: none;
  text-shadow: none;
}

.cke_dialog_ui_input_text {
  box-shadow: none !important;
}

.cke_button,
.cke_combo_button,
.cke_toolbar,
.cke_toolgroup {
  background-image: none !important;
  filter: none !important;
  border: 0;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.cke_button,
.cke_combo_button,
.cke_hc.cke_panel_listItem a,
.cke_panel_grouptitle {
  background-image: none !important;
  filter: none;
  text-shadow: none;
}

.cke_button:hover,
.cke_combo_button:hover {
  background-color: #ddd;
}

.cke_toolbar_break {
  background-image: none !important;
  filter: none !important;
  border: 0;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -ms-box-shadow: none !important;
  -o-box-shadow: none !important;
}

.has-error .cke {
  border: 1px solid #fbe1e3 !important;
}

.has-success .cke {
  border: 1px solid #abe7ed !important;
}

.has-warning .cke {
  border: 1px solid #f9e491 !important;
}

.clockface .cell {
  .inner.active,
  .outer.active {
    background-color: #4b8df8 !important;
    background-image: none;
    filter: none;
  }
}

.table-checkable tr > {
  td:first-child,
  th:first-child {
    text-align: center;
    max-width: 50px;
    min-width: 40px;
    padding-left: 0;
    padding-right: 0;
  }
}

table.dataTable {
  thead {
    td,
    th {
      border-bottom: 2px solid #e7ecf1;
      outline: 0 !important;
    }
  }

  &.no-footer {
    border-bottom-color: #e7ecf1;
  }
}

.dataTables_extended_wrapper.DTS {
  .dataTables_scrollBody,
  .dataTables_scrollHead {
    border-bottom: 0 !important;
  }
}

table.dataTable {
  tr.heading > th {
    background-color: #fbfcfd;
  }

  td {
    &.sorting_1,
    &.sorting_2,
    &.sorting_3 {
      background: #fbfcfd !important;
    }
  }

  th {
    &.sorting_1,
    &.sorting_2,
    &.sorting_3 {
      background: #fbfcfd !important;
    }
  }
}

.paging_bootstrap_extended {
  margin: 5px 0 0 !important;
  padding: 0 !important;
  float: none !important;
  font-size: 13px;
}

.dataTables_extended_wrapper {
  margin-top: 10px;

  .seperator {
    padding: 0 2px;
  }

  .table.dataTable {
    margin: 20px 0 !important;
  }

  div {
    &.dataTables_info,
    &.dataTables_length,
    &.dataTables_paginate {
      display: inline-block;
      float: none !important;
      padding: 0 !important;
      margin: 0 !important;
      position: static !important;
    }
  }

  .table-group-actions > span {
    font-size: 13px;
  }

  &.DTS {
    .paging_bootstrap_extended {
      display: inline-block;
    }

    .dataTables_info {
      display: inline-block;
      padding-top: 8px !important;

      .seperator {
        display: none;
      }
    }

    .dataTables_scrollHead .dataTables_scrollHeadInner .table.dataTable {
      margin-bottom: 0 !important;
    }

    .dataTables_scrollBody .table.dataTable {
      margin: 0 !important;
    }
  }

  div.dataTables_length label {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 13px;
    float: none !important;
    display: inline-block !important;
  }
}
@media (max-width: 480px) {
  .dataTables_extended_wrapper {
    div {
      &.dataTables_info,
      &.dataTables_length,
      &.dataTables_paginate {
        display: block;
        margin-bottom: 10px !important;
      }
    }

    .seperator {
      display: none !important;
    }
  }
}

.table-container .table-actions-wrapper {
  display: none;
}

.dataTables_scroll {
  margin-bottom: 10px;
}

.dataTables_scrollHead {
  border-bottom: 2px solid #e7ecf1 !important;

  thead th {
    border-bottom: 0 !important;
  }
}

.dataTables_scrollBody {
  border-bottom: 1px solid #e7ecf1 !important;
}

.table-both-scroll .dataTables_scrollBody {
  border-bottom: 0 !important;
}
.dataTables_wrapper {
  position: relative; 
}
.dataTables_wrapper .dataTables_processing {
  width: 100%;
  display: inline-block;
  text-align: center;
  color: #3f444a;
  background: #eef1f5;
  vertical-align: middle;
  height: 100%;
  position: absolute;
  z-index: 4;
  opacity: 0.7;
  left: 0;
  right: 0;
}

.dt-button-background {
  display: none !important;
}

div.dt-button-collection {
  border: 1px solid #eee;
  background: #fff;
  box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
  padding: 0;

  > a.dt-button {
    background: 0 0;
    padding: 8px 16px;
    font-weight: 300;
    margin: 1px;
    box-shadow: none !important;
    border: 0 !important;

    > span {
      font-size: 14px;
      color: #333;
    }

    &.active {
      background: #f6f6f6 !important;
      box-shadow: none !important;
      border: 0 !important;

      > span {
        color: #333;
      }
    }
  }
}

a.dt-button,
button.dt-button,
div.dt-button {
  background-image: none !important;
  padding: 6px 12px !important;
}

a.dt-button:last-child,
button.dt-button:last-child,
div.dt-button:last-child {
  margin-right: 0;
}

.dataTables_wrapper .dt-buttons {
  float: right;
}

.portlet {
  &.light .dataTables_wrapper .dt-buttons {
    margin-top: -64px;
  }

  &.box .dataTables_wrapper .dt-buttons {
    margin-top: -50px;
  }
}

.dataTable .row-details {
  &.row-details-close {
    background: url("../img/datatable-row-openclose.png") no-repeat;
  }

  &.row-details-open {
    background: url("../img/datatable-row-openclose.png") 0 -23px no-repeat;
  }
}

table.dataTable {
  thead {
    .sorting,
    .sorting_asc,
    .sorting_asc_disabled,
    .sorting_desc,
    .sorting_desc_disabled {
      cursor: pointer;
    }

    .sorting:after,
    .sorting_asc:after,
    .sorting_asc_disabled:after,
    .sorting_desc:after,
    .sorting_desc_disabled:after {
      display: none;
    }

    .sorting {
      background: url("../../img/admin/sort_both.png") center right no-repeat;
    }

    .sorting_asc {
      background: url("../../img/admin/sort_asc.png") center right no-repeat;
    }

    .sorting_desc {
      background: url("../../img/admin/sort_desc.png") center right no-repeat;
    }

    .sorting_asc_disabled {
      background: url("../../plugins/datatables/images/sort_asc_disabled.png") center right no-repeat;
    }

    .sorting_desc_disabled {
      background: url("../../plugins/datatables/images/sort_desc_disabled.png") center right no-repeat;
    }
  }

  th:active {
    outline: 0;
  }

  &.fixedHeader-floating {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    border: 1px solid #dee5ec;
    -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  }
}

.table-header-fixed thead > tr > th {
  border-top: 0;
}

.table {
  > tfoot > tr > {
    td,
    th {
      border-bottom: 0;
      padding: 10px 18px;
      border-top: 1px solid #e7ecf1;
      font-weight: 600;
    }
  }

  &.dataTable.dt-responsive .table.dataTable.responsive {
    width: 100%;
  }
}

table.dataTable {
  &.dtr-column > tbody > tr > {
    td.control,
    th.control {
      text-align: center;
      width: 14px;
    }

    td.control:before,
    th.control:before {
      margin-left: -8px;
      text-indent: 0;
      text-align: center;
      margin-top: -8px;
    }

    td.control:before,
    th.control:before {
      box-shadow: none !important;
      background-color: #b0c1d2;
      font-weight: 300;
      vertical-align: middle;
      line-height: 16px;
      border: 0;
    }
  }

  &.dtr-inline.collapsed > tbody > tr > {
    td:first-child:before,
    th:first-child:before {
      box-shadow: none !important;
      background-color: #b0c1d2;
      font-weight: 300;
      vertical-align: middle;
      line-height: 16px;
      border: 0;
    }
  }

  &.dtr-column > tbody > tr.parent {
    td.control:before,
    th.control:before {
      background-color: #36c6d3;
      font-weight: 300;
      vertical-align: middle;
      border: 0;
    }
  }

  &.dtr-inline.collapsed > tbody > tr {
    &.parent > {
      td:first-child:before,
      th:first-child:before {
        background-color: #36c6d3;
        font-weight: 300;
        vertical-align: middle;
        border: 0;
      }
    }

    > {
      td:first-child:before,
      th:first-child:before {
        top: 10px;
      }
    }
  }

  > tbody > tr.child ul li {
    border: 0;
  }
}
@-moz-document url-prefix() {
  .portlet-datatable .dataTables_wrapper .table-responsive {
    padding: 0 2px !important;
  }
}
@media (max-width: 767px) {
  .portlet {
    &.box .dataTables_wrapper .dt-buttons,
    &.light .dataTables_wrapper .dt-buttons {
      margin-top: 0;
      margin-bottom: 20px;
    }

    &.box .dataTables_wrapper .dt-buttons > .dt-button,
    &.light .dataTables_wrapper .dt-buttons > .dt-button {
      margin-top: 5px;
    }
  }
}
.dataTables_wrapper {
 .dataTables_filter {
  label {
    margin-bottom: 10px;
  }
 } 
}

.table-scrollable .dataTable {
  td > .btn-group,
  th > .btn-group {
    position: absolute;
    margin-top: -2px;
  }
}

.dataTable td .popover-content .btn-group {
  position: static !important;
}

.table .btn-group .btn {
  margin-left: 0;
  margin-right: -1px;
}

.dropzone {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.dropzone-file-area {
  border: 2px dashed #028AF4;
  background: #fff;
  padding: 20px;
  margin: 0 auto;
  text-align: center;
}

.dz-hidden-input {
  left: 0;
}
@media (max-width: 768px) {
  .dropzone-file-area {
    width: auto;
  }
}

.fancybox-overlay {
  z-index: 100000;
}

.fancybox-opened {
  z-index: 100001;
}

.spinner-buttons.btn-group-vertical .btn {
  text-align: center;
  margin: 0;
  height: 17px;
  width: 22px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 0;
}

.page-style-rounded .spinner-buttons.btn-group-vertical .btn {
  &.spinner-up {
    border-radius: 0;
    border-top-right-radius: 4px !important;
  }

  &.spinner-down {
    border-radius: 0 0 4px;
  }
}

.external-event {
  display: inline-block;
  cursor: move;
  margin-bottom: 5px;
  margin-left: 5px;
}

.fc-scroller {
  overflow-y: auto;
  overflow-x: hidden;
}

.fc-month-view .fc-scroller {
  height: auto !important;
}

.portlet {
  .event-form-title {
    font-size: 16px;
    margin-top: 4px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  &.calendar {
    .fc-button {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      text-shadow: none;
      border: 0 #ddd;
      height: 35px;
      padding: 5px 8px 8px;
      margin-left: 2px;
      background: 0 0;
      color: #fff;
      top: -48px;
      outline: 0 !important;
      -webkit-border-radius: 4px 4px 0 0;
      -moz-border-radius: 4px 4px 0 0;
      -ms-border-radius: 4px 4px 0 0;
      -o-border-radius: 4px 4px 0 0;
      border-radius: 4px 4px 0 0;
      border-style: none solid;
    }

    .fc-toolbar {
      margin-bottom: 0;

      h2 {
        margin-top: 3px;
        font-size: 17px;
      }
    }

    .fc-button {
      .fc-icon-left-single-arrow,
      .fc-icon-right-single-arrow {
        font-family: FontAwesome;
        font-size: 16px;
        display: inline-block;
      }
    }

    .fc-header {
      margin-bottom: -21px;
    }

    .fc-button-prev {
      padding-right: 10px;
      padding-left: 8px;
    }

    .fc-button-next {
      padding-right: 8px;
      padding-left: 10px;
    }

    .fc-button {
      .fc-icon {
        height: 20px;
        top: -5px;
      }

      &.fc-state-active,
      &.fc-state-hover {
        color: #666;
        background-color: #fff;
      }

      &.fc-state-disabled {
        color: #ddd;
      }

      .fc-icon-left-single-arrow {
        &:after {
          content: "";
          margin: 0;
        }

        &:before {
          content: "\f104";
        }
      }

      .fc-icon-right-single-arrow {
        &:after {
          margin: 0;
          content: "";
        }

        &:before {
          content: "\f105";
        }
      }
    }

    .fc-text-arrow {
      font-size: 22px;
      font-family: "Courier New",Courier,monospace;
      vertical-align: baseline;
    }

    .fc-event {
      border: 0;
      background-color: #69a4e0;
      color: #fff;

      .fc-content {
        border: 0;
        padding: 5px 7px;
      }

      .fc-time {
        float: left;
        text-align: left;
        color: #fff;
        font-size: 14px;
        font-weight: 300;
        padding-right: 5px;
      }

      .fc-title {
        text-align: left;
        float: left;
        color: #fff;
        font-size: 14px;
        font-weight: 300;
      }
    }

    .fc-header-title h2 {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: #111;
    }

    .fc-widget-header {
      background-image: none;
      filter: none;
      background-color: #eee;
      text-transform: uppercase;
      font-weight: 300;
    }

    &.light .fc-button {
      top: -60px;
      color: #666;
      text-transform: uppercase;
      font-size: 12px;
      padding-bottom: 35px;

      .fc-text-arrow {
        margin-top: -6px;
        display: inline-block;
      }

      &.fc-state-active,
      &.fc-state-hover {
        color: #333;
        border-bottom: 2px solid #36c6d3;
      }

      &.fc-state-disabled {
        color: #aaa;
      }
    }

    .mobile {
      .fc-button {
        padding: 0 6px 20px;
        margin-left: 2px;
        border: 0;
        background-color: #ddd;
        background-image: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        color: #000;
        text-shadow: none;
        text-align: center;

        &.fc-state-active,
        &.fc-state-hover {
          background-color: #eee;
        }

        &.fc-state-disabled {
          color: #bbb;
        }
      }

      .fc-button-prev {
        margin-right: 5px;
        margin-top: -2px;
      }

      .fc-button-next {
        margin-right: 0;
        margin-top: -2px;
      }

      .fc-header-space {
        margin: 0;
        padding: 0;
        width: 0;
      }

      .fc-header-left {
        position: absolute;
        z-index: 10;

        .fc-button {
          top: -2px;
        }
      }

      .fc-header-right {
        position: relative;
        right: 0;

        .fc-button {
          top: 35px;
        }
      }

      .fc-content {
        margin-top: 53px;
      }
    }
  }
}

.gmaps {
  height: 300px;
  width: 100%;

  img {
    max-width: none;
  }
}

#gmap_static div {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: block;
  height: 300px;
}

#gmap_routes_instructions {
  margin-top: 10px;
  margin-bottom: 0;
}

#gritter-notice-wrapper {
  right: 1px !important;
}

.gritter-close {
  left: auto !important;
  right: 3px !important;
}

.gritter-title {
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 300;
}

.easy-pie-chart .number {
  font-size: 14px !important;
  position: relative;
  text-align: center;
  height: 75px;
  line-height: 75px;

  canvas {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.blueimp-gallery {
  .close {
    background-image: url("../img/portlet-remove-icon-white.png") !important;
    margin-top: -2px;
  }

  .next,
  .prev {
    border-radius: 23px !important;
  }
}

.ms-container {
  .ms-list {
    border: 1px solid #e5e5e5;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .ms-optgroup-label {
    font-size: 14px;
  }
}

.jquery-notific8-message {
  font-size: 13px;
}

.ms-container {
  .ms-selectable li.ms-elem-selectable,
  .ms-selection li.ms-elem-selection {
    font-size: 13px;
  }

  .ms-list.ms-focus {
    border-color: #999;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .ms-selectable li.ms-hover,
  .ms-selection li.ms-hover {
    color: #333;
    background-color: #eee;
  }

  .form-control {
    margin-bottom: 5px;
  }
}

[class*=jquery-notific8] {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;

  &:after,
  &:before {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
}

.left .jquery-notific8-close-sticky span,
.right .jquery-notific8-close-sticky span {
  font-size: 10px;
}

.jquery-notific8-heading {
  font-weight: 300;
  font-size: 16px;
}

.password-strength {
  .password-verdict {
    display: inline-block;
    margin-top: 6px;
    margin-left: 5px;
  }

  .progress {
    margin-top: 5px;
    margin-bottom: 0;
  }

  .progress-bar {
    padding: 2px;
  }
}

.scroller {
  padding: 0 12px 0 0;
  margin: 0;
  overflow: hidden;
}

.scroller-footer {
  margin-top: 10px;

  &:after,
  &:before {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}

.portlet-body .slimScrollBar {
  margin-right: 0;
  opacity: 0.6 !important;
  border-radius: 5px !important;
}

.jqstooltip {
  width: auto !important;
  height: auto !important;
}

.easy-pie-chart {
  text-align: center;
}

.sparkline-chart {
  text-align: center;
  margin-top: 15px;
  position: relative;
}

.easy-pie-chart .number {
  font-weight: 300;
  width: 85px;
  margin: 0 auto;
}

.sparkline-chart .number {
  width: 100px;
  margin: 0 auto 10px;
}

.easy-pie-chart .title,
.sparkline-chart .title {
  display: block;
  text-align: center;
  color: #333;
  font-weight: 300;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.easy-pie-chart .title:hover,
.sparkline-chart .title:hover {
  color: #666;
  text-decoration: none;
}

.easy-pie-chart .title > i,
.sparkline-chart .title > i {
  margin-top: 5px;
}

.slider {
  border: 0;
  padding: 0;
  display: block;
  margin: 12px 5px;
  min-height: 11px;
}

.ui-slider-vertical {
  width: 11px;
}

.ui-slider-horizontal .ui-slider-handle {
  top: -3px;
}

.ui-slider-vertical .ui-slider-handle {
  left: -3px;
}

.ui-slider-handle,
.ui-slider-vertical {
  filter: none !important;
  background-image: none !important;
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  height: 16px;
  width: 16px;
  background-color: #666;
}

.vmaps {
  position: relative;
  overflow: hidden;
  height: 300px;
}

.jstree-default {
  .jstree-clicked {
    border: 0;
    background-color: #e1e1e1;
    box-shadow: none;
  }

  .jstree-hovered {
    border: 0;
    background-color: #eee;
    box-shadow: none;
  }

  .jstree-wholerow-clicked {
    background: #e1e1e1;
    border: 0;
    box-shadow: none;
  }
}

.jstree-wholerow .jstree-wholerow-clicked {
  background: #e1e1e1;
  border: 0;
  box-shadow: none;
}

.jstree-default .jstree-wholerow-hovered,
.jstree-wholerow .jstree-wholerow-hovered {
  border: 0;
  background-color: #eee;
  box-shadow: none;
}

.jstree-icon.icon-lg {
  margin-top: 1px;
}

.jstree-open > .jstree-anchor > .fa-folder:before {
  margin-left: 2px;
  content: "\f07c";
}

.jstree-default.jstree-rtl .jstree-last {
  background: no-repeat;
}

.vakata-context {
  padding: 0;
  min-width: 125px;
  background-color: #fff;
  font-size: 14px;
  font-family: "Segoe UI",Helvetica,Arial,sans-serif;
  box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
  border: 1px solid #efefef;

  ul {
    padding: 0;
    min-width: 125px;
    background-color: #fff;
    font-size: 14px;
    font-family: "Segoe UI",Helvetica,Arial,sans-serif;
    box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
    border: 1px solid #efefef;
  }
}

// .morris-hover.morris-default-style .morris-hover-point {
//   font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
// }

// .select2-container--bootstrap {
//   .select2-results__group,
//   .select2-selection {
//     font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
//   }
// }

.vakata-context {
  li {
    border: 0;

    a {
      padding: 0 10px;
      border: 0;

      .vakata-contextmenu-sep,
      i {
        display: none;
      }
    }
  }

  .vakata-context-hover > a,
  li a:hover {
    background-color: #eee;
    color: #333;
    box-shadow: none;
    margin: 0;
  }

  .vakata-context-hover > a {
    .ins,
    .span {
      border: 0 !important;
    }
  }

  li a {
    &:hover {
      .ins,
      .span {
        border: 0 !important;
      }
    }

    ins,
    span {
      display: none;
      border: 0 !important;
    }
  }

  .vakata-context-separator a {
    margin: 0;
    border: 0;
  }
}

.vakata-context-rtl .vakata-context-separator a {
  margin: 0;
  border: 0;
}

.jstree-rename-input {
  background-color: #fff !important;
  border: 1px solid #e5e5e5 !important;
  outline: 0 !important;
  padding: 2px 6px !important;
  margin-right: (-4px) !important;
}

.noUi-horizontal {
  margin: 9px 0;
}

.noUi-primary .noUi-connect {
  background: #337ab7;
}

.noUi-info .noUi-connect {
  background: #659be0;
}

.noUi-success .noUi-connect {
  background: #36c6d3;
}

.noUi-warning .noUi-connect {
  background: #F1C40F;
}

.noUi-danger .noUi-connect {
  background: #ed6b75;
}

.noUi-default .noUi-connect {
  background: #bac3d0;
}

.noui-connect {
  .noUi-origin {
    right: auto;
    width: 0;
  }

  .connect {
    position: absolute;
    top: 0;
    bottom: 0;
    background: #80C9F5;
    box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45);
  }

  &.noUi-state-tap .connect {
    -webkit-transition: left 0.3s,right 0.3s;
    transition: left 0.3s,right 0.3s;
  }
}

.socicon-btn {
  transition: background,color 0.3s;

  &:hover {
    transition: background,color 0.3s;
  }
}

.noUi-handle .noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  font-size: 12px;
  border-radius: 3px;
  background: #fff;
  top: -55px;
  padding: 5px;
  left: -10px;
  text-align: center;
  min-width: 50px;

  strong {
    display: block;
    padding: 2px;
  }
}

.form-recaptcha-img {
  margin-bottom: 10px;
  clear: both;
  border: 1px solid #e5e5e5;
  padding: 5px;
}

iframe[src="about:blank"] {
  display: none;
}

.select2-container--bootstrap {
  &.select2-container--focus .select2-selection,
  &.select2-container--open .select2-selection,
  .select2-search--dropdown .select2-search__field,
  .select2-selection {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .select2-selection--multiple .select2-selection__choice__remove {
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    margin-right: 3px;
  }

  .select2-results__group {
    display: block;
    font-size: 12px;
    white-space: nowrap;
    font-weight: 600;
  }
}

.modal-open {
  .select2-dropdown {
    z-index: 10060;
  }

  .select2-close-mask {
    z-index: 10055;
  }

  .select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field {
    width: auto !important;
  }
}

.select2-result-repository {
  padding-top: 4px;
  padding-bottom: 3px;
}

.select2-result-repository__avatar {
  float: left;
  width: 60px;
  margin-right: 10px;

  img {
    width: 100%;
    height: auto;
    border-radius: 2px;
  }
}

.select2-result-repository__meta {
  margin-left: 70px;
}

.select2-result-repository__title {
  color: #000;
  font-weight: 700;
  word-wrap: break-word;
  line-height: 1.1;
  margin-bottom: 4px;
}

.select2-result-repository__forks,
.select2-result-repository__stargazers {
  margin-right: 1em;
}

.select2-result-repository__forks,
.select2-result-repository__stargazers,
.select2-result-repository__watchers {
  display: inline-block;
  color: #aaa;
  font-size: 11px;
}

.select2-result-repository__description {
  font-size: 13px;
  color: #777;
  margin-top: 4px;
}

.select2-results__option--highlighted {
  .select2-result-repository__title {
    color: #fff;
  }

  .select2-result-repository__description,
  .select2-result-repository__forks,
  .select2-result-repository__stargazers,
  .select2-result-repository__watchers {
    color: #c1d7e9;
  }
}

.select2-selection__choice {
  background-color: #eee !important;
  border: 1px solid #eee !important;

  .select2-selection__choice__remove {
    font-weight: 400 !important;
  }
}

.portlet-body-morris-fit {
  margin-right: -20px;
  margin-left: -20px;
  margin-bottom: -15px;

  > svg {
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    -ms-border-radius: 0 0 4px 4px;
    -o-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
  }
}

.morris-hover.morris-default-style {
  .morris-hover-row-label {
    text-align: left;
    font-weight: 400;
    font-size: 15px;
    color: #7D8C9D;
    // font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  }

  .morris-hover-point {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
  }
}

.socicon,
.socicon-btn {
  position: relative;
  font-family: socicon;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.icheck-list > label {
  display: block;
  margin-bottom: 8px;
}

.icheck-colors > li,
.icheck-inline > label,
.socicon,
.socicon-btn {
  display: inline-block;
}

.icheck-list > label:last-child {
  margin-bottom: 0;
}

.form-horizontal .icheck-inline {
  margin-top: 8px;
}

.icheck-inline > label {
  margin-left: 15px;

  &:first-child {
    margin-left: 0;
  }
}

div {
  &[class*=icheckbox_],
  &[class*=iradio_] {
    margin-right: 5px;
    top: (-1px) !important;
  }
}

.icheck-colors {
  padding: 0;
  margin: 0;
  list-style: none;

  > li {
    padding: 0;
    margin: 4px;
    float: left;
    height: 20px;
    width: 20px;
    background: #000;
    opacity: 0.6;
    filter: alpha(opacity = 60);

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      opacity: 1;
      filter: alpha(opacity = 100);
      cursor: pointer;
    }

    &.active {
      height: 26px;
      margin-top: 0;
      opacity: 0.6;
      filter: alpha(opacity = 60);
    }

    &.red {
      background: #d54e21;
    }

    &.green {
      background: #78a300;
    }

    &.blue {
      background: #0e76a8;
    }

    &.aero {
      background: #9cc2cb;
    }

    &.grey {
      background: #73716e;
    }

    &.orange {
      background: #f70;
    }

    &.yellow {
      background: #fc0;
    }

    &.pink {
      background: #ff66b5;
    }

    &.purple {
      background: #6a5a8c;
    }
  }
}

.ui-select-match-close {
  margin-top: 5px !important;
  margin-left: 5px !important;
}

.minicolors-swatch {
  border: 0;
}

.CodeMirror {
  border: 1px solid #eee;
  height: auto;
}

pre[class*=language-] {
  padding: 10px;
  background: 0 0 !important;
  border: 1px solid #f1f4f7 !important;
  box-shadow: none !important;
  margin: 0;

  code {
    background: 0 0 !important;
    box-shadow: none !important;
    border: 0 !important;
  }
}

.socicon {
  top: 1px;
}

.socicon-btn {
  &:empty .socicon:empty {
    width: 1em;
  }
  text-align: center;
  width: 44px;
  height: 44px;
  padding-top: 11px;
  font-size: 24px;
  border: 1px solid #e7ecf1;
  color: #3f444a;

  &.socicon-solid {
    border: 0;
    width: 46px;
    height: 46px;
    padding-top: 12px;
  }

  &.socicon-btn-circle {
    border-radius: 44px !important;
  }

  &:hover {
    text-decoration: none;
  }

  &.socicon-lg {
    width: 54px;
    height: 54px;
    padding-top: 13px;
    font-size: 28px;

    &.socicon-solid {
      border: 0;
      width: 55px;
      height: 55px;
      padding-top: 13px;
    }
  }

  &.socicon-sm {
    width: 30px;
    height: 30px;
    padding-top: 8px;
    font-size: 16px;

    &.socicon-solid {
      border: 0;
      width: 30px;
      height: 30px;
      padding-top: 8px;
    }
  }
}

.socicons .socicon-btn {
  margin-bottom: 5px;
}

.socicon-twitter:before {
  content: "a";
}

.socicon-facebook:before {
  content: "b";
}

.socicon-google:before {
  content: "c";
}

.socicon-pinterest:before {
  content: "d";
}

.socicon-foursquare:before {
  content: "e";
}

.socicon-yahoo:before {
  content: "f";
}

.socicon-skype:before {
  content: "g";
}

.socicon-yelp:before {
  content: "h";
}

.socicon-feedburner:before {
  content: "i";
}

.socicon-linkedin:before {
  content: "j";
}

.socicon-viadeo:before {
  content: "k";
}

.socicon-xing:before {
  content: "l";
}

.socicon-myspace:before {
  content: "m";
}

.socicon-soundcloud:before {
  content: "n";
}

.socicon-spotify:before {
  content: "o";
}

.socicon-grooveshark:before {
  content: "p";
}

.socicon-lastfm:before {
  content: "q";
}

.socicon-youtube:before {
  content: "r";
}

.socicon-vimeo:before {
  content: "s";
}

.socicon-dailymotion:before {
  content: "t";
}

.socicon-vine:before {
  content: "u";
}

.socicon-flickr:before {
  content: "v";
}

.socicon-500px:before {
  content: "w";
}

.socicon-instagram:before {
  content: "x";
}

.socicon-wordpress:before {
  content: "y";
}

.socicon-tumblr:before {
  content: "z";
}

.socicon-blogger:before {
  content: "A";
}

.socicon-technorati:before {
  content: "B";
}

.socicon-reddit:before {
  content: "C";
}

.socicon-dribbble:before {
  content: "D";
}

.socicon-stumbleupon:before {
  content: "E";
}

.socicon-digg:before {
  content: "F";
}

.socicon-envato:before {
  content: "G";
}

.socicon-behance:before {
  content: "H";
}

.socicon-delicious:before {
  content: "I";
}

.socicon-deviantart:before {
  content: "J";
}

.socicon-forrst:before {
  content: "K";
}

.socicon-playstore:before {
  content: "L";
}

.socicon-zerply:before {
  content: "M";
}

.socicon-wikipedia:before {
  content: "N";
}

.socicon-apple:before {
  content: "O";
}

.socicon-flattr:before {
  content: "P";
}

.socicon-github:before {
  content: "Q";
}

.socicon-chimein:before {
  content: "R";
}

.socicon-friendfeed:before {
  content: "S";
}

.socicon-newsvine:before {
  content: "T";
}

.socicon-identica:before {
  content: "U";
}

.socicon-bebo:before {
  content: "V";
}

.socicon-zynga:before {
  content: "W";
}

.socicon-steam:before {
  content: "X";
}

.socicon-xbox:before {
  content: "Y";
}

.socicon-windows:before {
  content: "Z";
}

.socicon-outlook:before {
  content: "1";
}

.socicon-coderwall:before {
  content: "2";
}

.socicon-tripadvisor:before {
  content: "3";
}

.socicon-appnet:before {
  content: "4";
}

.socicon-goodreads:before {
  content: "5";
}

.socicon-tripit:before {
  content: "6";
}

.socicon-lanyrd:before {
  content: "7";
}

.socicon-slideshare:before {
  content: "8";
}

.socicon-buffer:before {
  content: "9";
}

.socicon-rss:before {
  content: ",";
}

.socicon-vkontakte:before {
  content: ";";
}

.socicon-disqus:before {
  content: ":";
}

.socicon-houzz:before {
  content: "+";
}

.socicon-mail:before {
  content: "@";
}

.socicon-patreon:before {
  content: "=";
}

.socicon-paypal:before {
  content: "-";
}

.socicon-playstation:before {
  content: "^";
}

.socicon-smugmug:before {
  content: "¨";
}

.socicon-swarm:before {
  content: "$";
}

.socicon-triplej:before {
  content: "*";
}

.socicon-yammer:before {
  content: "&";
}

.socicon-stackoverflow:before {
  content: "(";
}

.socicon-drupal:before {
  content: "#";
}

.socicon-odnoklassniki:before {
  content: ".";
}

.socicon-android:before {
  content: "_";
}

.socicon-meetup:before {
  content: "]";
}

.socicon-persona:before {
  content: ")";
}
