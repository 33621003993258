@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700&subset=all);

@import 'font-awesome';
@import 'bootstrap.min';
@import 'components.min';
@import 'plugins.min';

/****** Custom Css *******/

@import 'login';

/****** Custom Css *******/



