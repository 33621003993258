body,
h1,
h2,
h3,
h4,
h5,
h6 {
    // font-family: "Open Sans",sans-serif;
    // font-family: 'Poppins', sans-serif;
    // font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    letter-spacing: 0.7px;
}

.bold.btn,
.sbold.btn {
    letter-spacing: 0;
}

a,
button,
code,
div,
img,
input,
label,
li,
p,
pre,
select,
span,
svg,
table,
td,
textarea,
th,
ul {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

.img-circle {
    border-radius: 50% !important;
}

.img-rounded {
    border-radius: 6px !important;
}

body {
    color: #000;
    padding: 0 !important;
    margin: 0 !important;
    direction: "ltr";
    font-size: 14px;
    overflow-x: hidden;
}

@-ms-viewport {
    width: device-width;
}

@-o-viewport {
    width: device-width;
}

@viewport {
    width: device-width;
}

@-ms-viewport {
    width: auto !important;
}

.md-shadow-z-1 {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.18);
}

.md-shadow-z-1-i {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.18) !important;
}

.md-shadow-z-1-hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.14), 0 1px 2px rgba(0, 0, 0, 0.22);
}

.md-shadow-z-2 {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.22);
}

.md-shadow-z-2-i {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.22) !important;
}

.md-shadow-z-2-hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.26);
}

.md-shadow-z-3 {
    box-shadow: 0 8px 18px rgba(0, 0, 0, 0.18), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.md-shadow-z-3-i {
    box-shadow: 0 8px 18px rgba(0, 0, 0, 0.18), 0 6px 6px rgba(0, 0, 0, 0.24) !important;
}

.md-shadow-z-3-hover {
    box-shadow: 0 8px 18px rgba(0, 0, 0, 0.22), 0 6px 6px rgba(0, 0, 0, 0.26);
}

.md-shadow-z-4 {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.26), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.md-shadow-z-4-i {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.26), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
}

.md-shadow-z-5 {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.28), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.md-shadow-z-5-i {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.28), 0 15px 12px rgba(0, 0, 0, 0.22) !important;
}

.md-shadow-none {
    box-shadow: none !important;
}

.md-click-circle {
    display: block;
    position: absolute;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: scale(0);
    animation: scale(0);
    -moz-transform: scale(0);
    transform: scale(0);
    &.md-click-animate {
        -webkit-animation: mdClickEffect .65s linear;
        -moz-animation: mdClickEffect .65s linear;
        animation: mdClickEffect .65s linear;
    }
}

@-webkit-keyframes mdClickEffect {
    0% {
        opacity: 1;
        -webkit-transform: scale(0);
    }
    100% {
        opacity: 0 !important;
        -webkit-transform: scale(2.5);
    }
}

@-moz-keyframes mdClickEffect {
    0% {
        opacity: 1;
        -webkit-transform: scale(0);
    }
    100% {
        opacity: 0 !important;
        -moz-transform: scale(2.5);
    }
}

@keyframes mdClickEffect {
    0% {
        opacity: 1;
        -webkit-transform: scale(0);
    }
    100% {
        opacity: 0 !important;
        transform: scale(2.5);
    }
}

.form-group.form-md-line-input .form-control {
    &.edited:not([readonly])~label,
    &:focus:not([readonly])~label {
        opacity: 1;
        filter: alpha(opacity=100);
    }
    ~ {
        .help-block-error,
        label {
            opacity: 1;
            filter: alpha(opacity=100);
        }
    }
}

.form-horizontal .form-group.form-md-line-input>label {
    opacity: 1;
    filter: alpha(opacity=100);
}

.form-group.form-md-line-input {
    position: relative;
    margin: 0 0 35px;
    padding-top: 20px;
}

.form-horizontal .form-group.form-md-line-input {
    padding-top: 10px;
    margin: 0 -15px 20px;
    >label {
        padding-top: 5px;
        font-size: 14px;
        color: #888;
    }
}

.form-group.form-md-line-input {
    .form-control {
        background: 0 0;
        border: 0;
        border-bottom: 1px solid #c2cad8;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0;
        color: #555;
        box-shadow: none;
        padding-left: 0;
        padding-right: 0;
        font-size: 14px;
        &::-moz-placeholder {
            color: #999;
            opacity: 1;
        }
        &:-ms-input-placeholder,
        &::-webkit-input-placeholder {
            color: #999;
        }
        &.form-control-static {
            border-bottom: 0;
        }
        &.input-sm {
            font-size: 14px;
            padding: 6px 0;
        }
        &.input-lg {
            font-size: 20px;
            padding: 14px 0;
        }
    }
    .input-group {
        padding-top: 0;
        +.input-group-control {
            padding-top: 0;
        }
    }
    + {
        .input-group,
        .input-icon {
            padding-top: 0;
        }
    }
}

.form-horizontal .form-group.form-md-line-input .input-group {
    padding-top: 0;
    >.input-group-control {
        padding-top: 0;
    }
}

.form-inline .form-md-line-input {
    padding-top: 0;
}

.form-group.form-md-line-input .form-control {
    ~ {
        .form-control-focus,
        label {
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            pointer-events: none;
        }
    }
}

.form-horizontal .form-group.form-md-line-input .form-control {
    ~ {
        .form-control-focus,
        label {
            width: auto;
            left: 15px;
            right: 15px;
        }
    }
}

.form-group.form-md-line-input .form-control {
    ~ {
        .form-control-focus:after {
            content: '';
            position: absolute;
            z-index: 5;
            bottom: 0;
            left: 50%;
            height: 2px;
            width: 0;
            visibility: hidden;
            transition: .2s ease all;
        }
        label {
            &:after {
                content: '';
                position: absolute;
                z-index: 5;
                bottom: 0;
                left: 50%;
                height: 2px;
                width: 0;
                visibility: hidden;
                transition: .2s ease all;
            }
            top: 0;
            margin-bottom: 0;
            font-size: 14px;
            color: #888;
        }
    }
    &.edited:not([readonly]) {
        ~ {
            .form-control-focus,
            label {
                color: #888;
            }
        }
    }
    &:focus:not([readonly]) {
        ~ {
            .form-control-focus,
            label {
                color: #888;
            }
        }
    }
    &.edited:not([readonly]) {
        ~ {
            .form-control-focus:after,
            label:after {
                visibility: visible;
                left: 0;
                width: 100%;
                background: #36c6d3;
            }
        }
    }
    &:focus:not([readonly]) {
        ~ {
            .form-control-focus:after,
            label:after {
                visibility: visible;
                left: 0;
                width: 100%;
                background: #36c6d3;
            }
        }
    }
    &.edited:not([readonly])~.help-block,
    &:focus:not([readonly])~.help-block {
        color: #36c6d3;
        opacity: 1;
        filter: alpha(opacity=100);
    }
    &.edited:not([readonly]) {
        &:not(:focus) {
            ~ {
                .help-block,
                .help-block-error {
                    opacity: 0;
                    filter: alpha(opacity=0);
                }
            }
        }
        ~.help-block-error {
            opacity: 0;
            filter: alpha(opacity=0);
        }
    }
    &:focus:not([readonly])~.help-block-error {
        opacity: 0;
        filter: alpha(opacity=0);
    }
    &[disabled],
    &[readonly] {
        background: 0 0;
        cursor: not-allowed;
        border-bottom: 1px dashed #c2cad8;
    }
}

fieldset[disabled] .form-group.form-md-line-input .form-control {
    background: 0 0;
    cursor: not-allowed;
    border-bottom: 1px dashed #c2cad8;
}

.form-group.form-md-line-input {
    &.form-md-floating-label {
        .form-control {
            ~label {
                font-size: 16px;
                top: 25px;
                transition: .2s ease all;
                color: #999;
            }
            &.edited~label,
            &.focus:not([readonly])~label,
            &.form-control-static~label,
            &:focus:not([readonly])~label,
            &[readonly]~label {
                top: 0;
                font-size: 13px;
            }
            &.input-sm {
                ~label {
                    font-size: 14px;
                    top: 24px;
                }
                &.edited~label,
                &.focus:not([readonly])~label,
                &.form-control-static~label,
                &:focus:not([readonly])~label,
                &[readonly]~label {
                    top: 0;
                    font-size: 13px;
                }
            }
            &.input-lg {
                ~label {
                    font-size: 20px;
                    top: 30px;
                }
                &.edited~label,
                &.focus:not([readonly])~label,
                &.form-control-static~label,
                &:focus:not([readonly])~label,
                &[readonly]~label {
                    top: 0;
                    font-size: 13px;
                }
            }
        }
        .input-icon {
            >label {
                padding-left: 34px;
            }
            &.right>label {
                padding-left: 0;
                padding-right: 34px;
            }
        }
        .input-group {
            &.left-addon label {
                padding-left: 34px;
            }
            &.right-addon label {
                padding-right: 34px;
            }
        }
    }
    >.input-icon .form-control {
        padding-left: 34px;
    }
    .help-block {
        position: absolute;
        margin: 2px 0 0;
        opacity: 0;
        filter: alpha(opacity=0);
        font-size: 13px;
    }
    >.input-icon>i {
        left: 0;
        bottom: 0;
        margin: 9px 2px 10px 10px;
        color: #888;
    }
    &.has-success {
        .form-control {
            &.edited:not([readonly]) {
                ~ {
                    .help-block,
                    i,
                    label {
                        color: #27a4b0;
                    }
                }
            }
            &.focus:not([readonly]) {
                ~ {
                    .help-block,
                    i,
                    label {
                        color: #27a4b0;
                    }
                }
            }
            &.form-control-static {
                ~ {
                    .help-block,
                    i,
                    label {
                        color: #27a4b0;
                    }
                }
            }
            &:focus:not([readonly]) {
                ~ {
                    .help-block,
                    i,
                    label {
                        color: #27a4b0;
                    }
                }
            }
        }
        label {
            color: #27a4b0;
        }
    }
    >.input-icon {
        &.input-icon-lg>i {
            top: 6px;
        }
        &.input-icon-sm>i {
            top: -1px;
        }
        >label {
            margin-top: -20px;
        }
        &.right {
            .form-control {
                padding-left: 0;
                padding-right: 34px;
            }
            >i {
                left: auto;
                right: 8px;
                margin: 11px 2px 10px 10px;
            }
        }
    }
}

.form-horizontal .form-group.form-md-line-input {
    .input-group {
        > {
            .form-control-focus,
            .input-group-control>.form-control-focus {
                left: 0 !important;
                right: 0 !important;
            }
        }
    }
    .input-icon>.form-control-focus {
        left: 0 !important;
        right: 0 !important;
    }
}

.form-group.form-md-line-input .input-group {
    .input-group-control>label,
    >label {
        margin-top: -20px;
    }
    .input-group-addon {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0;
        background: 0 0;
        border: 0;
        border-bottom: 1px solid #c2cad8;
    }
    .input-group-control {
        padding-top: 0;
        position: relative;
        display: table-cell;
        vertical-align: bottom;
    }
}

.badge,
.input-inline {
    vertical-align: middle;
}

.form-group.form-md-line-input {
    .input-group {
        .input-group-btn {
            .btn {
                -webkit-border-radius: 4px;
                -moz-border-radius: 4px;
                -ms-border-radius: 4px;
                -o-border-radius: 4px;
                border-radius: 4px;
            }
            &.btn-left .btn {
                margin-right: 10px;
            }
            &.btn-right .btn {
                margin-left: 10px;
            }
        }
        .help-block {
            margin-top: 35px;
        }
        &.input-group-sm .help-block {
            margin-top: 30px;
        }
        &.input-group-lg .help-block {
            margin-top: 47px;
        }
    }
    &.has-success {
        .form-control {
            border-bottom: 1px solid #27a4b0;
            &.edited:not([readonly]) {
                ~ {
                    .form-control-focus:after,
                    label:after {
                        background: #27a4b0;
                    }
                }
            }
            &.focus:not([readonly]) {
                ~ {
                    .form-control-focus:after,
                    label:after {
                        background: #27a4b0;
                    }
                }
            }
            &.form-control-static {
                ~ {
                    .form-control-focus:after,
                    label:after {
                        background: #27a4b0;
                    }
                }
            }
            &:focus:not([readonly]) {
                ~ {
                    .form-control-focus:after,
                    label:after {
                        background: #27a4b0;
                    }
                }
            }
        }
        .input-group-addon {
            color: #27a4b0;
            border-bottom: 1px solid #27a4b0;
        }
    }
    &.has-warning {
        .form-control {
            &.edited:not([readonly]) {
                ~ {
                    .help-block,
                    i,
                    label {
                        color: #c29d0b;
                    }
                }
            }
            &.focus:not([readonly]) {
                ~ {
                    .help-block,
                    i,
                    label {
                        color: #c29d0b;
                    }
                }
            }
            &.form-control-static {
                ~ {
                    .help-block,
                    i,
                    label {
                        color: #c29d0b;
                    }
                }
            }
            &:focus:not([readonly]) {
                ~ {
                    .help-block,
                    i,
                    label {
                        color: #c29d0b;
                    }
                }
            }
        }
        label {
            color: #c29d0b;
        }
        .form-control {
            border-bottom: 1px solid #c29d0b;
            &.edited:not([readonly]) {
                ~ {
                    .form-control-focus:after,
                    label:after {
                        background: #c29d0b;
                    }
                }
            }
            &.focus:not([readonly]) {
                ~ {
                    .form-control-focus:after,
                    label:after {
                        background: #c29d0b;
                    }
                }
            }
            &.form-control-static {
                ~ {
                    .form-control-focus:after,
                    label:after {
                        background: #c29d0b;
                    }
                }
            }
            &:focus:not([readonly]) {
                ~ {
                    .form-control-focus:after,
                    label:after {
                        background: #c29d0b;
                    }
                }
            }
        }
        .input-group-addon {
            color: #c29d0b;
            border-bottom: 1px solid #c29d0b;
        }
    }
    &.has-error {
        .form-control {
            &.edited:not([readonly]) {
                ~ {
                    .help-block,
                    i,
                    label {
                        color: #e73d4a;
                    }
                }
            }
            &.focus:not([readonly]) {
                ~ {
                    .help-block,
                    i,
                    label {
                        color: #e73d4a;
                    }
                }
            }
            &.form-control-static {
                ~ {
                    .help-block,
                    i,
                    label {
                        color: #e73d4a;
                    }
                }
            }
            &:focus:not([readonly]) {
                ~ {
                    .help-block,
                    i,
                    label {
                        color: #e73d4a;
                    }
                }
            }
        }
        label {
            color: #e73d4a;
        }
        .form-control {
            border-bottom: 1px solid #e73d4a;
            &.edited:not([readonly]) {
                ~ {
                    .form-control-focus:after,
                    label:after {
                        background: #e73d4a;
                    }
                }
            }
            &.focus:not([readonly]) {
                ~ {
                    .form-control-focus:after,
                    label:after {
                        background: #e73d4a;
                    }
                }
            }
            &.form-control-static {
                ~ {
                    .form-control-focus:after,
                    label:after {
                        background: #e73d4a;
                    }
                }
            }
            &:focus:not([readonly]) {
                ~ {
                    .form-control-focus:after,
                    label:after {
                        background: #e73d4a;
                    }
                }
            }
        }
        .input-group-addon {
            color: #e73d4a;
            border-bottom: 1px solid #e73d4a;
        }
    }
    &.has-info {
        .form-control {
            &.edited:not([readonly]) {
                ~ {
                    .help-block,
                    i,
                    label {
                        color: #327ad5;
                    }
                }
            }
            &.focus:not([readonly]) {
                ~ {
                    .help-block,
                    i,
                    label {
                        color: #327ad5;
                    }
                }
            }
            &.form-control-static {
                ~ {
                    .help-block,
                    i,
                    label {
                        color: #327ad5;
                    }
                }
            }
            &:focus:not([readonly]) {
                ~ {
                    .help-block,
                    i,
                    label {
                        color: #327ad5;
                    }
                }
            }
        }
        label {
            color: #327ad5;
        }
        .form-control {
            border-bottom: 1px solid #327ad5;
            &.edited:not([readonly]) {
                ~ {
                    .form-control-focus:after,
                    label:after {
                        background: #327ad5;
                    }
                }
            }
            &.focus:not([readonly]) {
                ~ {
                    .form-control-focus:after,
                    label:after {
                        background: #327ad5;
                    }
                }
            }
            &.form-control-static {
                ~ {
                    .form-control-focus:after,
                    label:after {
                        background: #327ad5;
                    }
                }
            }
            &:focus:not([readonly]) {
                ~ {
                    .form-control-focus:after,
                    label:after {
                        background: #327ad5;
                    }
                }
            }
        }
        .input-group-addon {
            color: #327ad5;
            border-bottom: 1px solid #327ad5;
        }
    }
}

.form-inline .form-md-line-input {
    margin: 0 20px 0 0;
    >.input-icon {
        padding: 0;
    }
}

.form-horizontal .form-group.form-md-line-input {
    .input-icon {
        .form-control {
            padding-left: 33px;
        }
        >i {
            top: 0;
        }
        &.right .form-control {
            padding-left: 0;
            padding-right: 33px;
        }
    }
    .input-group>.input-group-btn .btn {
        margin-bottom: 0 !important;
    }
}

.form-md-checkboxes,
.form-md-radios {
    padding-top: 5px;
}

.md-checkbox {
    position: relative;
    &.md-checkbox-inline {
        display: inline-block;
    }
}

.form-inline .md-checkbox.md-checkbox-inline {
    margin-right: 20px;
    top: 3px;
}

.md-checkbox {
    input[type=checkbox] {
        visibility: hidden;
        position: absolute;
    }
    label {
        cursor: pointer;
        padding-left: 30px;
        > {
            span {
                display: block;
                position: absolute;
                left: 0;
                -webkit-transition-duration: .3s;
                -moz-transition-duration: .3s;
                transition-duration: .3s;
                &.inc {
                    background: #fff;
                    left: -20px;
                    top: -20px;
                    height: 60px;
                    width: 60px;
                    opacity: 0;
                    border-radius: 50% !important;
                    -moz-border-radius: 50% !important;
                    -webkit-border-radius: 50% !important;
                    -webkit-animation: growCircle .3s ease;
                    -moz-animation: growCircle .3s ease;
                    animation: growCircle .3s ease;
                }
            }
            .box {
                top: 0;
                border: 2px solid #666;
                height: 20px;
                width: 20px;
                z-index: 5;
                -webkit-transition-delay: .2s;
                -moz-transition-delay: .2s;
                transition-delay: .2s;
            }
            .check {
                top: -4px;
                left: 6px;
                width: 10px;
                height: 20px;
                border: 2px solid #36c6d3;
                border-top: none;
                border-left: none;
                opacity: 0;
                z-index: 5;
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                transform: rotate(180deg);
                -webkit-transition-delay: .3s;
                -moz-transition-delay: .3s;
                transition-delay: .3s;
            }
        }
    }
    input[type=checkbox] {
        &:checked {
            ~label {
                > {
                    .box {
                        opacity: 0;
                        -webkit-transform: scale(0) rotate(-180deg);
                        -moz-transform: scale(0) rotate(-180deg);
                        transform: scale(0) rotate(-180deg);
                    }
                    .check {
                        opacity: 1;
                        -webkit-transform: scale(1) rotate(45deg);
                        -moz-transform: scale(1) rotate(45deg);
                        transform: scale(1) rotate(45deg);
                    }
                }
            }
        }
        &:disabled,
        &[disabled] {
            &:checked~label>.check {
                cursor: not-allowed;
                opacity: .7;
                filter: alpha(opacity=70);
            }
            ~label {
                cursor: not-allowed;
                opacity: .7;
                filter: alpha(opacity=70);
                >.box {
                    cursor: not-allowed;
                    opacity: .7;
                    filter: alpha(opacity=70);
                }
            }
        }
    }
}

.has-error {
    .md-checkbox label,
    &.md-checkbox label {
        color: #e73d4a;
    }
    .md-checkbox label {
        > {
            .box,
            .check {
                border-color: #e73d4a;
            }
        }
    }
    &.md-checkbox label {
        > {
            .box,
            .check {
                border-color: #e73d4a;
            }
        }
    }
}

.has-success {
    .md-checkbox label,
    &.md-checkbox label {
        color: #27a4b0;
    }
    .md-checkbox label {
        > {
            .box,
            .check {
                border-color: #27a4b0;
            }
        }
    }
    &.md-checkbox label {
        > {
            .box,
            .check {
                border-color: #27a4b0;
            }
        }
    }
}

.has-warning {
    .md-checkbox label,
    &.md-checkbox label {
        color: #c29d0b;
    }
    .md-checkbox label {
        > {
            .box,
            .check {
                border-color: #c29d0b;
            }
        }
    }
    &.md-checkbox {
        label {
            > {
                .box,
                .check {
                    border-color: #c29d0b;
                }
            }
        }
    }
}

.has-info {
    .md-checkbox label,
    &.md-checkbox label {
        color: #327ad5;
    }
    .md-checkbox label {
        > {
            .box,
            .check {
                border-color: #327ad5;
            }
        }
    }
    &.md-checkbox label {
        > {
            .box,
            .check {
                border-color: #327ad5;
            }
        }
    }
}

.form-md-checkboxes {
    >label {
        font-size: 14px;
        color: #888;
        opacity: 1;
        filter: alpha(opacity=100);
    }
    &.has-error label {
        color: #ed6b75;
    }
    &.has-info label {
        color: #659be0;
    }
    &.has-success label {
        color: #36c6d3;
    }
    &.has-warning label {
        color: #F1C40F;
    }
}

.md-checkbox-list {
    margin: 5px 0;
}

.form-horizontal .md-checkbox-list {
    margin-top: 5px;
}

.md-checkbox-list .md-checkbox {
    display: block;
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
}

.md-checkbox-inline {
    margin: 5px 0;
}

.form-horizontal .md-checkbox-inline {
    margin-top: 7px;
}

.md-checkbox-inline .md-checkbox {
    display: inline-block;
    margin-right: 20px;
    &:last-child {
        margin-right: 0;
    }
}

@-webkit-keyframes growCircle {
    0%,
    100% {
        -webkit-transform: scale(0);
        opacity: 1;
    }
    70% {
        background: #eee;
        -webkit-transform: scale(1.25);
    }
}

@-moz-keyframes growCircle {
    0%,
    100% {
        -moz-transform: scale(0);
        opacity: 1;
    }
    70% {
        background: #eee;
        -moz-transform: scale(1.25);
    }
}

@keyframes growCircle {
    0%,
    100% {
        transform: scale(0);
        opacity: 1;
    }
    70% {
        background: #eee;
        transform: scale(1.25);
    }
}

.md-radio {
    position: relative;
    input[type=radio] {
        visibility: hidden;
        position: absolute;
    }
    label {
        cursor: pointer;
        padding-left: 30px;
        > {
            span {
                display: block;
                position: absolute;
                left: 0;
                -webkit-transition-duration: .3s;
                -moz-transition-duration: .3s;
                transition-duration: .3s;
                &.inc {
                    background: #fff;
                    left: -20px;
                    top: -20px;
                    height: 60px;
                    width: 60px;
                    opacity: 0;
                    border-radius: 50% !important;
                    -moz-border-radius: 50% !important;
                    -webkit-border-radius: 50% !important;
                    -webkit-animation: growCircleRadio .3s ease;
                    -moz-animation: growCircleRadio .3s ease;
                    animation: growCircleRadio .3s ease;
                }
            }
            .box {
                top: 0;
                border: 2px solid #666;
                height: 20px;
                width: 20px;
                border-radius: 50% !important;
                -moz-border-radius: 50% !important;
                -webkit-border-radius: 50% !important;
                z-index: 5;
            }
            .check {
                top: 5px;
                left: 5px;
                width: 10px;
                height: 10px;
                background: #36c6d3;
                opacity: 0;
                z-index: 6;
                border-radius: 50% !important;
                -moz-border-radius: 50% !important;
                -webkit-border-radius: 50% !important;
                -webkit-transform: scale(0);
                -moz-transform: scale(0);
                transform: scale(0);
            }
        }
    }
    input[type=radio] {
        &:checked~label>.check {
            opacity: 1;
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            transform: scale(1);
        }
        &:disabled,
        &[disabled] {
            &:checked~label>.check {
                cursor: not-allowed;
                opacity: .7;
                filter: alpha(opacity=70);
            }
            ~label {
                cursor: not-allowed;
                opacity: .7;
                filter: alpha(opacity=70);
                >.box {
                    cursor: not-allowed;
                    opacity: .7;
                    filter: alpha(opacity=70);
                }
            }
        }
    }
}

.has-error {
    .md-radio label,
    &.md-radio label {
        color: #e73d4a;
    }
    .md-radio label>.box,
    &.md-radio label>.box {
        border-color: #e73d4a;
    }
    .md-radio label>.check,
    &.md-radio label>.check {
        background: #e73d4a;
    }
}

.has-success {
    .md-radio label,
    &.md-radio label {
        color: #27a4b0;
    }
    .md-radio label>.box,
    &.md-radio label>.box {
        border-color: #27a4b0;
    }
    .md-radio label>.check,
    &.md-radio label>.check {
        background: #27a4b0;
    }
}

.has-warning {
    .md-radio label,
    &.md-radio label {
        color: #c29d0b;
    }
    .md-radio label>.box,
    &.md-radio label>.box {
        border-color: #c29d0b;
    }
    .md-radio label>.check,
    &.md-radio label>.check {
        background: #c29d0b;
    }
}

.has-info {
    .md-radio label,
    &.md-radio label {
        color: #327ad5;
    }
    .md-radio label>.box,
    &.md-radio label>.box {
        border-color: #327ad5;
    }
    .md-radio label>.check,
    &.md-radio label>.check {
        background: #327ad5;
    }
}

.form-md-radios {
    >label {
        font-size: 14px;
        color: #888;
        opacity: 1;
        filter: alpha(opacity=100);
    }
    &.has-error label {
        color: #ed6b75;
    }
    &.has-info label {
        color: #659be0;
    }
    &.has-success label {
        color: #36c6d3;
    }
    &.has-warning label {
        color: #F1C40F;
    }
}

.md-radio-list {
    margin: 5px 0;
}

.form-horizontal .md-radio-list {
    margin-top: 5px;
}

.md-radio-list .md-radio {
    display: block;
    margin-bottom: 10px;
}

.md-radio-inline {
    margin: 5px 0;
}

.form-horizontal .md-radio-inline {
    margin-top: 7px;
}

.md-radio-inline .md-radio {
    display: inline-block;
    margin-right: 20px;
    &:last-child {
        margin-right: 0;
    }
}

hr,
p {
    margin: 20px 0;
}

@-webkit-keyframes growCircleRadio {
    0%,
    100% {
        -webkit-transform: scale(0);
        opacity: 1;
    }
    70% {
        background: #eee;
        -webkit-transform: scale(1.25);
    }
}

@-moz-keyframes growCircleRadio {
    0%,
    100% {
        -moz-transform: scale(0);
        opacity: 1;
    }
    70% {
        background: #eee;
        -moz-transform: scale(1.25);
    }
}

@keyframes growCircleRadio {
    0%,
    100% {
        transform: scale(0);
        opacity: 1;
    }
    70% {
        background: #eee;
        transform: scale(1.25);
    }
}

.primary-link {
    color: #65A0D0;
    font-weight: 600;
    &:hover {
        color: #5194ca;
    }
}

label {
    font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 300;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 30px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 14px;
}

h6 {
    font-size: 12px;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
    color: #444;
}

h1.block,
h2.block,
h3.block,
h4.block,
h5.block,
h6.block {
    padding-top: 10px;
    padding-bottom: 10px;
}

a {
    text-shadow: none;
    color: #000;
    &:hover {
        cursor: pointer;
        color: #333;
    }
    &:active,
    &:focus,
    &:hover {
        outline: 0;
    }
}

hr {
    border: 0;
    border-top: 1px solid #eee;
    border-bottom: 0;
}

.list-unstyled li>.list-unstyled {
    margin-left: 25px;
}

code {
    border: 1px solid #e1e1e1;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.btn,
.form-control {
    box-shadow: none !important;
}

.disabled-link {
    .disable-target,
    &.disable-target {
        opacity: 0.6 !important;
        filter: alpha(opacity=60) !important;
    }
    .disable-target:hover,
    &.disable-target:hover,
    &:hover {
        cursor: not-allowed !important;
    }
}

.rounded-2 {
    border-radius: 2px !important;
}

.rounded-3 {
    border-radius: 3px !important;
}

.rounded-4 {
    border-radius: 4px !important;
}

.circle {
    border-radius: 25px !important;
}

.circle-right {
    border-radius: 0 25px 25px 0 !important;
}

.circle-left {
    border-radius: 25px 0 0 25px !important;
}

.circle-bottom {
    border-radius: 0 0 25px 25px !important;
}

.circle-top {
    border-radius: 25px 25px 0 0 !important;
}

.rounded {
    border-radius: 50% !important;
}

.display-hide,
.display-none {
    display: none;
}

.hidden {
    display: none !important;
}

.bold {
    font-weight: 700 !important;
}

.sbold {
    font-weight: 600 !important;
}

.thin {
    font-weight: 300 !important;
}

.uppercase {
    text-transform: uppercase !important;
}

.fix-margin {
    margin-left: 0 !important;
}

.border {
    border: 1px solid red;
}

.font-hg {
    font-size: 23px;
}

.font-lg {
    font-size: 18px;
}

.font-md {
    font-size: 14px;
}

.font-sm {
    font-size: 13px;
}

.font-xs {
    font-size: 11px;
}

.inline {
    display: inline;
}

.inline-block {
    display: inline-block;
}

.text-align-reverse {
    text-align: right;
}

.no-space {
    margin: 0 !important;
    padding: 0 !important;
}

.no-margin {
    margin: 0;
}

.margin-bottom-5 {
    margin-bottom: 5px;
}

.margin-bottom-10 {
    margin-bottom: 10px !important;
}

.margin-top-10 {
    margin-top: 10px !important;
}

.margin-top-15 {
    margin-top: 15px !important;
}

.margin-bottom-15 {
    margin-bottom: 15px !important;
}

.margin-bottom-20 {
    margin-bottom: 20px !important;
}

.margin-top-20 {
    margin-top: 20px !important;
}

.margin-top-30 {
    margin-top: 30px !important;
}

.margin-top-40 {
    margin-top: 40px !important;
}

.margin-bottom-25 {
    margin-bottom: 25px !important;
}

.margin-bottom-30 {
    margin-bottom: 30px !important;
}

.margin-bottom-40 {
    margin-bottom: 40px !important;
}

.margin-right-10 {
    margin-right: 10px !important;
}

.visible-ie8 {
    display: none;
}

.ie8 .visible-ie8 {
    display: inherit !important;
}

.visible-ie9 {
    display: none;
}

.ie9 .visible-ie9 {
    display: inherit !important;
}

.hidden-ie8 {
    display: inherit;
}

.ie8 .hidden-ie8 {
    display: none !important;
}

.hidden-ie9 {
    display: inherit;
}

.ie9 .hidden-ie9 {
    display: none !important;
}

@media (max-width: 1024px) {
    .hidden-1024 {
        display: none;
    }
}

@media (max-width: 480px) {
    .hidden-480 {
        display: none;
    }
}

@media (max-width: 320px) {
    .hidden-320 {
        display: none;
    }
}

.scrollspy-example {
    position: relative;
    height: 200px;
    margin-top: 10px;
    overflow: auto;
}

.util-btn-group-margin-bottom-5 .btn-group,
.util-btn-margin-bottom-5 .btn {
    margin-bottom: 5px !important;
}

.padding-tb-10,
.padding-tb-15 {
    padding: 10px 0;
}

.padding-tb-20 {
    padding: 20px 0;
}

.user-info {
    margin-bottom: 10px !important;
    img {
        float: left;
        margin-right: 5px;
    }
}

.number-stats .stat-left {
    float: right;
    .stat-chart {
        float: right;
    }
}

.user-info {
    .details {
        display: inline-block;
    }
    .label {
        font-weight: 300;
        font-size: 11px;
    }
}

.ver-inline-menu {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
        position: relative;
        margin-bottom: 1px;
        i {
            width: 37px;
            height: 37px;
            display: inline-block;
            color: #b9cbd5;
            font-size: 15px;
            padding: 12px 10px 10px 8px;
            margin: 0 8px 0 0;
            text-align: center;
            background: #e0eaf0 !important;
        }
        a {
            font-size: 14px;
            font-weight: 300;
            color: #557386;
            display: block;
            background: #f0f6fa;
            border-left: solid 2px #c4d5df;
        }
        &:hover {
            a {
                background: #e0eaf0;
                text-decoration: none;
            }
            i {
                color: #fff;
                background: #c4d5df !important;
            }
        }
        &.active {
            a {
                border-left: solid 2px #0c91e5;
            }
            i {
                background: #0c91e5 !important;
            }
            a,
            i {
                color: #fff;
                background: #169ef4;
                text-decoration: none;
            }
            a {
                font-size: 14px;
            }
        }
        &:hover a {
            font-size: 14px;
        }
        &.active:after {
            content: '';
            display: inline-block;
            border-bottom: 6px solid transparent;
            border-top: 6px solid transparent;
            border-left: 6px solid #169ef4;
            position: absolute;
            top: 12px;
            right: -5px;
        }
    }
}

.list-separated {
    margin-top: 10px;
    margin-bottom: 15px;
    >div:last-child {
        border-right: 0;
    }
}

@media (max-width: 767px) {
    .ver-inline-menu>li.active:after {
        display: none;
    }
    .list-separated>div {
        margin-bottom: 20px;
    }
}

.number-stats {
    margin: 10px 0;
    .stat-number {
        display: inline-block;
        margin: 0 5px;
        .title {
            font-size: 13px;
            margin-bottom: 3px;
            color: #B8C3C7;
        }
        .number {
            font-size: 27px;
            line-height: 27px;
            color: #7D8C9D;
        }
    }
    >div {
        border-right: 1px solid #f5f5f5;
        &:last-child {
            border-right: 0;
        }
    }
    .stat-left .stat-number {
        float: right;
        text-align: right;
    }
    .stat-right {
        float: left !important;
        .stat-number {
            float: left;
            text-align: left;
        }
        .stat-chart {
            float: left;
        }
    }
    .stat-number {
        float: left;
        text-align: left;
    }
    .stat-chart {
        display: inline-block;
        margin: 0 5px;
        float: left;
    }
}

.general-item-list>.item {
    padding: 10px 0;
    border-bottom: 1px solid #F1F4F7;
    &:last-child {
        border-bottom: 0;
    }
    > {
        .item-head {
            margin-bottom: 5px;
            &:after,
            &:before {
                content: " ";
                display: table;
            }
            &:after {
                clear: both;
            }
            > {
                .item-details {
                    display: inline-block;
                    float: left;
                    > {
                        .item-pic {
                            height: 35px;
                            margin-right: 10px;
                            -webkit-border-radius: 100%;
                            -moz-border-radius: 100%;
                            -ms-border-radius: 100%;
                            -o-border-radius: 100%;
                            border-radius: 100%;
                        }
                        .item-name {
                            display: inline-block;
                            margin-right: 10px;
                        }
                        .item-label {
                            color: #C0C9CC;
                        }
                    }
                }
                .item-status {
                    color: #C0C9CC;
                    top: 10px;
                    position: relative;
                    display: inline-block;
                    float: right;
                    >.badge {
                        margin-top: -2px;
                    }
                }
            }
        }
        .item-body {
            color: #96a5aa;
        }
    }
}

.file-drop-zone {
    border: 2px dashed #ddd;
    padding: 30px;
    text-align: center;
    &.file-drop-zone-over {
        border-color: #aaa;
    }
}

[class*=" fa-"]:not(.fa-stack),
[class*=" glyphicon-"],
[class*=" icon-"],
[class^=fa-]:not(.fa-stack),
[class^=glyphicon-],
[class^=icon-] {
    display: inline-block;
    line-height: 14px;
    -webkit-font-smoothing: antialiased;
}

li {
    [class*=" fa-"],
    [class*=" glyphicon-"],
    [class*=" icon-"],
    [class^=fa-],
    [class^=glyphicon-],
    [class^=icon-] {
        display: inline-block;
        width: 1.25em;
        text-align: center;
    }
    [class*=" glyphicon-"],
    [class^=glyphicon-] {
        top: 2px;
    }
    [class*=" icon-"],
    [class^=icon-] {
        top: 1px;
        position: relative;
    }
    [class*=" fa-"].icon-large,
    [class*=" glyphicon-"].icon-large,
    [class*=" icon-"].icon-large,
    [class^=fa-].icon-large,
    [class^=glyphicon-].icon-large,
    [class^=icon-].icon-large {
        width: 1.5625em;
    }
}

.icon-state-default {
    color: #bac3d0;
}

.icon-state-success {
    color: #36c6d3;
}

.icon-state-info {
    color: #659be0;
}

.icon-state-warning {
    color: #F1C40F;
}

.icon-state-danger {
    color: #ed6b75;
}

.fa-item {
    font-size: 14px;
    padding: 10px 10px 10px 20px;
    i {
        font-size: 16px;
        display: inline-block;
        width: 20px;
        color: #333;
    }
    &:hover {
        cursor: pointer;
        background: #eee;
    }
}

.badge-danger,
.badge-default,
.badge-info,
.badge-primary,
.badge-success,
.badge-warning {
    background-image: none;
}

.btn.white:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.white:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.simplelineicons-demo .item-box {
    display: inline-block;
    font-size: 16px;
    margin: 0 -.22em 1em 0;
    padding-left: 1em;
    width: 100%;
    .item {
        background-color: #fff;
        color: #33383e;
        border-radius: 8px;
        display: inline-block;
        padding: 10px;
        width: 100%;
    }
}

.bs-glyphicons,
.glyphicons-demo ul {
    padding-left: 0;
    padding-bottom: 1px;
    margin-bottom: 20px;
    overflow: hidden;
    list-style: none;
}

.simplelineicons-demo .item-box .item span {
    font-size: 22px;
}

@media only screen and (min-width: 768px) {
    .simplelineicons-demo .item-box {
        width: 33.333%;
    }
}

.bs-glyphicon-class {
    text-align: center;
}

.glyphicons-demo {
    ul li {
        float: left;
        width: 25%;
        height: 115px;
        padding: 10px;
        margin: 0 -1px -1px 0;
        font-size: 14px;
        line-height: 1.4;
        text-align: center;
        border: 1px solid #ddd;
    }
    .glyphicon {
        display: block;
        margin: 5px auto 10px;
        font-size: 24px;
        color: #444;
    }
    ul li:hover {
        background-color: rgba(86, 61, 124, 0.1);
    }
}

.badge-default,
.label-default {
    background-color: #bac3d0;
}

@media (min-width: 768px) {
    .glyphicons-demo ul li {
        width: 12.5%;
    }
}

.alert {
    border-width: 1px;
    >p {
        margin: 0;
    }
    &.alert-borderless {
        border: 0;
    }
}

.badge {
    font-size: 11px !important;
    font-weight: 300;
    height: 18px;
    color: #fff;
    padding: 3px 6px;
    -webkit-border-radius: 12px !important;
    -moz-border-radius: 12px !important;
    border-radius: 12px !important;
    text-shadow: none !important;
    text-align: center;
    &.badge-roundless {
        -webkit-border-radius: 0 !important;
        -moz-border-radius: 0 !important;
        border-radius: 0 !important;
    }
    &.badge-empty {
        display: inline-block;
        padding: 0;
        min-width: 8px;
        height: 8px;
        width: 8px;
    }
}

.badge-primary {
    background-color: #337ab7;
}

.badge-info {
    background-color: #659be0;
}

.badge-success {
    background-color: #36c6d3;
}

.badge-danger {
    background-color: #ed6b75;
}

.badge-warning {
    background-color: #F1C40F;
}

.nav {
    &.nav-pills>li>a>.badge,
    &.nav-stacked>li>a>.badge {
        margin-top: -2px;
    }
}

.dropdown-menu {
    >li>a>.badge {
        position: absolute;
        margin-top: 1px;
        right: 3px;
        display: inline;
    }
    &.badge-roundless {
        -webkit-border-radius: 0 !important;
        -moz-border-radius: 0 !important;
        border-radius: 0 !important;
    }
}

.btn {
    outline: 0 !important;
    &:hover {
        transition: all .3s;
    }
    &:not(.btn-sm):not(.btn-lg) {
        line-height: 1.44;
    }
    &.white:not(.btn-outline) {
        color: #666;
        background-color: #fff;
        border-color: #fff;
        &.focus,
        &:focus {
            color: #666;
            background-color: #e6e6e6;
            border-color: #bfbfbf;
        }
        &.active,
        &:active,
        &:hover {
            color: #666;
            background-color: #e6e6e6;
            border-color: #e0e0e0;
        }
    }
}

.fileinput .btn {
    border-radius: 0px !important;
    // min-width: 80px;
    margin-right: 0px;
}

.open>.btn.white:not(.btn-outline).dropdown-toggle {
    color: #666;
    background-color: #e6e6e6;
    border-color: #e0e0e0;
}

.btn.white:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #666;
            background-color: #d4d4d4;
            border-color: #bfbfbf;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #666;
            background-color: #d4d4d4;
            border-color: #bfbfbf;
        }
    }
}

.open>.btn.white:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #666;
        background-color: #d4d4d4;
        border-color: #bfbfbf;
    }
}

.btn.white:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #fff;
            border-color: #fff;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #fff;
            border-color: #fff;
        }
    }
}

fieldset[disabled] .btn.white:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #fff;
        border-color: #fff;
    }
}

.btn {
    &.white:not(.btn-outline) .badge {
        color: #fff;
        background-color: #666;
    }
    &.btn-outline.white {
        border-color: #fff;
        color: #fff;
        background: 0 0;
        &.active {
            border-color: #fff;
            color: #666;
            background-color: #fff;
        }
        &:active {
            border-color: #fff;
            color: #666;
            background-color: #fff;
            &:focus,
            &:hover {
                border-color: #fff;
                color: #666;
                background-color: #fff;
            }
        }
        &:focus,
        &:hover {
            border-color: #fff;
            color: #666;
            background-color: #fff;
        }
    }
    &.white-stripe {
        border-left: 4px solid #fff !important;
    }
    &.white.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.default:not(.btn-outline) {
        color: #666;
        background-color: #e1e5ec;
        border-color: #e1e5ec;
        &.focus,
        &:focus {
            color: #666;
            background-color: #c2cad8;
            border-color: #93a1bb;
        }
        &.active,
        &:active,
        &:hover {
            color: #666;
            background-color: #c2cad8;
            border-color: #bcc5d4;
        }
    }
}

.open>.btn.default:not(.btn-outline).dropdown-toggle {
    color: #666;
    background-color: #c2cad8;
    border-color: #bcc5d4;
}

.btn.default:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #666;
            background-color: #acb7ca;
            border-color: #93a1bb;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #666;
            background-color: #acb7ca;
            border-color: #93a1bb;
        }
    }
}

.open>.btn.default:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #666;
        background-color: #acb7ca;
        border-color: #93a1bb;
    }
}

.btn.default:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.default:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.default:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #e1e5ec;
            border-color: #e1e5ec;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #e1e5ec;
            border-color: #e1e5ec;
        }
    }
}

fieldset[disabled] .btn.default:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #e1e5ec;
        border-color: #e1e5ec;
    }
}

.btn {
    &.default:not(.btn-outline) .badge {
        color: #e1e5ec;
        background-color: #666;
    }
    &.btn-outline.default {
        border-color: #e1e5ec;
        color: #e1e5ec;
        background: 0 0;
        &.active {
            border-color: #e1e5ec;
            color: #666;
            background-color: #e1e5ec;
        }
        &:active {
            border-color: #e1e5ec;
            color: #666;
            background-color: #e1e5ec;
            &:focus,
            &:hover {
                border-color: #e1e5ec;
                color: #666;
                background-color: #e1e5ec;
            }
        }
        &:focus,
        &:hover {
            border-color: #e1e5ec;
            color: #666;
            background-color: #e1e5ec;
        }
    }
    &.default-stripe {
        border-left: 4px solid #e1e5ec !important;
    }
    &.default.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.dark:not(.btn-outline) {
        color: #FFF;
        background-color: #2f353b;
        border-color: #2f353b;
        &.focus,
        &:focus {
            color: #FFF;
            background-color: #181c1f;
            border-color: #000;
        }
        &.active,
        &:active,
        &:hover {
            color: #FFF;
            background-color: #181c1f;
            border-color: #141619;
        }
    }
}

.open>.btn.dark:not(.btn-outline).dropdown-toggle {
    color: #FFF;
    background-color: #181c1f;
    border-color: #141619;
}

.btn.dark:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #090a0b;
            border-color: #000;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #090a0b;
            border-color: #000;
        }
    }
}

.open>.btn.dark:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #FFF;
        background-color: #090a0b;
        border-color: #000;
    }
}

.btn.dark:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.dark:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.dark:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #2f353b;
            border-color: #2f353b;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #2f353b;
            border-color: #2f353b;
        }
    }
}

fieldset[disabled] .btn.dark:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #2f353b;
        border-color: #2f353b;
    }
}

.btn {
    &.dark:not(.btn-outline) .badge {
        color: #2f353b;
        background-color: #FFF;
    }
    &.btn-outline.dark {
        border-color: #2f353b;
        color: #2f353b;
        background: 0 0;
        &.active {
            border-color: #2f353b;
            color: #FFF;
            background-color: #2f353b;
        }
        &:active {
            border-color: #2f353b;
            color: #FFF;
            background-color: #2f353b;
            &:focus,
            &:hover {
                border-color: #2f353b;
                color: #FFF;
                background-color: #2f353b;
            }
        }
        &:focus,
        &:hover {
            border-color: #2f353b;
            color: #FFF;
            background-color: #2f353b;
        }
    }
    &.dark-stripe {
        border-left: 4px solid #2f353b !important;
    }
    &.dark.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.blue:not(.btn-outline) {
        color: #FFF;
        background-color: #3598dc;
        border-color: #3598dc;
        &.focus,
        &:focus {
            color: #FFF;
            background-color: #217ebd;
            border-color: #15527c;
        }
        &.active,
        &:active,
        &:hover {
            color: #FFF;
            background-color: #217ebd;
            border-color: #1f78b5;
        }
    }
}

.open>.btn.blue:not(.btn-outline).dropdown-toggle {
    color: #FFF;
    background-color: #217ebd;
    border-color: #1f78b5;
}

.btn.blue:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #1c699f;
            border-color: #15527c;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #1c699f;
            border-color: #15527c;
        }
    }
}

.open>.btn.blue:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #FFF;
        background-color: #1c699f;
        border-color: #15527c;
    }
}

.btn.blue:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.blue:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.blue:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #3598dc;
            border-color: #3598dc;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #3598dc;
            border-color: #3598dc;
        }
    }
}

fieldset[disabled] .btn.blue:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #3598dc;
        border-color: #3598dc;
    }
}

.btn {
    &.blue:not(.btn-outline) .badge {
        color: #3598dc;
        background-color: #FFF;
    }
    &.btn-outline.blue {
        border-color: #3598dc;
        color: #3598dc;
        background: 0 0;
        &.active {
            border-color: #3598dc;
            color: #FFF;
            background-color: #3598dc;
        }
        &:active {
            border-color: #3598dc;
            color: #FFF;
            background-color: #3598dc;
            &:focus,
            &:hover {
                border-color: #3598dc;
                color: #FFF;
                background-color: #3598dc;
            }
        }
        &:focus,
        &:hover {
            border-color: #3598dc;
            color: #FFF;
            background-color: #3598dc;
        }
    }
    &.blue-stripe {
        border-left: 4px solid #3598dc !important;
    }
    &.blue.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.blue-madison:not(.btn-outline) {
        color: #FFF;
        background-color: #578ebe;
        border-color: #578ebe;
        &.focus,
        &:focus {
            color: #FFF;
            background-color: #3f74a3;
            border-color: #2a4d6c;
        }
        &.active,
        &:active,
        &:hover {
            color: #FFF;
            background-color: #3f74a3;
            border-color: #3c6f9c;
        }
    }
}

.open>.btn.blue-madison:not(.btn-outline).dropdown-toggle {
    color: #FFF;
    background-color: #3f74a3;
    border-color: #3c6f9c;
}

.btn.blue-madison:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #356289;
            border-color: #2a4d6c;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #356289;
            border-color: #2a4d6c;
        }
    }
}

.open>.btn.blue-madison:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #FFF;
        background-color: #356289;
        border-color: #2a4d6c;
    }
}

.btn.blue-madison:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.blue-madison:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.blue-madison:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #578ebe;
            border-color: #578ebe;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #578ebe;
            border-color: #578ebe;
        }
    }
}

fieldset[disabled] .btn.blue-madison:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #578ebe;
        border-color: #578ebe;
    }
}

.btn {
    &.blue-madison:not(.btn-outline) .badge {
        color: #578ebe;
        background-color: #FFF;
    }
    &.btn-outline.blue-madison {
        border-color: #578ebe;
        color: #578ebe;
        background: 0 0;
        &.active {
            border-color: #578ebe;
            color: #FFF;
            background-color: #578ebe;
        }
        &:active {
            border-color: #578ebe;
            color: #FFF;
            background-color: #578ebe;
            &:focus,
            &:hover {
                border-color: #578ebe;
                color: #FFF;
                background-color: #578ebe;
            }
        }
        &:focus,
        &:hover {
            border-color: #578ebe;
            color: #FFF;
            background-color: #578ebe;
        }
    }
    &.blue-madison-stripe {
        border-left: 4px solid #578ebe !important;
    }
    &.blue-madison.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.blue-chambray:not(.btn-outline) {
        color: #FFF;
        background-color: #2C3E50;
        border-color: #2C3E50;
        &.focus,
        &:focus {
            color: #FFF;
            background-color: #1a252f;
            border-color: #000;
        }
        &.active,
        &:active,
        &:hover {
            color: #FFF;
            background-color: #1a252f;
            border-color: #161f29;
        }
    }
}

.open>.btn.blue-chambray:not(.btn-outline).dropdown-toggle {
    color: #FFF;
    background-color: #1a252f;
    border-color: #161f29;
}

.btn.blue-chambray:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #0d1318;
            border-color: #000;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #0d1318;
            border-color: #000;
        }
    }
}

.open>.btn.blue-chambray:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #FFF;
        background-color: #0d1318;
        border-color: #000;
    }
}

.btn.blue-chambray:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.blue-chambray:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.blue-chambray:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #2C3E50;
            border-color: #2C3E50;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #2C3E50;
            border-color: #2C3E50;
        }
    }
}

fieldset[disabled] .btn.blue-chambray:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #2C3E50;
        border-color: #2C3E50;
    }
}

.btn {
    &.blue-chambray:not(.btn-outline) .badge {
        color: #2C3E50;
        background-color: #FFF;
    }
    &.btn-outline.blue-chambray {
        border-color: #2C3E50;
        color: #2C3E50;
        background: 0 0;
        &.active {
            border-color: #2C3E50;
            color: #FFF;
            background-color: #2C3E50;
        }
        &:active {
            border-color: #2C3E50;
            color: #FFF;
            background-color: #2C3E50;
            &:focus,
            &:hover {
                border-color: #2C3E50;
                color: #FFF;
                background-color: #2C3E50;
            }
        }
        &:focus,
        &:hover {
            border-color: #2C3E50;
            color: #FFF;
            background-color: #2C3E50;
        }
    }
    &.blue-chambray-stripe {
        border-left: 4px solid #2C3E50 !important;
    }
    &.blue-chambray.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.blue-ebonyclay:not(.btn-outline) {
        color: #FFF;
        background-color: #22313F;
        border-color: #22313F;
        &.focus,
        &:focus {
            color: #FFF;
            background-color: #10171e;
            border-color: #000;
        }
        &.active,
        &:active,
        &:hover {
            color: #FFF;
            background-color: #10171e;
            border-color: #0d1217;
        }
    }
}

.open>.btn.blue-ebonyclay:not(.btn-outline).dropdown-toggle {
    color: #FFF;
    background-color: #10171e;
    border-color: #0d1217;
}

.btn.blue-ebonyclay:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #040507;
            border-color: #000;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #040507;
            border-color: #000;
        }
    }
}

.open>.btn.blue-ebonyclay:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #FFF;
        background-color: #040507;
        border-color: #000;
    }
}

.btn.blue-ebonyclay:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.blue-ebonyclay:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.blue-ebonyclay:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #22313F;
            border-color: #22313F;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #22313F;
            border-color: #22313F;
        }
    }
}

fieldset[disabled] .btn.blue-ebonyclay:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #22313F;
        border-color: #22313F;
    }
}

.btn {
    &.blue-ebonyclay:not(.btn-outline) .badge {
        color: #22313F;
        background-color: #FFF;
    }
    &.btn-outline.blue-ebonyclay {
        border-color: #22313F;
        color: #22313F;
        background: 0 0;
        &.active {
            border-color: #22313F;
            color: #FFF;
            background-color: #22313F;
        }
        &:active {
            border-color: #22313F;
            color: #FFF;
            background-color: #22313F;
            &:focus,
            &:hover {
                border-color: #22313F;
                color: #FFF;
                background-color: #22313F;
            }
        }
        &:focus,
        &:hover {
            border-color: #22313F;
            color: #FFF;
            background-color: #22313F;
        }
    }
    &.blue-ebonyclay-stripe {
        border-left: 4px solid #22313F !important;
    }
    &.blue-ebonyclay.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.blue-hoki:not(.btn-outline) {
        color: #FFF;
        background-color: #67809F;
        border-color: #67809F;
        &.focus,
        &:focus {
            color: #FFF;
            background-color: #526781;
            border-color: #344252;
        }
        &.active,
        &:active,
        &:hover {
            color: #FFF;
            background-color: #526781;
            border-color: #4e627b;
        }
    }
}

.open>.btn.blue-hoki:not(.btn-outline).dropdown-toggle {
    color: #FFF;
    background-color: #526781;
    border-color: #4e627b;
}

.btn.blue-hoki:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #44566b;
            border-color: #344252;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #44566b;
            border-color: #344252;
        }
    }
}

.open>.btn.blue-hoki:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #FFF;
        background-color: #44566b;
        border-color: #344252;
    }
}

.btn.blue-hoki:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.blue-hoki:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.blue-hoki:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #67809F;
            border-color: #67809F;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #67809F;
            border-color: #67809F;
        }
    }
}

fieldset[disabled] .btn.blue-hoki:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #67809F;
        border-color: #67809F;
    }
}

.btn {
    &.blue-hoki:not(.btn-outline) .badge {
        color: #67809F;
        background-color: #FFF;
    }
    &.btn-outline.blue-hoki {
        border-color: #67809F;
        color: #67809F;
        background: 0 0;
        &.active {
            border-color: #67809F;
            color: #FFF;
            background-color: #67809F;
        }
        &:active {
            border-color: #67809F;
            color: #FFF;
            background-color: #67809F;
            &:focus,
            &:hover {
                border-color: #67809F;
                color: #FFF;
                background-color: #67809F;
            }
        }
        &:focus,
        &:hover {
            border-color: #67809F;
            color: #FFF;
            background-color: #67809F;
        }
    }
    &.blue-hoki-stripe {
        border-left: 4px solid #67809F !important;
    }
    &.blue-hoki.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.blue-steel:not(.btn-outline) {
        color: #FFF;
        background-color: #4B77BE;
        border-color: #4B77BE;
        &.focus,
        &:focus {
            color: #FFF;
            background-color: #395f9d;
            border-color: #243d65;
        }
        &.active,
        &:active,
        &:hover {
            color: #FFF;
            background-color: #395f9d;
            border-color: #365b96;
        }
    }
}

.open>.btn.blue-steel:not(.btn-outline).dropdown-toggle {
    color: #FFF;
    background-color: #395f9d;
    border-color: #365b96;
}

.btn.blue-steel:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #2f4f83;
            border-color: #243d65;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #2f4f83;
            border-color: #243d65;
        }
    }
}

.open>.btn.blue-steel:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #FFF;
        background-color: #2f4f83;
        border-color: #243d65;
    }
}

.btn.blue-steel:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.blue-steel:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.blue-steel:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #4B77BE;
            border-color: #4B77BE;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #4B77BE;
            border-color: #4B77BE;
        }
    }
}

fieldset[disabled] .btn.blue-steel:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #4B77BE;
        border-color: #4B77BE;
    }
}

.btn {
    &.blue-steel:not(.btn-outline) .badge {
        color: #4B77BE;
        background-color: #FFF;
    }
    &.btn-outline.blue-steel {
        border-color: #4B77BE;
        color: #4B77BE;
        background: 0 0;
        &.active {
            border-color: #4B77BE;
            color: #FFF;
            background-color: #4B77BE;
        }
        &:active {
            border-color: #4B77BE;
            color: #FFF;
            background-color: #4B77BE;
            &:focus,
            &:hover {
                border-color: #4B77BE;
                color: #FFF;
                background-color: #4B77BE;
            }
        }
        &:focus,
        &:hover {
            border-color: #4B77BE;
            color: #FFF;
            background-color: #4B77BE;
        }
    }
    &.blue-steel-stripe {
        border-left: 4px solid #4B77BE !important;
    }
    &.blue-steel.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.blue-soft:not(.btn-outline) {
        color: #FFF;
        background-color: #4c87b9;
        border-color: #4c87b9;
        &.focus,
        &:focus {
            color: #FFF;
            background-color: #3b6d97;
            border-color: #264560;
        }
        &.active,
        &:active,
        &:hover {
            color: #FFF;
            background-color: #3b6d97;
            border-color: #386890;
        }
    }
}

.open>.btn.blue-soft:not(.btn-outline).dropdown-toggle {
    color: #FFF;
    background-color: #3b6d97;
    border-color: #386890;
}

.btn.blue-soft:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #315a7d;
            border-color: #264560;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #315a7d;
            border-color: #264560;
        }
    }
}

.open>.btn.blue-soft:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #FFF;
        background-color: #315a7d;
        border-color: #264560;
    }
}

.btn.blue-soft:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.blue-soft:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.blue-soft:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #4c87b9;
            border-color: #4c87b9;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #4c87b9;
            border-color: #4c87b9;
        }
    }
}

fieldset[disabled] .btn.blue-soft:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #4c87b9;
        border-color: #4c87b9;
    }
}

.btn {
    &.blue-soft:not(.btn-outline) .badge {
        color: #4c87b9;
        background-color: #FFF;
    }
    &.btn-outline.blue-soft {
        border-color: #4c87b9;
        color: #4c87b9;
        background: 0 0;
        &.active {
            border-color: #4c87b9;
            color: #FFF;
            background-color: #4c87b9;
        }
        &:active {
            border-color: #4c87b9;
            color: #FFF;
            background-color: #4c87b9;
            &:focus,
            &:hover {
                border-color: #4c87b9;
                color: #FFF;
                background-color: #4c87b9;
            }
        }
        &:focus,
        &:hover {
            border-color: #4c87b9;
            color: #FFF;
            background-color: #4c87b9;
        }
    }
    &.blue-soft-stripe {
        border-left: 4px solid #4c87b9 !important;
    }
    &.blue-soft.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.blue-dark:not(.btn-outline) {
        color: #FFF;
        background-color: #5e738b;
        border-color: #5e738b;
        &.focus,
        &:focus {
            color: #FFF;
            background-color: #495a6d;
            border-color: #2b343f;
        }
        &.active,
        &:active,
        &:hover {
            color: #FFF;
            background-color: #495a6d;
            border-color: #455566;
        }
    }
}

.open>.btn.blue-dark:not(.btn-outline).dropdown-toggle {
    color: #FFF;
    background-color: #495a6d;
    border-color: #455566;
}

.btn.blue-dark:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #3b4857;
            border-color: #2b343f;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #3b4857;
            border-color: #2b343f;
        }
    }
}

.open>.btn.blue-dark:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #FFF;
        background-color: #3b4857;
        border-color: #2b343f;
    }
}

.btn.blue-dark:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.blue-dark:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.blue-dark:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #5e738b;
            border-color: #5e738b;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #5e738b;
            border-color: #5e738b;
        }
    }
}

fieldset[disabled] .btn.blue-dark:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #5e738b;
        border-color: #5e738b;
    }
}

.btn {
    &.blue-dark:not(.btn-outline) .badge {
        color: #5e738b;
        background-color: #FFF;
    }
    &.btn-outline.blue-dark {
        border-color: #5e738b;
        color: #5e738b;
        background: 0 0;
        &.active {
            border-color: #5e738b;
            color: #FFF;
            background-color: #5e738b;
        }
        &:active {
            border-color: #5e738b;
            color: #FFF;
            background-color: #5e738b;
            &:focus,
            &:hover {
                border-color: #5e738b;
                color: #FFF;
                background-color: #5e738b;
            }
        }
        &:focus,
        &:hover {
            border-color: #5e738b;
            color: #FFF;
            background-color: #5e738b;
        }
    }
    &.blue-dark-stripe {
        border-left: 4px solid #5e738b !important;
    }
    &.blue-dark.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.blue-sharp:not(.btn-outline) {
        color: #FFF;
        background-color: #5C9BD1;
        border-color: #5C9BD1;
        &.focus,
        &:focus {
            color: #FFF;
            background-color: #3782c3;
            border-color: #265a87;
        }
        &.active,
        &:active,
        &:hover {
            color: #FFF;
            background-color: #3782c3;
            border-color: #357dbb;
        }
    }
}

.open>.btn.blue-sharp:not(.btn-outline).dropdown-toggle {
    color: #FFF;
    background-color: #3782c3;
    border-color: #357dbb;
}

.btn.blue-sharp:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #2f70a7;
            border-color: #265a87;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #2f70a7;
            border-color: #265a87;
        }
    }
}

.open>.btn.blue-sharp:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #FFF;
        background-color: #2f70a7;
        border-color: #265a87;
    }
}

.btn.blue-sharp:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.blue-sharp:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.blue-sharp:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #5C9BD1;
            border-color: #5C9BD1;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #5C9BD1;
            border-color: #5C9BD1;
        }
    }
}

fieldset[disabled] .btn.blue-sharp:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #5C9BD1;
        border-color: #5C9BD1;
    }
}

.btn {
    &.blue-sharp:not(.btn-outline) .badge {
        color: #5C9BD1;
        background-color: #FFF;
    }
    &.btn-outline.blue-sharp {
        border-color: #5C9BD1;
        color: #5C9BD1;
        background: 0 0;
        &.active {
            border-color: #5C9BD1;
            color: #FFF;
            background-color: #5C9BD1;
        }
        &:active {
            border-color: #5C9BD1;
            color: #FFF;
            background-color: #5C9BD1;
            &:focus,
            &:hover {
                border-color: #5C9BD1;
                color: #FFF;
                background-color: #5C9BD1;
            }
        }
        &:focus,
        &:hover {
            border-color: #5C9BD1;
            color: #FFF;
            background-color: #5C9BD1;
        }
    }
    &.blue-sharp-stripe {
        border-left: 4px solid #5C9BD1 !important;
    }
    &.blue-sharp.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.blue-oleo:not(.btn-outline) {
        color: #FFF;
        background-color: #94A0B2;
        border-color: #94A0B2;
        &.focus,
        &:focus {
            color: #FFF;
            background-color: #76869d;
            border-color: #536073;
        }
        &.active,
        &:active,
        &:hover {
            color: #FFF;
            background-color: #76869d;
            border-color: #708098;
        }
    }
}

.open>.btn.blue-oleo:not(.btn-outline).dropdown-toggle {
    color: #FFF;
    background-color: #76869d;
    border-color: #708098;
}

.btn.blue-oleo:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #64748b;
            border-color: #536073;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #64748b;
            border-color: #536073;
        }
    }
}

.open>.btn.blue-oleo:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #FFF;
        background-color: #64748b;
        border-color: #536073;
    }
}

.btn.blue-oleo:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.blue-oleo:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.blue-oleo:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #94A0B2;
            border-color: #94A0B2;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #94A0B2;
            border-color: #94A0B2;
        }
    }
}

fieldset[disabled] .btn.blue-oleo:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #94A0B2;
        border-color: #94A0B2;
    }
}

.btn {
    &.blue-oleo:not(.btn-outline) .badge {
        color: #94A0B2;
        background-color: #FFF;
    }
    &.btn-outline.blue-oleo {
        border-color: #94A0B2;
        color: #94A0B2;
        background: 0 0;
        &.active {
            border-color: #94A0B2;
            color: #FFF;
            background-color: #94A0B2;
        }
        &:active {
            border-color: #94A0B2;
            color: #FFF;
            background-color: #94A0B2;
            &:focus,
            &:hover {
                border-color: #94A0B2;
                color: #FFF;
                background-color: #94A0B2;
            }
        }
        &:focus,
        &:hover {
            border-color: #94A0B2;
            color: #FFF;
            background-color: #94A0B2;
        }
    }
    &.blue-oleo-stripe {
        border-left: 4px solid #94A0B2 !important;
    }
    &.blue-oleo.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.green:not(.btn-outline) {
        color: #FFF;
        background-color: #32c5d2;
        border-color: #32c5d2;
        &.focus,
        &:focus {
            color: #FFF;
            background-color: #26a1ab;
            border-color: #18666d;
        }
        &.active,
        &:active,
        &:hover {
            color: #FFF;
            background-color: #26a1ab;
            border-color: #2499a3;
        }
    }
}

.open>.btn.green:not(.btn-outline).dropdown-toggle {
    color: #FFF;
    background-color: #26a1ab;
    border-color: #2499a3;
}

.btn.green:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #1f858e;
            border-color: #18666d;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #1f858e;
            border-color: #18666d;
        }
    }
}

.open>.btn.green:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #FFF;
        background-color: #1f858e;
        border-color: #18666d;
    }
}

.btn.green:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.green:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.green:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #32c5d2;
            border-color: #32c5d2;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #32c5d2;
            border-color: #32c5d2;
        }
    }
}

fieldset[disabled] .btn.green:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #32c5d2;
        border-color: #32c5d2;
    }
}

.btn {
    &.green:not(.btn-outline) .badge {
        color: #32c5d2;
        background-color: #FFF;
    }
    &.btn-outline.green {
        border-color: #32c5d2;
        color: #32c5d2;
        background: 0 0;
        &.active {
            border-color: #32c5d2;
            color: #FFF;
            background-color: #32c5d2;
        }
        &:active {
            border-color: #32c5d2;
            color: #FFF;
            background-color: #32c5d2;
            &:focus,
            &:hover {
                border-color: #32c5d2;
                color: #FFF;
                background-color: #32c5d2;
            }
        }
        &:focus,
        &:hover {
            border-color: #32c5d2;
            color: #FFF;
            background-color: #32c5d2;
        }
    }
    &.green-stripe {
        border-left: 4px solid #32c5d2 !important;
    }
    &.green.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.green-meadow:not(.btn-outline) {
        color: #FFF;
        background-color: #1BBC9B;
        border-color: #1BBC9B;
        &.focus,
        &:focus {
            color: #FFF;
            background-color: #158f76;
            border-color: #0b4d3f;
        }
        &.active,
        &:active,
        &:hover {
            color: #FFF;
            background-color: #158f76;
            border-color: #13866f;
        }
    }
}

.open>.btn.green-meadow:not(.btn-outline).dropdown-toggle {
    color: #FFF;
    background-color: #158f76;
    border-color: #13866f;
}

.btn.green-meadow:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #10705c;
            border-color: #0b4d3f;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #10705c;
            border-color: #0b4d3f;
        }
    }
}

.open>.btn.green-meadow:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #FFF;
        background-color: #10705c;
        border-color: #0b4d3f;
    }
}

.btn.green-meadow:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.green-meadow:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.green-meadow:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #1BBC9B;
            border-color: #1BBC9B;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #1BBC9B;
            border-color: #1BBC9B;
        }
    }
}

fieldset[disabled] .btn.green-meadow:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #1BBC9B;
        border-color: #1BBC9B;
    }
}

.btn {
    &.green-meadow:not(.btn-outline) .badge {
        color: #1BBC9B;
        background-color: #FFF;
    }
    &.btn-outline.green-meadow {
        border-color: #1BBC9B;
        color: #1BBC9B;
        background: 0 0;
        &.active {
            border-color: #1BBC9B;
            color: #FFF;
            background-color: #1BBC9B;
        }
        &:active {
            border-color: #1BBC9B;
            color: #FFF;
            background-color: #1BBC9B;
            &:focus,
            &:hover {
                border-color: #1BBC9B;
                color: #FFF;
                background-color: #1BBC9B;
            }
        }
        &:focus,
        &:hover {
            border-color: #1BBC9B;
            color: #FFF;
            background-color: #1BBC9B;
        }
    }
    &.green-meadow-stripe {
        border-left: 4px solid #1BBC9B !important;
    }
    &.green-meadow.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.green-seagreen:not(.btn-outline) {
        color: #FFF;
        background-color: #1BA39C;
        border-color: #1BA39C;
        &.focus,
        &:focus {
            color: #FFF;
            background-color: #147772;
            border-color: #093633;
        }
        &.active,
        &:active,
        &:hover {
            color: #FFF;
            background-color: #147772;
            border-color: #126e6a;
        }
    }
}

.open>.btn.green-seagreen:not(.btn-outline).dropdown-toggle {
    color: #FFF;
    background-color: #147772;
    border-color: #126e6a;
}

.btn.green-seagreen:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #0f5955;
            border-color: #093633;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #0f5955;
            border-color: #093633;
        }
    }
}

.open>.btn.green-seagreen:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #FFF;
        background-color: #0f5955;
        border-color: #093633;
    }
}

.btn.green-seagreen:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.green-seagreen:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.green-seagreen:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #1BA39C;
            border-color: #1BA39C;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #1BA39C;
            border-color: #1BA39C;
        }
    }
}

fieldset[disabled] .btn.green-seagreen:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #1BA39C;
        border-color: #1BA39C;
    }
}

.btn {
    &.green-seagreen:not(.btn-outline) .badge {
        color: #1BA39C;
        background-color: #FFF;
    }
    &.btn-outline.green-seagreen {
        border-color: #1BA39C;
        color: #1BA39C;
        background: 0 0;
        &.active {
            border-color: #1BA39C;
            color: #FFF;
            background-color: #1BA39C;
        }
        &:active {
            border-color: #1BA39C;
            color: #FFF;
            background-color: #1BA39C;
            &:focus,
            &:hover {
                border-color: #1BA39C;
                color: #FFF;
                background-color: #1BA39C;
            }
        }
        &:focus,
        &:hover {
            border-color: #1BA39C;
            color: #FFF;
            background-color: #1BA39C;
        }
    }
    &.green-seagreen-stripe {
        border-left: 4px solid #1BA39C !important;
    }
    &.green-seagreen.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.green-turquoise:not(.btn-outline) {
        color: #FFF;
        background-color: #36D7B7;
        border-color: #36D7B7;
        &.focus,
        &:focus {
            color: #FFF;
            background-color: #24b699;
            border-color: #177663;
        }
        &.active,
        &:active,
        &:hover {
            color: #FFF;
            background-color: #24b699;
            border-color: #22ad92;
        }
    }
}

.open>.btn.green-turquoise:not(.btn-outline).dropdown-toggle {
    color: #FFF;
    background-color: #24b699;
    border-color: #22ad92;
}

.btn.green-turquoise:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #1e9880;
            border-color: #177663;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #1e9880;
            border-color: #177663;
        }
    }
}

.open>.btn.green-turquoise:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #FFF;
        background-color: #1e9880;
        border-color: #177663;
    }
}

.btn.green-turquoise:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.green-turquoise:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.green-turquoise:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #36D7B7;
            border-color: #36D7B7;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #36D7B7;
            border-color: #36D7B7;
        }
    }
}

fieldset[disabled] .btn.green-turquoise:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #36D7B7;
        border-color: #36D7B7;
    }
}

.btn {
    &.green-turquoise:not(.btn-outline) .badge {
        color: #36D7B7;
        background-color: #FFF;
    }
    &.btn-outline.green-turquoise {
        border-color: #36D7B7;
        color: #36D7B7;
        background: 0 0;
        &.active {
            border-color: #36D7B7;
            color: #FFF;
            background-color: #36D7B7;
        }
        &:active {
            border-color: #36D7B7;
            color: #FFF;
            background-color: #36D7B7;
            &:focus,
            &:hover {
                border-color: #36D7B7;
                color: #FFF;
                background-color: #36D7B7;
            }
        }
        &:focus,
        &:hover {
            border-color: #36D7B7;
            color: #FFF;
            background-color: #36D7B7;
        }
    }
    &.green-turquoise-stripe {
        border-left: 4px solid #36D7B7 !important;
    }
    &.green-turquoise.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.green-haze:not(.btn-outline) {
        color: #FFF;
        background-color: #44b6ae;
        border-color: #44b6ae;
        &.focus,
        &:focus {
            color: #FFF;
            background-color: #36918b;
            border-color: #215955;
        }
        &.active,
        &:active,
        &:hover {
            color: #FFF;
            background-color: #36918b;
            border-color: #338983;
        }
    }
}

.open>.btn.green-haze:not(.btn-outline).dropdown-toggle {
    color: #FFF;
    background-color: #36918b;
    border-color: #338983;
}

.btn.green-haze:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #2c7772;
            border-color: #215955;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #2c7772;
            border-color: #215955;
        }
    }
}

.open>.btn.green-haze:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #FFF;
        background-color: #2c7772;
        border-color: #215955;
    }
}

.btn.green-haze:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.green-haze:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.green-haze:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #44b6ae;
            border-color: #44b6ae;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #44b6ae;
            border-color: #44b6ae;
        }
    }
}

fieldset[disabled] .btn.green-haze:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #44b6ae;
        border-color: #44b6ae;
    }
}

.btn {
    &.green-haze:not(.btn-outline) .badge {
        color: #44b6ae;
        background-color: #FFF;
    }
    &.btn-outline.green-haze {
        border-color: #44b6ae;
        color: #44b6ae;
        background: 0 0;
        &.active {
            border-color: #44b6ae;
            color: #FFF;
            background-color: #44b6ae;
        }
        &:active {
            border-color: #44b6ae;
            color: #FFF;
            background-color: #44b6ae;
            &:focus,
            &:hover {
                border-color: #44b6ae;
                color: #FFF;
                background-color: #44b6ae;
            }
        }
        &:focus,
        &:hover {
            border-color: #44b6ae;
            color: #FFF;
            background-color: #44b6ae;
        }
    }
    &.green-haze-stripe {
        border-left: 4px solid #44b6ae !important;
    }
    &.green-haze.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.green-jungle:not(.btn-outline) {
        color: #FFF;
        background-color: #26C281;
        border-color: #26C281;
        &.focus,
        &:focus {
            color: #FFF;
            background-color: #1e9765;
            border-color: #11573a;
        }
        &.active,
        &:active,
        &:hover {
            color: #FFF;
            background-color: #1e9765;
            border-color: #1c8f5f;
        }
    }
}

.open>.btn.green-jungle:not(.btn-outline).dropdown-toggle {
    color: #FFF;
    background-color: #1e9765;
    border-color: #1c8f5f;
}

.btn.green-jungle:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #187a51;
            border-color: #11573a;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #187a51;
            border-color: #11573a;
        }
    }
}

.open>.btn.green-jungle:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #FFF;
        background-color: #187a51;
        border-color: #11573a;
    }
}

.btn.green-jungle:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.green-jungle:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.green-jungle:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #26C281;
            border-color: #26C281;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #26C281;
            border-color: #26C281;
        }
    }
}

fieldset[disabled] .btn.green-jungle:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #26C281;
        border-color: #26C281;
    }
}

.btn {
    &.green-jungle:not(.btn-outline) .badge {
        color: #26C281;
        background-color: #FFF;
    }
    &.btn-outline.green-jungle {
        border-color: #26C281;
        color: #26C281;
        background: 0 0;
        &.active {
            border-color: #26C281;
            color: #FFF;
            background-color: #26C281;
        }
        &:active {
            border-color: #26C281;
            color: #FFF;
            background-color: #26C281;
            &:focus,
            &:hover {
                border-color: #26C281;
                color: #FFF;
                background-color: #26C281;
            }
        }
        &:focus,
        &:hover {
            border-color: #26C281;
            color: #FFF;
            background-color: #26C281;
        }
    }
    &.green-jungle-stripe {
        border-left: 4px solid #26C281 !important;
    }
    &.green-jungle.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.green-soft:not(.btn-outline) {
        color: #FFF;
        background-color: #3faba4;
        border-color: #3faba4;
        &.focus,
        &:focus {
            color: #FFF;
            background-color: #318680;
            border-color: #1d4e4b;
        }
        &.active,
        &:active,
        &:hover {
            color: #FFF;
            background-color: #318680;
            border-color: #2f7e79;
        }
    }
}

.open>.btn.green-soft:not(.btn-outline).dropdown-toggle {
    color: #FFF;
    background-color: #318680;
    border-color: #2f7e79;
}

.btn.green-soft:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #286c67;
            border-color: #1d4e4b;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #286c67;
            border-color: #1d4e4b;
        }
    }
}

.open>.btn.green-soft:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #FFF;
        background-color: #286c67;
        border-color: #1d4e4b;
    }
}

.btn.green-soft:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.green-soft:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.green-soft:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #3faba4;
            border-color: #3faba4;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #3faba4;
            border-color: #3faba4;
        }
    }
}

fieldset[disabled] .btn.green-soft:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #3faba4;
        border-color: #3faba4;
    }
}

.btn {
    &.green-soft:not(.btn-outline) .badge {
        color: #3faba4;
        background-color: #FFF;
    }
    &.btn-outline.green-soft {
        border-color: #3faba4;
        color: #3faba4;
        background: 0 0;
        &.active {
            border-color: #3faba4;
            color: #FFF;
            background-color: #3faba4;
        }
        &:active {
            border-color: #3faba4;
            color: #FFF;
            background-color: #3faba4;
            &:focus,
            &:hover {
                border-color: #3faba4;
                color: #FFF;
                background-color: #3faba4;
            }
        }
        &:focus,
        &:hover {
            border-color: #3faba4;
            color: #FFF;
            background-color: #3faba4;
        }
    }
    &.green-soft-stripe {
        border-left: 4px solid #3faba4 !important;
    }
    &.green-soft.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.green-dark:not(.btn-outline) {
        color: #FFF;
        background-color: #4DB3A2;
        border-color: #4DB3A2;
        &.focus,
        &:focus {
            color: #FFF;
            background-color: #3d9082;
            border-color: #265a51;
        }
        &.active,
        &:active,
        &:hover {
            color: #FFF;
            background-color: #3d9082;
            border-color: #3a897b;
        }
    }
}

.open>.btn.green-dark:not(.btn-outline).dropdown-toggle {
    color: #FFF;
    background-color: #3d9082;
    border-color: #3a897b;
}

.btn.green-dark:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #33776b;
            border-color: #265a51;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #33776b;
            border-color: #265a51;
        }
    }
}

.open>.btn.green-dark:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #FFF;
        background-color: #33776b;
        border-color: #265a51;
    }
}

.btn.green-dark:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.green-dark:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.green-dark:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #4DB3A2;
            border-color: #4DB3A2;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #4DB3A2;
            border-color: #4DB3A2;
        }
    }
}

fieldset[disabled] .btn.green-dark:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #4DB3A2;
        border-color: #4DB3A2;
    }
}

.btn {
    &.green-dark:not(.btn-outline) .badge {
        color: #4DB3A2;
        background-color: #FFF;
    }
    &.btn-outline.green-dark {
        border-color: #4DB3A2;
        color: #4DB3A2;
        background: 0 0;
        &.active {
            border-color: #4DB3A2;
            color: #FFF;
            background-color: #4DB3A2;
        }
        &:active {
            border-color: #4DB3A2;
            color: #FFF;
            background-color: #4DB3A2;
            &:focus,
            &:hover {
                border-color: #4DB3A2;
                color: #FFF;
                background-color: #4DB3A2;
            }
        }
        &:focus,
        &:hover {
            border-color: #4DB3A2;
            color: #FFF;
            background-color: #4DB3A2;
        }
    }
    &.green-dark-stripe {
        border-left: 4px solid #4DB3A2 !important;
    }
    &.green-dark.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.green-sharp:not(.btn-outline) {
        color: #FFF;
        background-color: #2ab4c0;
        border-color: #2ab4c0;
        &.focus,
        &:focus {
            color: #FFF;
            background-color: #218d96;
            border-color: #135257;
        }
        &.active,
        &:active,
        &:hover {
            color: #FFF;
            background-color: #218d96;
            border-color: #1f858e;
        }
    }
}

.open>.btn.green-sharp:not(.btn-outline).dropdown-toggle {
    color: #FFF;
    background-color: #218d96;
    border-color: #1f858e;
}

.btn.green-sharp:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #1a7179;
            border-color: #135257;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #1a7179;
            border-color: #135257;
        }
    }
}

.open>.btn.green-sharp:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #FFF;
        background-color: #1a7179;
        border-color: #135257;
    }
}

.btn.green-sharp:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.green-sharp:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.green-sharp:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #2ab4c0;
            border-color: #2ab4c0;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #2ab4c0;
            border-color: #2ab4c0;
        }
    }
}

fieldset[disabled] .btn.green-sharp:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #2ab4c0;
        border-color: #2ab4c0;
    }
}

.btn {
    &.green-sharp:not(.btn-outline) .badge {
        color: #2ab4c0;
        background-color: #FFF;
    }
    &.btn-outline.green-sharp {
        border-color: #2ab4c0;
        color: #2ab4c0;
        background: 0 0;
        &.active {
            border-color: #2ab4c0;
            color: #FFF;
            background-color: #2ab4c0;
        }
        &:active {
            border-color: #2ab4c0;
            color: #FFF;
            background-color: #2ab4c0;
            &:focus,
            &:hover {
                border-color: #2ab4c0;
                color: #FFF;
                background-color: #2ab4c0;
            }
        }
        &:focus,
        &:hover {
            border-color: #2ab4c0;
            color: #FFF;
            background-color: #2ab4c0;
        }
    }
    &.green-sharp-stripe {
        border-left: 4px solid #2ab4c0 !important;
    }
    &.green-sharp.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.green-steel:not(.btn-outline) {
        color: #FFF;
        background-color: #29b4b6;
        border-color: #29b4b6;
        &.focus,
        &:focus {
            color: #FFF;
            background-color: #208b8c;
            border-color: #124d4e;
        }
        &.active,
        &:active,
        &:hover {
            color: #FFF;
            background-color: #208b8c;
            border-color: #1e8384;
        }
    }
}

.open>.btn.green-steel:not(.btn-outline).dropdown-toggle {
    color: #FFF;
    background-color: #208b8c;
    border-color: #1e8384;
}

.btn.green-steel:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #196e6f;
            border-color: #124d4e;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #196e6f;
            border-color: #124d4e;
        }
    }
}

.open>.btn.green-steel:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #FFF;
        background-color: #196e6f;
        border-color: #124d4e;
    }
}

.btn.green-steel:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.green-steel:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.green-steel:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #29b4b6;
            border-color: #29b4b6;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #29b4b6;
            border-color: #29b4b6;
        }
    }
}

fieldset[disabled] .btn.green-steel:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #29b4b6;
        border-color: #29b4b6;
    }
}

.btn {
    &.green-steel:not(.btn-outline) .badge {
        color: #29b4b6;
        background-color: #FFF;
    }
    &.btn-outline.green-steel {
        border-color: #29b4b6;
        color: #29b4b6;
        background: 0 0;
        &.active {
            border-color: #29b4b6;
            color: #FFF;
            background-color: #29b4b6;
        }
        &:active {
            border-color: #29b4b6;
            color: #FFF;
            background-color: #29b4b6;
            &:focus,
            &:hover {
                border-color: #29b4b6;
                color: #FFF;
                background-color: #29b4b6;
            }
        }
        &:focus,
        &:hover {
            border-color: #29b4b6;
            color: #FFF;
            background-color: #29b4b6;
        }
    }
    &.green-steel-stripe {
        border-left: 4px solid #29b4b6 !important;
    }
    &.green-steel.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.grey:not(.btn-outline) {
        color: #333;
        background-color: #E5E5E5;
        border-color: #E5E5E5;
        &.focus,
        &:focus {
            color: #333;
            background-color: #ccc;
            border-color: #a5a5a5;
        }
        &.active,
        &:active,
        &:hover {
            color: #333;
            background-color: #ccc;
            border-color: #c6c6c6;
        }
    }
}

.open>.btn.grey:not(.btn-outline).dropdown-toggle {
    color: #333;
    background-color: #ccc;
    border-color: #c6c6c6;
}

.btn.grey:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #333;
            background-color: #bababa;
            border-color: #a5a5a5;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #333;
            background-color: #bababa;
            border-color: #a5a5a5;
        }
    }
}

.open>.btn.grey:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #333;
        background-color: #bababa;
        border-color: #a5a5a5;
    }
}

.btn.grey:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.grey:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.grey:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #E5E5E5;
            border-color: #E5E5E5;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #E5E5E5;
            border-color: #E5E5E5;
        }
    }
}

fieldset[disabled] .btn.grey:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #E5E5E5;
        border-color: #E5E5E5;
    }
}

.btn {
    &.grey:not(.btn-outline) .badge {
        color: #E5E5E5;
        background-color: #333;
    }
    &.btn-outline.grey {
        border-color: #E5E5E5;
        color: #E5E5E5;
        background: 0 0;
        &.active {
            border-color: #E5E5E5;
            color: #333;
            background-color: #E5E5E5;
        }
        &:active {
            border-color: #E5E5E5;
            color: #333;
            background-color: #E5E5E5;
            &:focus,
            &:hover {
                border-color: #E5E5E5;
                color: #333;
                background-color: #E5E5E5;
            }
        }
        &:focus,
        &:hover {
            border-color: #E5E5E5;
            color: #333;
            background-color: #E5E5E5;
        }
    }
    &.grey-stripe {
        border-left: 4px solid #E5E5E5 !important;
    }
    &.grey.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.grey-steel:not(.btn-outline) {
        color: #80898e;
        background-color: #e9edef;
        border-color: #e9edef;
        &.focus,
        &:focus {
            color: #80898e;
            background-color: #cbd5da;
            border-color: #9fb1b9;
        }
        &.active,
        &:active,
        &:hover {
            color: #80898e;
            background-color: #cbd5da;
            border-color: #c6d0d5;
        }
    }
}

.open>.btn.grey-steel:not(.btn-outline).dropdown-toggle {
    color: #80898e;
    background-color: #cbd5da;
    border-color: #c6d0d5;
}

.btn.grey-steel:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #80898e;
            background-color: #b7c4ca;
            border-color: #9fb1b9;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #80898e;
            background-color: #b7c4ca;
            border-color: #9fb1b9;
        }
    }
}

.open>.btn.grey-steel:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #80898e;
        background-color: #b7c4ca;
        border-color: #9fb1b9;
    }
}

.btn.grey-steel:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.grey-steel:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.grey-steel:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #e9edef;
            border-color: #e9edef;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #e9edef;
            border-color: #e9edef;
        }
    }
}

fieldset[disabled] .btn.grey-steel:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #e9edef;
        border-color: #e9edef;
    }
}

.btn {
    &.grey-steel:not(.btn-outline) .badge {
        color: #e9edef;
        background-color: #80898e;
    }
    &.btn-outline.grey-steel {
        border-color: #e9edef;
        color: #e9edef;
        background: 0 0;
        &.active {
            border-color: #e9edef;
            color: #80898e;
            background-color: #e9edef;
        }
        &:active {
            border-color: #e9edef;
            color: #80898e;
            background-color: #e9edef;
            &:focus,
            &:hover {
                border-color: #e9edef;
                color: #80898e;
                background-color: #e9edef;
            }
        }
        &:focus,
        &:hover {
            border-color: #e9edef;
            color: #80898e;
            background-color: #e9edef;
        }
    }
    &.grey-steel-stripe {
        border-left: 4px solid #e9edef !important;
    }
    &.grey-steel.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.grey-cararra:not(.btn-outline) {
        color: #333;
        background-color: #fafafa;
        border-color: #fafafa;
        &.focus,
        &:focus {
            color: #333;
            background-color: #e1e1e1;
            border-color: #bababa;
        }
        &.active,
        &:active,
        &:hover {
            color: #333;
            background-color: #e1e1e1;
            border-color: #dbdbdb;
        }
    }
}

.open>.btn.grey-cararra:not(.btn-outline).dropdown-toggle {
    color: #333;
    background-color: #e1e1e1;
    border-color: #dbdbdb;
}

.btn.grey-cararra:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #333;
            background-color: #cfcfcf;
            border-color: #bababa;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #333;
            background-color: #cfcfcf;
            border-color: #bababa;
        }
    }
}

.open>.btn.grey-cararra:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #333;
        background-color: #cfcfcf;
        border-color: #bababa;
    }
}

.btn.grey-cararra:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.grey-cararra:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.grey-cararra:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #fafafa;
            border-color: #fafafa;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #fafafa;
            border-color: #fafafa;
        }
    }
}

fieldset[disabled] .btn.grey-cararra:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #fafafa;
        border-color: #fafafa;
    }
}

.btn {
    &.grey-cararra:not(.btn-outline) .badge {
        color: #fafafa;
        background-color: #333;
    }
    &.btn-outline.grey-cararra {
        border-color: #fafafa;
        color: #fafafa;
        background: 0 0;
        &.active {
            border-color: #fafafa;
            color: #333;
            background-color: #fafafa;
        }
        &:active {
            border-color: #fafafa;
            color: #333;
            background-color: #fafafa;
            &:focus,
            &:hover {
                border-color: #fafafa;
                color: #333;
                background-color: #fafafa;
            }
        }
        &:focus,
        &:hover {
            border-color: #fafafa;
            color: #333;
            background-color: #fafafa;
        }
    }
    &.grey-cararra-stripe {
        border-left: 4px solid #fafafa !important;
    }
    &.grey-cararra.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.grey-gallery:not(.btn-outline) {
        color: #fff;
        background-color: #555;
        border-color: #555;
        &.focus,
        &:focus {
            color: #fff;
            background-color: #3c3c3c;
            border-color: #151515;
        }
        &.active,
        &:active,
        &:hover {
            color: #fff;
            background-color: #3c3c3c;
            border-color: #363636;
        }
    }
}

.open>.btn.grey-gallery:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #3c3c3c;
    border-color: #363636;
}

.btn.grey-gallery:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #2a2a2a;
            border-color: #151515;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #2a2a2a;
            border-color: #151515;
        }
    }
}

.open>.btn.grey-gallery:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #fff;
        background-color: #2a2a2a;
        border-color: #151515;
    }
}

.btn.grey-gallery:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.grey-gallery:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.grey-gallery:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #555;
            border-color: #555;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #555;
            border-color: #555;
        }
    }
}

fieldset[disabled] .btn.grey-gallery:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #555;
        border-color: #555;
    }
}

.btn {
    &.grey-gallery:not(.btn-outline) .badge {
        color: #555;
        background-color: #fff;
    }
    &.btn-outline.grey-gallery {
        border-color: #555;
        color: #555;
        background: 0 0;
        &.active {
            border-color: #555;
            color: #fff;
            background-color: #555;
        }
        &:active {
            border-color: #555;
            color: #fff;
            background-color: #555;
            &:focus,
            &:hover {
                border-color: #555;
                color: #fff;
                background-color: #555;
            }
        }
        &:focus,
        &:hover {
            border-color: #555;
            color: #fff;
            background-color: #555;
        }
    }
    &.grey-gallery-stripe {
        border-left: 4px solid #555 !important;
    }
    &.grey-gallery.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.grey-cascade:not(.btn-outline) {
        color: #FFF;
        background-color: #95A5A6;
        border-color: #95A5A6;
        &.focus,
        &:focus {
            color: #FFF;
            background-color: #798d8f;
            border-color: #566566;
        }
        &.active,
        &:active,
        &:hover {
            color: #FFF;
            background-color: #798d8f;
            border-color: #74898a;
        }
    }
}

.open>.btn.grey-cascade:not(.btn-outline).dropdown-toggle {
    color: #FFF;
    background-color: #798d8f;
    border-color: #74898a;
}

.btn.grey-cascade:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #687b7c;
            border-color: #566566;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #687b7c;
            border-color: #566566;
        }
    }
}

.open>.btn.grey-cascade:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #FFF;
        background-color: #687b7c;
        border-color: #566566;
    }
}

.btn.grey-cascade:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.grey-cascade:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.grey-cascade:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #95A5A6;
            border-color: #95A5A6;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #95A5A6;
            border-color: #95A5A6;
        }
    }
}

fieldset[disabled] .btn.grey-cascade:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #95A5A6;
        border-color: #95A5A6;
    }
}

.btn {
    &.grey-cascade:not(.btn-outline) .badge {
        color: #95A5A6;
        background-color: #FFF;
    }
    &.btn-outline.grey-cascade {
        border-color: #95A5A6;
        color: #95A5A6;
        background: 0 0;
        &.active {
            border-color: #95A5A6;
            color: #FFF;
            background-color: #95A5A6;
        }
        &:active {
            border-color: #95A5A6;
            color: #FFF;
            background-color: #95A5A6;
            &:focus,
            &:hover {
                border-color: #95A5A6;
                color: #FFF;
                background-color: #95A5A6;
            }
        }
        &:focus,
        &:hover {
            border-color: #95A5A6;
            color: #FFF;
            background-color: #95A5A6;
        }
    }
    &.grey-cascade-stripe {
        border-left: 4px solid #95A5A6 !important;
    }
    &.grey-cascade.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.grey-silver:not(.btn-outline) {
        color: #FAFCFB;
        background-color: #BFBFBF;
        border-color: #BFBFBF;
        &.focus,
        &:focus {
            color: #FAFCFB;
            background-color: #a6a6a6;
            border-color: #7f7f7f;
        }
        &.active,
        &:active,
        &:hover {
            color: #FAFCFB;
            background-color: #a6a6a6;
            border-color: #a0a0a0;
        }
    }
}

.open>.btn.grey-silver:not(.btn-outline).dropdown-toggle {
    color: #FAFCFB;
    background-color: #a6a6a6;
    border-color: #a0a0a0;
}

.btn.grey-silver:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #FAFCFB;
            background-color: #949494;
            border-color: #7f7f7f;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #FAFCFB;
            background-color: #949494;
            border-color: #7f7f7f;
        }
    }
}

.open>.btn.grey-silver:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #FAFCFB;
        background-color: #949494;
        border-color: #7f7f7f;
    }
}

.btn.grey-silver:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.grey-silver:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.grey-silver:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #BFBFBF;
            border-color: #BFBFBF;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #BFBFBF;
            border-color: #BFBFBF;
        }
    }
}

fieldset[disabled] .btn.grey-silver:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #BFBFBF;
        border-color: #BFBFBF;
    }
}

.btn {
    &.grey-silver:not(.btn-outline) .badge {
        color: #BFBFBF;
        background-color: #FAFCFB;
    }
    &.btn-outline.grey-silver {
        border-color: #BFBFBF;
        color: #BFBFBF;
        background: 0 0;
        &.active {
            border-color: #BFBFBF;
            color: #FAFCFB;
            background-color: #BFBFBF;
        }
        &:active {
            border-color: #BFBFBF;
            color: #FAFCFB;
            background-color: #BFBFBF;
            &:focus,
            &:hover {
                border-color: #BFBFBF;
                color: #FAFCFB;
                background-color: #BFBFBF;
            }
        }
        &:focus,
        &:hover {
            border-color: #BFBFBF;
            color: #FAFCFB;
            background-color: #BFBFBF;
        }
    }
    &.grey-silver-stripe {
        border-left: 4px solid #BFBFBF !important;
    }
    &.grey-silver.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.grey-salsa:not(.btn-outline) {
        color: #FAFCFB;
        background-color: #ACB5C3;
        border-color: #ACB5C3;
        &.focus,
        &:focus {
            color: #FAFCFB;
            background-color: #8e9bae;
            border-color: #64748b;
        }
        &.active,
        &:active,
        &:hover {
            color: #FAFCFB;
            background-color: #8e9bae;
            border-color: #8895a9;
        }
    }
}

.open>.btn.grey-salsa:not(.btn-outline).dropdown-toggle {
    color: #FAFCFB;
    background-color: #8e9bae;
    border-color: #8895a9;
}

.btn.grey-salsa:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #FAFCFB;
            background-color: #7a889f;
            border-color: #64748b;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #FAFCFB;
            background-color: #7a889f;
            border-color: #64748b;
        }
    }
}

.open>.btn.grey-salsa:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #FAFCFB;
        background-color: #7a889f;
        border-color: #64748b;
    }
}

.btn.grey-salsa:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.grey-salsa:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.grey-salsa:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #ACB5C3;
            border-color: #ACB5C3;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #ACB5C3;
            border-color: #ACB5C3;
        }
    }
}

fieldset[disabled] .btn.grey-salsa:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #ACB5C3;
        border-color: #ACB5C3;
    }
}

.btn {
    &.grey-salsa:not(.btn-outline) .badge {
        color: #ACB5C3;
        background-color: #FAFCFB;
    }
    &.btn-outline.grey-salsa {
        border-color: #ACB5C3;
        color: #ACB5C3;
        background: 0 0;
        &.active {
            border-color: #ACB5C3;
            color: #FAFCFB;
            background-color: #ACB5C3;
        }
        &:active {
            border-color: #ACB5C3;
            color: #FAFCFB;
            background-color: #ACB5C3;
            &:focus,
            &:hover {
                border-color: #ACB5C3;
                color: #FAFCFB;
                background-color: #ACB5C3;
            }
        }
        &:focus,
        &:hover {
            border-color: #ACB5C3;
            color: #FAFCFB;
            background-color: #ACB5C3;
        }
    }
    &.grey-salsa-stripe {
        border-left: 4px solid #ACB5C3 !important;
    }
    &.grey-salsa.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.grey-salt:not(.btn-outline) {
        color: #FAFCFB;
        background-color: #bfcad1;
        border-color: #bfcad1;
        &.focus,
        &:focus {
            color: #FAFCFB;
            background-color: #a1b1bc;
            border-color: #758d9c;
        }
        &.active,
        &:active,
        &:hover {
            color: #FAFCFB;
            background-color: #a1b1bc;
            border-color: #9badb7;
        }
    }
}

.open>.btn.grey-salt:not(.btn-outline).dropdown-toggle {
    color: #FAFCFB;
    background-color: #a1b1bc;
    border-color: #9badb7;
}

.btn.grey-salt:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #FAFCFB;
            background-color: #8da0ad;
            border-color: #758d9c;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #FAFCFB;
            background-color: #8da0ad;
            border-color: #758d9c;
        }
    }
}

.open>.btn.grey-salt:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #FAFCFB;
        background-color: #8da0ad;
        border-color: #758d9c;
    }
}

.btn.grey-salt:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.grey-salt:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.grey-salt:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #bfcad1;
            border-color: #bfcad1;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #bfcad1;
            border-color: #bfcad1;
        }
    }
}

fieldset[disabled] .btn.grey-salt:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #bfcad1;
        border-color: #bfcad1;
    }
}

.btn {
    &.grey-salt:not(.btn-outline) .badge {
        color: #bfcad1;
        background-color: #FAFCFB;
    }
    &.btn-outline.grey-salt {
        border-color: #bfcad1;
        color: #bfcad1;
        background: 0 0;
        &.active {
            border-color: #bfcad1;
            color: #FAFCFB;
            background-color: #bfcad1;
        }
        &:active {
            border-color: #bfcad1;
            color: #FAFCFB;
            background-color: #bfcad1;
            &:focus,
            &:hover {
                border-color: #bfcad1;
                color: #FAFCFB;
                background-color: #bfcad1;
            }
        }
        &:focus,
        &:hover {
            border-color: #bfcad1;
            color: #FAFCFB;
            background-color: #bfcad1;
        }
    }
    &.grey-salt-stripe {
        border-left: 4px solid #bfcad1 !important;
    }
    &.grey-salt.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.grey-mint:not(.btn-outline) {
        color: #FFF;
        background-color: #525e64;
        border-color: #525e64;
        &.focus,
        &:focus {
            color: #FFF;
            background-color: #3b4448;
            border-color: #191c1e;
        }
        &.active,
        &:active,
        &:hover {
            color: #FFF;
            background-color: #3b4448;
            border-color: #363e42;
        }
    }
}

.open>.btn.grey-mint:not(.btn-outline).dropdown-toggle {
    color: #FFF;
    background-color: #3b4448;
    border-color: #363e42;
}

.btn.grey-mint:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #2b3134;
            border-color: #191c1e;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #FFF;
            background-color: #2b3134;
            border-color: #191c1e;
        }
    }
}

.open>.btn.grey-mint:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #FFF;
        background-color: #2b3134;
        border-color: #191c1e;
    }
}

.btn.grey-mint:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.grey-mint:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.grey-mint:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #525e64;
            border-color: #525e64;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #525e64;
            border-color: #525e64;
        }
    }
}

fieldset[disabled] .btn.grey-mint:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #525e64;
        border-color: #525e64;
    }
}

.btn {
    &.grey-mint:not(.btn-outline) .badge {
        color: #525e64;
        background-color: #FFF;
    }
    &.btn-outline.grey-mint {
        border-color: #525e64;
        color: #525e64;
        background: 0 0;
        &.active {
            border-color: #525e64;
            color: #FFF;
            background-color: #525e64;
        }
        &:active {
            border-color: #525e64;
            color: #FFF;
            background-color: #525e64;
            &:focus,
            &:hover {
                border-color: #525e64;
                color: #FFF;
                background-color: #525e64;
            }
        }
        &:focus,
        &:hover {
            border-color: #525e64;
            color: #FFF;
            background-color: #525e64;
        }
    }
    &.grey-mint-stripe {
        border-left: 4px solid #525e64 !important;
    }
    &.grey-mint.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.red:not(.btn-outline) {
        color: #fff;
        background-color: #e7505a;
        border-color: #e7505a;
        &.focus,
        &:focus {
            color: #fff;
            background-color: #e12330;
            border-color: #a1161f;
        }
        &.active,
        &:active,
        &:hover {
            color: #fff;
            background-color: #e12330;
            border-color: #dc1e2b;
        }
    }
}

.open>.btn.red:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #e12330;
    border-color: #dc1e2b;
}

.btn.red:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #c51b26;
            border-color: #a1161f;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #c51b26;
            border-color: #a1161f;
        }
    }
}

.open>.btn.red:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #fff;
        background-color: #c51b26;
        border-color: #a1161f;
    }
}

.btn.red:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.red:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.red:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #e7505a;
            border-color: #e7505a;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #e7505a;
            border-color: #e7505a;
        }
    }
}

fieldset[disabled] .btn.red:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #e7505a;
        border-color: #e7505a;
    }
}

.btn {
    &.red:not(.btn-outline) .badge {
        color: #e7505a;
        background-color: #fff;
    }
    &.btn-outline.red {
        border-color: #e7505a;
        color: #e7505a;
        background: 0 0;
        &.active {
            border-color: #e7505a;
            color: #fff;
            background-color: #e7505a;
        }
        &:active {
            border-color: #e7505a;
            color: #fff;
            background-color: #e7505a;
            &:focus,
            &:hover {
                border-color: #e7505a;
                color: #fff;
                background-color: #e7505a;
            }
        }
        &:focus,
        &:hover {
            border-color: #e7505a;
            color: #fff;
            background-color: #e7505a;
        }
    }
    &.red-stripe {
        border-left: 4px solid #e7505a !important;
    }
    &.red.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.red-pink:not(.btn-outline) {
        color: #fff;
        background-color: #E08283;
        border-color: #E08283;
        &.focus,
        &:focus {
            color: #fff;
            background-color: #d6595a;
            border-color: #b52d2e;
        }
        &.active,
        &:active,
        &:hover {
            color: #fff;
            background-color: #d6595a;
            border-color: #d45152;
        }
    }
}

.open>.btn.red-pink:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #d6595a;
    border-color: #d45152;
}

.btn.red-pink:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #cf3d3e;
            border-color: #b52d2e;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #cf3d3e;
            border-color: #b52d2e;
        }
    }
}

.open>.btn.red-pink:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #fff;
        background-color: #cf3d3e;
        border-color: #b52d2e;
    }
}

.btn.red-pink:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.red-pink:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.red-pink:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #E08283;
            border-color: #E08283;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #E08283;
            border-color: #E08283;
        }
    }
}

fieldset[disabled] .btn.red-pink:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #E08283;
        border-color: #E08283;
    }
}

.btn {
    &.red-pink:not(.btn-outline) .badge {
        color: #E08283;
        background-color: #fff;
    }
    &.btn-outline.red-pink {
        border-color: #E08283;
        color: #E08283;
        background: 0 0;
        &.active {
            border-color: #E08283;
            color: #fff;
            background-color: #E08283;
        }
        &:active {
            border-color: #E08283;
            color: #fff;
            background-color: #E08283;
            &:focus,
            &:hover {
                border-color: #E08283;
                color: #fff;
                background-color: #E08283;
            }
        }
        &:focus,
        &:hover {
            border-color: #E08283;
            color: #fff;
            background-color: #E08283;
        }
    }
    &.red-pink-stripe {
        border-left: 4px solid #E08283 !important;
    }
    &.red-pink.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.red-sunglo:not(.btn-outline) {
        color: #fff;
        background-color: #E26A6A;
        border-color: #E26A6A;
        &.focus,
        &:focus {
            color: #fff;
            background-color: #da3f3f;
            border-color: #ab2121;
        }
        &.active,
        &:active,
        &:hover {
            color: #fff;
            background-color: #da3f3f;
            border-color: #d83737;
        }
    }
}

.open>.btn.red-sunglo:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #da3f3f;
    border-color: #d83737;
}

.btn.red-sunglo:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #cd2828;
            border-color: #ab2121;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #cd2828;
            border-color: #ab2121;
        }
    }
}

.open>.btn.red-sunglo:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #fff;
        background-color: #cd2828;
        border-color: #ab2121;
    }
}

.btn.red-sunglo:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.red-sunglo:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.red-sunglo:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #E26A6A;
            border-color: #E26A6A;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #E26A6A;
            border-color: #E26A6A;
        }
    }
}

fieldset[disabled] .btn.red-sunglo:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #E26A6A;
        border-color: #E26A6A;
    }
}

.btn {
    &.red-sunglo:not(.btn-outline) .badge {
        color: #E26A6A;
        background-color: #fff;
    }
    &.btn-outline.red-sunglo {
        border-color: #E26A6A;
        color: #E26A6A;
        background: 0 0;
        &.active {
            border-color: #E26A6A;
            color: #fff;
            background-color: #E26A6A;
        }
        &:active {
            border-color: #E26A6A;
            color: #fff;
            background-color: #E26A6A;
            &:focus,
            &:hover {
                border-color: #E26A6A;
                color: #fff;
                background-color: #E26A6A;
            }
        }
        &:focus,
        &:hover {
            border-color: #E26A6A;
            color: #fff;
            background-color: #E26A6A;
        }
    }
    &.red-sunglo-stripe {
        border-left: 4px solid #E26A6A !important;
    }
    &.red-sunglo.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.red-intense:not(.btn-outline) {
        color: #fff;
        background-color: #e35b5a;
        border-color: #e35b5a;
        &.focus,
        &:focus {
            color: #fff;
            background-color: #dc302e;
            border-color: #a21c1b;
        }
        &.active,
        &:active,
        &:hover {
            color: #fff;
            background-color: #dc302e;
            border-color: #da2726;
        }
    }
}

.open>.btn.red-intense:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #dc302e;
    border-color: #da2726;
}

.btn.red-intense:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #c52321;
            border-color: #a21c1b;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #c52321;
            border-color: #a21c1b;
        }
    }
}

.open>.btn.red-intense:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #fff;
        background-color: #c52321;
        border-color: #a21c1b;
    }
}

.btn.red-intense:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.red-intense:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.red-intense:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #e35b5a;
            border-color: #e35b5a;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #e35b5a;
            border-color: #e35b5a;
        }
    }
}

fieldset[disabled] .btn.red-intense:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #e35b5a;
        border-color: #e35b5a;
    }
}

.btn {
    &.red-intense:not(.btn-outline) .badge {
        color: #e35b5a;
        background-color: #fff;
    }
    &.btn-outline.red-intense {
        border-color: #e35b5a;
        color: #e35b5a;
        background: 0 0;
        &.active {
            border-color: #e35b5a;
            color: #fff;
            background-color: #e35b5a;
        }
        &:active {
            border-color: #e35b5a;
            color: #fff;
            background-color: #e35b5a;
            &:focus,
            &:hover {
                border-color: #e35b5a;
                color: #fff;
                background-color: #e35b5a;
            }
        }
        &:focus,
        &:hover {
            border-color: #e35b5a;
            color: #fff;
            background-color: #e35b5a;
        }
    }
    &.red-intense-stripe {
        border-left: 4px solid #e35b5a !important;
    }
    &.red-intense.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.red-thunderbird:not(.btn-outline) {
        color: #fff;
        background-color: #D91E18;
        border-color: #D91E18;
        &.focus,
        &:focus {
            color: #fff;
            background-color: #ab1813;
            border-color: #660e0b;
        }
        &.active,
        &:active,
        &:hover {
            color: #fff;
            background-color: #ab1813;
            border-color: #a21612;
        }
    }
}

.open>.btn.red-thunderbird:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #ab1813;
    border-color: #a21612;
}

.btn.red-thunderbird:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #8b130f;
            border-color: #660e0b;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #8b130f;
            border-color: #660e0b;
        }
    }
}

.open>.btn.red-thunderbird:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #fff;
        background-color: #8b130f;
        border-color: #660e0b;
    }
}

.btn.red-thunderbird:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.red-thunderbird:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.red-thunderbird:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #D91E18;
            border-color: #D91E18;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #D91E18;
            border-color: #D91E18;
        }
    }
}

fieldset[disabled] .btn.red-thunderbird:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #D91E18;
        border-color: #D91E18;
    }
}

.btn {
    &.red-thunderbird:not(.btn-outline) .badge {
        color: #D91E18;
        background-color: #fff;
    }
    &.btn-outline.red-thunderbird {
        border-color: #D91E18;
        color: #D91E18;
        background: 0 0;
        &.active {
            border-color: #D91E18;
            color: #fff;
            background-color: #D91E18;
        }
        &:active {
            border-color: #D91E18;
            color: #fff;
            background-color: #D91E18;
            &:focus,
            &:hover {
                border-color: #D91E18;
                color: #fff;
                background-color: #D91E18;
            }
        }
        &:focus,
        &:hover {
            border-color: #D91E18;
            color: #fff;
            background-color: #D91E18;
        }
    }
    &.red-thunderbird-stripe {
        border-left: 4px solid #D91E18 !important;
    }
    &.red-thunderbird.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.red-flamingo:not(.btn-outline) {
        color: #fff;
        background-color: #EF4836;
        border-color: #EF4836;
        &.focus,
        &:focus {
            color: #fff;
            background-color: #e02612;
            border-color: #991a0c;
        }
        &.active,
        &:active,
        &:hover {
            color: #fff;
            background-color: #e02612;
            border-color: #d72411;
        }
    }
}

.open>.btn.red-flamingo:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #e02612;
    border-color: #d72411;
}

.btn.red-flamingo:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #bf200f;
            border-color: #991a0c;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #bf200f;
            border-color: #991a0c;
        }
    }
}

.open>.btn.red-flamingo:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #fff;
        background-color: #bf200f;
        border-color: #991a0c;
    }
}

.btn.red-flamingo:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.red-flamingo:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.red-flamingo:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #EF4836;
            border-color: #EF4836;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #EF4836;
            border-color: #EF4836;
        }
    }
}

fieldset[disabled] .btn.red-flamingo:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #EF4836;
        border-color: #EF4836;
    }
}

.btn {
    &.red-flamingo:not(.btn-outline) .badge {
        color: #EF4836;
        background-color: #fff;
    }
    &.btn-outline.red-flamingo {
        border-color: #EF4836;
        color: #EF4836;
        background: 0 0;
        &.active {
            border-color: #EF4836;
            color: #fff;
            background-color: #EF4836;
        }
        &:active {
            border-color: #EF4836;
            color: #fff;
            background-color: #EF4836;
            &:focus,
            &:hover {
                border-color: #EF4836;
                color: #fff;
                background-color: #EF4836;
            }
        }
        &:focus,
        &:hover {
            border-color: #EF4836;
            color: #fff;
            background-color: #EF4836;
        }
    }
    &.red-flamingo-stripe {
        border-left: 4px solid #EF4836 !important;
    }
    &.red-flamingo.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.red-soft:not(.btn-outline) {
        color: #fff;
        background-color: #d05454;
        border-color: #d05454;
        &.focus,
        &:focus {
            color: #fff;
            background-color: #bd3434;
            border-color: #812323;
        }
        &.active,
        &:active,
        &:hover {
            color: #fff;
            background-color: #bd3434;
            border-color: #b53232;
        }
    }
}

.open>.btn.red-soft:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #bd3434;
    border-color: #b53232;
}

.btn.red-soft:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #a12c2c;
            border-color: #812323;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #a12c2c;
            border-color: #812323;
        }
    }
}

.open>.btn.red-soft:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #fff;
        background-color: #a12c2c;
        border-color: #812323;
    }
}

.btn.red-soft:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.red-soft:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.red-soft:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #d05454;
            border-color: #d05454;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #d05454;
            border-color: #d05454;
        }
    }
}

fieldset[disabled] .btn.red-soft:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #d05454;
        border-color: #d05454;
    }
}

.btn {
    &.red-soft:not(.btn-outline) .badge {
        color: #d05454;
        background-color: #fff;
    }
    &.btn-outline.red-soft {
        border-color: #d05454;
        color: #d05454;
        background: 0 0;
        &.active {
            border-color: #d05454;
            color: #fff;
            background-color: #d05454;
        }
        &:active {
            border-color: #d05454;
            color: #fff;
            background-color: #d05454;
            &:focus,
            &:hover {
                border-color: #d05454;
                color: #fff;
                background-color: #d05454;
            }
        }
        &:focus,
        &:hover {
            border-color: #d05454;
            color: #fff;
            background-color: #d05454;
        }
    }
    &.red-soft-stripe {
        border-left: 4px solid #d05454 !important;
    }
    &.red-soft.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.red-haze:not(.btn-outline) {
        color: #fff;
        background-color: #f36a5a;
        border-color: #f36a5a;
        &.focus,
        &:focus {
            color: #fff;
            background-color: #f03f2a;
            border-color: #c0210e;
        }
        &.active,
        &:active,
        &:hover {
            color: #fff;
            background-color: #f03f2a;
            border-color: #ef3621;
        }
    }
}

.open>.btn.red-haze:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #f03f2a;
    border-color: #ef3621;
}

.btn.red-haze:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #e62711;
            border-color: #c0210e;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #e62711;
            border-color: #c0210e;
        }
    }
}

.open>.btn.red-haze:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #fff;
        background-color: #e62711;
        border-color: #c0210e;
    }
}

.btn.red-haze:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.red-haze:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.red-haze:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #f36a5a;
            border-color: #f36a5a;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #f36a5a;
            border-color: #f36a5a;
        }
    }
}

fieldset[disabled] .btn.red-haze:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #f36a5a;
        border-color: #f36a5a;
    }
}

.btn {
    &.red-haze:not(.btn-outline) .badge {
        color: #f36a5a;
        background-color: #fff;
    }
    &.btn-outline.red-haze {
        border-color: #f36a5a;
        color: #f36a5a;
        background: 0 0;
        &.active {
            border-color: #f36a5a;
            color: #fff;
            background-color: #f36a5a;
        }
        &:active {
            border-color: #f36a5a;
            color: #fff;
            background-color: #f36a5a;
            &:focus,
            &:hover {
                border-color: #f36a5a;
                color: #fff;
                background-color: #f36a5a;
            }
        }
        &:focus,
        &:hover {
            border-color: #f36a5a;
            color: #fff;
            background-color: #f36a5a;
        }
    }
    &.red-haze-stripe {
        border-left: 4px solid #f36a5a !important;
    }
    &.red-haze.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.red-mint:not(.btn-outline) {
        color: #fff;
        background-color: #e43a45;
        border-color: #e43a45;
        &.focus,
        &:focus {
            color: #fff;
            background-color: #cf1c28;
            border-color: #8b131b;
        }
        &.active,
        &:active,
        &:hover {
            color: #fff;
            background-color: #cf1c28;
            border-color: #c61b26;
        }
    }
}

.open>.btn.red-mint:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #cf1c28;
    border-color: #c61b26;
}

.btn.red-mint:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #af1822;
            border-color: #8b131b;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #af1822;
            border-color: #8b131b;
        }
    }
}

.open>.btn.red-mint:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #fff;
        background-color: #af1822;
        border-color: #8b131b;
    }
}

.btn.red-mint:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.red-mint:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.red-mint:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #e43a45;
            border-color: #e43a45;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #e43a45;
            border-color: #e43a45;
        }
    }
}

fieldset[disabled] .btn.red-mint:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #e43a45;
        border-color: #e43a45;
    }
}

.btn {
    &.red-mint:not(.btn-outline) .badge {
        color: #e43a45;
        background-color: #fff;
    }
    &.btn-outline.red-mint {
        border-color: #e43a45;
        color: #e43a45;
        background: 0 0;
        &.active {
            border-color: #e43a45;
            color: #fff;
            background-color: #e43a45;
        }
        &:active {
            border-color: #e43a45;
            color: #fff;
            background-color: #e43a45;
            &:focus,
            &:hover {
                border-color: #e43a45;
                color: #fff;
                background-color: #e43a45;
            }
        }
        &:focus,
        &:hover {
            border-color: #e43a45;
            color: #fff;
            background-color: #e43a45;
        }
    }
    &.red-mint-stripe {
        border-left: 4px solid #e43a45 !important;
    }
    &.red-mint.btn-no-border:not(.active) {
        border-color: transparent;
    }
    // &.yellow:not(.btn-outline) {
    //     color: #fff;
    //     background-color: #c49f47;
    //     border-color: #c49f47;
    //     &.focus, &:focus {
    //         color: #fff;
    //         background-color: #a48334;
    //         border-color: #6a5422;
    //     }
    //     &.active, &:active, &:hover {
    //         color: #fff;
    //         background-color: #a48334;
    //         border-color: #9c7c32;
    //     }
    // }
}

.open>.btn.yellow:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #a48334;
    border-color: #9c7c32;
}

// .btn.yellow:not(.btn-outline) {
//     &.active {
//         &.focus, &:focus, &:hover {
//             color: #fff;
//             background-color: #896d2c;
//             border-color: #6a5422;
//         }
//     }
//     &:active {
//         &.focus, &:focus, &:hover {
//             color: #fff;
//             background-color: #896d2c;
//             border-color: #6a5422;
//         }
//     }
// }
.open>.btn.yellow:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #fff;
        background-color: #896d2c;
        border-color: #6a5422;
    }
}

.btn.yellow:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.yellow:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

// .btn.yellow:not(.btn-outline) {
//     &.disabled {
//         &.focus, &:focus, &:hover {
//             background-color: #c49f47;
//             border-color: #c49f47;
//         }
//     }
//     &[disabled] {
//         &.focus, &:focus, &:hover {
//             background-color: #c49f47;
//             border-color: #c49f47;
//         }
//     }
// }
fieldset[disabled] .btn.yellow:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #c49f47;
        border-color: #c49f47;
    }
}

.btn {
    &.yellow:not(.btn-outline) .badge {
        color: #c49f47;
        background-color: #fff;
    }
    &.btn-outline.yellow {
        border-color: #c49f47;
        color: #c49f47;
        background: 0 0;
        &.active {
            border-color: #c49f47;
            color: #fff;
            background-color: #c49f47;
        }
        &:active {
            border-color: #c49f47;
            color: #fff;
            background-color: #c49f47;
            &:focus,
            &:hover {
                border-color: #c49f47;
                color: #fff;
                background-color: #c49f47;
            }
        }
        &:focus,
        &:hover {
            border-color: #c49f47;
            color: #fff;
            background-color: #c49f47;
        }
    }
    &.yellow-stripe {
        border-left: 4px solid #c49f47 !important;
    }
    &.yellow.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.yellow-gold:not(.btn-outline) {
        color: #fff;
        background-color: #E87E04;
        border-color: #E87E04;
        &.focus,
        &:focus {
            color: #fff;
            background-color: #b66303;
            border-color: #6b3a02;
        }
        &.active,
        &:active,
        &:hover {
            color: #fff;
            background-color: #b66303;
            border-color: #ac5d03;
        }
    }
}

.open>.btn.yellow-gold:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #b66303;
    border-color: #ac5d03;
}

.btn.yellow-gold:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #935003;
            border-color: #6b3a02;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #935003;
            border-color: #6b3a02;
        }
    }
}

.open>.btn.yellow-gold:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #fff;
        background-color: #935003;
        border-color: #6b3a02;
    }
}

.btn.yellow-gold:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.yellow-gold:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.yellow-gold:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #E87E04;
            border-color: #E87E04;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #E87E04;
            border-color: #E87E04;
        }
    }
}

fieldset[disabled] .btn.yellow-gold:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #E87E04;
        border-color: #E87E04;
    }
}

.btn {
    &.yellow-gold:not(.btn-outline) .badge {
        color: #E87E04;
        background-color: #fff;
    }
    &.btn-outline.yellow-gold {
        border-color: #E87E04;
        color: #E87E04;
        background: 0 0;
        &.active {
            border-color: #E87E04;
            color: #fff;
            background-color: #E87E04;
        }
        &:active {
            border-color: #E87E04;
            color: #fff;
            background-color: #E87E04;
            &:focus,
            &:hover {
                border-color: #E87E04;
                color: #fff;
                background-color: #E87E04;
            }
        }
        &:focus,
        &:hover {
            border-color: #E87E04;
            color: #fff;
            background-color: #E87E04;
        }
    }
    &.yellow-gold-stripe {
        border-left: 4px solid #E87E04 !important;
    }
    &.yellow-gold.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.yellow-casablanca:not(.btn-outline) {
        color: #fff;
        background-color: #f2784b;
        border-color: #f2784b;
        &.focus,
        &:focus {
            color: #fff;
            background-color: #ef541b;
            border-color: #b1390d;
        }
        &.active,
        &:active,
        &:hover {
            color: #fff;
            background-color: #ef541b;
            border-color: #ee4d12;
        }
    }
}

.open>.btn.yellow-casablanca:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #ef541b;
    border-color: #ee4d12;
}

.btn.yellow-casablanca:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #d74510;
            border-color: #b1390d;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #d74510;
            border-color: #b1390d;
        }
    }
}

.open>.btn.yellow-casablanca:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #fff;
        background-color: #d74510;
        border-color: #b1390d;
    }
}

.btn.yellow-casablanca:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.yellow-casablanca:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.yellow-casablanca:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #f2784b;
            border-color: #f2784b;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #f2784b;
            border-color: #f2784b;
        }
    }
}

fieldset[disabled] .btn.yellow-casablanca:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #f2784b;
        border-color: #f2784b;
    }
}

.btn {
    &.yellow-casablanca:not(.btn-outline) .badge {
        color: #f2784b;
        background-color: #fff;
    }
    &.btn-outline.yellow-casablanca {
        border-color: #f2784b;
        color: #f2784b;
        background: 0 0;
        &.active {
            border-color: #f2784b;
            color: #fff;
            background-color: #f2784b;
        }
        &:active {
            border-color: #f2784b;
            color: #fff;
            background-color: #f2784b;
            &:focus,
            &:hover {
                border-color: #f2784b;
                color: #fff;
                background-color: #f2784b;
            }
        }
        &:focus,
        &:hover {
            border-color: #f2784b;
            color: #fff;
            background-color: #f2784b;
        }
    }
    &.yellow-casablanca-stripe {
        border-left: 4px solid #f2784b !important;
    }
    &.yellow-casablanca.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.yellow-crusta:not(.btn-outline) {
        color: #fff;
        background-color: #f3c200;
        border-color: #f3c200;
        &.focus,
        &:focus {
            color: #fff;
            background-color: #c09900;
            border-color: #745c00;
        }
        &.active,
        &:active,
        &:hover {
            color: #fff;
            background-color: #c09900;
            border-color: #b69100;
        }
    }
}

.open>.btn.yellow-crusta:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #c09900;
    border-color: #b69100;
}

.btn.yellow-crusta:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #9c7d00;
            border-color: #745c00;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #9c7d00;
            border-color: #745c00;
        }
    }
}

.open>.btn.yellow-crusta:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #fff;
        background-color: #9c7d00;
        border-color: #745c00;
    }
}

.btn.yellow-crusta:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.yellow-crusta:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.yellow-crusta:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #f3c200;
            border-color: #f3c200;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #f3c200;
            border-color: #f3c200;
        }
    }
}

fieldset[disabled] .btn.yellow-crusta:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #f3c200;
        border-color: #f3c200;
    }
}

.btn {
    &.yellow-crusta:not(.btn-outline) .badge {
        color: #f3c200;
        background-color: #fff;
    }
    &.btn-outline.yellow-crusta {
        border-color: #f3c200;
        color: #f3c200;
        background: 0 0;
        &.active {
            border-color: #f3c200;
            color: #fff;
            background-color: #f3c200;
        }
        &:active {
            border-color: #f3c200;
            color: #fff;
            background-color: #f3c200;
            &:focus,
            &:hover {
                border-color: #f3c200;
                color: #fff;
                background-color: #f3c200;
            }
        }
        &:focus,
        &:hover {
            border-color: #f3c200;
            color: #fff;
            background-color: #f3c200;
        }
    }
    &.yellow-crusta-stripe {
        border-left: 4px solid #f3c200 !important;
    }
    &.yellow-crusta.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.yellow-lemon:not(.btn-outline) {
        color: #fff;
        background-color: #F7CA18;
        border-color: #F7CA18;
        &.focus,
        &:focus {
            color: #fff;
            background-color: #d5ab07;
            border-color: #8b7005;
        }
        &.active,
        &:active,
        &:hover {
            color: #fff;
            background-color: #d5ab07;
            border-color: #cba307;
        }
    }
}

.open>.btn.yellow-lemon:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #d5ab07;
    border-color: #cba307;
}

.btn.yellow-lemon:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #b28f06;
            border-color: #8b7005;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #b28f06;
            border-color: #8b7005;
        }
    }
}

.open>.btn.yellow-lemon:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #fff;
        background-color: #b28f06;
        border-color: #8b7005;
    }
}

.btn.yellow-lemon:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.yellow-lemon:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.yellow-lemon:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #F7CA18;
            border-color: #F7CA18;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #F7CA18;
            border-color: #F7CA18;
        }
    }
}

fieldset[disabled] .btn.yellow-lemon:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #F7CA18;
        border-color: #F7CA18;
    }
}

.btn {
    &.yellow-lemon:not(.btn-outline) .badge {
        color: #F7CA18;
        background-color: #fff;
    }
    &.btn-outline.yellow-lemon {
        border-color: #F7CA18;
        color: #F7CA18;
        background: 0 0;
        &.active {
            border-color: #F7CA18;
            color: #fff;
            background-color: #F7CA18;
        }
        &:active {
            border-color: #F7CA18;
            color: #fff;
            background-color: #F7CA18;
            &:focus,
            &:hover {
                border-color: #F7CA18;
                color: #fff;
                background-color: #F7CA18;
            }
        }
        &:focus,
        &:hover {
            border-color: #F7CA18;
            color: #fff;
            background-color: #F7CA18;
        }
    }
    &.yellow-lemon-stripe {
        border-left: 4px solid #F7CA18 !important;
    }
    &.yellow-lemon.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.yellow-saffron:not(.btn-outline) {
        color: #fff;
        background-color: #F4D03F;
        border-color: #F4D03F;
        &.focus,
        &:focus {
            color: #fff;
            background-color: #f1c40f;
            border-color: #aa8a0a;
        }
        &.active,
        &:active,
        &:hover {
            color: #fff;
            background-color: #f1c40f;
            border-color: #e8bd0d;
        }
    }
}

.open>.btn.yellow-saffron:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #f1c40f;
    border-color: #e8bd0d;
}

.btn.yellow-saffron:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #d0a90c;
            border-color: #aa8a0a;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #d0a90c;
            border-color: #aa8a0a;
        }
    }
}

.open>.btn.yellow-saffron:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #fff;
        background-color: #d0a90c;
        border-color: #aa8a0a;
    }
}

.btn.yellow-saffron:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.yellow-saffron:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.yellow-saffron:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #F4D03F;
            border-color: #F4D03F;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #F4D03F;
            border-color: #F4D03F;
        }
    }
}

fieldset[disabled] .btn.yellow-saffron:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #F4D03F;
        border-color: #F4D03F;
    }
}

.btn {
    &.yellow-saffron:not(.btn-outline) .badge {
        color: #F4D03F;
        background-color: #fff;
    }
    &.btn-outline.yellow-saffron {
        border-color: #F4D03F;
        color: #F4D03F;
        background: 0 0;
        &.active {
            border-color: #F4D03F;
            color: #fff;
            background-color: #F4D03F;
        }
        &:active {
            border-color: #F4D03F;
            color: #fff;
            background-color: #F4D03F;
            &:focus,
            &:hover {
                border-color: #F4D03F;
                color: #fff;
                background-color: #F4D03F;
            }
        }
        &:focus,
        &:hover {
            border-color: #F4D03F;
            color: #fff;
            background-color: #F4D03F;
        }
    }
    &.yellow-saffron-stripe {
        border-left: 4px solid #F4D03F !important;
    }
    &.yellow-saffron.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.yellow-soft:not(.btn-outline) {
        color: #fff;
        background-color: #c8d046;
        border-color: #c8d046;
        &.focus,
        &:focus {
            color: #fff;
            background-color: #adb52e;
            border-color: #73781e;
        }
        &.active,
        &:active,
        &:hover {
            color: #fff;
            background-color: #adb52e;
            border-color: #a5ad2c;
        }
    }
}

.open>.btn.yellow-soft:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #adb52e;
    border-color: #a5ad2c;
}

.btn.yellow-soft:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #929927;
            border-color: #73781e;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #929927;
            border-color: #73781e;
        }
    }
}

.open>.btn.yellow-soft:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #fff;
        background-color: #929927;
        border-color: #73781e;
    }
}

.btn.yellow-soft:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.yellow-soft:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.yellow-soft:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #c8d046;
            border-color: #c8d046;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #c8d046;
            border-color: #c8d046;
        }
    }
}

fieldset[disabled] .btn.yellow-soft:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #c8d046;
        border-color: #c8d046;
    }
}

.btn {
    &.yellow-soft:not(.btn-outline) .badge {
        color: #c8d046;
        background-color: #fff;
    }
    &.btn-outline.yellow-soft {
        border-color: #c8d046;
        color: #c8d046;
        background: 0 0;
        &.active {
            border-color: #c8d046;
            color: #fff;
            background-color: #c8d046;
        }
        &:active {
            border-color: #c8d046;
            color: #fff;
            background-color: #c8d046;
            &:focus,
            &:hover {
                border-color: #c8d046;
                color: #fff;
                background-color: #c8d046;
            }
        }
        &:focus,
        &:hover {
            border-color: #c8d046;
            color: #fff;
            background-color: #c8d046;
        }
    }
    &.yellow-soft-stripe {
        border-left: 4px solid #c8d046 !important;
    }
    &.yellow-soft.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.yellow-haze:not(.btn-outline) {
        color: #fff;
        background-color: #c5bf66;
        border-color: #c5bf66;
        &.focus,
        &:focus {
            color: #fff;
            background-color: #b4ad44;
            border-color: #7c772f;
        }
        &.active,
        &:active,
        &:hover {
            color: #fff;
            background-color: #b4ad44;
            border-color: #aca641;
        }
    }
}

.open>.btn.yellow-haze:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #b4ad44;
    border-color: #aca641;
}

.btn.yellow-haze:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #9a943a;
            border-color: #7c772f;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #9a943a;
            border-color: #7c772f;
        }
    }
}

.open>.btn.yellow-haze:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #fff;
        background-color: #9a943a;
        border-color: #7c772f;
    }
}

.btn.yellow-haze:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.yellow-haze:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.yellow-haze:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #c5bf66;
            border-color: #c5bf66;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #c5bf66;
            border-color: #c5bf66;
        }
    }
}

fieldset[disabled] .btn.yellow-haze:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #c5bf66;
        border-color: #c5bf66;
    }
}

.btn {
    &.yellow-haze:not(.btn-outline) .badge {
        color: #c5bf66;
        background-color: #fff;
    }
    &.btn-outline.yellow-haze {
        border-color: #c5bf66;
        color: #c5bf66;
        background: 0 0;
        &.active {
            border-color: #c5bf66;
            color: #fff;
            background-color: #c5bf66;
        }
        &:active {
            border-color: #c5bf66;
            color: #fff;
            background-color: #c5bf66;
            &:focus,
            &:hover {
                border-color: #c5bf66;
                color: #fff;
                background-color: #c5bf66;
            }
        }
        &:focus,
        &:hover {
            border-color: #c5bf66;
            color: #fff;
            background-color: #c5bf66;
        }
    }
    &.yellow-haze-stripe {
        border-left: 4px solid #c5bf66 !important;
    }
    &.yellow-haze.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.yellow-mint:not(.btn-outline) {
        color: #fff;
        background-color: #c5b96b;
        border-color: #c5b96b;
        &.focus,
        &:focus {
            color: #fff;
            background-color: #b6a747;
            border-color: #7f7532;
        }
        &.active,
        &:active,
        &:hover {
            color: #fff;
            background-color: #b6a747;
            border-color: #aea044;
        }
    }
}

.open>.btn.yellow-mint:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #b6a747;
    border-color: #aea044;
}

.btn.yellow-mint:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #9c8f3d;
            border-color: #7f7532;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #9c8f3d;
            border-color: #7f7532;
        }
    }
}

.open>.btn.yellow-mint:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #fff;
        background-color: #9c8f3d;
        border-color: #7f7532;
    }
}

.btn.yellow-mint:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.yellow-mint:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.yellow-mint:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #c5b96b;
            border-color: #c5b96b;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #c5b96b;
            border-color: #c5b96b;
        }
    }
}

fieldset[disabled] .btn.yellow-mint:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #c5b96b;
        border-color: #c5b96b;
    }
}

.btn {
    &.yellow-mint:not(.btn-outline) .badge {
        color: #c5b96b;
        background-color: #fff;
    }
    &.btn-outline.yellow-mint {
        border-color: #c5b96b;
        color: #c5b96b;
        background: 0 0;
        &.active {
            border-color: #c5b96b;
            color: #fff;
            background-color: #c5b96b;
        }
        &:active {
            border-color: #c5b96b;
            color: #fff;
            background-color: #c5b96b;
            &:focus,
            &:hover {
                border-color: #c5b96b;
                color: #fff;
                background-color: #c5b96b;
            }
        }
        &:focus,
        &:hover {
            border-color: #c5b96b;
            color: #fff;
            background-color: #c5b96b;
        }
    }
    &.yellow-mint-stripe {
        border-left: 4px solid #c5b96b !important;
    }
    &.yellow-mint.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.purple:not(.btn-outline) {
        color: #fff;
        background-color: #8E44AD;
        border-color: #8E44AD;
        &.focus,
        &:focus {
            color: #fff;
            background-color: #703688;
            border-color: #432051;
        }
        &.active,
        &:active,
        &:hover {
            color: #fff;
            background-color: #703688;
            border-color: #6a3381;
        }
    }
}

.open>.btn.purple:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #703688;
    border-color: #6a3381;
}

.btn.purple:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #5b2c6f;
            border-color: #432051;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #5b2c6f;
            border-color: #432051;
        }
    }
}

.open>.btn.purple:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #fff;
        background-color: #5b2c6f;
        border-color: #432051;
    }
}

.btn.purple:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.purple:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.purple:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #8E44AD;
            border-color: #8E44AD;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #8E44AD;
            border-color: #8E44AD;
        }
    }
}

fieldset[disabled] .btn.purple:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #8E44AD;
        border-color: #8E44AD;
    }
}

.btn {
    &.purple:not(.btn-outline) .badge {
        color: #8E44AD;
        background-color: #fff;
    }
    &.btn-outline.purple {
        border-color: #8E44AD;
        color: #8E44AD;
        background: 0 0;
        &.active {
            border-color: #8E44AD;
            color: #fff;
            background-color: #8E44AD;
        }
        &:active {
            border-color: #8E44AD;
            color: #fff;
            background-color: #8E44AD;
            &:focus,
            &:hover {
                border-color: #8E44AD;
                color: #fff;
                background-color: #8E44AD;
            }
        }
        &:focus,
        &:hover {
            border-color: #8E44AD;
            color: #fff;
            background-color: #8E44AD;
        }
    }
    &.purple-stripe {
        border-left: 4px solid #8E44AD !important;
    }
    &.purple.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.purple-plum:not(.btn-outline) {
        color: #fff;
        background-color: #8775a7;
        border-color: #8775a7;
        &.focus,
        &:focus {
            color: #fff;
            background-color: #6d5b8e;
            border-color: #493d60;
        }
        &.active,
        &:active,
        &:hover {
            color: #fff;
            background-color: #6d5b8e;
            border-color: #685788;
        }
    }
}

.open>.btn.purple-plum:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #6d5b8e;
    border-color: #685788;
}

.btn.purple-plum:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #5d4d78;
            border-color: #493d60;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #5d4d78;
            border-color: #493d60;
        }
    }
}

.open>.btn.purple-plum:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #fff;
        background-color: #5d4d78;
        border-color: #493d60;
    }
}

.btn.purple-plum:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.purple-plum:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.purple-plum:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #8775a7;
            border-color: #8775a7;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #8775a7;
            border-color: #8775a7;
        }
    }
}

fieldset[disabled] .btn.purple-plum:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #8775a7;
        border-color: #8775a7;
    }
}

.btn {
    &.purple-plum:not(.btn-outline) .badge {
        color: #8775a7;
        background-color: #fff;
    }
    &.btn-outline.purple-plum {
        border-color: #8775a7;
        color: #8775a7;
        background: 0 0;
        &.active {
            border-color: #8775a7;
            color: #fff;
            background-color: #8775a7;
        }
        &:active {
            border-color: #8775a7;
            color: #fff;
            background-color: #8775a7;
            &:focus,
            &:hover {
                border-color: #8775a7;
                color: #fff;
                background-color: #8775a7;
            }
        }
        &:focus,
        &:hover {
            border-color: #8775a7;
            color: #fff;
            background-color: #8775a7;
        }
    }
    &.purple-plum-stripe {
        border-left: 4px solid #8775a7 !important;
    }
    &.purple-plum.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.purple-medium:not(.btn-outline) {
        color: #fff;
        background-color: #BF55EC;
        border-color: #BF55EC;
        &.focus,
        &:focus {
            color: #fff;
            background-color: #ae27e7;
            border-color: #8013ae;
        }
        &.active,
        &:active,
        &:hover {
            color: #fff;
            background-color: #ae27e7;
            border-color: #aa1ee6;
        }
    }
}

.open>.btn.purple-medium:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #ae27e7;
    border-color: #aa1ee6;
}

.btn.purple-medium:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #9b18d3;
            border-color: #8013ae;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #9b18d3;
            border-color: #8013ae;
        }
    }
}

.open>.btn.purple-medium:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #fff;
        background-color: #9b18d3;
        border-color: #8013ae;
    }
}

.btn.purple-medium:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.purple-medium:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.purple-medium:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #BF55EC;
            border-color: #BF55EC;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #BF55EC;
            border-color: #BF55EC;
        }
    }
}

fieldset[disabled] .btn.purple-medium:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #BF55EC;
        border-color: #BF55EC;
    }
}

.btn {
    &.purple-medium:not(.btn-outline) .badge {
        color: #BF55EC;
        background-color: #fff;
    }
    &.btn-outline.purple-medium {
        border-color: #BF55EC;
        color: #BF55EC;
        background: 0 0;
        &.active {
            border-color: #BF55EC;
            color: #fff;
            background-color: #BF55EC;
        }
        &:active {
            border-color: #BF55EC;
            color: #fff;
            background-color: #BF55EC;
            &:focus,
            &:hover {
                border-color: #BF55EC;
                color: #fff;
                background-color: #BF55EC;
            }
        }
        &:focus,
        &:hover {
            border-color: #BF55EC;
            color: #fff;
            background-color: #BF55EC;
        }
    }
    &.purple-medium-stripe {
        border-left: 4px solid #BF55EC !important;
    }
    &.purple-medium.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.purple-studio:not(.btn-outline) {
        color: #fff;
        background-color: #8E44AD;
        border-color: #8E44AD;
        &.focus,
        &:focus {
            color: #fff;
            background-color: #703688;
            border-color: #432051;
        }
        &.active,
        &:active,
        &:hover {
            color: #fff;
            background-color: #703688;
            border-color: #6a3381;
        }
    }
}

.open>.btn.purple-studio:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #703688;
    border-color: #6a3381;
}

.btn.purple-studio:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #5b2c6f;
            border-color: #432051;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #5b2c6f;
            border-color: #432051;
        }
    }
}

.open>.btn.purple-studio:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #fff;
        background-color: #5b2c6f;
        border-color: #432051;
    }
}

.btn.purple-studio:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.purple-studio:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.purple-studio:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #8E44AD;
            border-color: #8E44AD;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #8E44AD;
            border-color: #8E44AD;
        }
    }
}

fieldset[disabled] .btn.purple-studio:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #8E44AD;
        border-color: #8E44AD;
    }
}

.btn {
    &.purple-studio:not(.btn-outline) .badge {
        color: #8E44AD;
        background-color: #fff;
    }
    &.btn-outline.purple-studio {
        border-color: #8E44AD;
        color: #8E44AD;
        background: 0 0;
        &.active {
            border-color: #8E44AD;
            color: #fff;
            background-color: #8E44AD;
        }
        &:active {
            border-color: #8E44AD;
            color: #fff;
            background-color: #8E44AD;
            &:focus,
            &:hover {
                border-color: #8E44AD;
                color: #fff;
                background-color: #8E44AD;
            }
        }
        &:focus,
        &:hover {
            border-color: #8E44AD;
            color: #fff;
            background-color: #8E44AD;
        }
    }
    &.purple-studio-stripe {
        border-left: 4px solid #8E44AD !important;
    }
    &.purple-studio.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.purple-wisteria:not(.btn-outline) {
        color: #fff;
        background-color: #9B59B6;
        border-color: #9B59B6;
        &.focus,
        &:focus {
            color: #fff;
            background-color: #804399;
            border-color: #532c64;
        }
        &.active,
        &:active,
        &:hover {
            color: #fff;
            background-color: #804399;
            border-color: #7a4092;
        }
    }
}

.open>.btn.purple-wisteria:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #804399;
    border-color: #7a4092;
}

.btn.purple-wisteria:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #6b3880;
            border-color: #532c64;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #6b3880;
            border-color: #532c64;
        }
    }
}

.open>.btn.purple-wisteria:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #fff;
        background-color: #6b3880;
        border-color: #532c64;
    }
}

.btn.purple-wisteria:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.purple-wisteria:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.purple-wisteria:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #9B59B6;
            border-color: #9B59B6;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #9B59B6;
            border-color: #9B59B6;
        }
    }
}

fieldset[disabled] .btn.purple-wisteria:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #9B59B6;
        border-color: #9B59B6;
    }
}

.btn {
    &.purple-wisteria:not(.btn-outline) .badge {
        color: #9B59B6;
        background-color: #fff;
    }
    &.btn-outline.purple-wisteria {
        border-color: #9B59B6;
        color: #9B59B6;
        background: 0 0;
        &.active {
            border-color: #9B59B6;
            color: #fff;
            background-color: #9B59B6;
        }
        &:active {
            border-color: #9B59B6;
            color: #fff;
            background-color: #9B59B6;
            &:focus,
            &:hover {
                border-color: #9B59B6;
                color: #fff;
                background-color: #9B59B6;
            }
        }
        &:focus,
        &:hover {
            border-color: #9B59B6;
            color: #fff;
            background-color: #9B59B6;
        }
    }
    &.purple-wisteria-stripe {
        border-left: 4px solid #9B59B6 !important;
    }
    &.purple-wisteria.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.purple-seance:not(.btn-outline) {
        color: #fff;
        background-color: #9A12B3;
        border-color: #9A12B3;
        &.focus,
        &:focus {
            color: #fff;
            background-color: #720d85;
            border-color: #36063f;
        }
        &.active,
        &:active,
        &:hover {
            color: #fff;
            background-color: #720d85;
            border-color: #6a0c7b;
        }
    }
}

.open>.btn.purple-seance:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #720d85;
    border-color: #6a0c7b;
}

.btn.purple-seance:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #560a64;
            border-color: #36063f;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #560a64;
            border-color: #36063f;
        }
    }
}

.open>.btn.purple-seance:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #fff;
        background-color: #560a64;
        border-color: #36063f;
    }
}

.btn.purple-seance:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.purple-seance:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.purple-seance:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #9A12B3;
            border-color: #9A12B3;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #9A12B3;
            border-color: #9A12B3;
        }
    }
}

fieldset[disabled] .btn.purple-seance:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #9A12B3;
        border-color: #9A12B3;
    }
}

.btn {
    &.purple-seance:not(.btn-outline) .badge {
        color: #9A12B3;
        background-color: #fff;
    }
    &.btn-outline.purple-seance {
        border-color: #9A12B3;
        color: #9A12B3;
        background: 0 0;
        &.active {
            border-color: #9A12B3;
            color: #fff;
            background-color: #9A12B3;
        }
        &:active {
            border-color: #9A12B3;
            color: #fff;
            background-color: #9A12B3;
            &:focus,
            &:hover {
                border-color: #9A12B3;
                color: #fff;
                background-color: #9A12B3;
            }
        }
        &:focus,
        &:hover {
            border-color: #9A12B3;
            color: #fff;
            background-color: #9A12B3;
        }
    }
    &.purple-seance-stripe {
        border-left: 4px solid #9A12B3 !important;
    }
    &.purple-seance.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.purple-intense:not(.btn-outline) {
        color: #fff;
        background-color: #8775a7;
        border-color: #8775a7;
        &.focus,
        &:focus {
            color: #fff;
            background-color: #6d5b8e;
            border-color: #493d60;
        }
        &.active,
        &:active,
        &:hover {
            color: #fff;
            background-color: #6d5b8e;
            border-color: #685788;
        }
    }
}

.open>.btn.purple-intense:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #6d5b8e;
    border-color: #685788;
}

.btn.purple-intense:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #5d4d78;
            border-color: #493d60;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #5d4d78;
            border-color: #493d60;
        }
    }
}

.open>.btn.purple-intense:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #fff;
        background-color: #5d4d78;
        border-color: #493d60;
    }
}

.btn.purple-intense:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.purple-intense:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.purple-intense:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #8775a7;
            border-color: #8775a7;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #8775a7;
            border-color: #8775a7;
        }
    }
}

fieldset[disabled] .btn.purple-intense:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #8775a7;
        border-color: #8775a7;
    }
}

.btn {
    &.purple-intense:not(.btn-outline) .badge {
        color: #8775a7;
        background-color: #fff;
    }
    &.btn-outline.purple-intense {
        border-color: #8775a7;
        color: #8775a7;
        background: 0 0;
        &.active {
            border-color: #8775a7;
            color: #fff;
            background-color: #8775a7;
        }
        &:active {
            border-color: #8775a7;
            color: #fff;
            background-color: #8775a7;
            &:focus,
            &:hover {
                border-color: #8775a7;
                color: #fff;
                background-color: #8775a7;
            }
        }
        &:focus,
        &:hover {
            border-color: #8775a7;
            color: #fff;
            background-color: #8775a7;
        }
    }
    &.purple-intense-stripe {
        border-left: 4px solid #8775a7 !important;
    }
    &.purple-intense.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.purple-sharp:not(.btn-outline) {
        color: #fff;
        background-color: #796799;
        border-color: #796799;
        &.focus,
        &:focus {
            color: #fff;
            background-color: #61527b;
            border-color: #3d344d;
        }
        &.active,
        &:active,
        &:hover {
            color: #fff;
            background-color: #61527b;
            border-color: #5c4e75;
        }
    }
}

.open>.btn.purple-sharp:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #61527b;
    border-color: #5c4e75;
}

.btn.purple-sharp:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #504465;
            border-color: #3d344d;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #504465;
            border-color: #3d344d;
        }
    }
}

.open>.btn.purple-sharp:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #fff;
        background-color: #504465;
        border-color: #3d344d;
    }
}

.btn.purple-sharp:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.purple-sharp:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.purple-sharp:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #796799;
            border-color: #796799;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #796799;
            border-color: #796799;
        }
    }
}

fieldset[disabled] .btn.purple-sharp:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #796799;
        border-color: #796799;
    }
}

.btn {
    &.purple-sharp:not(.btn-outline) .badge {
        color: #796799;
        background-color: #fff;
    }
    &.btn-outline.purple-sharp {
        border-color: #796799;
        color: #796799;
        background: 0 0;
        &.active {
            border-color: #796799;
            color: #fff;
            background-color: #796799;
        }
        &:active {
            border-color: #796799;
            color: #fff;
            background-color: #796799;
            &:focus,
            &:hover {
                border-color: #796799;
                color: #fff;
                background-color: #796799;
            }
        }
        &:focus,
        &:hover {
            border-color: #796799;
            color: #fff;
            background-color: #796799;
        }
    }
    &.purple-sharp-stripe {
        border-left: 4px solid #796799 !important;
    }
    &.purple-sharp.btn-no-border:not(.active) {
        border-color: transparent;
    }
    &.purple-soft:not(.btn-outline) {
        color: #fff;
        background-color: #8877a9;
        border-color: #8877a9;
        &.focus,
        &:focus {
            color: #fff;
            background-color: #6e5c91;
            border-color: #4a3e62;
        }
        &.active,
        &:active,
        &:hover {
            color: #fff;
            background-color: #6e5c91;
            border-color: #69588b;
        }
    }
}

.open>.btn.purple-soft:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #6e5c91;
    border-color: #69588b;
}

.btn.purple-soft:not(.btn-outline) {
    &.active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #5d4e7b;
            border-color: #4a3e62;
        }
    }
    &:active {
        &.focus,
        &:focus,
        &:hover {
            color: #fff;
            background-color: #5d4e7b;
            border-color: #4a3e62;
        }
    }
}

.open>.btn.purple-soft:not(.btn-outline).dropdown-toggle {
    &.focus,
    &:focus,
    &:hover {
        color: #fff;
        background-color: #5d4e7b;
        border-color: #4a3e62;
    }
}

.btn.purple-soft:not(.btn-outline) {
    &.active,
    &:active {
        background-image: none;
    }
}

.open>.btn.purple-soft:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.purple-soft:not(.btn-outline) {
    &.disabled {
        &.focus,
        &:focus,
        &:hover {
            background-color: #8877a9;
            border-color: #8877a9;
        }
    }
    &[disabled] {
        &.focus,
        &:focus,
        &:hover {
            background-color: #8877a9;
            border-color: #8877a9;
        }
    }
}

fieldset[disabled] .btn.purple-soft:not(.btn-outline) {
    &.focus,
    &:focus,
    &:hover {
        background-color: #8877a9;
        border-color: #8877a9;
    }
}

.btn {
    &.purple-soft:not(.btn-outline) .badge {
        color: #8877a9;
        background-color: #fff;
    }
    &.btn-outline.purple-soft {
        border-color: #8877a9;
        color: #8877a9;
        background: 0 0;
        &.active {
            border-color: #8877a9;
            color: #fff;
            background-color: #8877a9;
        }
        &:active {
            border-color: #8877a9;
            color: #fff;
            background-color: #8877a9;
            &:focus,
            &:hover {
                border-color: #8877a9;
                color: #fff;
                background-color: #8877a9;
            }
        }
        &:focus,
        &:hover {
            border-color: #8877a9;
            color: #fff;
            background-color: #8877a9;
        }
    }
    &.purple-soft-stripe {
        border-left: 4px solid #8877a9 !important;
    }
    &.purple-soft.btn-no-border:not(.active) {
        border-color: transparent;
    }
}

.btn-circle {
    border-radius: 25px !important;
    overflow: hidden;
}

.btn-circle-right {
    border-radius: 0 25px 25px 0 !important;
}

.btn-circle-left {
    border-radius: 25px 0 0 25px !important;
}

.btn-circle-bottom {
    border-radius: 0 0 25px 25px !important;
}

.btn-circle-top {
    border-radius: 25px 25px 0 0 !important;
}

.btn-icon-only {
    height: 34px;
    width: 34px;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    > {
        [class^=icon-],
        i {
            text-align: center;
            margin-top: 2px;
        }
    }
}

.btn-group {
    &.btn-group-circle>.btn {
        &:first-child {
            border-radius: 25px 0 0 25px !important;
        }
        &:last-child {
            border-radius: 0 25px 25px 0 !important;
        }
    }
    &.btn-group-devided>.btn {
        margin-right: 5px;
        &:last-child {
            margin-right: 0;
        }
    }
}

.btn-group-vertical.btn-group-vertical-circle>.btn {
    &:first-child {
        border-radius: 25px 25px 0 0 !important;
    }
    &:last-child {
        border-radius: 0 0 25px 25px !important;
    }
}

.carousel.image-carousel {
    .carousel-inner {
        padding-top: 0;
        padding-bottom: 0;
    }
    .carousel-control i {
        position: absolute;
        top: 40%;
    }
    &.image-carousel-hoverable {
        .carousel-control i {
            display: none;
        }
        &:hover .carousel-control i {
            display: inline-block;
        }
    }
    .carousel-control {
        &.left i {
            left: 10px;
        }
        &.right i {
            right: 10px;
        }
    }
    .carousel-indicators {
        margin-top: 10px;
        bottom: -7px;
        li {
            background-color: #666;
            &.active {
                background-color: #666;
            }
        }
    }
    .carousel-caption {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 15px 15px 25px;
        background: #333;
        background: rgba(0, 0, 0, 0.75);
        h2,
        h3,
        h4,
        p {
            text-align: left;
            line-height: 20px;
            color: #fff;
        }
        h2,
        h3,
        h4 {
            margin: 0 0 5px;
        }
        h2 a,
        h3 a,
        h4 a {
            color: #aaa;
        }
        p {
            margin-bottom: 0;
        }
        .item {
            margin: 0;
        }
    }
}

.bars,
.chart,
.pie {
    overflow: hidden;
    height: 300px;
    width: 100%;
    font-size: 14px;
}

.item-list.table .percent {
    width: 30px;
    float: right;
    margin-right: 10px;
    margin-top: 3px;
}

.chart-tooltip {
    clear: both;
    z-index: 100;
    background-color: #736e6e !important;
    padding: 5px !important;
    color: #fff;
    .label {
        clear: both;
        display: block;
        margin-bottom: 2px;
    }
}

.bar-chart,
.line-chart {
    display: none;
}

.chats {
    margin: -15px 0 0;
    padding: 0;
    li {
        list-style: none;
        padding: 5px 0;
        margin: 10px auto;
        font-size: 12px;
        .body {
            display: block;
        }
        .avatar {
            height: 45px;
            width: 45px;
            -webkit-border-radius: 50% !important;
            -moz-border-radius: 50% !important;
            border-radius: 50% !important;
        }
        &.in .avatar {
            float: left;
            margin-right: 10px;
        }
        &.out .avatar {
            float: right;
            margin-left: 10px;
        }
        .name {
            color: #3590c1;
            font-size: 13px;
            font-weight: 400;
        }
        .datetime {
            color: #333;
            font-size: 13px;
            font-weight: 400;
        }
        .message {
            display: block;
            padding: 5px;
            position: relative;
        }
        &.in .message .arrow,
        &.out .message .arrow {
            display: block;
            position: absolute;
            top: 5px;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
        }
        &.in .message {
            text-align: left;
            border-left: 2px solid #1BBC9B;
            margin-left: 65px;
            background: #fafafa;
            .arrow {
                left: -8px;
                width: 0;
                height: 0;
                border-right: 8px solid #1BBC9B;
            }
        }
        &.out {
            .message {
                border-right: 2px solid #F3565D;
                margin-right: 65px;
                background: #fafafa;
                text-align: right;
                .arrow {
                    right: -8px;
                    border-left: 8px solid #F3565D;
                }
            }
            .datetime,
            .name {
                text-align: right;
            }
        }
    }
}

.chat-form {
    margin-top: 15px;
    padding: 10px;
    background-color: #e9eff3;
    overflow: hidden;
    clear: both;
    .input-cont {
        margin-right: 40px;
        .form-control {
            border: 1px solid #ddd;
            width: 100% !important;
            margin-top: 0;
            background-color: #fff !important;
            &:focus {
                border: 1px solid #4b8df9 !important;
            }
        }
    }
    .btn-cont {
        margin-top: -41px;
        position: relative;
        float: right;
        width: 44px;
        .arrow {
            position: absolute;
            top: 17px;
            right: 43px;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-right: 8px solid #4d90fe;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }
        .btn {
            margin-top: 7px;
        }
        &:hover {
            .arrow {
                border-right-color: #0362fd;
                transition: all .3s;
            }
            .btn {
                background-color: #0362fd;
                transition: all .3s;
            }
        }
    }
}

.close {
    display: inline-block;
    margin-top: 0;
    margin-right: 0;
    width: 9px;
    height: 9px;
    background-repeat: no-repeat !important;
    text-indent: -10000px;
    outline: 0;
    background-image: url(../../img/admin/remove-icon-small.png) !important;
}

.dropdown.open>.dropdown-toggle,
.dropup.open>.dropdown-toggle {
    border-color: #ddd;
}

.dropdown-menu {
    box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
    left: 0;
    min-width: 175px;
    position: absolute;
    z-index: 1000;
    display: none;
    float: left;
    list-style: none;
    text-shadow: none;
    padding: 0;
    background-color: #fff;
    margin: 10px 0 0;
    border: 1px solid #eee;
    // font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    li {
        &.divider {
            background: #f1f3f6;
        }
        &.header {
            padding: 8px 14px 2px;
        }
    }
    >li {
        >a {
            padding: 8px 16px;
            color: #333;
            text-decoration: none;
            display: block;
            clear: both;
            font-weight: 300;
            line-height: 18px;
            white-space: nowrap;
            > {
                [class*=" fa-"],
                [class^=fa-] {
                    color: #888;
                }
                [class*=" icon-"],
                [class^=icon-] {
                    color: #666;
                }
                [class*=" glyphicon-"],
                [class^=glyphicon-] {
                    color: #888;
                }
            }
        }
        &.active {
            &:hover>a,
            >a {
                text-decoration: none;
                background-image: none;
                background-color: #f6f6f6;
                color: #555;
                filter: none;
            }
        }
        &:hover>a {
            text-decoration: none;
            background-image: none;
            background-color: #f6f6f6;
            color: #555;
            filter: none;
        }
    }
    &.bottom-up {
        top: auto;
        bottom: 100%;
        margin-bottom: 2px;
        &:after,
        &:before {
            display: none;
        }
    }
    >li {
        &:first-child:hover>a {
            border-radius: 4px 4px 0 0;
        }
        &:last-child:hover>a {
            border-radius: 0 0 4px 4px;
        }
    }
}

.dropdown-hover:hover>.dropdown-menu {
    display: block;
}

.btn-group>.dropdown-menu,
.dropdown-toggle>.dropdown-menu,
.dropdown>.dropdown-menu {
    margin-top: 10px;
}

.btn-group>.dropdown-menu:before,
.dropdown-toggle>.dropdown-menu:before,
.dropdown>.dropdown-menu:before {
    position: absolute;
    top: -8px;
    left: 9px;
    right: auto;
    display: inline-block !important;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #e0e0e0;
    border-left: 8px solid transparent;
    content: '';
}

.btn-group>.dropdown-menu:after,
.dropdown-toggle>.dropdown-menu:after,
.dropdown>.dropdown-menu:after {
    position: absolute;
    top: -7px;
    left: 10px;
    right: auto;
    display: inline-block !important;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;
    border-left: 7px solid transparent;
    content: '';
}

.btn-group>.dropdown-menu.pull-left:before,
.dropdown-toggle>.dropdown-menu.pull-left:before,
.dropdown>.dropdown-menu.pull-left:before {
    left: auto;
    right: 9px;
}

.btn-group>.dropdown-menu.pull-left:after,
.dropdown-toggle>.dropdown-menu.pull-left:after,
.dropdown>.dropdown-menu.pull-left:after {
    left: auto;
    right: 10px;
}

.btn-group>.dropdown-menu.pull-right:before,
.dropdown-toggle>.dropdown-menu.pull-right:before,
.dropdown>.dropdown-menu.pull-right:before {
    left: auto;
    right: 9px;
}

.btn-group>.dropdown-menu.pull-right:after,
.dropdown-toggle>.dropdown-menu.pull-right:after,
.dropdown>.dropdown-menu.pull-right:after {
    left: auto;
    right: 10px;
}

.btn-group.dropup>.dropdown-menu,
.dropdown-toggle.dropup>.dropdown-menu,
.dropdown.dropup>.dropdown-menu {
    margin-top: 0;
    margin-bottom: 10px;
}

.btn-group.dropup>.dropdown-menu {
    &:after,
    &:before {
        display: none !important;
    }
}

.dropdown-toggle.dropup>.dropdown-menu {
    &:after,
    &:before {
        display: none !important;
    }
}

.dropdown.dropup>.dropdown-menu {
    &:after,
    &:before {
        display: none !important;
    }
}

.dropdown-toggle:after {
    display: none;
}

.dropdown-submenu {
    position: relative;
    > {
        .dropdown-menu {
            top: 5px;
            left: 100%;
            margin-top: -6px;
            margin-left: -1px;
        }
        a:after {
            position: absolute;
            display: inline-block;
            font-size: 14px;
            right: 7px;
            top: 7px;
            font-family: FontAwesome;
            height: auto;
            content: "\f105";
            font-weight: 300;
        }
    }
    &:hover {
        > {
            .dropdown-menu {
                display: block;
            }
            a:after {
                border-left-color: #fff;
            }
        }
    }
    &.pull-left {
        float: none;
        >.dropdown-menu {
            left: -100%;
            margin-left: 10px;
        }
    }
}

.dropup .dropdown-submenu>.dropdown-menu {
    top: auto;
    bottom: 0;
    margin-top: 0;
    margin-bottom: -2px;
}

.nav {
    &.pull-right {
        >li>.dropdown-menu {
            right: 0;
            left: auto;
        }
        >li>.dropdown-menu:before {
            right: 12px;
            left: auto;
        }
        >li>.dropdown-menu:after {
            right: 13px;
            left: auto;
        }
        >li>.dropdown-menu .dropdown-menu {
            right: 100%;
            left: auto;
            margin-right: -1px;
            margin-left: 0;
        }
    }
    >li>.dropdown-menu.pull-right {
        right: 0;
        left: auto;
    }
    >li>.dropdown-menu.pull-right:before {
        right: 12px;
        left: auto;
    }
    >li>.dropdown-menu.pull-right:after {
        right: 13px;
        left: auto;
    }
    >li>.dropdown-menu.pull-right .dropdown-menu {
        right: 100%;
        left: auto;
        margin-right: -1px;
        margin-left: 0;
    }
}

@media (max-width: 767px) {
    .navbar-nav .open .dropdown-menu {
        position: absolute;
        float: left;
        width: auto;
        margin-top: 0;
        background-color: #fff;
        border: 1px solid #efefef;
        box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
        >li>a {
            padding: 6px 0 6px 13px;
            color: #333;
            &:active,
            &:hover {
                background-color: #eee;
            }
        }
    }
}

.dropdown-content {
    padding: 10px;
    form {
        margin: 0;
    }
}

.dropdown.inline .dropdown-menu {
    display: inline-block;
    position: relative;
}

.dropdown-checkboxes,
.dropdown-radiobuttons {
    padding: 5px;
}

.dropdown-checkboxes label,
.dropdown-radiobuttons label {
    display: block;
    font-weight: 300;
    color: #333;
    margin-bottom: 4px;
    margin-top: 4px;
}

.dropdown-checkboxes label .radio,
.dropdown-radiobuttons label .radio {
    margin-right: 3px;
}

.dropdown-menu-v2 {
    top: 100%;
    right: 0;
    z-index: 1001;
    display: none;
    float: left;
    min-width: 225px;
    padding: 20px 0;
    margin: 25px 0 0;
    font-size: 13px;
    text-align: left;
    list-style: none;
    position: absolute;
    background: #242b31;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    transition-duration: .3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
    &:before {
        top: -10px;
        right: 0;
        width: 0;
        height: 0;
        content: ' ';
        position: absolute;
        border-style: solid;
        display: inline-block;
        border-width: 0 0 15px 15px;
        border-color: transparent transparent #242b31;
    }
}

.feeds li .col1 {
    width: 100%;
    float: left;
    >.cont>.cont-col2 {
        width: 100%;
        float: left;
    }
}

.dropdown-menu-v2 {
    >li>a {
        clear: both;
        color: #606e7a;
        display: block;
        font-weight: 600;
        padding: 8px 20px;
        position: relative;
        white-space: nowrap;
        line-height: 1.42857143;
        text-transform: uppercase;
        &:focus,
        &:hover {
            background: #21282e;
            text-decoration: none;
            color: #009dc7;
        }
    }
    .active>a {
        outline: 0;
        text-decoration: none;
        background-color: #21282e;
        color: #009dc7;
        &:focus,
        &:hover {
            outline: 0;
            text-decoration: none;
            background-color: #21282e;
            color: #009dc7;
        }
    }
    .disabled>a {
        color: #009dc7;
        &:focus,
        &:hover {
            color: #009dc7;
        }
        &:focus,
        &:hover {
            text-decoration: none;
            cursor: not-allowed;
            background-color: transparent;
            background-image: none;
        }
    }
    .divider {
        height: 1px;
        margin: 9px 0;
        overflow: hidden;
        background: #2e353c;
    }
    .badge {
        top: 8px;
        right: 10px;
        position: absolute;
    }
    &.pull-right {
        right: 0;
        left: auto;
    }
}

.pull-right>.dropdown-menu-v2 {
    right: 0;
    left: auto;
}

.open>.dropdown-menu-v2 {
    display: block;
}

td {
    > {
        .mt-checkbox.mt-checkbox-single,
        .mt-radio.mt-radio-single {
            right: -5px;
        }
    }
}

th {
    > {
        .mt-checkbox.mt-checkbox-single,
        .mt-radio.mt-radio-single {
            right: -5px;
        }
    }
}

.feeds {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
        background-color: #fafafa;
        color: #82949a;
        margin-bottom: 7px;
        &:after,
        &:before {
            display: table;
            line-height: 0;
            content: "";
        }
        &:after {
            clear: both;
        }
        &:last-child {
            margin-bottom: 0;
        }
        .col1 {
            clear: both;
            >.cont {
                float: left;
                margin-right: 75px;
                overflow: hidden;
                > {
                    .cont-col1 {
                        float: left;
                        margin-right: -100%;
                        >.label {
                            display: inline-block;
                            padding: 5px 4px 6px 5px;
                            vertical-align: middle;
                            text-align: center;
                            >i {
                                text-align: center;
                                font-size: 14px;
                            }
                        }
                    }
                    .cont-col2>.desc {
                        margin-left: 35px;
                        padding-top: 4px;
                        padding-bottom: 5px;
                        overflow: hidden;
                    }
                }
            }
        }
        .col2 {
            float: left;
            width: 75px;
            margin-left: -75px;
        }
    }
}

.input-mini,
.input-xxs {
    width: 45px !important;
}

.feeds li .col2>.date {
    padding: 4px 9px 5px 4px;
    text-align: right;
    font-style: italic;
    color: #c1cbd0;
}

.static-info {
    margin-bottom: 10px;
    .name {
        font-size: 14px;
    }
    .value {
        font-size: 14px;
        font-weight: 600;
    }
    &.align-reverse {
        .name,
        .value {
            text-align: right;
        }
    }
}

.help-block {
    margin-top: 5px;
    margin-bottom: 5px;
}

.help-inline {
    font-size: 13px;
    color: #737373;
    display: inline-block;
    padding: 5px;
}

.form-inline input {
    margin-bottom: 0 !important;
}

.control-label {
    margin-top: 1px;
    font-weight: 400;
    .required {
        color: #e02222;
        font-size: 12px;
        padding-left: 2px;
    }
}

.form-group .required {
    color: #e02222;
    font-size: 12px;
    padding-left: 2px;
}

.form {
    padding: 0 !important;
    .form-body {
        padding: 20px;
    }
}

.portlet-form .form-body {
    padding: 20px;
}

.portlet.light {
    .form .form-body,
    .portlet-form .form-body {
        padding-left: 0;
        padding-right: 0;
    }
}

.form .form-actions,
.portlet-form .form-actions {
    padding: 20px;
    margin: 0;
    background-color: #f5f5f5;
    border-top: 1px solid #e7ecf1;
}

.portlet.light {
    .form .form-actions,
    .portlet-form .form-actions {
        background: 0 0;
        padding-left: 0;
        padding-right: 0;
    }
}

.form .form-actions.nobg,
.portlet-form .form-actions.nobg,
.tiles .tile .tile-object {
    background-color: transparent;
}

.form .form-actions.noborder,
.portlet-form .form-actions.noborder {
    border-top: 0;
}

.portlet {
    .form .form-actions,
    .portlet-form .form-actions {
        -webkit-border-radius: 0 0 4px 4px;
        -moz-border-radius: 0 0 4px 4px;
        -ms-border-radius: 0 0 4px 4px;
        -o-border-radius: 0 0 4px 4px;
        border-radius: 0 0 4px 4px;
    }
}

.form .form-actions {
    &:after,
    &:before {
        content: " ";
        display: table;
    }
}

.portlet-form .form-actions {
    &:after,
    &:before {
        content: " ";
        display: table;
    }
}

.form .form-actions:after,
.portlet-form .form-actions:after {
    clear: both;
}

.form .form-actions.right,
.portlet-form .form-actions.right {
    padding-left: 0;
    padding-right: 20px;
    text-align: right;
}

.portlet.light {
    .form .form-actions.right,
    .portlet-form .form-actions.right {
        padding-right: 0;
    }
}

.form .form-actions.left,
.portlet-form .form-actions.left {
    padding-left: 20px;
    padding-right: 0;
    text-align: left;
}

.checkbox-list>label.checkbox-inline:first-child,
.checkbox>label,
.form-horizontal .checkbox>label {
    padding-left: 0;
}

.portlet.light {
    .form .form-actions.left,
    .portlet-form .form-actions.left {
        padding-left: 0;
    }
}

.radio-list>label.radio-inline:first-child {
    padding-left: 0;
}

.form .form-actions.top,
.portlet-form .form-actions.top {
    margin-top: 0;
    margin-bottom: 20px;
    border-top: 0;
    border-bottom: 1px solid #e7ecf1;
}

.portlet.light {
    .form .form-actions.top,
    .portlet-form .form-actions.top {
        background: 0 0;
    }
}

.form .form-actions .btn-set,
.portlet-form .form-actions .btn-set {
    display: inline-block;
}

@media (max-width: 767px) {
    .form .form-actions .btn-set,
    .portlet-form .form-actions .btn-set {
        margin-bottom: 3px;
        margin-top: 3px;
        float: left !important;
    }
}

.form .form-section,
.portlet-form .form-section {
    margin: 30px 0;
    padding-bottom: 5px;
    border-bottom: 1px solid #e7ecf1;
}

.form {
    .form-bordered .form-group .help-block,
    .form-row-seperated .form-group .help-block {
        margin-bottom: 0;
    }
}

.form-horizontal .radio-list>label {
    margin-bottom: 0;
}

.form-fit {
    .form .form-section,
    .portlet-form .form-section {
        margin-left: 20px;
        margin-right: 20px;
    }
}

.checkbox,
.form-horizontal .checkbox {
    padding: 0;
}

.checkbox-list>label {
    display: block;
    &.checkbox-inline {
        display: inline-block;
    }
}

.radio-list>label {
    display: block;
}

.form-control-static,
.input-inline,
.radio-list>label.radio-inline {
    display: inline-block;
}

.form-horizontal {
    .radio-list .radio {
        padding-top: 1px;
    }
    .radio>span {
        margin-top: 2px;
    }
}

.form {
    .form-row-seperated {
        .portlet-body {
            padding: 0;
        }
        .form-group {
            margin: 0;
            border-bottom: 1px solid #efefef;
            padding: 15px 0;
            &.last {
                border-bottom: 0;
                margin-bottom: 0;
                padding-bottom: 13px;
            }
        }
        .form-body {
            padding: 0;
        }
        .form-actions {
            padding-left: 15px !important;
            padding-right: 15px !important;
        }
    }
    .form-bordered {
        .form-group {
            margin: 0;
            border-bottom: 1px solid #efefef;
            >div {
                padding: 15px;
                border-left: 1px solid #efefef;
            }
            &.last {
                border-bottom: 0;
            }
            .control-label {
                padding-top: 20px;
            }
            .form-control {
                margin: 0;
            }
        }
        .form-body {
            margin: 0;
            padding: 0;
        }
        .form-actions {
            margin-top: 0;
            padding-left: 16px !important;
            padding-right: 16px !important;
        }
    }
    .form-horizontal.form-bordered {
        &.form-row-stripped {
            .form-group:nth-child(even) {
                background-color: #fcfcfc;
            }
            .form-control {
                background: #fff !important;
            }
        }
        &.form-label-stripped .form-group:nth-child(even) {
            >div {
                background-color: #fff;
            }
            background-color: #fcfcfc;
        }
    }
}

@media (max-width: 991px) {
    .form .form-bordered .form-group {
        >div {
            border-left: 0;
        }
        .control-label {
            padding-top: 10px;
        }
    }
}

@media (max-width: 991px) {
    .form .form-bordered .form-actions {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

.form-horizontal .form-group {
    .input-sm {
        margin-top: 3px;
    }
    .form-control-static {
        margin-top: 1px;
    }
}

.form-control {
    outline: 0 !important;
    border: 0;
    padding: 8px 24px 6px 25px;
    &.height-auto {
        height: auto;
    }
}

.input-group-addon>i {
    color: #c5cdda;
}

.form-control {
    &.white {
        border-color: #fff;
        &:focus {
            border-color: #e6e6e6;
        }
    }
    &.focus-white:focus {
        border-color: #fff;
    }
    &.default {
        border-color: #e1e5ec;
        &:focus {
            border-color: #c2cad8;
        }
    }
    &.focus-default:focus {
        border-color: #e1e5ec;
    }
    &.dark {
        border-color: #2f353b;
        &:focus {
            border-color: #181c1f;
        }
    }
    &.focus-dark:focus {
        border-color: #2f353b;
    }
    &.blue {
        border-color: #3598dc;
        &:focus {
            border-color: #217ebd;
        }
    }
    &.focus-blue:focus {
        border-color: #3598dc;
    }
    &.blue-madison {
        border-color: #578ebe;
        &:focus {
            border-color: #3f74a3;
        }
    }
    &.focus-blue-madison:focus {
        border-color: #578ebe;
    }
    &.blue-chambray {
        border-color: #2C3E50;
        &:focus {
            border-color: #1a252f;
        }
    }
    &.focus-blue-chambray:focus {
        border-color: #2C3E50;
    }
    &.blue-ebonyclay {
        border-color: #22313F;
        &:focus {
            border-color: #10171e;
        }
    }
    &.focus-blue-ebonyclay:focus {
        border-color: #22313F;
    }
    &.blue-hoki {
        border-color: #67809F;
        &:focus {
            border-color: #526781;
        }
    }
    &.focus-blue-hoki:focus {
        border-color: #67809F;
    }
    &.blue-steel {
        border-color: #4B77BE;
        &:focus {
            border-color: #395f9d;
        }
    }
    &.focus-blue-steel:focus {
        border-color: #4B77BE;
    }
    &.blue-soft {
        border-color: #4c87b9;
        &:focus {
            border-color: #3b6d97;
        }
    }
    &.focus-blue-soft:focus {
        border-color: #4c87b9;
    }
    &.blue-dark {
        border-color: #5e738b;
        &:focus {
            border-color: #495a6d;
        }
    }
    &.focus-blue-dark:focus {
        border-color: #5e738b;
    }
    &.blue-sharp {
        border-color: #5C9BD1;
        &:focus {
            border-color: #3782c3;
        }
    }
    &.focus-blue-sharp:focus {
        border-color: #5C9BD1;
    }
    &.blue-oleo {
        border-color: #94A0B2;
        &:focus {
            border-color: #76869d;
        }
    }
    &.focus-blue-oleo:focus {
        border-color: #94A0B2;
    }
    &.green {
        border-color: #32c5d2;
        &:focus {
            border-color: #26a1ab;
        }
    }
    &.focus-green:focus {
        border-color: #32c5d2;
    }
    &.green-meadow {
        border-color: #1BBC9B;
        &:focus {
            border-color: #158f76;
        }
    }
    &.focus-green-meadow:focus {
        border-color: #1BBC9B;
    }
    &.green-seagreen {
        border-color: #1BA39C;
        &:focus {
            border-color: #147772;
        }
    }
    &.focus-green-seagreen:focus {
        border-color: #1BA39C;
    }
    &.green-turquoise {
        border-color: #36D7B7;
        &:focus {
            border-color: #24b699;
        }
    }
    &.focus-green-turquoise:focus {
        border-color: #36D7B7;
    }
    &.green-haze {
        border-color: #44b6ae;
        &:focus {
            border-color: #36918b;
        }
    }
    &.focus-green-haze:focus {
        border-color: #44b6ae;
    }
    &.green-jungle {
        border-color: #26C281;
        &:focus {
            border-color: #1e9765;
        }
    }
    &.focus-green-jungle:focus {
        border-color: #26C281;
    }
    &.green-soft {
        border-color: #3faba4;
        &:focus {
            border-color: #318680;
        }
    }
    &.focus-green-soft:focus {
        border-color: #3faba4;
    }
    &.green-dark {
        border-color: #4DB3A2;
        &:focus {
            border-color: #3d9082;
        }
    }
    &.focus-green-dark:focus {
        border-color: #4DB3A2;
    }
    &.green-sharp {
        border-color: #2ab4c0;
        &:focus {
            border-color: #218d96;
        }
    }
    &.focus-green-sharp:focus {
        border-color: #2ab4c0;
    }
    &.green-steel {
        border-color: #29b4b6;
        &:focus {
            border-color: #208b8c;
        }
    }
    &.focus-green-steel:focus {
        border-color: #29b4b6;
    }
    &.grey {
        border-color: #E5E5E5;
        &:focus {
            border-color: #ccc;
        }
    }
    &.focus-grey:focus {
        border-color: #E5E5E5;
    }
    &.grey-steel {
        border-color: #e9edef;
        &:focus {
            border-color: #cbd5da;
        }
    }
    &.focus-grey-steel:focus {
        border-color: #e9edef;
    }
    &.grey-cararra {
        border-color: #fafafa;
        &:focus {
            border-color: #e1e1e1;
        }
    }
    &.focus-grey-cararra:focus {
        border-color: #fafafa;
    }
    &.grey-gallery {
        border-color: #555;
        &:focus {
            border-color: #3c3c3c;
        }
    }
    &.focus-grey-gallery:focus {
        border-color: #555;
    }
    &.grey-cascade {
        border-color: #95A5A6;
        &:focus {
            border-color: #798d8f;
        }
    }
    &.focus-grey-cascade:focus {
        border-color: #95A5A6;
    }
    &.grey-silver {
        border-color: #BFBFBF;
        &:focus {
            border-color: #a6a6a6;
        }
    }
    &.focus-grey-silver:focus {
        border-color: #BFBFBF;
    }
    &.grey-salsa {
        border-color: #ACB5C3;
        &:focus {
            border-color: #8e9bae;
        }
    }
    &.focus-grey-salsa:focus {
        border-color: #ACB5C3;
    }
    &.grey-salt {
        border-color: #bfcad1;
        &:focus {
            border-color: #a1b1bc;
        }
    }
    &.focus-grey-salt:focus {
        border-color: #bfcad1;
    }
    &.grey-mint {
        border-color: #525e64;
        &:focus {
            border-color: #3b4448;
        }
    }
    &.focus-grey-mint:focus {
        border-color: #525e64;
    }
    &.red {
        border-color: #e7505a;
        &:focus {
            border-color: #e12330;
        }
    }
    &.focus-red:focus {
        border-color: #e7505a;
    }
    &.red-pink {
        border-color: #E08283;
        &:focus {
            border-color: #d6595a;
        }
    }
    &.focus-red-pink:focus {
        border-color: #E08283;
    }
    &.red-sunglo {
        border-color: #E26A6A;
        &:focus {
            border-color: #da3f3f;
        }
    }
    &.focus-red-sunglo:focus {
        border-color: #E26A6A;
    }
    &.red-intense {
        border-color: #e35b5a;
        &:focus {
            border-color: #dc302e;
        }
    }
    &.focus-red-intense:focus {
        border-color: #e35b5a;
    }
    &.red-thunderbird {
        border-color: #D91E18;
        &:focus {
            border-color: #ab1813;
        }
    }
    &.focus-red-thunderbird:focus {
        border-color: #D91E18;
    }
    &.red-flamingo {
        border-color: #EF4836;
        &:focus {
            border-color: #e02612;
        }
    }
    &.focus-red-flamingo:focus {
        border-color: #EF4836;
    }
    &.red-soft {
        border-color: #d05454;
        &:focus {
            border-color: #bd3434;
        }
    }
    &.focus-red-soft:focus {
        border-color: #d05454;
    }
    &.red-haze {
        border-color: #f36a5a;
        &:focus {
            border-color: #f03f2a;
        }
    }
    &.focus-red-haze:focus {
        border-color: #f36a5a;
    }
    &.red-mint {
        border-color: #e43a45;
        &:focus {
            border-color: #cf1c28;
        }
    }
    &.focus-red-mint:focus {
        border-color: #e43a45;
    }
    &.yellow {
        border-color: #c49f47;
        &:focus {
            border-color: #a48334;
        }
    }
    &.focus-yellow:focus {
        border-color: #c49f47;
    }
    &.yellow-gold {
        border-color: #E87E04;
        &:focus {
            border-color: #b66303;
        }
    }
    &.focus-yellow-gold:focus {
        border-color: #E87E04;
    }
    &.yellow-casablanca {
        border-color: #f2784b;
        &:focus {
            border-color: #ef541b;
        }
    }
    &.focus-yellow-casablanca:focus {
        border-color: #f2784b;
    }
    &.yellow-crusta {
        border-color: #f3c200;
        &:focus {
            border-color: #c09900;
        }
    }
    &.focus-yellow-crusta:focus {
        border-color: #f3c200;
    }
    &.yellow-lemon {
        border-color: #F7CA18;
        &:focus {
            border-color: #d5ab07;
        }
    }
    &.focus-yellow-lemon:focus {
        border-color: #F7CA18;
    }
    &.yellow-saffron {
        border-color: #F4D03F;
        &:focus {
            border-color: #f1c40f;
        }
    }
    &.focus-yellow-saffron:focus {
        border-color: #F4D03F;
    }
    &.yellow-soft {
        border-color: #c8d046;
        &:focus {
            border-color: #adb52e;
        }
    }
    &.focus-yellow-soft:focus {
        border-color: #c8d046;
    }
    &.yellow-haze {
        border-color: #c5bf66;
        &:focus {
            border-color: #b4ad44;
        }
    }
    &.focus-yellow-haze:focus {
        border-color: #c5bf66;
    }
    &.yellow-mint {
        border-color: #c5b96b;
        &:focus {
            border-color: #b6a747;
        }
    }
    &.focus-yellow-mint:focus {
        border-color: #c5b96b;
    }
    &.purple {
        border-color: #8E44AD;
        &:focus {
            border-color: #703688;
        }
    }
    &.focus-purple:focus {
        border-color: #8E44AD;
    }
    &.purple-plum {
        border-color: #8775a7;
        &:focus {
            border-color: #6d5b8e;
        }
    }
    &.focus-purple-plum:focus {
        border-color: #8775a7;
    }
    &.purple-medium {
        border-color: #BF55EC;
        &:focus {
            border-color: #ae27e7;
        }
    }
    &.focus-purple-medium:focus {
        border-color: #BF55EC;
    }
    &.purple-studio {
        border-color: #8E44AD;
        &:focus {
            border-color: #703688;
        }
    }
    &.focus-purple-studio:focus {
        border-color: #8E44AD;
    }
    &.purple-wisteria {
        border-color: #9B59B6;
        &:focus {
            border-color: #804399;
        }
    }
    &.focus-purple-wisteria:focus {
        border-color: #9B59B6;
    }
    &.purple-seance {
        border-color: #9A12B3;
        &:focus {
            border-color: #720d85;
        }
    }
    &.focus-purple-seance:focus {
        border-color: #9A12B3;
    }
    &.purple-intense {
        border-color: #8775a7;
        &:focus {
            border-color: #6d5b8e;
        }
    }
    &.focus-purple-intense:focus {
        border-color: #8775a7;
    }
    &.purple-sharp {
        border-color: #796799;
        &:focus {
            border-color: #61527b;
        }
    }
    &.focus-purple-sharp:focus {
        border-color: #796799;
    }
    &.purple-soft {
        border-color: #8877a9;
        &:focus {
            border-color: #6e5c91;
        }
    }
    &.focus-purple-soft:focus {
        border-color: #8877a9;
    }
}

.uneditable-input {
    padding: 6px 12px;
    min-width: 206px;
    font-size: 14px;
    font-weight: 400;
    // height: 32px;
    color: #555;
    background-color: #fff;
    // border: 1px solid #c2cad8;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.form-control-static {
    margin: 0;
}

.input-xsmall {
    width: 80px !important;
}

.input-small {
    width: 145px !important;
}

.input-medium {
    width: 240px !important;
}

.input-large {
    width: 320px !important;
}

.input-xlarge {
    width: 420px !important;
}

@media (max-width: 768px) {
    .input-large {
        width: 250px !important;
    }
    .input-xlarge {
        width: 300px !important;
    }
}

.input-inline {
    width: auto;
}

.input-fixed {
    overflow: hidden;
}

.form-group .input-inline {
    margin-right: 5px;
}

@media (max-width: 768px) {
    .input-lg {
        width: 250px !important;
    }
    .input-xlg {
        width: 300px !important;
    }
}

.input-circle {
    border-radius: 25px !important;
}

.input-circle-right {
    border-radius: 0 25px 25px 0 !important;
}

.input-circle-left {
    border-radius: 25px 0 0 25px !important;
}

.input-circle-bottom {
    border-radius: 0 0 25px 25px !important;
}

.input-circle-top {
    border-radius: 25px 25px 0 0 !important;
}

.mt-checkbox,
.mt-radio {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 14px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.mt-checkbox {
    &.mt-checkbox-disabled,
    &.mt-radio-disabled {
        opacity: .6;
        filter: alpha(opacity=60);
    }
}

.mt-radio {
    &.mt-checkbox-disabled,
    &.mt-radio-disabled {
        opacity: .6;
        filter: alpha(opacity=60);
    }
}

.mt-checkbox>input,
.mt-radio>input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    filter: alpha(opacity=0);
}

.mt-checkbox>span,
.mt-radio>span {
    border: 1px solid transparent;
    position: absolute;
    top: 0;
    left: 0;
    height: 19px;
    width: 19px;
    background: #E6E6E6;
}

.mt-checkbox>span:after,
.mt-radio>span:after {
    content: '';
    position: absolute;
    display: none;
}

.input-icon>i {
    display: block;
}

.mt-checkbox-list {
    .mt-checkbox,
    .mt-radio {
        display: block;
    }
}

.mt-checkbox>input:checked~span:after {
    display: block;
}

.mt-radio-list {
    .mt-checkbox,
    .mt-radio {
        display: block;
    }
}

.mt-radio>input:checked~span:after {
    display: block;
}

.mt-checkbox {
    &:hover>input:not([disabled])~span,
    >input:focus~span {
        background: #d9d9d9;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        -ms-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
    }
}

.mt-radio {
    &:hover>input:not([disabled])~span,
    >input:focus~span {
        background: #d9d9d9;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        -ms-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
    }
}

.mt-checkbox {
    &:hover>input:not([disabled]):checked~span,
    >input:checked~span {
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        -ms-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
        background: #d9d9d9;
    }
}

.mt-radio {
    &:hover>input:not([disabled]):checked~span,
    >input:checked~span {
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        -ms-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
        background: #d9d9d9;
    }
}

.icon-btn {
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    &:hover {
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -ms-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease;
    }
}

.mt-checkbox>input:disabled~span,
.mt-radio>input:disabled~span {
    opacity: .6;
    filter: alpha(opacity=60);
    pointer-events: none;
}

.icon-btn,
.ie8 .icon-btn:hover {
    filter: none;
}

.mt-checkbox {
    &.mt-checkbox-outline {
        &:hover>input:not([disabled]) {
            &:checked~span,
            ~span {
                border: 1px solid #d9d9d9;
                background: 0 0;
            }
        }
        > {
            input {
                &:checked~span,
                &:focus~span {
                    border: 1px solid #d9d9d9;
                    background: 0 0;
                }
            }
            span {
                border: 1px solid #d9d9d9;
                background: 0 0;
            }
        }
    }
    &.mt-radio-outline {
        &:hover>input:not([disabled]) {
            &:checked~span,
            ~span {
                border: 1px solid #d9d9d9;
                background: 0 0;
            }
        }
        > {
            input {
                &:checked~span,
                &:focus~span {
                    border: 1px solid #d9d9d9;
                    background: 0 0;
                }
            }
            span {
                border: 1px solid #d9d9d9;
                background: 0 0;
            }
        }
    }
}

.mt-radio {
    &.mt-checkbox-outline {
        &:hover>input:not([disabled]) {
            &:checked~span,
            ~span {
                border: 1px solid #d9d9d9;
                background: 0 0;
            }
        }
        > {
            input {
                &:checked~span,
                &:focus~span {
                    border: 1px solid #d9d9d9;
                    background: 0 0;
                }
            }
            span {
                border: 1px solid #d9d9d9;
                background: 0 0;
            }
        }
    }
    &.mt-radio-outline {
        &:hover>input:not([disabled]) {
            &:checked~span,
            ~span {
                border: 1px solid #d9d9d9;
                background: 0 0;
            }
        }
        > {
            input {
                &:checked~span,
                &:focus~span {
                    border: 1px solid #d9d9d9;
                    background: 0 0;
                }
            }
            span {
                border: 1px solid #d9d9d9;
                background: 0 0;
            }
        }
    }
    >span {
        -webkit-border-radius: 50% !important;
        -moz-border-radius: 50% !important;
        border-radius: 50% !important;
    }
}

.timeline .timeline-badge-userpic img {
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    border-radius: 50% !important;
}

.mt-radio {
    > {
        span {
            -ms-border-radius: 50% !important;
            -o-border-radius: 50% !important;
            border-radius: 50% !important;
            &:after {
                left: 6px;
                top: 6px;
                height: 6px;
                width: 6px;
                border-radius: 50%;
                background: #666;
            }
        }
        input:disabled~span:after {
            background: #666;
        }
    }
}

.mt-checkbox {
    > {
        span:after {
            left: 6px;
            top: 3px;
            width: 5px;
            height: 10px;
            border: solid #666;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
        input:disabled~span:after {
            border-color: #666;
        }
    }
}

.form-inline .mt-checkbox {
    margin-left: 15px;
    margin-right: 15px;
}

.mt-checkbox-list,
.mt-radio-list {
    padding: 10px 0;
}

.form-horizontal .form-group {
    .mt-checkbox-list,
    .mt-radio-list {
        padding-top: 0;
    }
}

.mt-checkbox-inline,
.mt-radio-inline {
    padding: 10px 0;
}

.form-horizontal .form-group {
    .mt-checkbox-inline,
    .mt-radio-inline {
        padding-top: 8px;
    }
}

.mt-checkbox-inline {
    .mt-checkbox,
    .mt-radio {
        display: inline-block;
        margin-right: 15px;
    }
}

.mt-radio-inline {
    .mt-checkbox,
    .mt-radio {
        display: inline-block;
        margin-right: 15px;
    }
}

.mt-checkbox-inline {
    .mt-checkbox:last-child,
    .mt-radio:last-child {
        margin-right: 0;
    }
}

.mt-radio-inline {
    .mt-checkbox:last-child,
    .mt-radio:last-child {
        margin-right: 0;
    }
}

.icon-btn {
    height: 60px;
    min-width: 80px;
    margin: 5px 5px 0 0;
    border: 1px solid #ddd;
    padding: 12px 0 0;
    background-color: #fafafa;
    background-image: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    display: inline-block;
    color: #646464;
    text-shadow: none;
    text-align: center;
    cursor: pointer;
    position: relative;
    transition: all .3s ease;
}

.loading-message,
.page-loading {
    min-width: 125px;
    vertical-align: middle;
}

.icon-btn:hover {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    >.badge {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
}

.modal.draggable-modal .modal-header,
.portlet-sortable:not(.portlet-fullscreen)>.portlet-title {
    cursor: move;
}

.icon-btn {
    &:hover {
        text-decoration: none;
        border-color: #999;
        color: #444;
        text-shadow: 0 1px 0 #fff;
        transition: all .3s ease;
        box-shadow: none;
        >.badge {
            -webkit-transition: all .3s ease;
            -moz-transition: all .3s ease;
            -ms-transition: all .3s ease;
            -o-transition: all .3s ease;
            transition: all .3s ease;
            box-shadow: none;
        }
    }
    > {
        div {
            margin-top: 5px;
            margin-bottom: 20px;
            color: #3f444a;
            font-size: 12px;
            font-weight: 300;
        }
        .badge {
            position: absolute;
            font-size: 11px;
            font-weight: 300;
            top: -5px;
            right: -5px;
            padding: 3px 6px;
            color: #fff;
            text-shadow: none;
            border-width: 0;
            border-style: solid;
            -webkit-border-radius: 12px;
            -moz-border-radius: 12px;
            border-radius: 12px;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }
    }
}

.input-group .input-icon>.form-control {
    -webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    -ms-border-radius: 4px 0 0 4px;
    -o-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
}

.tabs-left.nav-tabs>li>a {
    &:focus,
    &:hover {
        -webkit-border-radius: 4px 0 0 4px;
        -moz-border-radius: 4px 0 0 4px;
        -ms-border-radius: 4px 0 0 4px;
        -o-border-radius: 4px 0 0 4px;
        border-radius: 4px 0 0 4px;
    }
}

.icon-btn>i {
    font-size: 18px;
}

.input-icon {
    position: relative;
    left: 0;
    >.form-control {
        padding-left: 33px;
    }
}

.input-group .input-icon>.form-control {
    border-radius: 4px 0 0 4px;
}

.input-icon>i {
    color: #ccc;
    position: absolute;
    margin: 11px 2px 4px 10px;
    z-index: 3;
    width: 16px;
    font-size: 16px;
    text-align: center;
    left: 0;
}

.modal .input-icon>i {
    z-index: 10055;
}

.has-success .input-icon>i {
    color: #36c6d3;
}

.has-warning .input-icon>i {
    color: #F1C40F;
}

.has-info .input-icon>i {
    color: #659be0;
}

.has-error .input-icon>i {
    color: #ed6b75;
}

.input-icon.right {
    left: auto;
    right: 0;
    >.form-control {
        padding-right: 33px;
        padding-left: 12px;
    }
}

.input-group .input-icon.right>.form-control {
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    -ms-border-radius: 0 4px 4px 0;
    -o-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
}

.input-icon {
    &.right>i {
        left: auto;
        right: 8px;
        float: right;
    }
    &.input-icon-lg>i {
        margin-top: 16px;
    }
    &.input-icon-sm>i {
        margin-top: 8px;
        font-size: 13px;
    }
}

.label {
    text-shadow: none !important;
    font-size: 14px;
    font-weight: 300;
    padding: 3px 6px;
    color: #fff;
    // font-family: "Open Sans",sans-serif;    
    &.label-sm {
        font-size: 13px;
        padding: 2px 5px;
    }
}

h1 .label,
h2 .label,
h3 .label,
h4 .label,
h5 .label,
h6 .label {
    font-size: 75%;
}

.label-default[href] {
    &:focus,
    &:hover {
        background-color: #9ca8bb;
    }
}

.label-primary {
    background-color: #337ab7;
    &[href] {
        &:focus,
        &:hover {
            background-color: #286090;
        }
    }
}

.label-success {
    background-color: #36c6d3;
    &[href] {
        &:focus,
        &:hover {
            background-color: #27a4b0;
        }
    }
}

.label-info {
    background-color: #659be0;
    &[href] {
        &:focus,
        &:hover {
            background-color: #3a80d7;
        }
    }
}

.label-warning {
    background-color: #F1C40F;
    &[href] {
        &:focus,
        &:hover {
            background-color: #c29d0b;
        }
    }
}

.label-danger {
    background-color: #ed6b75;
    &[href] {
        &:focus,
        &:hover {
            background-color: #e73d4a;
        }
    }
}

.label.label-icon {
    padding: 4px 0 4px 4px;
    margin-right: 2px;
    text-align: center !important;
    >i {
        font-size: 12px;
        text-align: center !important;
    }
}

.note p,
.page-loading,
.panel .panel-body {
    font-size: 13px;
}

.ie8 .label.label-icon,
.ie9 .label.label-icon {
    padding: 3px 0 3px 3px;
}

.text-default {
    color: #bac3d0;
}

.text-primary {
    color: #337ab7;
}

.text-success {
    color: #36c6d3;
}

.text-info {
    color: #659be0;
}

.text-warning {
    color: #F1C40F;
}

.text-danger {
    color: #ed6b75;
}

.list-group {
    >li {
        &:first-child {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }
        &:last-child {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
    .list-group-item-success {
        color: #27a4b0;
        background-color: #abe7ed;
    }
    a.list-group-item-success,
    button.list-group-item-success {
        color: #27a4b0;
    }
    a.list-group-item-success .list-group-item-heading,
    button.list-group-item-success .list-group-item-heading {
        color: inherit;
    }
    a.list-group-item-success {
        &:focus,
        &:hover {
            color: #27a4b0;
            background-color: #96e1e8;
        }
    }
    button.list-group-item-success {
        &:focus,
        &:hover {
            color: #27a4b0;
            background-color: #96e1e8;
        }
    }
    a.list-group-item-success.active {
        color: #fff;
        background-color: #27a4b0;
        border-color: #27a4b0;
        &:focus,
        &:hover {
            color: #fff;
            background-color: #27a4b0;
            border-color: #27a4b0;
        }
    }
    button.list-group-item-success.active {
        color: #fff;
        background-color: #27a4b0;
        border-color: #27a4b0;
        &:focus,
        &:hover {
            color: #fff;
            background-color: #27a4b0;
            border-color: #27a4b0;
        }
    }
    .list-group-item-info {
        color: #327ad5;
        background-color: #e0ebf9;
    }
    a.list-group-item-info,
    button.list-group-item-info {
        color: #327ad5;
    }
    a.list-group-item-info .list-group-item-heading,
    button.list-group-item-info .list-group-item-heading {
        color: inherit;
    }
    a.list-group-item-info {
        &:focus,
        &:hover {
            color: #327ad5;
            background-color: #caddf4;
        }
    }
    button.list-group-item-info {
        &:focus,
        &:hover {
            color: #327ad5;
            background-color: #caddf4;
        }
    }
    a.list-group-item-info.active {
        color: #fff;
        background-color: #327ad5;
        border-color: #327ad5;
        &:focus,
        &:hover {
            color: #fff;
            background-color: #327ad5;
            border-color: #327ad5;
        }
    }
    button.list-group-item-info.active {
        color: #fff;
        background-color: #327ad5;
        border-color: #327ad5;
        &:focus,
        &:hover {
            color: #fff;
            background-color: #327ad5;
            border-color: #327ad5;
        }
    }
    .list-group-item-warning {
        color: #c29d0b;
        background-color: #f9e491;
    }
    a.list-group-item-warning,
    button.list-group-item-warning {
        color: #c29d0b;
    }
    a.list-group-item-warning .list-group-item-heading,
    button.list-group-item-warning .list-group-item-heading {
        color: inherit;
    }
    a.list-group-item-warning {
        &:focus,
        &:hover {
            color: #c29d0b;
            background-color: #f7de79;
        }
    }
    button.list-group-item-warning {
        &:focus,
        &:hover {
            color: #c29d0b;
            background-color: #f7de79;
        }
    }
    a.list-group-item-warning.active {
        color: #fff;
        background-color: #c29d0b;
        border-color: #c29d0b;
        &:focus,
        &:hover {
            color: #fff;
            background-color: #c29d0b;
            border-color: #c29d0b;
        }
    }
    button.list-group-item-warning.active {
        color: #fff;
        background-color: #c29d0b;
        border-color: #c29d0b;
        &:focus,
        &:hover {
            color: #fff;
            background-color: #c29d0b;
            border-color: #c29d0b;
        }
    }
    .list-group-item-danger {
        color: #e73d4a;
        background-color: #fbe1e3;
    }
    a.list-group-item-danger,
    button.list-group-item-danger {
        color: #e73d4a;
    }
    a.list-group-item-danger .list-group-item-heading,
    button.list-group-item-danger .list-group-item-heading {
        color: inherit;
    }
    a.list-group-item-danger {
        &:focus,
        &:hover {
            color: #e73d4a;
            background-color: #f8cace;
        }
    }
    button.list-group-item-danger {
        &:focus,
        &:hover {
            color: #e73d4a;
            background-color: #f8cace;
        }
    }
    a.list-group-item-danger.active {
        color: #fff;
        background-color: #e73d4a;
        border-color: #e73d4a;
        &:focus,
        &:hover {
            color: #fff;
            background-color: #e73d4a;
            border-color: #e73d4a;
        }
    }
    button.list-group-item-danger.active {
        color: #fff;
        background-color: #e73d4a;
        border-color: #e73d4a;
        &:focus,
        &:hover {
            color: #fff;
            background-color: #e73d4a;
            border-color: #e73d4a;
        }
    }
}

.loading-message {
    display: inline-block;
    padding: 10px;
    margin: 0 auto;
    color: #000 !important;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    &.loading-message-boxed {
        border: 1px solid #ddd;
        background-color: #eee;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        border-radius: 4px;
        -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    }
    >span {
        line-height: 20px;
        vertical-align: middle;
    }
}

.page-loading {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -60px;
    margin-top: -30px;
    padding: 7px;
    text-align: center;
    color: #333;
    border: 1px solid #ddd;
    background-color: #eee;
    -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}

.block-spinner-bar>div,
.page-spinner-bar>div {
    background: #eee;
    border-radius: 100% !important;
}

.page-loading>span {
    line-height: 20px;
    vertical-align: middle;
}

.page-spinner-bar {
    position: fixed;
    z-index: 10051;
    width: 100px;
    top: 40%;
    left: 50%;
    margin-left: -55px;
    text-align: center;
    >div {
        margin: 0 5px;
        width: 18px;
        height: 18px;
        display: inline-block;
        -webkit-animation: bounceDelay 1.4s infinite ease-in-out;
        animation: bounceDelay 1.4s infinite ease-in-out;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    .bounce1 {
        -webkit-animation-delay: -.32s;
        animation-delay: -.32s;
    }
    .bounce2 {
        -webkit-animation-delay: -.16s;
        animation-delay: -.16s;
    }
}

.block-spinner-bar {
    display: inline-block;
    width: 80px;
    text-align: center;
    >div {
        margin: 0 2px;
        width: 15px;
        height: 15px;
        display: inline-block;
        -webkit-animation: bounceDelay 1.4s infinite ease-in-out;
        animation: bounceDelay 1.4s infinite ease-in-out;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
}

[class^=m-icon-],
[class^=m-icon-big-] {
    display: inline-block;
    background-image: url(../../img/admin/syncfusion-icons.png);
    background-position: 0 0;
    background-repeat: no-repeat;
}

.block-spinner-bar {
    .bounce1 {
        -webkit-animation-delay: -.32s;
        animation-delay: -.32s;
    }
    .bounce2 {
        -webkit-animation-delay: -.16s;
        animation-delay: -.16s;
    }
}

[class^=m-icon-] {
    width: 14px;
    height: 14px;
    margin-top: 3px;
    line-height: 14px;
    vertical-align: top;
}

[class^=m-icon-big-] {
    width: 30px;
    height: 30px;
    margin: 6px;
    vertical-align: middle;
}

.btn {
    &.m-icon-big {
        padding: 9px 16px 8px;
        &.m-icon-only {
            padding: 9px 8px 8px 0;
        }
        [class^=m-icon-big-] {
            margin: 0 0 0 10px;
        }
    }
    &.m-icon-ony>i {
        margin-left: 0;
    }
    &.m-icon {
        padding: 7px 14px;
        [class^=m-icon-] {
            margin: 4px 0 0 5px;
        }
        &.m-icon-only {
            padding: 7px 10px 7px 6px;
        }
    }
}

.m-icon-white {
    background-image: url(../../img/admin/syncfusion-icons-white.png);
}

.m-icon-swapright {
    background-position: -27px -10px;
}

.m-icon-swapdown {
    background-position: -68px -10px;
}

.m-icon-swapleft {
    background-position: -8px -10px;
}

.m-icon-swapup {
    background-position: -46px -10px;
}

.m-icon-big-swapright {
    background-position: -42px -28px;
}

.m-icon-big-swapdown {
    background-position: -115px -28px;
}

.m-icon-big-swapleft {
    background-position: -6px -28px;
}

.m-icon-big-swapup {
    background-position: -78px -28px;
}

.modal {
    z-index: 10050;
    outline: 0;
    overflow-y: auto !important;
}

.page-portlet-fullscreen .modal {
    z-index: 10060;
}

.modal {
    .modal-header {
        border-bottom: 1px solid #EFEFEF;
        h3 {
            font-weight: 300;
        }
        .close {
            margin-top: 0 !important;
        }
    }
    .modal-dialog {
        z-index: 10051;
    }
    >.loading {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -22px;
        margin-left: -22px;
    }
    &.in .page-loading {
        display: none;
    }
}

.modal-open {
    overflow-y: auto !important;
}

.modal-open-noscroll {
    overflow-y: hidden !important;
}

.modal-backdrop {
    border: 0;
    outline: 0;
}

.note {
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    -ms-border-radius: 0 4px 4px 0;
    -o-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
}

.tabs-right.nav-tabs>li>a {
    &:focus,
    &:hover {
        -webkit-border-radius: 0 4px 4px 0;
        -moz-border-radius: 0 4px 4px 0;
        -ms-border-radius: 0 4px 4px 0;
        -o-border-radius: 0 4px 4px 0;
        border-radius: 0 4px 4px 0;
    }
}

.page-portlet-fullscreen .modal-backdrop {
    z-index: 10059;
}

.modal-backdrop {
    background-color: #333 !important;
    &.fade.in {
        background-color: #333 !important;
    }
}

.note {
    .highlight,
    code,
    &.note-default {
        background-color: #fff;
    }
}

body[ng-controller] {
    .modal-backdrop {
        z-index: 10049;
    }
    .modal {
        z-index: 10050;
    }
}

.modal-full.modal-dialog {
    width: 99%;
}

@media (max-width: 768px) {
    .modal-full.modal-dialog {
        width: auto;
    }
}

.note {
    margin: 0 0 20px;
    padding: 15px 30px 15px 15px;
    border-left: 5px solid #eee;
    border-radius: 0 4px 4px 0;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 0;
    }
    h1 .close,
    h2 .close,
    h3 .close,
    h4 .close,
    h5 .close,
    h6 .close {
        margin-right: -10px;
    }
    p {
        margin: 0;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &.note-default {
        border-color: #b0c1d2;
        color: #000;
        &.note-bordered {
            background-color: #eef1f5;
            border-color: #c0cedb;
        }
        &.note-shadow {
            background-color: #f1f4f7;
            border-color: #d1dbe4;
            box-shadow: 5px 5px rgba(212, 221, 230, 0.2);
        }
    }
    &.note-success {
        background-color: #c0edf1;
        border-color: #58d0da;
        color: #000;
        &.note-bordered {
            background-color: #a7e6ec;
            border-color: #6dd6df;
        }
        &.note-shadow {
            background-color: #abe7ed;
            border-color: #81dbe3;
            box-shadow: 5px 5px rgba(134, 221, 228, 0.2);
        }
    }
    &.note-info {
        background-color: #f5f8fd;
        border-color: #8bb4e7;
        color: #010407;
        &.note_style {
            display: inline-block;
            padding: 8px;
            vertical-align: top;
            // margin: 0px 0px 0px 15px;
            // margin-top: 10px;
        }
        &.note-bordered {
            background-color: #dbe8f8;
            border-color: #a0c2ec;
        }
        &.note-shadow {
            background-color: #e0ebf9;
            border-color: #b5cff0;
            box-shadow: 5px 5px rgba(185, 210, 241, 0.2);
        }
    }
    &.note-warning {
        background-color: #faeaa9;
        border-color: #f3cc31;
        color: #000;
        &.note-bordered {
            background-color: #f8e38c;
            border-color: #f4d249;
        }
        &.note-shadow {
            background-color: #f9e491;
            border-color: #f6d861;
            box-shadow: 5px 5px rgba(246, 217, 102, 0.2);
        }
    }
    &.note-danger {
        background-color: #fef7f8;
        border-color: #f0868e;
        color: #210406;
        &.note-bordered {
            background-color: #fbdcde;
            border-color: #f39da3;
        }
        &.note-shadow {
            background-color: #fbe1e3;
            border-color: #f6b3b8;
            box-shadow: 5px 5px rgba(246, 184, 189, 0.2);
        }
    }
}

.pagination {
    margin: 10px 0;
    &.pagination-circle>li {
        &:first-child>a {
            border-radius: 25px 0 0 25px !important;
        }
        &:last-child>a {
            border-radius: 0 25px 25px 0 !important;
        }
    }
}

.dashboard-stat,
.portlet {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
}

.panel {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.panel-group .panel {
    overflow: visible;
}

.panel .panel-title>a:hover {
    text-decoration: none;
}

.accordion .panel {
    .panel-heading {
        padding: 0;
    }
    .panel-title {
        padding: 0;
        .accordion-toggle {
            display: block;
            padding: 10px 15px;
            &.accordion-toggle-styled {
                background: url(../../img/admin/accordion-plusminus.png) right -19px no-repeat;
                margin-right: 15px;
                &.collapsed {
                    background-position: right 12px;
                }
            }
        }
    }
}

.panel-heading {
    background: #eee;
    a {
        text-decoration: none;
        &:active,
        &:focus,
        &:hover {
            text-decoration: none;
        }
    }
}

.popover {
    box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
    padding: 0;
    .popover-title {
        margin: 0 !important;
    }
}

.page-portlet-fullscreen {
    overflow: hidden;
}

.portlet {
    margin-top: 0;
    // margin-bottom: 20px;
    padding: 0;
    border-radius: 4px;
    &.portlet-fullscreen {
        z-index: 10060;
        margin: 0;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        > {
            .portlet-body {
                overflow-y: auto;
                overflow-x: hidden;
                padding: 0 10px;
            }
            .portlet-title {
                padding: 0 10px;
            }
        }
    }
    > {
        .portlet-title {
            border-bottom: 1px solid #eee;
            padding: 0;
            // margin-bottom: 10px;
            min-height: 41px;
            -webkit-border-radius: 4px 4px 0 0;
            -moz-border-radius: 4px 4px 0 0;
            -ms-border-radius: 4px 4px 0 0;
            -o-border-radius: 4px 4px 0 0;
            border-radius: 4px 4px 0 0;
            &:after,
            &:before {
                content: " ";
                display: table;
            }
            &:after {
                clear: both;
            }
            > {
                .caption {
                    float: left;
                    display: inline-block;
                    font-size: 18px;
                    line-height: 18px;
                    padding: 10px 0;
                    &.bold {
                        font-weight: 400;
                    }
                    > {
                        i {
                            float: left;
                            margin-top: 4px;
                            display: inline-block;
                            font-size: 13px;
                            margin-right: 5px;
                            color: #666;
                            &.glyphicon {
                                margin-top: 2px;
                            }
                        }
                        .caption-helper {
                            padding: 0;
                            margin: 0;
                            line-height: 13px;
                            color: #9eacb4;
                            font-size: 13px;
                            font-weight: 400;
                        }
                    }
                }
                .actions {
                    float: right;
                    display: inline-block;
                    padding: 6px 0;
                    > {
                        .dropdown-menu i {
                            color: #555;
                        }
                        .btn {
                            padding: 4px 10px;
                            font-size: 13px;
                            line-height: 1.5;
                        }
                        .btn-group>.btn {
                            padding: 4px 10px;
                            font-size: 13px;
                            line-height: 1.5;
                            &.btn-sm {
                                padding: 4px 10px;
                                font-size: 13px;
                                line-height: 1.5;
                            }
                        }
                        .btn.btn-sm {
                            padding: 4px 10px;
                            font-size: 13px;
                            line-height: 1.5;
                        }
                        .btn-group>.btn {
                            &.btn-default,
                            &.btn-sm.btn-default {
                                padding: 3px 9px;
                            }
                        }
                        .btn {
                            &.btn-default,
                            &.btn-sm.btn-default {
                                padding: 3px 9px;
                            }
                        }
                        .btn-group>.btn {
                            &.btn-sm>i,
                            >i {
                                font-size: 13px;
                            }
                        }
                        .btn {
                            &.btn-sm>i,
                            >i {
                                font-size: 13px;
                            }
                        }
                    }
                    .btn-icon-only {
                        padding: 5px 7px 3px;
                        &.btn-default {
                            padding: 4px 6px 2px;
                            >i {
                                font-size: 14px;
                            }
                            &.fullscreen {
                                font-family: FontAwesome;
                                color: #a0a0a0;
                                padding-top: 3px;
                                &.btn-sm {
                                    padding: 3px !important;
                                    height: 27px;
                                    width: 27px;
                                }
                                &:before {
                                    content: "\f065";
                                }
                                &.on:before {
                                    content: "\f066";
                                }
                            }
                        }
                    }
                }
                .tools {
                    float: right;
                    display: inline-block;
                    padding: 12px 0 8px;
                    >a {
                        display: inline-block;
                        height: 16px;
                        margin-left: 5px;
                        opacity: 1;
                        filter: alpha(opacity=100);
                        &.remove {
                            background-image: url(../../img/admin/portlet-remove-icon.png);
                            background-repeat: no-repeat;
                            width: 11px;
                        }
                        &.config {
                            background-image: url(../../img/admin/portlet-config-icon.png);
                            background-repeat: no-repeat;
                            width: 12px;
                        }
                        &.reload {
                            background-image: url(../../img/admin/portlet-reload-icon.png);
                            width: 13px;
                        }
                        &.expand {
                            background-image: url(../../img/admin/portlet-expand-icon.png);
                            width: 14px;
                            visibility: visible;
                        }
                        &.collapse {
                            background-image: url(../../img/admin/portlet-collapse-icon.png);
                            width: 14px;
                            visibility: visible;
                        }
                        &.fullscreen {
                            display: inline-block;
                            top: -3px;
                            position: relative;
                            font-size: 13px;
                            font-family: FontAwesome;
                            color: #ACACAC;
                            &:before {
                                content: "\f065";
                            }
                            &.on:before {
                                content: "\f066";
                            }
                        }
                        &:hover {
                            text-decoration: none;
                            -webkit-transition: all .1s ease-in-out;
                            -moz-transition: all .1s ease-in-out;
                            -o-transition: all .1s ease-in-out;
                            -ms-transition: all .1s ease-in-out;
                            transition: all .1s ease-in-out;
                            opacity: .8;
                            filter: alpha(opacity=80);
                        }
                    }
                }
                .pagination {
                    float: right;
                    display: inline-block;
                    margin: 2px 0 0;
                    border: 0;
                    padding: 4px 0;
                }
                .nav-tabs {
                    background: 0 0;
                    margin: 1px 0 0;
                    float: right;
                    display: inline-block;
                    border: 0;
                    >li {
                        background: 0 0;
                        margin: 0;
                        border: 0;
                        >a {
                            background: 0 0;
                            margin: 5px 0 0 1px;
                            border: 0;
                            padding: 8px 10px;
                            color: #fff;
                        }
                    }
                }
            }
        }
        .portlet-body p {
            margin-top: 0;
        }
    }
}

.table .btn {
    margin-top: 0;
}

.portlet {
    > {
        .portlet-title>.nav-tabs>li {
            &.active>a,
            &:hover>a {
                color: #333;
                background: #fff;
                border: 0;
            }
        }
        .portlet-body {
            clear: both;
            -webkit-border-radius: 0 0 4px 4px;
            -moz-border-radius: 0 0 4px 4px;
            -ms-border-radius: 0 0 4px 4px;
            -o-border-radius: 0 0 4px 4px;
            border-radius: 0 0 4px 4px;
            /* width */
             ::-webkit-scrollbar {
                height: 8px;
                width: 5px;
            }
            /* Track */
             ::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px grey;
                border-radius: 10px;
            }
            /* Handle */
             ::-webkit-scrollbar-thumb {
                background: #ccc;
                border-radius: 10px;
            }
            /* Handle on hover */
             ::-webkit-scrollbar-thumb:hover {
                background: #aaa;
            }
        }
        .portlet-empty {
            min-height: 125px;
        }
    }
    &.full-height-content {
        margin-bottom: 0;
    }
    &.bordered {
        border-left: 2px solid #e6e9ec !important;
        >.portlet-title {
            border-bottom: 0;
        }
    }
    &.solid {
        padding: 0 10px 10px;
        border: 0;
        >.portlet-title {
            border-bottom: 0;
            margin-bottom: 10px;
            > {
                .caption {
                    padding: 16px 0 2px;
                }
                .actions {
                    padding: 12px 0 6px;
                }
                .tools {
                    padding: 14px 0 6px;
                }
            }
        }
        &.bordered>.portlet-title {
            margin-bottom: 10px;
        }
    }
    &.box {
        padding: 0 !important;
        > {
            .portlet-title {
                border-bottom: 0;
                padding: 0 10px;
                margin-bottom: 0;
                color: #fff;
                > {
                    .caption {
                        padding: 11px 0 9px;
                    }
                    .tools>a {
                        &.remove {
                            background-image: url(../../img/admin/portlet-remove-icon-white.png);
                        }
                        &.config {
                            background-image: url(../../img/admin/portlet-config-icon-white.png);
                        }
                        &.reload {
                            background-image: url(../../img/admin/portlet-reload-icon-white.png);
                        }
                        &.expand {
                            background-image: url(../../img/admin/portlet-expand-icon-white.png);
                        }
                        &.collapse {
                            background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                        }
                        &.fullscreen {
                            color: #fdfdfd;
                        }
                    }
                    .actions {
                        padding: 7px 0 5px;
                    }
                }
            }
            .portlet-body {
                background-color: #fff;
                padding: 15px;
            }
        }
    }
    &.light {
        // padding: 15px 0px;
        background-color: #fff;
        &.bordered {
            border: 1px solid #e7ecf1 !important;
            // padding: 10px;
            >.portlet-title {
                border-bottom: 1px solid #eef1f5;
            }
        }
        &.bg-inverse {
            background: #f1f4f7;
        }
        >.portlet-title {
            // padding: 0;
            padding: 8px;
            min-height: 35px;
            display: -webkit-flexbox!important;
            display: -ms-flexbox!important;
            display: flex!important;
            align-items: center;
            >.caption {
                color: #666;
                padding: 10px 0;
                > {
                    .caption-subject {
                        font-size: 14px;
                    }
                    i {
                        color: #777;
                        font-size: 15px;
                        font-weight: 300;
                        margin-top: 3px;
                    }
                }
            }
        }
    }
    &.solid {
        &.blue-chambray>.portlet-title>.caption,
        &.blue-dark>.portlet-title>.caption,
        &.blue-ebonyclay>.portlet-title>.caption,
        &.blue-hoki>.portlet-title>.caption,
        &.blue-madison>.portlet-title>.caption,
        &.blue-oleo>.portlet-title>.caption,
        &.blue-sharp>.portlet-title>.caption,
        &.blue-soft>.portlet-title>.caption,
        &.blue-steel>.portlet-title>.caption,
        &.blue>.portlet-title>.caption,
        &.dark>.portlet-title>.caption,
        &.default>.portlet-title>.caption,
        &.green-dark>.portlet-title>.caption,
        &.green-haze>.portlet-title>.caption,
        &.green-jungle>.portlet-title>.caption,
        &.green-meadow>.portlet-title>.caption,
        &.green-seagreen>.portlet-title>.caption,
        &.green-sharp>.portlet-title>.caption,
        &.green-soft>.portlet-title>.caption,
        &.green-steel>.portlet-title>.caption,
        &.green-turquoise>.portlet-title>.caption,
        &.green>.portlet-title>.caption,
        &.grey-cararra>.portlet-title>.caption,
        &.grey-cascade>.portlet-title>.caption,
        &.grey-gallery>.portlet-title>.caption,
        &.grey-mint>.portlet-title>.caption,
        &.grey-salt>.portlet-title>.caption,
        &.grey-silver>.portlet-title>.caption,
        &.grey-steel>.portlet-title>.caption,
        &.grey>.portlet-title>.caption,
        &.purple-intense>.portlet-title>.caption,
        &.purple-medium>.portlet-title>.caption,
        &.purple-plum>.portlet-title>.caption,
        &.purple-seance>.portlet-title>.caption,
        &.purple-sharp>.portlet-title>.caption,
        &.purple-soft>.portlet-title>.caption,
        &.purple-studio>.portlet-title>.caption,
        &.purple-wisteria>.portlet-title>.caption,
        &.purple>.portlet-title>.caption,
        &.red-flamingo>.portlet-title>.caption,
        &.red-haze>.portlet-title>.caption,
        &.red-intense>.portlet-title>.caption,
        &.red-mint>.portlet-title>.caption,
        &.red-pink>.portlet-title>.caption,
        &.red-soft>.portlet-title>.caption,
        &.red-sunglo>.portlet-title>.caption,
        &.red-thunderbird>.portlet-title>.caption,
        &.red>.portlet-title>.caption,
        &.white>.portlet-title>.caption,
        &.yellow-casablanca>.portlet-title>.caption,
        &.yellow-crusta>.portlet-title>.caption,
        &.yellow-gold>.portlet-title>.caption,
        &.yellow-haze>.portlet-title>.caption,
        &.yellow-lemon>.portlet-title>.caption,
        &.yellow-mint>.portlet-title>.caption,
        &.yellow-saffron>.portlet-title>.caption,
        &.yellow-soft>.portlet-title>.caption,
        &.yellow>.portlet-title>.caption {
            font-weight: 400;
        }
    }
    &.light {
        >.portlet-title {
            > {
                .caption.caption-md {
                    > {
                        .caption-subject {
                            font-size: 14px;
                        }
                        i {
                            font-size: 14px;
                        }
                    }
                }
                .actions {
                    padding: 6px 0 14px;
                    .btn-default {
                        color: #666;
                    }
                    .btn-icon-only {
                        height: 27px;
                        width: 27px;
                    }
                    .dropdown-menu li>a {
                        color: #555;
                    }
                }
                .inputs {
                    float: right;
                    display: inline-block;
                    padding: 4px 0;
                    >.portlet-input {
                        .input-icon {
                            > {
                                i {
                                    font-size: 14px;
                                    margin-top: 9px;
                                }
                                .form-control {
                                    height: 30px;
                                    padding: 2px 26px 3px 10px;
                                    font-size: 13px;
                                }
                            }
                        }
                        >.form-control {
                            height: 30px;
                            padding: 3px 10px;
                            font-size: 13px;
                        }
                    }
                }
                .pagination {
                    padding: 2px 0 13px;
                }
                .tools {
                    padding: 10px 0 13px;
                    // margin-top: 2px;
                }
                .nav-tabs>li {
                    margin: 0;
                    padding: 0;
                    >a {
                        margin: 0;
                        padding: 12px 13px 13px;
                        font-size: 13px;
                        color: #666;
                    }
                    &.active>a,
                    &:hover>a {
                        margin: 0;
                        background: 0 0;
                        color: #333;
                    }
                }
            }
        }
        &.form-fit {
            padding: 0;
            >.portlet-title {
                padding: 17px 20px 10px;
                margin-bottom: 0;
            }
        }
        .portlet-body {
            // padding-top: 8px;
            padding: 10px;
        }
        &.portlet-fullscreen>.portlet-body {
            padding: 8px 0;
        }
        &.portlet-fit {
            padding: 0;
            > {
                .portlet-title {
                    padding: 15px 20px 10px;
                }
                .portlet-body {
                    padding: 10px 20px 20px;
                }
            }
            &.portlet-form>.portlet-body {
                padding: 0;
                .form-actions {
                    background: 0 0;
                }
            }
        }
    }
    &.box.white>.portlet-title,
    &.white,
    >.portlet-body.white {
        background-color: #fff;
    }
    &.light.portlet-datatable.portlet-fit>.portlet-body {
        padding-top: 10px;
        padding-bottom: 25px;
    }
}

.tab-pane>p:last-child {
    margin-bottom: 0;
}

.tabs-reversed>li {
    float: right;
    margin-right: 0;
    >a {
        margin-right: 0;
    }
}

.portlet-sortable-placeholder {
    border: 2px dashed #eee;
    margin-bottom: 25px;
}

.portlet-sortable-empty {
    box-shadow: none !important;
    height: 45px;
}

.portlet-collapsed {
    display: none;
}

@media (max-width: 991px) {
    .portlet-collapsed-on-mobile {
        display: none;
    }
}

.portlet {
    &.solid.white {
        > {
            .portlet-body {
                border: 0;
                color: #666;
            }
            .portlet-title {
                border: 0;
                color: #666;
                > {
                    .caption>i {
                        color: #666;
                    }
                    .tools>a {
                        &.remove {
                            background-image: url(../../img/admin/portlet-remove-icon-white.png);
                        }
                        &.config {
                            background-image: url(../../img/admin/portlet-config-icon-white.png);
                        }
                        &.reload {
                            background-image: url(../../img/admin/portlet-reload-icon-white.png);
                        }
                        &.expand {
                            background-image: url(../../img/admin/portlet-expand-icon-white.png);
                        }
                        &.collapse {
                            background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                        }
                        &.fullscreen {
                            color: #fdfdfd;
                        }
                    }
                }
            }
        }
    }
    &.box {
        &.white {
            border: 1px solid #fff;
            border-top: 0;
            >.portlet-title {
                > {
                    .caption {
                        color: #666;
                        >i {
                            color: #666;
                        }
                    }
                    .actions .btn-default {
                        background: 0 0 !important;
                        border: 1px solid #fff;
                        color: #fff;
                    }
                }
            }
        }
        &.default>.portlet-title {
            background-color: #e1e5ec;
        }
    }
    &.default,
    >.portlet-body.default {
        background-color: #e1e5ec;
    }
    &.box.white>.portlet-title>.actions .btn-default {
        >i {
            color: #fff;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #fff;
            color: #fff;
        }
    }
    &.solid.default {
        > {
            .portlet-body {
                border: 0;
                color: #666;
            }
            .portlet-title {
                border: 0;
                color: #666;
                > {
                    .caption {
                        >i {
                            color: #666;
                        }
                    }
                    .tools>a {
                        &.remove {
                            background-image: url(../../img/admin/portlet-remove-icon-white.png);
                        }
                        &.config {
                            background-image: url(../../img/admin/portlet-config-icon-white.png);
                        }
                        &.reload {
                            background-image: url(../../img/admin/portlet-reload-icon-white.png);
                        }
                        &.expand {
                            background-image: url(../../img/admin/portlet-expand-icon-white.png);
                        }
                        &.collapse {
                            background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                        }
                        &.fullscreen {
                            color: #fdfdfd;
                        }
                    }
                }
            }
        }
    }
    &.box {
        &.default {
            border: 1px solid #fff;
            border-top: 0;
            >.portlet-title {
                > {
                    .caption {
                        color: #666;
                        >i {
                            color: #666;
                        }
                    }
                    .actions .btn-default {
                        background: 0 0 !important;
                        border: 1px solid #fff;
                        color: #fff;
                    }
                }
            }
        }
        &.dark>.portlet-title {
            background-color: #2f353b;
        }
    }
    &.dark,
    >.portlet-body.dark {
        background-color: #2f353b;
    }
    &.box.default>.portlet-title>.actions .btn-default {
        >i {
            color: #fff;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #fff;
            color: #fff;
        }
    }
    &.solid.dark {
        > {
            .portlet-body {
                border: 0;
                color: #FFF;
            }
            .portlet-title {
                border: 0;
                color: #FFF;
                > {
                    .caption {
                        >i {
                            color: #FFF;
                        }
                    }
                    .tools>a {
                        &.remove {
                            background-image: url(../../img/admin/portlet-remove-icon-white.png);
                        }
                        &.config {
                            background-image: url(../../img/admin/portlet-config-icon-white.png);
                        }
                        &.reload {
                            background-image: url(../../img/admin/portlet-reload-icon-white.png);
                        }
                        &.expand {
                            background-image: url(../../img/admin/portlet-expand-icon-white.png);
                        }
                        &.collapse {
                            background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                        }
                        &.fullscreen {
                            color: #fdfdfd;
                        }
                    }
                }
            }
        }
    }
    &.box.dark {
        border: 1px solid #464f57;
        border-top: 0;
        >.portlet-title {
            > {
                .caption {
                    color: #FFF;
                    >i {
                        color: #FFF;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #616d79;
                    color: #6c7a88;
                }
            }
        }
    }
    &.blue {
        background-color: #3598dc;
    }
    &.box {
        &.blue>.portlet-title {
            background-color: #3598dc;
        }
        &.dark>.portlet-title>.actions .btn-default {
            >i {
                color: #738290;
            }
            &.active,
            &:active,
            &:focus,
            &:hover {
                border: 1px solid #798794;
                color: #8793a0;
            }
        }
    }
    >.portlet-body.blue {
        background-color: #3598dc;
    }
    &.solid.blue> {
        .portlet-body {
            border: 0;
            color: #FFF;
        }
        .portlet-title {
            border: 0;
            color: #FFF;
            > {
                .caption>i {
                    color: #FFF;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box.blue {
        border: 1px solid #60aee4;
        border-top: 0;
        >.portlet-title> {
            .caption {
                color: #FFF;
                >i {
                    color: #FFF;
                }
            }
            .actions .btn-default {
                background: 0 0 !important;
                border: 1px solid #95c9ed;
                color: #aad4f0;
            }
        }
    }
    &.blue-madison {
        background-color: #578ebe;
    }
    &.box {
        &.blue-madison>.portlet-title {
            background-color: #578ebe;
        }
        &.blue>.portlet-title>.actions .btn-default {
            >i {
                color: #b7daf3;
            }
            &.active,
            &:active,
            &:focus,
            &:hover {
                border: 1px solid #c0dff4;
                color: #d6eaf8;
            }
        }
    }
    >.portlet-body.blue-madison {
        background-color: #578ebe;
    }
    &.solid.blue-madison> {
        .portlet-body {
            border: 0;
            color: #FFF;
        }
        .portlet-title {
            border: 0;
            color: #FFF;
            > {
                .caption>i {
                    color: #FFF;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box.blue-madison {
        border: 1px solid #7ca7cc;
        border-top: 0;
        >.portlet-title> {
            .caption {
                color: #FFF;
                >i {
                    color: #FFF;
                }
            }
            .actions .btn-default {
                background: 0 0 !important;
                border: 1px solid #a8c4dd;
                color: #bad1e4;
            }
        }
    }
    &.blue-chambray {
        background-color: #2C3E50;
    }
    &.box {
        &.blue-chambray>.portlet-title {
            background-color: #2C3E50;
        }
        &.blue-madison>.portlet-title>.actions .btn-default {
            >i {
                color: #c5d8e9;
            }
            &.active,
            &:active,
            &:focus,
            &:hover {
                border: 1px solid #cdddec;
                color: #dfeaf3;
            }
        }
    }
    >.portlet-body.blue-chambray {
        background-color: #2C3E50;
    }
    &.solid.blue-chambray> {
        .portlet-body {
            border: 0;
            color: #FFF;
        }
        .portlet-title {
            border: 0;
            color: #FFF;
            > {
                .caption>i {
                    color: #FFF;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box.blue-chambray {
        border: 1px solid #3e5871;
        border-top: 0;
        >.portlet-title> {
            .caption {
                color: #FFF;
                >i {
                    color: #FFF;
                }
            }
            .actions .btn-default {
                background: 0 0 !important;
                border: 1px solid #547698;
                color: #5f83a7;
            }
        }
    }
    &.blue-ebonyclay {
        background-color: #22313F;
    }
    &.box {
        &.blue-ebonyclay>.portlet-title {
            background-color: #22313F;
        }
        &.blue-chambray>.portlet-title>.actions .btn-default {
            >i {
                color: #698bac;
            }
            &.active,
            &:active,
            &:focus,
            &:hover {
                border: 1px solid #6f90b0;
                color: #809cb9;
            }
        }
    }
    >.portlet-body.blue-ebonyclay {
        background-color: #22313F;
    }
    &.solid.blue-ebonyclay> {
        .portlet-body {
            border: 0;
            color: #FFF;
        }
        .portlet-title {
            border: 0;
            color: #FFF;
            > {
                .caption>i {
                    color: #FFF;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box.blue-ebonyclay {
        border: 1px solid #344b60;
        border-top: 0;
        >.portlet-title> {
            .caption {
                color: #FFF;
                >i {
                    color: #FFF;
                }
            }
            .actions .btn-default {
                background: 0 0 !important;
                border: 1px solid #496a88;
                color: #527798;
            }
        }
    }
    &.blue-hoki {
        background-color: #67809F;
    }
    &.box {
        &.blue-hoki>.portlet-title {
            background-color: #67809F;
        }
        &.blue-ebonyclay>.portlet-title>.actions .btn-default {
            >i {
                color: #587ea2;
            }
            &.active,
            &:active,
            &:focus,
            &:hover {
                border: 1px solid #5d83a7;
                color: #6d90b0;
            }
        }
    }
    >.portlet-body.blue-hoki {
        background-color: #67809F;
    }
    &.solid.blue-hoki> {
        .portlet-body {
            border: 0;
            color: #FFF;
        }
        .portlet-title {
            border: 0;
            color: #FFF;
            > {
                .caption>i {
                    color: #FFF;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box.blue-hoki {
        border: 1px solid #869ab3;
        border-top: 0;
        >.portlet-title> {
            .caption {
                color: #FFF;
                >i {
                    color: #FFF;
                }
            }
            .actions .btn-default {
                background: 0 0 !important;
                border: 1px solid #acb9ca;
                color: #bbc7d4;
            }
        }
    }
    &.blue-steel {
        background-color: #4B77BE;
    }
    &.box {
        &.blue-steel>.portlet-title {
            background-color: #4B77BE;
        }
        &.blue-hoki>.portlet-title>.actions .btn-default {
            >i {
                color: #c5ceda;
            }
            &.active,
            &:active,
            &:focus,
            &:hover {
                border: 1px solid #cbd4de;
                color: #dbe1e8;
            }
        }
    }
    >.portlet-body.blue-steel {
        background-color: #4B77BE;
    }
    &.solid.blue-steel> {
        .portlet-body {
            border: 0;
            color: #FFF;
        }
        .portlet-title {
            border: 0;
            color: #FFF;
            > {
                .caption>i {
                    color: #FFF;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box.blue-steel {
        border: 1px solid #7093cc;
        border-top: 0;
        >.portlet-title> {
            .caption {
                color: #FFF;
                >i {
                    color: #FFF;
                }
            }
            .actions .btn-default {
                background: 0 0 !important;
                border: 1px solid #9db5dc;
                color: #b0c3e3;
            }
        }
    }
    &.blue-soft {
        background-color: #4c87b9;
    }
    &.box {
        &.blue-soft>.portlet-title {
            background-color: #4c87b9;
        }
        &.blue-steel>.portlet-title>.actions .btn-default {
            >i {
                color: #bbcce7;
            }
            &.active,
            &:active,
            &:focus,
            &:hover {
                border: 1px solid #c3d2e9;
                color: #d6e0f0;
            }
        }
    }
    >.portlet-body.blue-soft {
        background-color: #4c87b9;
    }
    &.solid.blue-soft> {
        .portlet-body {
            border: 0;
            color: #FFF;
        }
        .portlet-title {
            border: 0;
            color: #FFF;
            > {
                .caption>i {
                    color: #FFF;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box.blue-soft {
        border: 1px solid #71a0c7;
        border-top: 0;
        >.portlet-title> {
            .caption {
                color: #FFF;
                >i {
                    color: #FFF;
                }
            }
            .actions .btn-default {
                background: 0 0 !important;
                border: 1px solid #9dbdd9;
                color: #afc9e0;
            }
        }
    }
    &.blue-dark {
        background-color: #5e738b;
    }
    &.box {
        &.blue-dark>.portlet-title {
            background-color: #5e738b;
        }
        &.blue-soft>.portlet-title>.actions .btn-default {
            >i {
                color: #bad1e4;
            }
            &.active,
            &:active,
            &:focus,
            &:hover {
                border: 1px solid #c1d6e7;
                color: #d4e2ee;
            }
        }
    }
    >.portlet-body.blue-dark {
        background-color: #5e738b;
    }
    &.solid.blue-dark> {
        .portlet-body {
            border: 0;
            color: #FFF;
        }
        .portlet-title {
            border: 0;
            color: #FFF;
            > {
                .caption>i {
                    color: #FFF;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box.blue-dark {
        border: 1px solid #788da4;
        border-top: 0;
        >.portlet-title> {
            .caption {
                color: #FFF;
                >i {
                    color: #FFF;
                }
            }
            .actions .btn-default {
                background: 0 0 !important;
                border: 1px solid #9dacbd;
                color: #acb8c7;
            }
        }
    }
    &.blue-sharp {
        background-color: #5C9BD1;
    }
    &.box {
        &.blue-sharp>.portlet-title {
            background-color: #5C9BD1;
        }
        &.blue-dark>.portlet-title>.actions .btn-default {
            >i {
                color: #b5c0cd;
            }
            &.active,
            &:active,
            &:focus,
            &:hover {
                border: 1px solid #bbc5d1;
                color: #cad2db;
            }
        }
    }
    >.portlet-body.blue-sharp {
        background-color: #5C9BD1;
    }
    &.solid.blue-sharp> {
        .portlet-body {
            border: 0;
            color: #FFF;
        }
        .portlet-title {
            border: 0;
            color: #FFF;
            > {
                .caption>i {
                    color: #FFF;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box.blue-sharp {
        border: 1px solid #84b3dc;
        border-top: 0;
        >.portlet-title> {
            .caption {
                color: #FFF;
                >i {
                    color: #FFF;
                }
            }
            .actions .btn-default {
                background: 0 0 !important;
                border: 1px solid #b4d1ea;
                color: #c7ddef;
            }
        }
    }
    &.blue-oleo {
        background-color: #94A0B2;
    }
    &.box {
        &.blue-oleo>.portlet-title {
            background-color: #94A0B2;
        }
        &.blue-sharp>.portlet-title>.actions .btn-default {
            >i {
                color: #d3e4f3;
            }
            &.active,
            &:active,
            &:focus,
            &:hover {
                border: 1px solid #dbe9f5;
                color: #eff5fb;
            }
        }
    }
    >.portlet-body.blue-oleo {
        background-color: #94A0B2;
    }
    &.solid.blue-oleo> {
        .portlet-body {
            border: 0;
            color: #FFF;
        }
        .portlet-title {
            border: 0;
            color: #FFF;
            > {
                .caption>i {
                    color: #FFF;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.blue-oleo {
            border: 1px solid #b2bac7;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #FFF;
                    >i {
                        color: #FFF;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #d5dae1;
                    color: #e4e7ec;
                }
            }
        }
        &.green>.portlet-title {
            background-color: #32c5d2;
        }
    }
    &.green,
    >.portlet-body.green {
        background-color: #32c5d2;
    }
    &.box.blue-oleo>.portlet-title>.actions .btn-default {
        >i {
            color: #edeff2;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #f3f4f6;
            color: #fff;
        }
    }
    &.solid.green> {
        .portlet-body {
            border: 0;
            color: #FFF;
        }
        .portlet-title {
            border: 0;
            color: #FFF;
            > {
                .caption>i {
                    color: #FFF;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.green {
            border: 1px solid #5cd1db;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #FFF;
                    >i {
                        color: #FFF;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #8edfe6;
                    color: #a3e5eb;
                }
            }
        }
        &.green-meadow>.portlet-title {
            background-color: #1BBC9B;
        }
    }
    &.green-meadow,
    >.portlet-body.green-meadow {
        background-color: #1BBC9B;
    }
    &.box.green>.portlet-title>.actions .btn-default {
        >i {
            color: #afe8ee;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #b8ebef;
            color: #cdf1f4;
        }
    }
    &.solid.green-meadow> {
        .portlet-body {
            border: 0;
            color: #FFF;
        }
        .portlet-title {
            border: 0;
            color: #FFF;
            > {
                .caption>i {
                    color: #FFF;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.green-meadow {
            border: 1px solid #2ae0bb;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #FFF;
                    >i {
                        color: #FFF;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #5fe8cc;
                    color: #75ebd3;
                }
            }
        }
        &.green-seagreen>.portlet-title {
            background-color: #1BA39C;
        }
    }
    &.green-seagreen,
    >.portlet-body.green-seagreen {
        background-color: #1BA39C;
    }
    &.box.green-meadow>.portlet-title>.actions .btn-default {
        >i {
            color: #83edd7;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #8ceeda;
            color: #a2f2e1;
        }
    }
    &.solid.green-seagreen> {
        .portlet-body {
            border: 0;
            color: #FFF;
        }
        .portlet-title {
            border: 0;
            color: #FFF;
            > {
                .caption>i {
                    color: #FFF;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.green-seagreen {
            border: 1px solid #22cfc6;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #FFF;
                    >i {
                        color: #FFF;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #4de1da;
                    color: #63e5de;
                }
            }
        }
        &.green-turquoise>.portlet-title {
            background-color: #36D7B7;
        }
    }
    &.green-turquoise,
    >.portlet-body.green-turquoise {
        background-color: #36D7B7;
    }
    &.box.green-seagreen>.portlet-title>.actions .btn-default {
        >i {
            color: #70e7e1;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #78e9e3;
            color: #8eece8;
        }
    }
    &.solid.green-turquoise> {
        .portlet-body {
            border: 0;
            color: #FFF;
        }
        .portlet-title {
            border: 0;
            color: #FFF;
            > {
                .caption>i {
                    color: #FFF;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.green-turquoise {
            border: 1px solid #61dfc6;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #FFF;
                    >i {
                        color: #FFF;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #94ead9;
                    color: #a9eee0;
                }
            }
        }
        &.green-haze>.portlet-title {
            background-color: #44b6ae;
        }
    }
    &.green-haze,
    >.portlet-body.green-haze {
        background-color: #44b6ae;
    }
    &.box.green-turquoise>.portlet-title>.actions .btn-default {
        >i {
            color: #b6f0e5;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #bef2e8;
            color: #d3f6ef;
        }
    }
    &.solid.green-haze> {
        .portlet-body {
            border: 0;
            color: #FFF;
        }
        .portlet-title {
            border: 0;
            color: #FFF;
            > {
                .caption>i {
                    color: #FFF;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.green-haze {
            border: 1px solid #67c6bf;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #FFF;
                    >i {
                        color: #FFF;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #93d7d2;
                    color: #a6deda;
                }
            }
        }
        &.green-jungle>.portlet-title {
            background-color: #26C281;
        }
    }
    &.green-jungle,
    >.portlet-body.green-jungle {
        background-color: #26C281;
    }
    &.box.green-haze>.portlet-title>.actions .btn-default {
        >i {
            color: #b1e2de;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #b9e5e2;
            color: #cbece9;
        }
    }
    &.solid.green-jungle> {
        .portlet-body {
            border: 0;
            color: #FFF;
        }
        .portlet-title {
            border: 0;
            color: #FFF;
            > {
                .caption>i {
                    color: #FFF;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.green-jungle {
            border: 1px solid #41da9a;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #FFF;
                    >i {
                        color: #FFF;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #74e4b5;
                    color: #8ae8c1;
                }
            }
        }
        &.green-soft>.portlet-title {
            background-color: #3faba4;
        }
    }
    &.green-soft,
    >.portlet-body.green-soft {
        background-color: #3faba4;
    }
    &.box.green-jungle>.portlet-title>.actions .btn-default {
        >i {
            color: #96ebc8;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #9feccc;
            color: #b4f0d7;
        }
    }
    &.solid.green-soft> {
        .portlet-body {
            border: 0;
            color: #FFF;
        }
        .portlet-title {
            border: 0;
            color: #FFF;
            > {
                .caption>i {
                    color: #FFF;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.green-soft {
            border: 1px solid #5bc2bc;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #FFF;
                    >i {
                        color: #FFF;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #87d3ce;
                    color: #9adad6;
                }
            }
        }
        &.green-dark>.portlet-title {
            background-color: #4DB3A2;
        }
    }
    &.green-dark,
    >.portlet-body.green-dark {
        background-color: #4DB3A2;
    }
    &.box.green-soft>.portlet-title>.actions .btn-default {
        >i {
            color: #a5deda;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #ade1dd;
            color: #bfe7e5;
        }
    }
    &.solid.green-dark> {
        .portlet-body {
            border: 0;
            color: #FFF;
        }
        .portlet-title {
            border: 0;
            color: #FFF;
            > {
                .caption>i {
                    color: #FFF;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.green-dark {
            border: 1px solid #71c2b5;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #FFF;
                    >i {
                        color: #FFF;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #9cd5cb;
                    color: #addcd4;
                }
            }
        }
        &.green-sharp>.portlet-title {
            background-color: #2ab4c0;
        }
    }
    &.green-sharp,
    >.portlet-body.green-sharp {
        background-color: #2ab4c0;
    }
    &.box.green-dark>.portlet-title>.actions .btn-default {
        >i {
            color: #b8e1da;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #bfe4de;
            color: #d1ebe7;
        }
    }
    &.solid.green-sharp> {
        .portlet-body {
            border: 0;
            color: #FFF;
        }
        .portlet-title {
            border: 0;
            color: #FFF;
            > {
                .caption>i {
                    color: #FFF;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.green-sharp {
            border: 1px solid #46cbd7;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #FFF;
                    >i {
                        color: #FFF;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #79d9e2;
                    color: #8edfe6;
                }
            }
        }
        &.green-steel>.portlet-title {
            background-color: #29b4b6;
        }
    }
    &.green-steel,
    >.portlet-body.green-steel {
        background-color: #29b4b6;
    }
    &.box.green-sharp>.portlet-title>.actions .btn-default {
        >i {
            color: #9ae3e9;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #a2e5eb;
            color: #b7ebef;
        }
    }
    &.solid.green-steel> {
        .portlet-body {
            border: 0;
            color: #FFF;
        }
        .portlet-title {
            border: 0;
            color: #FFF;
            > {
                .caption>i {
                    color: #FFF;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.green-steel {
            border: 1px solid #3ed1d4;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #FFF;
                    >i {
                        color: #FFF;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #70dddf;
                    color: #85e2e4;
                }
            }
        }
        &.grey>.portlet-title {
            background-color: #E5E5E5;
        }
    }
    &.grey,
    >.portlet-body.grey {
        background-color: #E5E5E5;
    }
    &.box.green-steel>.portlet-title>.actions .btn-default {
        >i {
            color: #92e5e6;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #9ae7e8;
            color: #afeced;
        }
    }
    &.solid.grey> {
        .portlet-body {
            border: 0;
            color: #333;
        }
        .portlet-title {
            border: 0;
            color: #333;
            > {
                .caption>i {
                    color: #333;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.grey {
            border: 1px solid #fff;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #333;
                    >i {
                        color: #333;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #fff;
                    color: #fff;
                }
            }
        }
        &.grey-steel>.portlet-title {
            background-color: #e9edef;
        }
    }
    &.grey-steel,
    >.portlet-body.grey-steel {
        background-color: #e9edef;
    }
    &.box.grey>.portlet-title>.actions .btn-default {
        >i {
            color: #fff;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #fff;
            color: #fff;
        }
    }
    &.solid.grey-steel> {
        .portlet-body {
            border: 0;
            color: #80898e;
        }
        .portlet-title {
            border: 0;
            color: #80898e;
            > {
                .caption>i {
                    color: #80898e;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.grey-steel {
            border: 1px solid #fff;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #80898e;
                    >i {
                        color: #80898e;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #fff;
                    color: #fff;
                }
            }
        }
        &.grey-cararra>.portlet-title {
            background-color: #fafafa;
        }
    }
    &.grey-cararra,
    >.portlet-body.grey-cararra {
        background-color: #fafafa;
    }
    &.box.grey-steel>.portlet-title>.actions .btn-default {
        >i {
            color: #fff;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #fff;
            color: #fff;
        }
    }
    &.solid.grey-cararra> {
        .portlet-body {
            border: 0;
            color: #333;
        }
        .portlet-title {
            border: 0;
            color: #333;
            > {
                .caption>i {
                    color: #333;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.grey-cararra {
            border: 1px solid #fff;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #333;
                    >i {
                        color: #333;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #fff;
                    color: #fff;
                }
            }
        }
        &.grey-gallery>.portlet-title {
            background-color: #555;
        }
    }
    &.grey-gallery,
    >.portlet-body.grey-gallery {
        background-color: #555;
    }
    &.box.grey-cararra>.portlet-title>.actions .btn-default {
        >i {
            color: #fff;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #fff;
            color: #fff;
        }
    }
    &.solid.grey-gallery> {
        .portlet-body {
            border: 0;
            color: #fff;
        }
        .portlet-title {
            border: 0;
            color: #fff;
            > {
                .caption>i {
                    color: #fff;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.grey-gallery {
            border: 1px solid #6f6f6f;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #fff;
                    >i {
                        color: #fff;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #8d8d8d;
                    color: #9a9a9a;
                }
            }
        }
        &.grey-cascade>.portlet-title {
            background-color: #95A5A6;
        }
    }
    &.grey-cascade,
    >.portlet-body.grey-cascade {
        background-color: #95A5A6;
    }
    &.box.grey-gallery>.portlet-title>.actions .btn-default {
        >i {
            color: #a2a2a2;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #a7a7a7;
            color: #b3b3b3;
        }
    }
    &.solid.grey-cascade> {
        .portlet-body {
            border: 0;
            color: #FFF;
        }
        .portlet-title {
            border: 0;
            color: #FFF;
            > {
                .caption>i {
                    color: #FFF;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.grey-cascade {
            border: 1px solid #b1bdbd;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #FFF;
                    >i {
                        color: #FFF;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #d2d9d9;
                    color: #e0e5e5;
                }
            }
        }
        &.grey-silver>.portlet-title {
            background-color: #BFBFBF;
        }
    }
    &.grey-silver,
    >.portlet-body.grey-silver {
        background-color: #BFBFBF;
    }
    &.box.grey-cascade>.portlet-title>.actions .btn-default {
        >i {
            color: #e8ecec;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #eef0f0;
            color: #fcfcfc;
        }
    }
    &.solid.grey-silver> {
        .portlet-body {
            border: 0;
            color: #FAFCFB;
        }
        .portlet-title {
            border: 0;
            color: #FAFCFB;
            > {
                .caption>i {
                    color: #FAFCFB;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.grey-silver {
            border: 1px solid #d9d9d9;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #FAFCFB;
                    >i {
                        color: #FAFCFB;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #f7f7f7;
                    color: #fff;
                }
            }
        }
        &.grey-salsa>.portlet-title {
            background-color: #ACB5C3;
        }
    }
    &.grey-salsa,
    >.portlet-body.grey-salsa {
        background-color: #ACB5C3;
    }
    &.box.grey-silver>.portlet-title>.actions .btn-default {
        >i {
            color: #fff;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #fff;
            color: #fff;
        }
    }
    &.solid.grey-salsa> {
        .portlet-body {
            border: 0;
            color: #FAFCFB;
        }
        .portlet-title {
            border: 0;
            color: #FAFCFB;
            > {
                .caption {
                    font-weight: 400;
                    >i {
                        color: #FAFCFB;
                    }
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.grey-salsa {
            border: 1px solid #cacfd8;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #FAFCFB;
                    >i {
                        color: #FAFCFB;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #edeff2;
                    color: #fcfcfd;
                }
            }
        }
        &.grey-salt>.portlet-title {
            background-color: #bfcad1;
        }
    }
    &.grey-salt,
    >.portlet-body.grey-salt {
        background-color: #bfcad1;
    }
    &.box.grey-salsa>.portlet-title>.actions .btn-default {
        >i {
            color: #fff;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #fff;
            color: #fff;
        }
    }
    &.solid.grey-salt> {
        .portlet-body {
            border: 0;
            color: #FAFCFB;
        }
        .portlet-title {
            border: 0;
            color: #FAFCFB;
            > {
                .caption>i {
                    color: #FAFCFB;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.grey-salt {
            border: 1px solid #dde3e6;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #FAFCFB;
                    >i {
                        color: #FAFCFB;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #fff;
                    color: #fff;
                }
            }
        }
        &.grey-mint>.portlet-title {
            background-color: #525e64;
        }
    }
    &.grey-mint,
    >.portlet-body.grey-mint {
        background-color: #525e64;
    }
    &.box.grey-salt>.portlet-title>.actions .btn-default {
        >i {
            color: #fff;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #fff;
            color: #fff;
        }
    }
    &.solid.grey-mint> {
        .portlet-body {
            border: 0;
            color: #FFF;
        }
        .portlet-title {
            border: 0;
            color: #FFF;
            > {
                .caption>i {
                    color: #FFF;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.grey-mint {
            border: 1px solid #697880;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #FFF;
                    >i {
                        color: #FFF;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #88979e;
                    color: #96a3a9;
                }
            }
        }
        &.red>.portlet-title {
            background-color: #e7505a;
        }
    }
    &.red,
    >.portlet-body.red {
        background-color: #e7505a;
    }
    &.box.grey-mint>.portlet-title>.actions .btn-default {
        >i {
            color: #9faab0;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #a4afb5;
            color: #b2bcc0;
        }
    }
    &.solid.red> {
        .portlet-body {
            border: 0;
            color: #fff;
        }
        .portlet-title {
            border: 0;
            color: #fff;
            > {
                .caption>i {
                    color: #fff;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.red {
            border: 1px solid #ed7d84;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #fff;
                    >i {
                        color: #fff;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #f5b3b7;
                    color: #f8c9cc;
                }
            }
        }
        &.red-pink>.portlet-title {
            background-color: #E08283;
        }
    }
    &.red-pink,
    >.portlet-body.red-pink {
        background-color: #E08283;
    }
    &.box.red>.portlet-title>.actions .btn-default {
        >i {
            color: #f9d7d9;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #fbe0e1;
            color: #fef6f6;
        }
    }
    &.solid.red-pink> {
        .portlet-body {
            border: 0;
            color: #fff;
        }
        .portlet-title {
            border: 0;
            color: #fff;
            > {
                .caption>i {
                    color: #fff;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.red-pink {
            border: 1px solid #eaabac;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #fff;
                    >i {
                        color: #fff;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #f6dcdc;
                    color: #fbf0f0;
                }
            }
        }
        &.red-sunglo>.portlet-title {
            background-color: #E26A6A;
        }
    }
    &.red-sunglo,
    >.portlet-body.red-sunglo {
        background-color: #E26A6A;
    }
    &.box.red-pink>.portlet-title>.actions .btn-default {
        >i {
            color: #fefdfd;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #fff;
            color: #fff;
        }
    }
    &.solid.red-sunglo> {
        .portlet-body {
            border: 0;
            color: #fff;
        }
        .portlet-title {
            border: 0;
            color: #fff;
            > {
                .caption>i {
                    color: #fff;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.red-sunglo {
            border: 1px solid #ea9595;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #fff;
                    >i {
                        color: #fff;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #f4c8c8;
                    color: #f8dddd;
                }
            }
        }
        &.red-intense>.portlet-title {
            background-color: #e35b5a;
        }
    }
    &.red-intense,
    >.portlet-body.red-intense {
        background-color: #e35b5a;
    }
    &.box.red-sunglo>.portlet-title>.actions .btn-default {
        >i {
            color: #fbeaea;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #fdf3f3;
            color: #fff;
        }
    }
    &.solid.red-intense> {
        .portlet-body {
            border: 0;
            color: #fff;
        }
        .portlet-title {
            border: 0;
            color: #fff;
            > {
                .caption>i {
                    color: #fff;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.red-intense {
            border: 1px solid #ea8686;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #fff;
                    >i {
                        color: #fff;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #f3baba;
                    color: #f7d0d0;
                }
            }
        }
        &.red-thunderbird>.portlet-title {
            background-color: #D91E18;
        }
    }
    &.red-thunderbird,
    >.portlet-body.red-thunderbird {
        background-color: #D91E18;
    }
    &.box.red-intense>.portlet-title>.actions .btn-default {
        >i {
            color: #f9dddd;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #fbe6e6;
            color: #fefbfb;
        }
    }
    &.solid.red-thunderbird> {
        .portlet-body {
            border: 0;
            color: #fff;
        }
        .portlet-title {
            border: 0;
            color: #fff;
            > {
                .caption>i {
                    color: #fff;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.red-thunderbird {
            border: 1px solid #e9403b;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #fff;
                    >i {
                        color: #fff;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #ef7672;
                    color: #f28c89;
                }
            }
        }
        &.red-flamingo>.portlet-title {
            background-color: #EF4836;
        }
    }
    &.red-flamingo,
    >.portlet-body.red-flamingo {
        background-color: #EF4836;
    }
    &.box.red-thunderbird>.portlet-title>.actions .btn-default {
        >i {
            color: #f39997;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #f4a2a0;
            color: #f7b9b7;
        }
    }
    &.solid.red-flamingo> {
        .portlet-body {
            border: 0;
            color: #fff;
        }
        .portlet-title {
            border: 0;
            color: #fff;
            > {
                .caption>i {
                    color: #fff;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.red-flamingo {
            border: 1px solid #f37365;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #fff;
                    >i {
                        color: #fff;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #f7a79e;
                    color: #f9bcb6;
                }
            }
        }
        &.red-soft>.portlet-title {
            background-color: #d05454;
        }
    }
    &.red-soft,
    >.portlet-body.red-soft {
        background-color: #d05454;
    }
    &.box.red-flamingo>.portlet-title>.actions .btn-default {
        >i {
            color: #fac9c4;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #fbd2cd;
            color: #fde7e5;
        }
    }
    &.solid.red-soft> {
        .portlet-body {
            border: 0;
            color: #fff;
        }
        .portlet-title {
            border: 0;
            color: #fff;
            > {
                .caption>i {
                    color: #fff;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.red-soft {
            border: 1px solid #db7c7c;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #fff;
                    >i {
                        color: #fff;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #e8acac;
                    color: #eec0c0;
                }
            }
        }
        &.red-haze>.portlet-title {
            background-color: #f36a5a;
        }
    }
    &.red-haze,
    >.portlet-body.red-haze {
        background-color: #f36a5a;
    }
    &.box.red-soft>.portlet-title>.actions .btn-default {
        >i {
            color: #f1cccc;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #f3d4d4;
            color: #f9e8e8;
        }
    }
    &.solid.red-haze> {
        .portlet-body {
            border: 0;
            color: #fff;
        }
        .portlet-title {
            border: 0;
            color: #fff;
            > {
                .caption>i {
                    color: #fff;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.red-haze {
            border: 1px solid #f6958a;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #fff;
                    >i {
                        color: #fff;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #fbc8c3;
                    color: #fcdeda;
                }
            }
        }
        &.red-mint>.portlet-title {
            background-color: #e43a45;
        }
    }
    &.red-mint,
    >.portlet-body.red-mint {
        background-color: #e43a45;
    }
    &.box.red-haze>.portlet-title>.actions .btn-default {
        >i {
            color: #fdebe9;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #fef3f2;
            color: #fff;
        }
    }
    &.solid.red-mint> {
        .portlet-body {
            border: 0;
            color: #fff;
        }
        .portlet-title {
            border: 0;
            color: #fff;
            > {
                .caption>i {
                    color: #fff;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.red-mint {
            border: 1px solid #ea676f;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #fff;
                    >i {
                        color: #fff;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #f29da2;
                    color: #f5b3b7;
                }
            }
        }
        &.yellow>.portlet-title {
            background-color: #c49f47;
        }
    }
    &.yellow,
    >.portlet-body.yellow {
        background-color: #c49f47;
    }
    &.box.red-mint>.portlet-title>.actions .btn-default {
        >i {
            color: #f6c1c4;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #f8cacd;
            color: #fbe0e2;
        }
    }
    &.solid.yellow> {
        .portlet-body {
            border: 0;
            color: #fff;
        }
        .portlet-title {
            border: 0;
            color: #fff;
            > {
                .caption>i {
                    color: #fff;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.yellow {
            border: 1px solid #d0b36e;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #fff;
                    >i {
                        color: #fff;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #dfcb9c;
                    color: #e5d5af;
                }
            }
        }
        &.yellow-gold>.portlet-title {
            background-color: #E87E04;
        }
    }
    &.yellow-gold,
    >.portlet-body.yellow-gold {
        background-color: #E87E04;
    }
    &.box.yellow>.portlet-title>.actions .btn-default {
        >i {
            color: #e9dbbb;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #ecdfc3;
            color: #f2ead6;
        }
    }
    &.solid.yellow-gold> {
        .portlet-body {
            border: 0;
            color: #fff;
        }
        .portlet-title {
            border: 0;
            color: #fff;
            > {
                .caption>i {
                    color: #fff;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.yellow-gold {
            border: 1px solid #fb9724;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #fff;
                    >i {
                        color: #fff;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #fcb460;
                    color: #fdbf79;
                }
            }
        }
        &.yellow-casablanca>.portlet-title {
            background-color: #f2784b;
        }
    }
    &.yellow-casablanca,
    >.portlet-body.yellow-casablanca {
        background-color: #f2784b;
    }
    &.box.yellow-gold>.portlet-title>.actions .btn-default {
        >i {
            color: #fdc788;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #fdcb92;
            color: #fed7ab;
        }
    }
    &.solid.yellow-casablanca> {
        .portlet-body {
            border: 0;
            color: #fff;
        }
        .portlet-title {
            border: 0;
            color: #fff;
            > {
                .caption>i {
                    color: #fff;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.yellow-casablanca {
            border: 1px solid #f59c7b;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #fff;
                    >i {
                        color: #fff;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #fac6b4;
                    color: #fbd8cb;
                }
            }
        }
        &.yellow-crusta>.portlet-title {
            background-color: #f3c200;
        }
    }
    &.yellow-crusta,
    >.portlet-body.yellow-crusta {
        background-color: #f3c200;
    }
    &.box.yellow-casablanca>.portlet-title>.actions .btn-default {
        >i {
            color: #fce3da;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #fdeae3;
            color: #fffcfb;
        }
    }
    &.solid.yellow-crusta> {
        .portlet-body {
            border: 0;
            color: #fff;
        }
        .portlet-title {
            border: 0;
            color: #fff;
            > {
                .caption>i {
                    color: #fff;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.yellow-crusta {
            border: 1px solid #ffd327;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #fff;
                    >i {
                        color: #fff;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #ffe064;
                    color: #ffe57e;
                }
            }
        }
        &.yellow-lemon>.portlet-title {
            background-color: #F7CA18;
        }
    }
    &.yellow-lemon,
    >.portlet-body.yellow-lemon {
        background-color: #F7CA18;
    }
    &.box.yellow-crusta>.portlet-title>.actions .btn-default {
        >i {
            color: #ffe88d;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #ffea97;
            color: #ffefb1;
        }
    }
    &.solid.yellow-lemon> {
        .portlet-body {
            border: 0;
            color: #fff;
        }
        .portlet-title {
            border: 0;
            color: #fff;
            > {
                .caption>i {
                    color: #fff;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.yellow-lemon {
            border: 1px solid #f9d549;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #fff;
                    >i {
                        color: #fff;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #fbe384;
                    color: #fce99d;
                }
            }
        }
        &.yellow-saffron>.portlet-title {
            background-color: #F4D03F;
        }
    }
    &.yellow-saffron,
    >.portlet-body.yellow-saffron {
        background-color: #F4D03F;
    }
    &.box.yellow-lemon>.portlet-title>.actions .btn-default {
        >i {
            color: #fcecac;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #fceeb6;
            color: #fdf4ce;
        }
    }
    &.solid.yellow-saffron> {
        .portlet-body {
            border: 0;
            color: #fff;
        }
        .portlet-title {
            border: 0;
            color: #fff;
            > {
                .caption>i {
                    color: #fff;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.yellow-saffron {
            border: 1px solid #f7dc6f;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #fff;
                    >i {
                        color: #fff;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #faeaa9;
                    color: #fbf0c1;
                }
            }
        }
        &.yellow-soft>.portlet-title {
            background-color: #c8d046;
        }
    }
    &.yellow-soft,
    >.portlet-body.yellow-soft {
        background-color: #c8d046;
    }
    &.box.yellow-saffron>.portlet-title>.actions .btn-default {
        >i {
            color: #fcf3d0;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #fdf6d9;
            color: #fefcf1;
        }
    }
    &.solid.yellow-soft> {
        .portlet-body {
            border: 0;
            color: #fff;
        }
        .portlet-title {
            border: 0;
            color: #fff;
            > {
                .caption>i {
                    color: #fff;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.yellow-soft {
            border: 1px solid #d4da6f;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #fff;
                    >i {
                        color: #fff;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #e3e79f;
                    color: #e9ecb4;
                }
            }
        }
        &.yellow-haze>.portlet-title {
            background-color: #c5bf66;
        }
    }
    &.yellow-haze,
    >.portlet-body.yellow-haze {
        background-color: #c5bf66;
    }
    &.box.yellow-soft>.portlet-title>.actions .btn-default {
        >i {
            color: #ecefc0;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #eff1c8;
            color: #f5f6dc;
        }
    }
    &.solid.yellow-haze> {
        .portlet-body {
            border: 0;
            color: #fff;
        }
        .portlet-title {
            border: 0;
            color: #fff;
            > {
                .caption>i {
                    color: #fff;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.yellow-haze {
            border: 1px solid #d3ce8b;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #fff;
                    >i {
                        color: #fff;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #e4e1b7;
                    color: #ebe9ca;
                }
            }
        }
        &.yellow-mint>.portlet-title {
            background-color: #c5b96b;
        }
    }
    &.yellow-mint,
    >.portlet-body.yellow-mint {
        background-color: #c5b96b;
    }
    &.box.yellow-haze>.portlet-title>.actions .btn-default {
        >i {
            color: #efedd5;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #f2f1dc;
            color: #f9f8ef;
        }
    }
    &.solid.yellow-mint> {
        .portlet-body {
            border: 0;
            color: #fff;
        }
        .portlet-title {
            border: 0;
            color: #fff;
            > {
                .caption>i {
                    color: #fff;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.yellow-mint {
            border: 1px solid #d3ca90;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #fff;
                    >i {
                        color: #fff;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #e5dfbc;
                    color: #ece8ce;
                }
            }
        }
        &.purple>.portlet-title {
            background-color: #8E44AD;
        }
    }
    &.purple,
    >.portlet-body.purple {
        background-color: #8E44AD;
    }
    &.box.yellow-mint>.portlet-title>.actions .btn-default {
        >i {
            color: #f0edd9;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #f3f0e0;
            color: #faf9f3;
        }
    }
    &.solid.purple> {
        .portlet-body {
            border: 0;
            color: #fff;
        }
        .portlet-title {
            border: 0;
            color: #fff;
            > {
                .caption>i {
                    color: #fff;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.purple {
            border: 1px solid #a563c1;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #fff;
                    >i {
                        color: #fff;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #bf8ed3;
                    color: #c9a1da;
                }
            }
        }
        &.purple-plum>.portlet-title {
            background-color: #8775a7;
        }
    }
    &.purple-plum,
    >.portlet-body.purple-plum {
        background-color: #8775a7;
    }
    &.box.purple>.portlet-title>.actions .btn-default {
        >i {
            color: #cfacde;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #d4b3e1;
            color: #dec5e8;
        }
    }
    &.solid.purple-plum> {
        .portlet-body {
            border: 0;
            color: #fff;
        }
        .portlet-title {
            border: 0;
            color: #fff;
            > {
                .caption>i {
                    color: #fff;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.purple-plum {
            border: 1px solid #a294bb;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #fff;
                    >i {
                        color: #fff;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #c3bad3;
                    color: #d0c9dd;
                }
            }
        }
        &.purple-medium>.portlet-title {
            background-color: #BF55EC;
        }
    }
    &.purple-medium,
    >.portlet-body.purple-medium {
        background-color: #BF55EC;
    }
    &.box.purple-plum>.portlet-title>.actions .btn-default {
        >i {
            color: #d8d2e3;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #ded9e7;
            color: #ebe8f0;
        }
    }
    &.solid.purple-medium> {
        .portlet-body {
            border: 0;
            color: #fff;
        }
        .portlet-title {
            border: 0;
            color: #fff;
            > {
                .caption>i {
                    color: #fff;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.purple-medium {
            border: 1px solid #d083f1;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #fff;
                    >i {
                        color: #fff;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #e5baf7;
                    color: #eed1fa;
                }
            }
        }
        &.purple-studio>.portlet-title {
            background-color: #8E44AD;
        }
    }
    &.purple-studio,
    >.portlet-body.purple-studio {
        background-color: #8E44AD;
    }
    &.box.purple-medium>.portlet-title>.actions .btn-default {
        >i {
            color: #f3dffb;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #f6e8fc;
            color: #fff;
        }
    }
    &.solid.purple-studio> {
        .portlet-body {
            border: 0;
            color: #fff;
        }
        .portlet-title {
            border: 0;
            color: #fff;
            > {
                .caption>i {
                    color: #fff;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.purple-studio {
            border: 1px solid #a563c1;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #fff;
                    >i {
                        color: #fff;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #bf8ed3;
                    color: #c9a1da;
                }
            }
        }
        &.purple-wisteria>.portlet-title {
            background-color: #9B59B6;
        }
    }
    &.purple-wisteria,
    >.portlet-body.purple-wisteria {
        background-color: #9B59B6;
    }
    &.box.purple-studio>.portlet-title>.actions .btn-default {
        >i {
            color: #cfacde;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #d4b3e1;
            color: #dec5e8;
        }
    }
    &.solid.purple-wisteria> {
        .portlet-body {
            border: 0;
            color: #fff;
        }
        .portlet-title {
            border: 0;
            color: #fff;
            > {
                .caption>i {
                    color: #fff;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.purple-wisteria {
            border: 1px solid #b07cc6;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #fff;
                    >i {
                        color: #fff;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #caa7d8;
                    color: #d5b9e0;
                }
            }
        }
        &.purple-seance>.portlet-title {
            background-color: #9A12B3;
        }
    }
    &.purple-seance,
    >.portlet-body.purple-seance {
        background-color: #9A12B3;
    }
    &.box.purple-wisteria>.portlet-title>.actions .btn-default {
        >i {
            color: #dbc3e5;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #dfcae8;
            color: #eadcf0;
        }
    }
    &.solid.purple-seance> {
        .portlet-body {
            border: 0;
            color: #fff;
        }
        .portlet-title {
            border: 0;
            color: #fff;
            > {
                .caption>i {
                    color: #fff;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.purple-seance {
            border: 1px solid #c217e1;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #fff;
                    >i {
                        color: #fff;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #d349ed;
                    color: #d960ef;
                }
            }
        }
        &.purple-intense>.portlet-title {
            background-color: #8775a7;
        }
    }
    &.purple-intense,
    >.portlet-body.purple-intense {
        background-color: #8775a7;
    }
    &.box.purple-seance>.portlet-title>.actions .btn-default {
        >i {
            color: #dc6ef0;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #de77f1;
            color: #e48ef4;
        }
    }
    &.solid.purple-intense> {
        .portlet-body {
            border: 0;
            color: #fff;
        }
        .portlet-title {
            border: 0;
            color: #fff;
            > {
                .caption>i {
                    color: #fff;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.purple-intense {
            border: 1px solid #a294bb;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #fff;
                    >i {
                        color: #fff;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #c3bad3;
                    color: #d0c9dd;
                }
            }
        }
        &.purple-sharp>.portlet-title {
            background-color: #796799;
        }
    }
    &.purple-sharp,
    >.portlet-body.purple-sharp {
        background-color: #796799;
    }
    &.box.purple-intense>.portlet-title>.actions .btn-default {
        >i {
            color: #d8d2e3;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #ded9e7;
            color: #ebe8f0;
        }
    }
    &.solid.purple-sharp> {
        .portlet-body {
            border: 0;
            color: #fff;
        }
        .portlet-title {
            border: 0;
            color: #fff;
            > {
                .caption>i {
                    color: #fff;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box {
        &.purple-sharp {
            border: 1px solid #9486ad;
            border-top: 0;
            >.portlet-title> {
                .caption {
                    color: #fff;
                    >i {
                        color: #fff;
                    }
                }
                .actions .btn-default {
                    background: 0 0 !important;
                    border: 1px solid #b4aac6;
                    color: #c2b9d0;
                }
            }
        }
        &.purple-soft>.portlet-title {
            background-color: #8877a9;
        }
    }
    &.purple-soft,
    >.portlet-body.purple-soft {
        background-color: #8877a9;
    }
    &.box.purple-sharp>.portlet-title>.actions .btn-default {
        >i {
            color: #cac3d6;
        }
        &.active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid #cfc9db;
            color: #ddd8e5;
        }
    }
    &.solid.purple-soft> {
        .portlet-body {
            border: 0;
            color: #fff;
        }
        .portlet-title {
            border: 0;
            color: #fff;
            > {
                .caption>i {
                    color: #fff;
                }
                .tools>a {
                    &.remove {
                        background-image: url(../../img/admin/portlet-remove-icon-white.png);
                    }
                    &.config {
                        background-image: url(../../img/admin/portlet-config-icon-white.png);
                    }
                    &.reload {
                        background-image: url(../../img/admin/portlet-reload-icon-white.png);
                    }
                    &.expand {
                        background-image: url(../../img/admin/portlet-expand-icon-white.png);
                    }
                    &.collapse {
                        background-image: url(../../img/admin/portlet-collapse-icon-white.png);
                    }
                    &.fullscreen {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }
    &.box.purple-soft {
        border: 1px solid #a396bd;
        border-top: 0;
        >.portlet-title> {
            .caption {
                color: #fff;
                >i {
                    color: #fff;
                }
            }
            .actions .btn-default {
                background: 0 0 !important;
                border: 1px solid #c4bcd4;
                color: #d2cbde;
                >i {
                    color: #dad5e4;
                }
                &.active,
                &:active,
                &:focus,
                &:hover {
                    border: 1px solid #dfdbe8;
                    color: #edebf2;
                }
            }
        }
    }
}

.progress {
    border: 0;
    background-image: none;
    filter: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    &.progress-sm {
        height: 12px;
    }
}

.page-md .progress {
    text-indent: -99999px;
}

.progress-bar {
    &.white {
        background: #fff !important;
        color: #666 !important;
    }
    &.default {
        background: #e1e5ec !important;
        color: #666 !important;
    }
    &.dark {
        background: #2f353b !important;
        color: #FFF !important;
    }
    &.blue {
        background: #3598dc !important;
        color: #FFF !important;
    }
    &.blue-madison {
        background: #578ebe !important;
        color: #FFF !important;
    }
    &.blue-chambray {
        background: #2C3E50 !important;
        color: #FFF !important;
    }
    &.blue-ebonyclay {
        background: #22313F !important;
        color: #FFF !important;
    }
    &.blue-hoki {
        background: #67809F !important;
        color: #FFF !important;
    }
    &.blue-steel {
        background: #4B77BE !important;
        color: #FFF !important;
    }
    &.blue-soft {
        background: #4c87b9 !important;
        color: #FFF !important;
    }
    &.blue-dark {
        background: #5e738b !important;
        color: #FFF !important;
    }
    &.blue-sharp {
        background: #5C9BD1 !important;
        color: #FFF !important;
    }
    &.blue-oleo {
        background: #94A0B2 !important;
        color: #FFF !important;
    }
    &.green {
        background: #32c5d2 !important;
        color: #FFF !important;
    }
    &.green-meadow {
        background: #1BBC9B !important;
        color: #FFF !important;
    }
    &.green-seagreen {
        background: #1BA39C !important;
        color: #FFF !important;
    }
    &.green-turquoise {
        background: #36D7B7 !important;
        color: #FFF !important;
    }
    &.green-haze {
        background: #44b6ae !important;
        color: #FFF !important;
    }
    &.green-jungle {
        background: #26C281 !important;
        color: #FFF !important;
    }
    &.green-soft {
        background: #3faba4 !important;
        color: #FFF !important;
    }
    &.green-dark {
        background: #4DB3A2 !important;
        color: #FFF !important;
    }
    &.green-sharp {
        background: #2ab4c0 !important;
        color: #FFF !important;
    }
    &.green-steel {
        background: #29b4b6 !important;
        color: #FFF !important;
    }
    &.grey {
        background: #E5E5E5 !important;
        color: #333 !important;
    }
    &.grey-steel {
        background: #e9edef !important;
        color: #80898e !important;
    }
    &.grey-cararra {
        background: #fafafa !important;
        color: #333 !important;
    }
    &.grey-gallery {
        background: #555 !important;
        color: #fff !important;
    }
    &.grey-cascade {
        background: #95A5A6 !important;
        color: #FFF !important;
    }
    &.grey-silver {
        background: #BFBFBF !important;
        color: #FAFCFB !important;
    }
    &.grey-salsa {
        background: #ACB5C3 !important;
        color: #FAFCFB !important;
    }
    &.grey-salt {
        background: #bfcad1 !important;
        color: #FAFCFB !important;
    }
    &.grey-mint {
        background: #525e64 !important;
        color: #FFF !important;
    }
    &.red {
        background: #e7505a !important;
        color: #fff !important;
    }
    &.red-pink {
        background: #E08283 !important;
        color: #fff !important;
    }
    &.red-sunglo {
        background: #E26A6A !important;
        color: #fff !important;
    }
    &.red-intense {
        background: #e35b5a !important;
        color: #fff !important;
    }
    &.red-thunderbird {
        background: #D91E18 !important;
        color: #fff !important;
    }
    &.red-flamingo {
        background: #EF4836 !important;
        color: #fff !important;
    }
    &.red-soft {
        background: #d05454 !important;
        color: #fff !important;
    }
    &.red-haze {
        background: #f36a5a !important;
        color: #fff !important;
    }
    &.red-mint {
        background: #e43a45 !important;
        color: #fff !important;
    }
    &.yellow {
        background: #c49f47 !important;
        color: #fff !important;
    }
    &.yellow-gold {
        background: #E87E04 !important;
        color: #fff !important;
    }
    &.yellow-casablanca {
        background: #f2784b !important;
        color: #fff !important;
    }
    &.yellow-crusta {
        background: #f3c200 !important;
        color: #fff !important;
    }
    &.yellow-lemon {
        background: #F7CA18 !important;
        color: #fff !important;
    }
    &.yellow-saffron {
        background: #F4D03F !important;
        color: #fff !important;
    }
    &.yellow-soft {
        background: #c8d046 !important;
        color: #fff !important;
    }
    &.yellow-haze {
        background: #c5bf66 !important;
        color: #fff !important;
    }
    &.yellow-mint {
        background: #c5b96b !important;
        color: #fff !important;
    }
    &.purple {
        background: #8E44AD !important;
        color: #fff !important;
    }
    &.purple-plum {
        background: #8775a7 !important;
        color: #fff !important;
    }
    &.purple-medium {
        background: #BF55EC !important;
        color: #fff !important;
    }
    &.purple-studio {
        background: #8E44AD !important;
        color: #fff !important;
    }
    &.purple-wisteria {
        background: #9B59B6 !important;
        color: #fff !important;
    }
    &.purple-seance {
        background: #9A12B3 !important;
        color: #fff !important;
    }
    &.purple-intense {
        background: #8775a7 !important;
        color: #fff !important;
    }
    &.purple-sharp {
        background: #796799 !important;
        color: #fff !important;
    }
    &.purple-soft {
        background: #8877a9 !important;
        color: #fff !important;
    }
}

.dashboard-stat {
    display: block;
    margin-bottom: 25px;
    overflow: hidden;
    border-radius: 4px;
    &:after,
    &:before {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

.portlet .dashboard-stat:last-child {
    margin-bottom: 0;
}

.dashboard-stat {
    .visual {
        width: 80px;
        height: 80px;
        display: block;
        float: left;
        padding-top: 10px;
        padding-left: 15px;
        margin-bottom: 15px;
        font-size: 35px;
        line-height: 35px;
        >i {
            margin-left: -35px;
            font-size: 110px;
            line-height: 110px;
        }
    }
    .details {
        position: absolute;
        right: 15px;
        padding-right: 15px;
        .number {
            padding-top: 25px;
            text-align: right;
            font-size: 34px;
            line-height: 36px;
            letter-spacing: -1px;
            margin-bottom: 0;
            font-weight: 300;
        }
        .desc {
            text-align: right;
            font-size: 16px;
            letter-spacing: 0;
            font-weight: 300;
        }
    }
    .more {
        clear: both;
        display: block;
        padding: 6px 10px;
        position: relative;
        text-transform: uppercase;
        font-weight: 300;
        font-size: 11px;
        opacity: .7;
        filter: alpha(opacity=70);
        &:hover {
            text-decoration: none;
            opacity: .9;
            filter: alpha(opacity=90);
        }
        >i {
            display: inline-block;
            margin-top: 1px;
            float: right;
        }
    }
    &.dashboard-stat-v2 .visual {
        padding-top: 35px;
        margin-bottom: 40px;
    }
    &.white {
        background-color: #fff;
        &.dashboard-stat-light:hover {
            background-color: #f5f5f5;
        }
        .visual>i {
            color: #666;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #666;
            }
            .desc {
                color: #666;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #666;
            background-color: #f5f5f5;
        }
    }
    &.default {
        background-color: #e1e5ec;
        &.dashboard-stat-light:hover {
            background-color: #d5dae4;
        }
        .visual>i {
            color: #666;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #666;
            }
            .desc {
                color: #666;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #666;
            background-color: #d5dae4;
        }
    }
    &.dark {
        background-color: #2f353b;
        &.dashboard-stat-light:hover {
            background-color: #262b30;
        }
        .visual>i {
            color: #FFF;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #FFF;
            }
            .desc {
                color: #FFF;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #FFF;
            background-color: #262b30;
        }
    }
    &.blue {
        background-color: #3598dc;
        &.dashboard-stat-light:hover {
            background-color: #258fd7;
        }
        .visual>i {
            color: #FFF;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #FFF;
            }
            .desc {
                color: #FFF;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #FFF;
            background-color: #258fd7;
        }
    }
    &.blue-madison {
        background-color: #578ebe;
        &.dashboard-stat-light:hover {
            background-color: #4884b8;
        }
        .visual>i {
            color: #FFF;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #FFF;
            }
            .desc {
                color: #FFF;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #FFF;
            background-color: #4884b8;
        }
    }
    &.blue-chambray {
        background-color: #2C3E50;
        &.dashboard-stat-light:hover {
            background-color: #253443;
        }
        .visual>i {
            color: #FFF;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #FFF;
            }
            .desc {
                color: #FFF;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #FFF;
            background-color: #253443;
        }
    }
    &.blue-ebonyclay {
        background-color: #22313F;
        &.dashboard-stat-light:hover {
            background-color: #1b2732;
        }
        .visual>i {
            color: #FFF;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #FFF;
            }
            .desc {
                color: #FFF;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #FFF;
            background-color: #1b2732;
        }
    }
    &.blue-hoki {
        background-color: #67809F;
        &.dashboard-stat-light:hover {
            background-color: #5e7694;
        }
        .visual>i {
            color: #FFF;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #FFF;
            }
            .desc {
                color: #FFF;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #FFF;
            background-color: #5e7694;
        }
    }
    &.blue-steel {
        background-color: #4B77BE;
        &.dashboard-stat-light:hover {
            background-color: #416db4;
        }
        .visual>i {
            color: #FFF;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #FFF;
            }
            .desc {
                color: #FFF;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #FFF;
            background-color: #416db4;
        }
    }
    &.blue-soft {
        background-color: #4c87b9;
        &.dashboard-stat-light:hover {
            background-color: #447dad;
        }
        .visual>i {
            color: #FFF;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #FFF;
            }
            .desc {
                color: #FFF;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #FFF;
            background-color: #447dad;
        }
    }
    &.blue-dark {
        background-color: #5e738b;
        &.dashboard-stat-light:hover {
            background-color: #56697f;
        }
        .visual>i {
            color: #FFF;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #FFF;
            }
            .desc {
                color: #FFF;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #FFF;
            background-color: #56697f;
        }
    }
    &.blue-sharp {
        background-color: #5C9BD1;
        &.dashboard-stat-light:hover {
            background-color: #4c91cd;
        }
        .visual>i {
            color: #FFF;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #FFF;
            }
            .desc {
                color: #FFF;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #FFF;
            background-color: #4c91cd;
        }
    }
    &.blue-oleo {
        background-color: #94A0B2;
        &.dashboard-stat-light:hover {
            background-color: #8895a9;
        }
        .visual>i {
            color: #FFF;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #FFF;
            }
            .desc {
                color: #FFF;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #FFF;
            background-color: #8895a9;
        }
    }
    &.green {
        background-color: #32c5d2;
        &.dashboard-stat-light:hover {
            background-color: #2bb8c4;
        }
        .visual>i {
            color: #FFF;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #FFF;
            }
            .desc {
                color: #FFF;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #FFF;
            background-color: #2bb8c4;
        }
    }
    &.green-meadow {
        background-color: #1BBC9B;
        &.dashboard-stat-light:hover {
            background-color: #18aa8c;
        }
        .visual>i {
            color: #FFF;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #FFF;
            }
            .desc {
                color: #FFF;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #FFF;
            background-color: #18aa8c;
        }
    }
    &.green-seagreen {
        background-color: #1BA39C;
        &.dashboard-stat-light:hover {
            background-color: #18918b;
        }
        .visual>i {
            color: #FFF;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #FFF;
            }
            .desc {
                color: #FFF;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #FFF;
            background-color: #18918b;
        }
    }
    &.green-turquoise {
        background-color: #36D7B7;
        &.dashboard-stat-light:hover {
            background-color: #29cfae;
        }
        .visual>i {
            color: #FFF;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #FFF;
            }
            .desc {
                color: #FFF;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #FFF;
            background-color: #29cfae;
        }
    }
    &.green-haze {
        background-color: #44b6ae;
        &.dashboard-stat-light:hover {
            background-color: #3ea7a0;
        }
        .visual>i {
            color: #FFF;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #FFF;
            }
            .desc {
                color: #FFF;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #FFF;
            background-color: #3ea7a0;
        }
    }
    &.green-jungle {
        background-color: #26C281;
        &.dashboard-stat-light:hover {
            background-color: #23b176;
        }
        .visual>i {
            color: #FFF;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #FFF;
            }
            .desc {
                color: #FFF;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #FFF;
            background-color: #23b176;
        }
    }
    &.green-soft {
        background-color: #3faba4;
        &.dashboard-stat-light:hover {
            background-color: #3a9c96;
        }
        .visual>i {
            color: #FFF;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #FFF;
            }
            .desc {
                color: #FFF;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #FFF;
            background-color: #3a9c96;
        }
    }
    &.green-dark {
        background-color: #4DB3A2;
        &.dashboard-stat-light:hover {
            background-color: #46a595;
        }
        .visual>i {
            color: #FFF;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #FFF;
            }
            .desc {
                color: #FFF;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #FFF;
            background-color: #46a595;
        }
    }
    &.green-sharp {
        background-color: #2ab4c0;
        &.dashboard-stat-light:hover {
            background-color: #26a4af;
        }
        .visual>i {
            color: #FFF;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #FFF;
            }
            .desc {
                color: #FFF;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #FFF;
            background-color: #26a4af;
        }
    }
    &.green-steel {
        background-color: #29b4b6;
        &.dashboard-stat-light:hover {
            background-color: #25a4a5;
        }
        .visual>i {
            color: #FFF;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #FFF;
            }
            .desc {
                color: #FFF;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #FFF;
            background-color: #25a4a5;
        }
    }
    &.grey {
        background-color: #E5E5E5;
        &.dashboard-stat-light:hover {
            background-color: #dbdbdb;
        }
        .visual>i {
            color: #333;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #333;
            }
            .desc {
                color: #333;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #333;
            background-color: #dbdbdb;
        }
    }
    &.grey-steel {
        background-color: #e9edef;
        &.dashboard-stat-light:hover {
            background-color: #dde3e6;
        }
        .visual>i {
            color: #80898e;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #80898e;
            }
            .desc {
                color: #80898e;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #80898e;
            background-color: #dde3e6;
        }
    }
    &.grey-cararra {
        background-color: #fafafa;
        &.dashboard-stat-light:hover {
            background-color: #f0f0f0;
        }
        .visual>i {
            color: #333;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #333;
            }
            .desc {
                color: #333;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #333;
            background-color: #f0f0f0;
        }
    }
    &.grey-gallery {
        background-color: #555;
        &.dashboard-stat-light:hover {
            background-color: #4b4b4b;
        }
        .visual>i {
            color: #fff;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #fff;
            }
            .desc {
                color: #fff;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #fff;
            background-color: #4b4b4b;
        }
    }
    &.grey-cascade {
        background-color: #95A5A6;
        &.dashboard-stat-light:hover {
            background-color: #8a9c9d;
        }
        .visual>i {
            color: #FFF;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #FFF;
            }
            .desc {
                color: #FFF;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #FFF;
            background-color: #8a9c9d;
        }
    }
    &.grey-silver {
        background-color: #BFBFBF;
        &.dashboard-stat-light:hover {
            background-color: #b5b5b5;
        }
        .visual>i {
            color: #FAFCFB;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #FAFCFB;
            }
            .desc {
                color: #FAFCFB;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #FAFCFB;
            background-color: #b5b5b5;
        }
    }
    &.grey-salsa {
        background-color: #ACB5C3;
        &.dashboard-stat-light:hover {
            background-color: #a0aaba;
        }
        .visual>i {
            color: #FAFCFB;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #FAFCFB;
            }
            .desc {
                color: #FAFCFB;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #FAFCFB;
            background-color: #a0aaba;
        }
    }
    &.grey-salt {
        background-color: #bfcad1;
        &.dashboard-stat-light:hover {
            background-color: #b3c0c8;
        }
        .visual>i {
            color: #FAFCFB;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #FAFCFB;
            }
            .desc {
                color: #FAFCFB;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #FAFCFB;
            background-color: #b3c0c8;
        }
    }
    &.grey-mint {
        background-color: #525e64;
        &.dashboard-stat-light:hover {
            background-color: #495359;
        }
        .visual>i {
            color: #FFF;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #FFF;
            }
            .desc {
                color: #FFF;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #FFF;
            background-color: #495359;
        }
    }
    &.red {
        background-color: #e7505a;
        &.dashboard-stat-light:hover {
            background-color: #e53e49;
        }
        .visual>i {
            color: #fff;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #fff;
            }
            .desc {
                color: #fff;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #fff;
            background-color: #e53e49;
        }
    }
    &.red-pink {
        background-color: #E08283;
        &.dashboard-stat-light:hover {
            background-color: #dc7273;
        }
        .visual>i {
            color: #fff;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #fff;
            }
            .desc {
                color: #fff;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #fff;
            background-color: #dc7273;
        }
    }
    &.red-sunglo {
        background-color: #E26A6A;
        &.dashboard-stat-light:hover {
            background-color: #df5959;
        }
        .visual>i {
            color: #fff;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #fff;
            }
            .desc {
                color: #fff;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #fff;
            background-color: #df5959;
        }
    }
    &.red-intense {
        background-color: #e35b5a;
        &.dashboard-stat-light:hover {
            background-color: #e04a49;
        }
        .visual>i {
            color: #fff;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #fff;
            }
            .desc {
                color: #fff;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #fff;
            background-color: #e04a49;
        }
    }
    &.red-thunderbird {
        background-color: #D91E18;
        &.dashboard-stat-light:hover {
            background-color: #c71b16;
        }
        .visual>i {
            color: #fff;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #fff;
            }
            .desc {
                color: #fff;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #fff;
            background-color: #c71b16;
        }
    }
    &.red-flamingo {
        background-color: #EF4836;
        &.dashboard-stat-light:hover {
            background-color: #ed3723;
        }
        .visual>i {
            color: #fff;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #fff;
            }
            .desc {
                color: #fff;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #fff;
            background-color: #ed3723;
        }
    }
    &.red-soft {
        background-color: #d05454;
        &.dashboard-stat-light:hover {
            background-color: #c44;
        }
        .visual>i {
            color: #fff;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #fff;
            }
            .desc {
                color: #fff;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #fff;
            background-color: #c44;
        }
    }
    &.red-haze {
        background-color: #f36a5a;
        &.dashboard-stat-light:hover {
            background-color: #f25947;
        }
        .visual>i {
            color: #fff;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #fff;
            }
            .desc {
                color: #fff;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #fff;
            background-color: #f25947;
        }
    }
    &.red-mint {
        background-color: #e43a45;
        &.dashboard-stat-light:hover {
            background-color: #e22834;
        }
        .visual>i {
            color: #fff;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #fff;
            }
            .desc {
                color: #fff;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #fff;
            background-color: #e22834;
        }
    }
    &.yellow {
        background-color: #c49f47;
        &.dashboard-stat-light:hover {
            background-color: #bb953c;
        }
        .visual>i {
            color: #fff;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #fff;
            }
            .desc {
                color: #fff;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #fff;
            background-color: #bb953c;
        }
    }
    &.yellow-gold {
        background-color: #E87E04;
        &.dashboard-stat-light:hover {
            background-color: #d47304;
        }
        .visual>i {
            color: #fff;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #fff;
            }
            .desc {
                color: #fff;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #fff;
            background-color: #d47304;
        }
    }
    &.yellow-casablanca {
        background-color: #f2784b;
        &.dashboard-stat-light:hover {
            background-color: #f16a38;
        }
        .visual>i {
            color: #fff;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #fff;
            }
            .desc {
                color: #fff;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #fff;
            background-color: #f16a38;
        }
    }
    &.yellow-crusta {
        background-color: #f3c200;
        &.dashboard-stat-light:hover {
            background-color: #dfb200;
        }
        .visual>i {
            color: #fff;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #fff;
            }
            .desc {
                color: #fff;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #fff;
            background-color: #dfb200;
        }
    }
    &.yellow-lemon {
        background-color: #F7CA18;
        &.dashboard-stat-light:hover {
            background-color: #f2c308;
        }
        .visual>i {
            color: #fff;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #fff;
            }
            .desc {
                color: #fff;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #fff;
            background-color: #f2c308;
        }
    }
    &.yellow-saffron {
        background-color: #F4D03F;
        &.dashboard-stat-light:hover {
            background-color: #f3cb2c;
        }
        .visual>i {
            color: #fff;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #fff;
            }
            .desc {
                color: #fff;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #fff;
            background-color: #f3cb2c;
        }
    }
    &.yellow-soft {
        background-color: #c8d046;
        &.dashboard-stat-light:hover {
            background-color: #c3cc36;
        }
        .visual>i {
            color: #fff;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #fff;
            }
            .desc {
                color: #fff;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #fff;
            background-color: #c3cc36;
        }
    }
    &.yellow-haze {
        background-color: #c5bf66;
        &.dashboard-stat-light:hover {
            background-color: #bfb957;
        }
        .visual>i {
            color: #fff;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #fff;
            }
            .desc {
                color: #fff;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #fff;
            background-color: #bfb957;
        }
    }
    &.yellow-mint {
        background-color: #c5b96b;
        &.dashboard-stat-light:hover {
            background-color: #bfb25c;
        }
        .visual>i {
            color: #fff;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #fff;
            }
            .desc {
                color: #fff;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #fff;
            background-color: #bfb25c;
        }
    }
    &.purple {
        background-color: #8E44AD;
        &.dashboard-stat-light:hover {
            background-color: #823e9e;
        }
        .visual>i {
            color: #fff;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #fff;
            }
            .desc {
                color: #fff;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #fff;
            background-color: #823e9e;
        }
    }
    &.purple-plum {
        background-color: #8775a7;
        &.dashboard-stat-light:hover {
            background-color: #7c699f;
        }
        .visual>i {
            color: #fff;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #fff;
            }
            .desc {
                color: #fff;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #fff;
            background-color: #7c699f;
        }
    }
    &.purple-medium {
        background-color: #BF55EC;
        &.dashboard-stat-light:hover {
            background-color: #b843ea;
        }
        .visual>i {
            color: #fff;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #fff;
            }
            .desc {
                color: #fff;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #fff;
            background-color: #b843ea;
        }
    }
    &.purple-studio {
        background-color: #8E44AD;
        &.dashboard-stat-light:hover {
            background-color: #823e9e;
        }
        .visual>i {
            color: #fff;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #fff;
            }
            .desc {
                color: #fff;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #fff;
            background-color: #823e9e;
        }
    }
    &.purple-wisteria {
        background-color: #9B59B6;
        &.dashboard-stat-light:hover {
            background-color: #924dae;
        }
        .visual>i {
            color: #fff;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #fff;
            }
            .desc {
                color: #fff;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #fff;
            background-color: #924dae;
        }
    }
    &.purple-seance {
        background-color: #9A12B3;
        &.dashboard-stat-light:hover {
            background-color: #8a10a0;
        }
        .visual>i {
            color: #fff;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #fff;
            }
            .desc {
                color: #fff;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #fff;
            background-color: #8a10a0;
        }
    }
    &.purple-intense {
        background-color: #8775a7;
        &.dashboard-stat-light:hover {
            background-color: #7c699f;
        }
        .visual>i {
            color: #fff;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #fff;
            }
            .desc {
                color: #fff;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #fff;
            background-color: #7c699f;
        }
    }
    &.purple-sharp {
        background-color: #796799;
        &.dashboard-stat-light:hover {
            background-color: #6f5f8d;
        }
        .visual>i {
            color: #fff;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #fff;
            }
            .desc {
                color: #fff;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #fff;
            background-color: #6f5f8d;
        }
    }
    &.purple-soft {
        background-color: #8877a9;
        &.dashboard-stat-light:hover {
            background-color: #7d6ba1;
        }
        .visual>i {
            color: #fff;
            opacity: .1;
            filter: alpha(opacity=10);
        }
        .details {
            .number {
                color: #fff;
            }
            .desc {
                color: #fff;
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        .more {
            color: #fff;
            background-color: #7d6ba1;
        }
    }
}

.dashboard-stat-light {
    padding-bottom: 20px;
    margin-bottom: 20px;
    .details {
        margin-bottom: 5px;
        .number {
            font-weight: 300;
            margin-bottom: 0;
        }
    }
}

.dashboard-stat2 {
    margin-bottom: 20px;
    .display {
        margin-bottom: 20px;
    }
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    background: #fff;
    padding: 15px 15px 30px;
    &.bordered {
        border: 1px solid #e7ecf1;
    }
    .display {
        &:after,
        &:before {
            content: " ";
            display: table;
        }
        &:after {
            clear: both;
        }
        .number {
            float: left;
            display: inline-block;
            h3 {
                margin: 0 0 2px;
                padding: 0;
                font-size: 30px;
                font-weight: 400;
                >small {
                    font-size: 23px;
                }
            }
            small {
                font-size: 14px;
                color: #AAB5BC;
                font-weight: 600;
                text-transform: uppercase;
            }
        }
        .icon {
            display: inline-block;
            float: right;
            padding: 7px 0 0;
            >i {
                color: #cbd4e0;
                font-size: 26px;
            }
        }
    }
    .progress-info {
        clear: both;
        .progress {
            margin: 0;
            height: 4px;
            clear: both;
            display: block;
        }
        .status {
            margin-top: 5px;
            font-size: 11px;
            color: #AAB5BC;
            font-weight: 600;
            text-transform: uppercase;
            .status-title {
                float: left;
                display: inline-block;
            }
            .status-number {
                float: right;
                display: inline-block;
            }
        }
    }
}

.text-stat {
    h3 {
        margin-top: 5px;
        margin-bottom: 0;
        font-size: 18px;
    }
    span {
        font-size: 13px !important;
    }
}

@media (max-width: 767px) {
    .portlet.light {
        padding: 12px 5px 20px;
    }
    .text-stat {
        margin-top: 20px;
    }
}

.social-icons {
    padding: 0;
    margin: 0;
    &:after,
    &:before {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
    li {
        float: left;
        list-style: none;
        margin-right: 5px;
        margin-bottom: 5px;
        text-indent: -9999px;
        >a {
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            -ms-border-radius: 2px;
            -o-border-radius: 2px;
            border-radius: 2px;
            width: 28px;
            height: 28px;
            display: block;
            background-position: 0 0;
            background-repeat: no-repeat;
            transition: all .3s ease-in-out;
            -o-transition: all .3s ease-in-out;
            -ms-transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            -webkit-transition: all .3s ease-in-out;
        }
        &:hover>a {
            background-position: 0 -38px;
        }
        .amazon {
            background: url(../../img/admin/social/amazon.png) no-repeat;
        }
        .behance {
            background: url(../../img/admin/social/behance.png) no-repeat;
        }
        .blogger {
            background: url(../../img/admin/social/blogger.png) no-repeat;
        }
        .deviantart {
            background: url(../../img/admin/social/deviantart.png) no-repeat;
        }
        .dribbble {
            background: url(../../img/admin/social/dribbble.png) no-repeat;
        }
        .dropbox {
            background: url(../../img/admin/social/dropbox.png) no-repeat;
        }
        .evernote {
            background: url(../../img/admin/social/evernote.png) no-repeat;
        }
        .facebook {
            background: url(../../img/admin/social/facebook.png) no-repeat;
        }
        .forrst {
            background: url(../../img/admin/social/forrst.png) no-repeat;
        }
        .github {
            background: url(../../img/admin/social/github.png) no-repeat;
        }
        .googleplus {
            background: url(../../img/admin/social/googleplus.png) no-repeat;
        }
        .jolicloud {
            background: url(../../img/admin/social/jolicloud.png) no-repeat;
        }
        .last-fm {
            background: url(../../img/admin/social/last-fm.png) no-repeat;
        }
        .linkedin {
            background: url(../../img/admin/social/linkedin.png) no-repeat;
        }
        .picasa {
            background: url(../../img/admin/social/picasa.png) no-repeat;
        }
        .pintrest {
            background: url(../../img/admin/social/pintrest.png) no-repeat;
        }
        .rss {
            background: url(../../img/admin/social/rss.png) no-repeat;
        }
        .skype {
            background: url(../../img/admin/social/skype.png) no-repeat;
        }
        .spotify {
            background: url(../../img/admin/social/spotify.png) no-repeat;
        }
        .stumbleupon {
            background: url(../../img/admin/social/stumbleupon.png) no-repeat;
        }
        .tumblr {
            background: url(../../img/admin/social/tumblr.png) no-repeat;
        }
        .twitter {
            background: url(../../img/admin/social/twitter.png) no-repeat;
        }
        .vimeo {
            background: url(../../img/admin/social/vimeo.png) no-repeat;
        }
        .wordpress {
            background: url(../../img/admin/social/wordpress.png) no-repeat;
        }
        .xing {
            background: url(../../img/admin/social/xing.png) no-repeat;
        }
        .yahoo {
            background: url(../../img/admin/social/yahoo.png) no-repeat;
        }
        .youtube {
            background: url(../../img/admin/social/youtube.png) no-repeat;
        }
        .vk {
            background: url(../../img/admin/social/vk.png) no-repeat;
        }
        .instagram {
            background: url(../../img/admin/social/instagram.png) no-repeat;
        }
        .reddit {
            background: url(../../img/admin/social/reddit.png) no-repeat;
        }
        .aboutme {
            background: url(../../img/admin/social/aboutme.png) no-repeat;
        }
        .flickr {
            background: url(../../img/admin/social/flickr.png) no-repeat;
        }
        .foursquare {
            background: url(../../img/admin/social/foursquare.png) no-repeat;
        }
        .gravatar {
            background: url(../../img/admin/social/gravatar.png) no-repeat;
        }
        .klout {
            background: url(../../img/admin/social/klout.png) no-repeat;
        }
        .myspace {
            background: url(../../img/admin/social/myspace.png) no-repeat;
        }
        .quora {
            background: url(../../img/admin/social/quora.png) no-repeat;
        }
    }
    &.social-icons-color>li>a {
        opacity: .7;
        background-position: 0 -38px !important;
        &:hover {
            opacity: 1;
        }
    }
    &.social-icons-circle>li>a {
        border-radius: 25px !important;
    }
}

.social-icon {
    display: inline-block !important;
    width: 28px;
    height: 28px;
    background-position: 0 0;
    background-repeat: no-repeat;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    &.social-icon-circle {
        border-radius: 25px !important;
    }
    &.amazon {
        background: url(../../img/admin/social/amazon.png) no-repeat;
    }
    &.behance {
        background: url(../../img/admin/social/behance.png) no-repeat;
    }
    &.blogger {
        background: url(../../img/admin/social/blogger.png) no-repeat;
    }
    &.deviantart {
        background: url(../../img/admin/social/deviantart.png) no-repeat;
    }
    &.dribbble {
        background: url(../../img/admin/social/dribbble.png) no-repeat;
    }
    &.dropbox {
        background: url(../../img/admin/social/dropbox.png) no-repeat;
    }
    &.evernote {
        background: url(../../img/admin/social/evernote.png) no-repeat;
    }
    &.facebook {
        background: url(../../img/admin/social/facebook.png) no-repeat;
    }
    &.forrst {
        background: url(../../img/admin/social/forrst.png) no-repeat;
    }
    &.github {
        background: url(../../img/admin/social/github.png) no-repeat;
    }
    &.googleplus {
        background: url(../../img/admin/social/googleplus.png) no-repeat;
    }
    &.jolicloud {
        background: url(../../img/admin/social/jolicloud.png) no-repeat;
    }
    &.last-fm {
        background: url(../../img/admin/social/last-fm.png) no-repeat;
    }
    &.linkedin {
        background: url(../../img/admin/social/linkedin.png) no-repeat;
    }
    &.picasa {
        background: url(../../img/admin/social/picasa.png) no-repeat;
    }
    &.pintrest {
        background: url(../../img/admin/social/pintrest.png) no-repeat;
    }
    &.rss {
        background: url(../../img/admin/social/rss.png) no-repeat;
    }
    &.skype {
        background: url(../../img/admin/social/skype.png) no-repeat;
    }
    &.spotify {
        background: url(../../img/admin/social/spotify.png) no-repeat;
    }
    &.stumbleupon {
        background: url(../../img/admin/social/stumbleupon.png) no-repeat;
    }
    &.tumblr {
        background: url(../../img/admin/social/tumblr.png) no-repeat;
    }
    &.twitter {
        background: url(../../img/admin/social/twitter.png) no-repeat;
    }
    &.vimeo {
        background: url(../../img/admin/social/vimeo.png) no-repeat;
    }
    &.wordpress {
        background: url(../../img/admin/social/wordpress.png) no-repeat;
    }
    &.xing {
        background: url(../../img/admin/social/xing.png) no-repeat;
    }
    &.yahoo {
        background: url(../../img/admin/social/yahoo.png) no-repeat;
    }
    &.youtube {
        background: url(../../img/admin/social/youtube.png) no-repeat;
    }
    &.vk {
        background: url(../../img/admin/social/vk.png) no-repeat;
    }
    &.instagram {
        background: url(../../img/admin/social/instagram.png) no-repeat;
    }
    &.reddit {
        background: url(../../img/admin/social/reddit.png) no-repeat;
    }
    &.aboutme {
        background: url(../../img/admin/social/aboutme.png) no-repeat;
    }
    &.flickr {
        background: url(../../img/admin/social/flickr.png) no-repeat;
    }
    &.foursquare {
        background: url(../../img/admin/social/foursquare.png) no-repeat;
    }
    &.gravatar {
        background: url(../../img/admin/social/gravatar.png) no-repeat;
    }
    &.klout {
        background: url(../../img/admin/social/klout.png) no-repeat;
    }
    &.myspace {
        background: url(../../img/admin/social/myspace.png) no-repeat;
    }
    &.quora {
        background: url(../../img/admin/social/quora.png) no-repeat;
    }
    &:hover {
        background-position: 0 -38px;
    }
}

.social-icon-color {
    opacity: .7;
    background-position: 0 -38px !important;
    &:hover {
        opacity: 1;
    }
}

.table-scrollable {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    border: 1px solid #e7ecf1;
    margin: 10px 0 !important;
}

.DTS .table-scrollable {
    border: 0;
}

.table-scrollable {
    &.table-scrollable-borderless {
        border: 0;
    }
    >.table-bordered {
        border: 0;
        > {
            tbody>tr:last-child> {
                td,
                th {
                    border-bottom: 0;
                }
            }
            tfoot>tr:last-child> {
                td,
                th {
                    border-bottom: 0;
                }
            }
            thead>tr:last-child> {
                td,
                th {
                    border-bottom: 0;
                }
            }
        }
    }
}

.table.table-bordered thead>tr>th {
    border-bottom: 0;
}

.table-scrollable> {
    .table {
        width: 100% !important;
        margin: 0 !important;
        background-color: #fff;
        > {
            tbody>tr>th {
                white-space: nowrap;
            }
            tfoot>tr> {
                td,
                th {
                    white-space: nowrap;
                }
            }
            thead>tr>th {
                white-space: nowrap;
            }
        }
        .form-control {
            border: 1px solid #ccc;
            height: 35px;
        }
    }
    .table-bordered> {
        tbody>tr> {
            td:first-child,
            th:first-child {
                border-left: 0;
            }
        }
        tfoot>tr> {
            td:first-child,
            th:first-child {
                border-left: 0;
            }
        }
        thead>tr> {
            td:first-child,
            th:first-child {
                border-left: 0;
            }
        }
        tbody>tr> {
            td:last-child,
            th:last-child {
                border-right: 0;
            }
        }
        tfoot>tr> {
            td:last-child,
            th:last-child {
                border-right: 0;
            }
        }
        thead>tr> {
            td:last-child,
            th:last-child {
                border-right: 0;
            }
        }
    }
}

.table {
    td,
    th {
        font-size: 13px;
    }
}

.flip-scroll table,
.table td .img-responsive {
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .flip-scroll {
        .flip-content:after {
            visibility: hidden;
            display: block;
            font-size: 0;
            content: " ";
            clear: both;
            height: 0;
        }
        * html .flip-content {
            zoom: 1;
        }
        td {
            margin: 0;
            vertical-align: top;
        }
        th {
            margin: 0;
            border: 0 !important;
            border-bottom: 1px solid #ddd !important;
            border-right: 1px solid #ddd !important;
            font-size: 13px !important;
            padding: 5px;
            width: auto !important;
            display: block;
            text-align: right;
        }
        table {
            border-collapse: collapse;
            border-spacing: 0;
            display: block;
            position: relative;
            width: 100%;
        }
        thead {
            display: block;
            float: left;
        }
        tbody {
            display: block;
            width: auto;
            position: relative;
            overflow-x: auto;
            white-space: nowrap;
        }
        .flip-content tbody tr td {
            font-size: 13px;
            line-height: 1.483;
        }
        .table-bordered.flip-content tbody tr td {
            font-size: 13px;
            line-height: 1.43;
        }
        thead tr {
            display: block;
        }
        tbody tr {
            display: inline-block;
            vertical-align: top;
            margin-left: -5px;
        }
        td {
            display: block;
            min-height: 1.25em;
            text-align: left;
            border-top: 0 !important;
            border-left: 0 !important;
            border-right: 0 !important;
            border-bottom: 0;
        }
        th {
            border-left: 0;
        }
        tbody tr {
            border-left: 1px solid #ddd;
        }
        td:last-child,
        th:last-child {
            border-bottom: 1px solid #ddd;
        }
    }
}

.table-toolbar {
    margin-bottom: 20px;
    &:after,
    &:before {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

.table {
    &.table-full-width {
        width: 100% !important;
    }
    .btn {
        margin-left: 0;
        margin-right: 5px;
    }
    thead tr th {
        font-size: 13px;
        font-weight: 600;
    }
}

.table-advance {
    margin-bottom: 10px !important;
    thead {
        color: #3f444a;
        tr th {
            background-color: #f1f4f7;
            font-size: 13px;
            font-weight: 400;
            color: #3f444a;
        }
    }
    div {
        &.danger,
        &.important,
        &.info,
        &.success,
        &.warning {
            position: absolute;
            margin-top: -5px;
            float: left;
            width: 2px;
            height: 30px;
            margin-right: 20px !important;
        }
    }
    tr td {
        border-left-width: 0;
        &:first-child {
            border-left-width: 2px !important;
        }
        &.highlight:first-child a {
            margin-left: 15px;
        }
    }
    td.highlight div {
        &.primary {
            border-left: 2px solid #337ab7;
        }
        &.success {
            border-left: 2px solid #36c6d3;
        }
        &.info {
            border-left: 2px solid #659be0;
        }
        &.warning {
            border-left: 2px solid #F1C40F;
        }
        &.danger {
            border-left: 2px solid #ed6b75;
        }
    }
}

@media (max-width: 767px) {
    .table-advance tr>td.highlight:first-child a {
        margin-left: 8px;
    }
}

.table.table-light {
    border: 0 !important;
    > {
        thead>tr {
            &:hover>th {
                background: 0 0;
            }
            &.uppercase {
                text-transform: uppercase;
            }
            >th {
                font-weight: 600;
                font-size: 13px;
                color: #93a2a9;
                // font-family: "Open Sans",sans-serif;
                border: 0;
                border-bottom: 1px solid #F2F5F8;
            }
        }
        tbody>tr {
            &:last-child>td {
                border: 0;
            }
            >td {
                border: 0;
                border-bottom: 1px solid #F2F5F8;
                color: #8896a0;
                vertical-align: middle;
                &.fit {
                    width: 1px;
                    padding-right: 3px;
                }
                .user-pic {
                    display: inline-block;
                    vertical-align: middle;
                    height: 30px;
                    -webkit-border-radius: 100%;
                    -moz-border-radius: 100%;
                    -ms-border-radius: 100%;
                    -o-border-radius: 100%;
                    border-radius: 100%;
                }
            }
        }
    }
    &.table-hover>tbody>tr {
        &:hover>td {
            background: #f9fafb !important;
        }
        > {
            td:hover,
            th:hover {
                background: #f9fafb !important;
            }
        }
    }
}

.table-hover>tbody>tr:hover {
    background: #f3f4f6 !important;
    >td {
        background: #f3f4f6 !important;
    }
}

.nav-pills,
.nav-tabs {
    margin-bottom: 10px;
}

.nav-pills>li>a,
.nav-tabs>li>a {
    font-size: 14px;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    -ms-border-radius: 4px 4px 0 0;
    -o-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
}

.nav-pills>li>a>.badge,
.nav-tabs>li>a>.badge {
    margin-top: -6px;
}

.nav-pills>li .dropdown-menu {
    &:after,
    &:before {
        display: none;
    }
}

.nav-tabs>li .dropdown-menu {
    &:after,
    &:before {
        display: none;
    }
}

.nav-pills {
    &.nav-pills-sm>li>a,
    &.nav-tabs-sm>li>a {
        font-size: 13px;
    }
}

.nav-tabs {
    &.nav-pills-sm>li>a,
    &.nav-tabs-sm>li>a {
        font-size: 13px;
    }
}

.nav-pills .dropdown.open>.dropdown-toggle,
.nav-tabs .dropdown.open>.dropdown-toggle {
    background: #eee;
    color: #0d638f;
    border-color: transparent;
}

.tabs-left.nav-tabs,
.tabs-right.nav-tabs {
    border-bottom: 0;
}

.tabs-left.nav-tabs>li,
.tabs-right.nav-tabs>li {
    float: none;
}

.tabs-left.nav-tabs>li>a,
.tabs-right.nav-tabs>li>a {
    margin-right: 0;
    margin-bottom: 3px;
}

.tabs-left.nav-tabs {
    border-right: 1px solid #ddd;
    >li {
        >a {
            display: block;
            margin-right: -1px;
            &:focus,
            &:hover {
                border-radius: 4px 0 0 4px;
                border-color: #eee #ddd #eee #eee;
            }
        }
        &.active>a {
            -webkit-border-radius: 4px 0 0 4px;
            -moz-border-radius: 4px 0 0 4px;
            -ms-border-radius: 4px 0 0 4px;
            -o-border-radius: 4px 0 0 4px;
            border-radius: 4px 0 0 4px;
            border-color: #ddd transparent #ddd #ddd;
            &:hover>li.active>a:focus {
                -webkit-border-radius: 4px 0 0 4px;
                -moz-border-radius: 4px 0 0 4px;
                -ms-border-radius: 4px 0 0 4px;
                -o-border-radius: 4px 0 0 4px;
                border-radius: 4px 0 0 4px;
                border-color: #ddd transparent #ddd #ddd;
            }
        }
    }
}

.tabs-right.nav-tabs {
    border-left: 1px solid #ddd;
    >li>a {
        display: block;
        margin-left: -1px;
    }
}

.tabbable {
    &:after,
    &:before {
        display: table;
        content: " ";
    }
}

.tiles {
    &:after,
    &:before {
        display: table;
        content: " ";
    }
}

.tabs-right.nav-tabs>li {
    >a {
        &:focus,
        &:hover {
            border-radius: 0 4px 4px 0;
            border-color: #eee #eee #eee #ddd;
        }
    }
    &.active>a {
        -webkit-border-radius: 0 4px 4px 0;
        -moz-border-radius: 0 4px 4px 0;
        -ms-border-radius: 0 4px 4px 0;
        -o-border-radius: 0 4px 4px 0;
        border-radius: 0 4px 4px 0;
        border-color: #ddd #ddd #ddd transparent;
        &:hover>li.active>a:focus {
            -webkit-border-radius: 0 4px 4px 0;
            -moz-border-radius: 0 4px 4px 0;
            -ms-border-radius: 0 4px 4px 0;
            -o-border-radius: 0 4px 4px 0;
            border-radius: 0 4px 4px 0;
            border-color: #ddd #ddd #ddd transparent;
        }
    }
}

.tabs-below> {
    .nav-pills {
        border-bottom: 0;
        margin-bottom: 0;
        margin-top: 10px;
    }
    .nav-tabs {
        border-bottom: 0;
        margin-bottom: 0;
        margin-top: 10px;
        border-top: 1px solid #ddd;
        margin-bottom: 0;
        margin-top: 10px;
        >li>a {
            margin-top: -1px;
            margin-bottom: 0;
            &:focus,
            &:hover {
                border-top-color: #ddd;
                border-bottom-color: transparent;
            }
            .dropdown-menu {
                -webkit-border-radius: 4px;
                -moz-border-radius: 4px;
                -ms-border-radius: 4px;
                -o-border-radius: 4px;
                border-radius: 4px;
            }
        }
        .active a {
            -webkit-border-radius: 0 0 4px 4px;
            -moz-border-radius: 0 0 4px 4px;
            -ms-border-radius: 0 0 4px 4px;
            -o-border-radius: 0 0 4px 4px;
            border-radius: 0 0 4px 4px;
            border-color: transparent #ddd #ddd !important;
            &:hover .active a:focus {
                -webkit-border-radius: 0 0 4px 4px;
                -moz-border-radius: 0 0 4px 4px;
                -ms-border-radius: 0 0 4px 4px;
                -o-border-radius: 0 0 4px 4px;
                border-radius: 0 0 4px 4px;
                border-color: transparent #ddd #ddd !important;
            }
        }
    }
}

.tabbable:after {
    clear: both;
}

.tabbable-custom {
    margin-bottom: 15px;
    padding: 0;
    overflow: hidden;
    > {
        .nav-tabs {
            border: none;
            margin: 0;
            >li {
                &.active {
                    >a {
                        -webkit-border-radius: 0;
                        -moz-border-radius: 0;
                        -ms-border-radius: 0;
                        -o-border-radius: 0;
                        border-radius: 0;
                    }
                    border-top: 3px solid #ed6b75;
                    margin-top: 0;
                    position: relative;
                    >a {
                        border-top: none !important;
                        font-weight: 400;
                        border-radius: 0;
                        &:hover {
                            -webkit-border-radius: 0;
                            -moz-border-radius: 0;
                            -ms-border-radius: 0;
                            -o-border-radius: 0;
                            border-radius: 0;
                            border-top: none;
                            background: #fff;
                            border-color: #d4d4d4 #d4d4d4 transparent;
                        }
                    }
                }
                >a {
                    -webkit-border-radius: 0;
                    -moz-border-radius: 0;
                    -ms-border-radius: 0;
                    -o-border-radius: 0;
                    margin-right: 0;
                    border-radius: 0;
                    &:hover {
                        background: 0 0;
                        border-color: transparent;
                    }
                }
                margin-right: 2px;
                border-top: 2px solid transparent;
            }
        }
        .tab-content {
            background-color: #fff;
            border: 1px solid #ddd;
            padding: 10px;
            -webkit-border-radius: 0 0 4px 4px;
            -moz-border-radius: 0 0 4px 4px;
            -ms-border-radius: 0 0 4px 4px;
            -o-border-radius: 0 0 4px 4px;
            border-radius: 0 0 4px 4px;
        }
    }
    &.nav-justified>.tab-content {
        margin-top: -1px;
    }
    &.boxless>.tab-content {
        padding: 15px 0;
        border-left: none;
        border-right: none;
        border-bottom: none;
    }
    &.tabs-below.nav-justified .tab-content {
        margin-top: 0;
        margin-bottom: -2px;
        -webkit-border-radius: 4px 4px 0 0;
        -moz-border-radius: 4px 4px 0 0;
        -ms-border-radius: 4px 4px 0 0;
        -o-border-radius: 4px 4px 0 0;
        border-radius: 4px 4px 0 0;
    }
    &.tabbable-full-width> {
        .nav-tabs>li>a {
            color: #424242;
            font-size: 15px;
            padding: 9px 15px;
        }
        .tab-content {
            padding: 15px 0;
            border-left: none;
            border-right: none;
            border-bottom: none;
        }
    }
    &.tabs-below .nav-tabs>li>a {
        border-top: none;
        border-bottom: 2px solid transparent;
        margin-top: -1px;
    }
}

.tabbable-line {
    &.tabs-below>.nav-tabs>li>a {
        margin-top: 0;
    }
    >.nav-tabs>li {
        &.open .dropdown-menu,
        &:hover .dropdown-menu {
            margin-top: 0;
        }
    }
}

.tabbable-custom {
    &.tabs-below .nav-tabs>li.active {
        border-top: none;
        border-bottom: 3px solid #d12610;
        margin-bottom: 0;
        position: relative;
        >a {
            border-bottom: none;
            &:hover {
                background: #fff;
                border-color: #d4d4d4 #d4d4d4 transparent;
            }
        }
    }
    &.tabbable-noborder {
        .tab-content,
        >.nav-tabs>li>a {
            border: 0;
        }
    }
}

.portlet:not(.light) .tabbable-line {
    padding-top: 15px;
}

.tabbable-line> {
    .nav-tabs {
        border: none;
        margin: 0;
        >li {
            margin: 0;
            border-bottom: 4px solid transparent;
            >a {
                background: 0 0 !important;
                border: 0;
                margin: 0;
                padding-left: 15px;
                padding-right: 15px;
                color: #737373;
                >i {
                    color: #a6a6a6;
                }
            }
            &.active {
                background: 0 0;
                border-bottom: 4px solid #36c6d3;
                position: relative;
                >a {
                    border: 0;
                    color: #333;
                    >i {
                        color: #404040;
                    }
                }
            }
            &.open,
            &:hover {
                background: 0 0;
                border-bottom: 4px solid #9fe4ea;
            }
            &.open>a,
            &:hover>a {
                border: 0;
                background: 0 0 !important;
                color: #333;
            }
            &.open>a>i,
            &:hover>a>i {
                color: #a6a6a6;
            }
        }
    }
    .tab-content {
        margin-top: 0;
        border: 0;
        border-top: 1px solid #eef1f5;
        padding: 30px 0;
    }
}

.page-container-bg-solid .tabbable-line>.tab-content {
    border-top: 1px solid #dae2ea;
}

.portlet .tabbable-line>.tab-content {
    padding-bottom: 0;
}

.tabbable-line.tabs-below> {
    .nav-tabs>li {
        border-top: 4px solid transparent;
        &:hover {
            border-bottom: 0;
            border-top: 4px solid #fbdcde;
        }
        &.active {
            margin-bottom: -2px;
            border-bottom: 0;
            border-top: 4px solid #ed6b75;
        }
    }
    .tab-content {
        margin-top: -10px;
        border-top: 0;
        border-bottom: 1px solid #eee;
        padding-bottom: 15px;
    }
}

.portlet .tabbable-bordered {
    margin-top: 20px;
}

.tabbable-bordered {
    .nav-tabs {
        margin-bottom: 0;
        border-bottom: 0;
    }
    .tab-content {
        padding: 30px 20px 20px;
        border: 1px solid #ddd;
        background: #fff;
    }
}

.tiles {
    margin-right: -10px;
    &:after {
        clear: both;
    }
    .tile {
        display: block;
        float: left;
        height: 135px;
        width: 135px !important;
        cursor: pointer;
        text-decoration: none;
        color: #fff;
        position: relative;
        font-weight: 300;
        font-size: 12px;
        letter-spacing: .02em;
        line-height: 20px;
        overflow: hidden;
        border: 4px solid transparent;
        margin: 0 10px 10px 0;
        .tile-body>.content,
        &.image .tile-body h3,
        &.selected .check:after {
            display: inline-block;
        }
        &:after,
        &:before {
            content: "";
            float: left;
        }
        &.double {
            width: 280px !important;
        }
        &.double-down {
            height: 280px !important;
            i {
                margin-top: 95px;
            }
        }
        &:hover {
            border-color: #aaa !important;
        }
        &.selected,
        &:active {
            border-color: #ccc !important;
        }
        &.selected {
            .corner:after {
                content: "";
                display: inline-block;
                border-left: 40px solid transparent;
                border-bottom: 40px solid transparent;
                border-right: 40px solid #ccc;
                position: absolute;
                top: -3px;
                right: -3px;
            }
            .check:after {
                font-family: FontAwesome;
                font-size: 13px;
                content: "\f00c";
                position: absolute;
                top: 2px;
                right: 2px;
            }
        }
        &.icon {
            padding: 0;
        }
        &.image .tile-body {
            padding: 0 !important;
            >img {
                width: 100%;
                height: auto;
                min-height: 100%;
                max-width: 100%;
            }
        }
        .tile-body {
            height: 100%;
            vertical-align: top;
            padding: 10px;
            overflow: hidden;
            position: relative;
            font-weight: 400;
            font-size: 12px;
            color: #fff;
            margin-bottom: 10px;
            p {
                font-weight: 400;
                font-size: 13px;
                color: #fff;
                overflow: hidden;
                &:active {
                    color: rgba(0, 0, 0, 0.4);
                }
                &:hover {
                    color: #fff;
                }
            }
            img {
                float: left;
                margin-right: 10px;
                &.pull-right {
                    float: right !important;
                    margin-left: 10px;
                    margin-right: 0;
                }
            }
            >i {
                margin-top: 17px;
                display: block;
                font-size: 56px;
                line-height: 56px;
                text-align: center;
            }
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
                padding: 0;
                margin: 0;
                line-height: 14px;
            }
            h1:hover,
            h2:hover,
            h3:hover,
            h4:hover,
            h5:hover,
            h6:hover,
            p:hover {
                color: #fff;
            }
            h3,
            h4 {
                margin-bottom: 5px;
            }
        }
        .tile-object {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            min-height: 30px;
        }
    }
}

.widget-gradient,
.widget-wrap-img {
    min-height: 350px;
    border-radius: 4px;
}

.tiles .tile .tile-object {
    &:after,
    &:before {
        display: table;
        content: "";
    }
    &:after {
        clear: both;
    }
    > {
        .name {
            position: absolute;
            bottom: 0;
            left: 0;
            margin-bottom: 5px;
            margin-left: 10px;
            margin-right: 15px;
            font-weight: 400;
            font-size: 13px;
            color: #fff;
            >i {
                vertical-align: middle;
                display: block;
                font-size: 24px;
                height: 18px;
                width: 24px;
            }
        }
        .number {
            position: absolute;
            bottom: 0;
            right: 0;
            color: #fff;
            text-align: center;
            font-weight: 600;
            font-size: 14px;
            letter-spacing: .01em;
            line-height: 14px;
            margin-bottom: 8px;
            margin-right: 10px;
        }
    }
}

.well {
    border: 0;
    padding: 20px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.widget-bg-color-purple {
    background: #9a7caf;
}

.widget-bg-color-purple-dark {
    background: #4b365a;
}

.widget-bg-color-purple-light {
    background: #674d79;
}

.widget-bg-color-green {
    background: #4db3a4;
}

.widget-bg-color-red {
    background: #f36a5a;
}

.widget-bg-color-blue {
    background: #5b9bd1;
}

.widget-bg-color-gray {
    background: #323c45;
}

.widget-bg-color-gray-dark {
    background: #144f57;
}

.widget-bg-color-white {
    background: #fff;
}

.widget-bg-color-dark {
    background: #3e4f5e;
}

.widget-bg-color-dark-light {
    background: #8e9daa;
}

.widget-bg-color-fb {
    background: #475e98;
}

.widget-bg-color-tw {
    background: #55acee;
}

.widget-title-color-purple {
    color: #9a7caf;
}

.widget-title-color-purple-dark {
    color: #4b365a;
}

.widget-title-color-purple-light {
    color: #674d79;
}

.widget-title-color-green {
    color: #4db3a4;
}

.widget-title-color-red {
    color: #f36a5a;
}

.widget-title-color-blue {
    color: #5b9bd1;
}

.widget-title-color-gray {
    color: #323c45;
}

.widget-title-color-gray-dark {
    color: #144f57;
}

.widget-title-color-white {
    color: #fff;
}

.widget-title-color-dark {
    color: #3e4f5e;
}

.widget-title-color-dark-light {
    color: #8e9daa;
}

.widget-title-color-fb {
    color: #475e98;
}

.widget-title-color-tw {
    color: #55acee;
}

.overflow-h {
    overflow: hidden;
}

.widget-carousel {
    .carousel-indicators {
        left: -18%;
        bottom: 10px;
        margin-left: 0;
    }
    .carousel-indicators-red>li {
        border-color: #f36a5a;
        &.active {
            background: #f36a5a;
        }
    }
}

.widget-gradient {
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: 50% 50%;
    .widget-gradient-body {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
        padding: 20px;
        .widget-gradient-title {
            font-size: 21px;
            font-weight: 600;
            color: #fff;
            margin: 0;
        }
        .widget-gradient-body-actions {
            position: absolute;
            right: 20px;
            bottom: 20px;
            padding: 0;
            margin: 0;
            li {
                font-size: 14px;
                padding: 0 0 0 8px;
                &:first-child {
                    padding-left: 0;
                }
                a {
                    color: #fff;
                    &:hover {
                        color: #a1afbb;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.widget-wrap-img {
    position: relative;
    padding: 20px;
    .widget-wrap-img-title {
        font-size: 21px;
        font-weight: 600;
        color: #3e4f5e;
        margin: 0 0 20px;
    }
    .widget-wrap-img-element {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

.widget-tab {
    min-height: 420px;
    border-radius: 4px;
    .nav-tabs {
        margin: 0;
        border-color: #eff1f3;
        >li {
            margin: 0 10px;
            &:first-child {
                margin-left: 20px;
            }
            >a {
                border: 0;
                font-weight: 700;
                color: #8e9daa;
                text-transform: uppercase;
                padding: 20px 0;
                >i {
                    color: #8e9daa;
                }
            }
            &.open,
            &:hover {
                border-bottom: 1px solid #f36a5a;
            }
            &.open>a,
            &:hover>a {
                border: 0;
                background: inherit;
                color: #f36a5a;
            }
            &.open>a>i,
            &:hover>a>i {
                color: #f36a5a;
            }
            &.active {
                border-bottom: 1px solid #f36a5a;
                position: relative;
                >a {
                    border: 0;
                    color: #f36a5a;
                    >i {
                        color: #f36a5a;
                    }
                }
            }
        }
    }
    .tab-content {
        padding: 20px;
        color: #8e9daa;
    }
    .slimScrollBar {
        right: 10px !important;
        margin-top: 17px !important;
        margin-bottom: 17px !important;
    }
}

.widget-news {
    overflow: hidden;
    margin-right: 10px;
    border-radius: 4px;
    .widget-news-left-elem {
        float: left;
        width: 100px;
        height: auto;
        margin-right: 15px;
    }
    .widget-news-right-body {
        overflow: hidden;
        .widget-news-right-body-title {
            font-size: 16px;
            font-weight: 600;
            color: #3e4f5e;
            margin: 0 0 5px;
            clear: both;
            .label {
                float: right;
                font-weight: 600;
                background: #a1afbb;
                border-radius: 3px !important;
            }
        }
        p {
            font-size: 13px;
        }
    }
}

.widget-thumb {
    padding: 20px;
    border-radius: 4px;
    &.bordered {
        border: 1px solid #e7ecf1;
    }
    .widget-thumb-heading {
        font-size: 14px;
        font-weight: 700;
        color: #8e9daa;
        margin: 0 0 20px;
    }
    .widget-thumb-wrap {
        overflow: hidden;
        .widget-thumb-icon {
            float: left;
            width: 60px;
            height: 60px;
            display: inline-block;
            font-size: 20px;
            line-height: 41px;
            color: #fff;
            text-align: center;
            padding: 10px;
            margin-right: 15px;
        }
    }
    .widget-thumb-body {
        overflow: hidden;
        .widget-thumb-subtitle {
            padding-top: 2px;
            display: block;
            font-size: 14px;
            font-weight: 600;
            color: #8e9daa;
        }
        .widget-thumb-body-stat {
            display: block;
            font-size: 30px;
            font-weight: 600;
            color: #3e4f5e;
        }
    }
}

.widget-socials {
    border-radius: 4px;
    min-height: 250px;
    padding: 20px;
}

.widget-comments,
.widget-progress {
    min-height: 420px;
}

.widget-socials {
    .widget-socials-title {
        font-size: 25px;
        font-weight: 700;
        line-height: 1.4;
        color: #fff;
        margin: 0 0 20px;
    }
    .widget-social-subtitle {
        color: #fff;
        font-weight: 200;
        line-height: 1.4;
    }
}

.widget-media {
    .widget-btn-blue .widget-btn-icon,
    .widget-btn-default .widget-btn-icon,
    .widget-btn-red .widget-btn-icon {
        line-height: 1.5;
    }
}

.widget-socials {
    .widget-social-subtitle a {
        color: #fff;
    }
    .widget-socials-paragraph {
        display: block;
        color: #65727d;
    }
    .widget-social-icon-fb,
    .widget-social-icon-tw {
        font-size: 30px;
        margin: 30px 0;
    }
    .widget-social-icon-fb {
        color: #2b3f72;
    }
    .widget-social-icon-tw {
        color: #3686c3;
    }
}

.widget-media {
    border-radius: 4px;
    border-bottom: 1px solid #f6f9fc;
    overflow: hidden;
    padding-bottom: 15px;
    margin-bottom: 15px;
    .widget-media-elements {
        float: left;
        margin-right: 20px;
    }
    .widget-media-avatar {
        width: 55px;
        height: 55px;
        display: block;
    }
    .widget-btn-blue,
    .widget-btn-default,
    .widget-btn-red {
        display: inline-block;
        font-size: 12px;
        border: 1px solid #ebf0f6;
        padding: 3px 10px;
    }
    .widget-btn-default {
        color: #96a2b1;
        &:hover {
            background: #ebf0f6;
            text-decoration: none;
        }
    }
    .widget-btn-red {
        color: #f36a5a;
        &:hover {
            color: #fff;
            background: #f36a5a;
            text-decoration: none;
        }
    }
    .widget-btn-blue {
        color: #fff;
        background: #337ab7;
        &:hover {
            color: #337ab7;
            background: #fff;
            text-decoration: none;
        }
    }
    .widget-media-body {
        overflow: hidden;
        .widget-media-body-title {
            font-size: 15px;
            font-weight: 600;
            color: #5b9bd1;
            margin: 0 0 7px;
        }
        .widget-media-body-subtitle {
            font-size: 13px;
            color: #7e8c9e;
        }
    }
}

.widget-blog {
    border-radius: 4px;
    background: center center #fff;
    padding: 30px 20px 20px;
    background-size: cover;
    .widget-blog-heading {
        position: relative;
        margin-bottom: 30px;
        &:before {
            position: absolute;
            bottom: -15px;
            left: 50%;
            width: 50px;
            height: 1px;
            border-width: 1px;
            background: #8e9daa;
            margin-left: -25px;
            content: " ";
        }
    }
    .widget-blog-title {
        font-size: 20px;
        font-weight: 400;
        color: #3e4f5e;
        margin: 0 0 15px;
        a {
            color: #3e4f5e;
        }
    }
    .widget-blog-subtitle {
        display: block;
        font-size: 13px;
        color: #8e9daa;
        letter-spacing: 3px;
    }
    .btn-widget-purple {
        display: inline-block;
        font-size: 13px;
        color: #8e9daa;
        border: 1px solid #8e9daa;
        padding: 7px 17px;
        &:hover {
            color: #fff;
            background: #8e9daa;
            text-decoration: none;
        }
    }
}

.widget-progress {
    .widget-progress-element {
        border-radius: 4px;
        overflow: hidden;
        padding: 30px 10px;
    }
    .widget-progress-title {
        display: block;
        color: #fff;
        margin-bottom: 5px;
    }
    .progress {
        height: 3px;
        background: rgba(255, 255, 255, 0.2);
        margin-bottom: 0;
    }
}

.widget-map {
    min-height: 350px;
    border-radius: 3px;
    .widget-map-mapplic {
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        .mapplic-container {
            background: #5b9bd1;
        }
        .mapplic-layer.world>img {
            opacity: .3;
        }
    }
    .widget-map-body {
        background: #fff;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        padding: 20px;
        overflow: hidden;
    }
    .widget-sparkline-chart {
        width: 25%;
        float: left;
        border-left: 1px solid #e7eff7;
        padding: 0 15px;
        &:first-child {
            border-left: none;
        }
        .widget-sparkline-title {
            display: block;
            font-size: 12px;
            font-weight: 600;
            color: #a1afbb;
        }
    }
}

@media (max-width: 480px) {
    .widget-map .widget-sparkline-chart {
        width: 50%;
        border-left: none;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.widget-subscribe {
    border-radius: 4px;
    min-height: 250px;
    overflow: hidden;
    padding: 30px;
    .widget-subscribe-no {
        float: left;
        font-size: 67px;
        font-weight: 600;
        line-height: 1;
        color: #9a7caf;
    }
    .widget-subscribe-title {
        font-size: 25px;
        font-weight: 700;
        line-height: 1.4;
        margin: 0 0 15px 45px;
    }
    .widget-subscribe-subtitle {
        font-size: 15px;
        font-weight: 600;
    }
    .widget-subscribe-subtitle-link {
        color: #cab0dd;
    }
    &.widget-subscribe-quote {
        position: relative;
        &:before {
            position: absolute;
            top: 2px;
            font-size: 70px;
            color: #fff;
            content: "\201C";
        }
    }
}

@media (max-width: 767px) {
    .widget-subscribe.widget-subscribe-border {
        border-top: 1px solid #f5f8fb;
        border-bottom: 1px solid #f5f8fb;
        border-right: none;
    }
}

@media (min-width: 768px) {
    .widget-subscribe.widget-subscribe-border {
        border-left: 1px solid #f5f8fb;
        border-right: 1px solid #f5f8fb;
    }
}

@media (min-width: 767px) and (max-width: 991px) {
    .widget-subscribe {
        &.widget-subscribe-border {
            border-left: none;
        }
        &.widget-subscribe-border-top {
            border-top: 1px solid #f5f8fb;
        }
    }
}

.page-md {
    .widget-bg-color-white,
    .widget-blog,
    .widget-carousel,
    .widget-map,
    .widget-progress-element,
    .widget-socials {
        box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.03);
    }
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(15px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@-moz-keyframes fadeInUp {
    0% {
        opacity: 0;
        -moz-transform: translateY(15px);
    }
    100% {
        opacity: 1;
        -moz-transform: translateY(0);
    }
}

@-o-keyframes fadeInUp {
    0% {
        opacity: 0;
        -o-transform: translateY(15px);
    }
    100% {
        opacity: 1;
        -o-transform: translateY(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(15px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in-up {
    -webkit-animation: fadeInUp .5s;
    animation: fadeInUp .5s;
}

@-webkit-keyframes bounceDelay {
    0%,
    100%,
    80% {
        -webkit-transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes bounceDelay {
    0%,
    100%,
    80% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }
    40% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

@keyframes input-focus {
    0% {
        left: 20%;
        width: 20%;
    }
    99% {
        width: 0;
        left: 0;
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.m-heading-1 {
    margin: 0 0 20px;
    background: #fff;
    padding-left: 15px;
    border-left: 8px solid #88909a;
    > {
        h3 {
            font-size: 20px;
            color: #3f444a;
            font-weight: 500;
            margin: 0 0 15px;
            >i {
                font-size: 18px;
                color: #88909a;
            }
        }
        p {
            color: #5c6873;
            margin: 10px 0 0;
            &:first-child {
                margin-top: 0;
            }
        }
    }
    &.m-bordered {
        border-right: 1px solid #10161c;
        border-top: 1px solid #10161c;
        border-bottom: 1px solid #10161c;
        padding: 15px;
    }
}

.page-container-bg-solid .m-heading-1.m-bordered {
    border-right: 0;
    border-top: 0;
    border-bottom: 0;
}

.m-heading-1 {
    &.m-title-md> {
        h3 {
            font-size: 18px;
            margin-bottom: 10px;
            >i {
                font-size: 16px;
                color: #88909a;
            }
        }
        p {
            margin: 15px 0;
        }
    }
    &.m-title-sm> {
        h3 {
            font-size: 16px;
            margin-bottom: 10px;
            >i {
                font-size: 14px;
                color: #88909a;
            }
        }
        p {
            margin: 10px 0;
        }
    }
}

@media (max-width: 991px) {
    .m-heading-1 {
        margin: 0;
    }
}

.timeline {
    margin: 0 0 30px;
    padding: 0;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        display: block;
        width: 4px;
        background: #f5f6fa;
        top: 0;
        bottom: 0;
        margin-left: 38px;
    }
    .timeline-item {
        margin: 0;
        padding: 0;
    }
    .timeline-badge {
        float: left;
        position: relative;
        padding-right: 30px;
        height: 80px;
        width: 80px;
    }
    .timeline-badge-userpic {
        width: 80px;
        border: 4px solid #f5f6fa;
        -webkit-border-radius: 50% !important;
        -moz-border-radius: 50% !important;
        border-radius: 50% !important;
        img {
            border-radius: 50% !important;
            vertical-align: middle !important;
        }
    }
    .timeline-icon {
        width: 80px;
        height: 80px;
        background-color: #f5f6fa;
        -webkit-border-radius: 50% !important;
        -moz-border-radius: 50% !important;
        border-radius: 50% !important;
        padding-top: 30px;
        padding-left: 22px;
        i {
            font-size: 34px;
        }
    }
    .timeline-body {
        position: relative;
        padding: 20px;
        margin-top: 20px;
        margin-left: 110px;
        background-color: #f5f6fa;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        border-radius: 4px;
        &:after,
        &:before {
            content: " ";
            display: table;
        }
        &:after {
            clear: both;
        }
    }
    .timeline-body-arrow {
        position: absolute;
        top: 30px;
        left: -14px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 14px 14px 14px 0;
        border-color: transparent #f5f6fa transparent transparent;
    }
    .timeline-body-head {
        margin-bottom: 10px;
    }
    .timeline-body-head-caption {
        float: left;
    }
    .timeline-body-alerttitle,
    .timeline-body-title {
        font-size: 16px;
        font-weight: 600;
    }
    .timeline-body-time {
        font-size: 14px;
        margin-left: 10px;
    }
    .timeline-body-head-actions {
        float: right;
        .btn-group {
            margin-top: -2px;
        }
    }
    .timeline-body-content {
        font-size: 14px;
        margin-top: 35px;
    }
    .timeline-body-img {
        width: 100px;
        height: 100px;
        margin: 5px 20px 0 0;
    }
    &.white-bg {
        &:before {
            background: #fff;
        }
        .timeline-badge-userpic {
            border-color: #fff;
        }
        .timeline-body,
        .timeline-icon {
            background-color: #fff;
        }
        .timeline-body-arrow {
            border-color: transparent #fff transparent transparent;
        }
    }
}

@media (max-width: 768px) {
    .timeline {
        .timeline-body-head-caption {
            width: 100%;
        }
        .timeline-body-head-actions {
            float: left;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
}

@media (max-width: 480px) {
    .timeline {
        &:before {
            margin-left: 28px;
        }
        .timeline-badge {
            padding-right: 40px;
            width: 60px;
            height: 60px;
        }
        .timeline-badge-userpic {
            width: 60px;
        }
        .timeline-icon {
            width: 60px;
            height: 60px;
            padding-top: 23px;
            padding-left: 18px;
            i {
                font-size: 25px;
            }
        }
        .timeline-body {
            margin-left: 80px;
        }
        .timeline-body-arrow {
            top: 17px;
        }
    }
}

.mt-timeline-2 {
    position: relative;
    > {
        .mt-timeline-line {
            position: absolute;
            z-index: 1;
            height: 100%;
            width: 1px;
            top: 0;
            left: 50%;
            border-left: 4px solid;
            transform: translateX(-2px);
        }
        .mt-container {
            position: relative;
            padding: 0;
            >.mt-item {
                list-style: none;
                padding-bottom: 60px;
                clear: both;
                .timeline-body-img.pull-left {
                    margin-right: 15px;
                }
                > {
                    .mt-timeline-icon {
                        width: 70px;
                        height: 70px;
                        background-color: #ccc;
                        border-radius: 50% !important;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        z-index: 5;
                        border: 0;
                        overflow: hidden;
                        > {
                            i {
                                top: 50%;
                                left: 50%;
                                transform: translateY(-50%) translateX(-50%);
                                font-size: 24px;
                            }
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                    .mt-timeline-content {
                        width: 50%;
                        display: inline-block;
                        position: relative;
                        >.mt-content-container {
                            text-align: left;
                            background-color: #f5f6fa;
                            padding: 30px;
                            border: 2px solid #d3d7e9;
                            &:after,
                            &:before {
                                content: " ";
                                display: table;
                            }
                            &:after {
                                clear: both;
                            }
                            .mt-author,
                            .mt-title {
                                width: 50%;
                                margin-bottom: 15px;
                            }
                            .mt-content-title {
                                opacity: .8;
                                filter: alpha(opacity=80);
                                margin-top: 10px;
                                font-size: 18px;
                                font-weight: 600;
                            }
                            .mt-avatar {
                                width: 40px;
                                height: 40px;
                                border-radius: 50% !important;
                                overflow: hidden;
                                >img {
                                    width: 100%;
                                    height: auto;
                                }
                            }
                            .mt-author-name {
                                opacity: .9;
                                filter: alpha(opacity=90);
                                font-size: 15px;
                                font-weight: 600;
                                text-decoration: none;
                                a {
                                    opacity: .9;
                                    filter: alpha(opacity=90);
                                    font-size: 15px;
                                    font-weight: 600;
                                    text-decoration: none;
                                }
                            }
                            .mt-author-notes {
                                font-size: 12px;
                            }
                            .mt-content {
                                padding-top: 15px;
                                border-top: 1px solid;
                                clear: both;
                                line-height: 1.7em;
                                >p {
                                    opacity: .7;
                                    filter: alpha(opacity=70);
                                }
                                a,
                                button {
                                    font-size: 14px;
                                }
                            }
                            .btn {
                                display: inline-block;
                                margin: 0 5px 10px 0;
                                &.pull-right {
                                    margin: 0 0 10px 5px;
                                }
                            }
                            &:before {
                                content: '';
                                position: absolute;
                                top: 28px;
                                height: 0;
                                width: 0;
                                border: 10px solid transparent;
                            }
                        }
                    }
                }
                &:nth-child(odd) {
                    text-align: left;
                }
                &:nth-child(even) {
                    text-align: right;
                }
                &:nth-child(odd)>.mt-timeline-content>.mt-content-container {
                    .mt-author-name,
                    .mt-author-notes {
                        text-align: right;
                    }
                    margin-right: 60px;
                    .mt-title {
                        float: left;
                    }
                    .mt-author {
                        float: right;
                    }
                    .mt-avatar {
                        float: right;
                        margin-left: 15px;
                    }
                    &:before {
                        right: 40px;
                        border-left: 10px solid #d3d7e9;
                    }
                }
                &:nth-child(even)> {
                    .mt-timeline-icon>i {
                        transform: translateY(-50%) translateX(50%);
                        left: -50%;
                    }
                    .mt-timeline-content>.mt-content-container {
                        margin-left: 60px;
                        .mt-avatar {
                            float: left;
                            margin-right: 15px;
                        }
                        .mt-title {
                            float: right;
                            text-align: right;
                        }
                        .mt-author {
                            float: left;
                        }
                        .mt-author-name,
                        .mt-author-notes {
                            text-align: left;
                        }
                        &:before {
                            left: 40px;
                            border-right: 10px solid #E9EDEF;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .mt-timeline-2> {
        .mt-container>.mt-item> {
            .mt-timeline-content {
                width: 100%;
                >.mt-content-container {
                    .mt-author,
                    .mt-title {
                        width: 100%;
                    }
                }
            }
            .mt-timeline-icon {
                left: 25px;
            }
        }
        .mt-timeline-line {
            left: 25px;
        }
        .mt-container>.mt-item {
            >.mt-timeline-icon>i {
                left: 0;
            }
            &:nth-child(even)>.mt-timeline-content>.mt-content-container:before {
                left: 70px;
                border-right: 10px solid #E9EDEF;
                border-left: none;
            }
            &:nth-child(odd) {
                >.mt-timeline-content>.mt-content-container:before {
                    left: 70px;
                    border-right: 10px solid #E9EDEF;
                    border-left: none;
                }
                text-align: right;
                >.mt-timeline-content>.mt-content-container {
                    margin-left: 80px;
                    margin-right: 0;
                    .mt-avatar {
                        float: left;
                        margin-right: 15px;
                    }
                    .mt-title {
                        float: right;
                        text-align: right;
                    }
                    .mt-author {
                        float: left;
                    }
                    .mt-author-name,
                    .mt-author-notes {
                        text-align: left;
                    }
                }
            }
            >.mt-timeline-content>.mt-content-container .mt-title {
                float: none;
                text-align: left !important;
            }
            &:nth-child(even)>.mt-timeline-content>.mt-content-container {
                margin-left: 80px;
                margin-right: 0;
            }
        }
    }
}

@media (max-width: 480px) {
    .mt-timeline-2 {
        .btn-group.pull-right,
        .btn.pull-right {
            float: none !important;
            margin: 0 5px 10px 0 !important;
        }
    }
}

@media (max-width: 400px) {
    .mt-timeline-2>.mt-container>.mt-item:nth-child(even)>.mt-timeline-content>.mt-content-container .mt-author-notes {
        clear: both;
        padding-top: 10px;
    }
}

.cd-horizontal-timeline {
    opacity: 0;
    -webkit-transition: opacity .2s;
    -moz-transition: opacity .2s;
    transition: opacity .2s;
    &::before {
        content: 'mobile';
        display: none;
    }
    &.loaded {
        opacity: 1;
    }
    .timeline {
        position: relative;
        height: 80px;
        width: 90%;
        max-width: 800px;
        margin: 0 auto;
    }
    .events-wrapper {
        position: relative;
        height: 100%;
        margin: 0 40px;
        overflow: hidden;
        &::after {
            content: '';
            position: absolute;
            z-index: 2;
            top: 0;
            height: 100%;
            width: 20px;
        }
        &::before {
            content: '';
            position: absolute;
            z-index: 2;
            top: 0;
            height: 100%;
            width: 20px;
            left: 0;
            background-image: -webkit-linear-gradient(left, #f8f8f8, rgba(248, 248, 248, 0));
            background-image: linear-gradient(to right, #f8f8f8, rgba(248, 248, 248, 0));
        }
        &::after {
            right: 0;
            background-image: -webkit-linear-gradient(right, #f8f8f8, rgba(248, 248, 248, 0));
            background-image: linear-gradient(to left, #f8f8f8, rgba(248, 248, 248, 0));
        }
    }
    .events {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 39px;
        height: 2px;
        background: #dfdfdf;
        -webkit-transition: -webkit-transform .4s;
        -moz-transition: -moz-transform .4s;
        transition: transform .4s;
    }
    .filling-line {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: #7b9d6f;
        -webkit-transform: scaleX(0);
        -moz-transform: scaleX(0);
        -ms-transform: scaleX(0);
        -o-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -ms-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
        -webkit-transition: -webkit-transform .3s;
        -moz-transition: -moz-transform .3s;
        transition: transform .3s;
    }
    .events a {
        position: absolute;
        bottom: 0;
        z-index: 2;
        text-align: center;
        font-size: 1.3rem;
        padding-bottom: 15px;
        color: #383838;
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -o-transform: translateZ(0);
        transform: translateZ(0);
        &::after {
            content: '';
            position: absolute;
            left: 50%;
            right: auto;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);
            bottom: -5px;
            height: 12px;
            width: 12px;
            border-radius: 50%;
            -webkit-transition: background-color .3s, border-color .3s;
            -moz-transition: background-color .3s, border-color .3s;
            transition: background-color .3s, border-color .3s;
        }
    }
}

.no-touch .cd-horizontal-timeline .events a:hover::after {
    background-color: #7b9d6f;
    border-color: #7b9d6f;
}

.cd-horizontal-timeline .events a.selected {
    pointer-events: none;
}

@media only screen and (min-width: 1100px) {
    .cd-horizontal-timeline::before {
        content: 'desktop';
    }
}

.cd-timeline-navigation a {
    position: absolute;
    z-index: 1;
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 34px;
    width: 34px;
    border-radius: 50%;
    border: 2px solid #dfdfdf;
    overflow: hidden;
    color: transparent;
    text-indent: 100%;
    white-space: nowrap;
    -webkit-transition: border-color .3s;
    -moz-transition: border-color .3s;
    transition: border-color .3s;
    &.prev {
        left: 0;
    }
    &.next {
        right: 0;
    }
    &.inactive {
        cursor: not-allowed;
        &::after {
            background-position: 0 -16px;
        }
    }
}

.no-touch .cd-timeline-navigation a.inactive:hover {
    border-color: #dfdfdf;
}

.cd-horizontal-timeline .events-content {
    position: relative;
    width: 100%;
    margin: 10px 0 0;
    overflow: hidden;
    -webkit-transition: height .4s;
    -moz-transition: height .4s;
    transition: height .4s;
    >ol>li {
        position: absolute;
        z-index: 1;
        width: 100%;
        left: 0;
        top: 0;
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);
        transform: translateX(-100%);
        padding: 0;
        opacity: 0;
        -webkit-animation-duration: .4s;
        -moz-animation-duration: .4s;
        animation-duration: .4s;
        -webkit-animation-timing-function: ease-in-out;
        -moz-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        margin: 0 auto;
        &.selected {
            position: relative;
            z-index: 2;
            opacity: 1;
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
            transform: translateX(0);
        }
        &.enter-right,
        &.leave-right {
            -webkit-animation-name: cd-enter-right;
            -moz-animation-name: cd-enter-right;
            animation-name: cd-enter-right;
        }
        &.enter-left {
            -webkit-animation-name: cd-enter-left;
            -moz-animation-name: cd-enter-left;
            animation-name: cd-enter-left;
        }
        &.leave-left {
            -webkit-animation-name: cd-enter-left;
            -moz-animation-name: cd-enter-left;
            animation-name: cd-enter-left;
            -webkit-animation-direction: reverse;
            -moz-animation-direction: reverse;
            animation-direction: reverse;
        }
        &.leave-right {
            -webkit-animation-direction: reverse;
            -moz-animation-direction: reverse;
            animation-direction: reverse;
        }
    }
    em {
        display: block;
        font-style: italic;
        margin: 10px auto;
        &::before {
            content: '- ';
        }
    }
}

@-webkit-keyframes cd-enter-right {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }
}

@-moz-keyframes cd-enter-right {
    0% {
        opacity: 0;
        -moz-transform: translateX(100%);
    }
    100% {
        opacity: 1;
        -moz-transform: translateX(0);
    }
}

@keyframes cd-enter-right {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-webkit-keyframes cd-enter-left {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }
}

@-moz-keyframes cd-enter-left {
    0% {
        opacity: 0;
        -moz-transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        -moz-transform: translateX(0);
    }
}

@keyframes cd-enter-left {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

.mt-timeline-horizontal {
    font-size: 14px;
    ol,
    ul {
        list-style: none;
    }
    blockquote,
    q {
        quotes: none;
    }
    blockquote {
        &:after,
        &:before {
            content: '';
            content: none;
        }
    }
    q {
        &:after,
        &:before {
            content: '';
            content: none;
        }
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
    .timeline {
        width: 100%;
        max-width: 100%;
        &:before {
            background: 0 0;
        }
        .events-wrapper {
            .events a {
                &:after {
                    background-color: transparent;
                    border: 2px solid;
                }
                &.selected:after {
                    background-color: #fff !important;
                }
                &:focus,
                &:hover {
                    text-decoration: none;
                }
            }
            &:after,
            &:before {
                background-image: none;
            }
        }
        .mt-ht-nav-icon li {
            a {
                border-radius: 50% !important;
                i {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateX(50%) translateY(-50%);
                    width: 10px;
                }
            }
            &:first-child a i {
                left: -2px;
            }
        }
        &.mt-timeline-square {
            .events a:after,
            .mt-ht-nav-icon li a {
                border-radius: 0 !important;
            }
        }
    }
    .events-content ol {
        padding: 0;
        li {
            .mt-title {
                margin-top: 15px;
                float: left;
                width: 60%;
                h2 {
                    margin: 0;
                    opacity: .8;
                    filter: alpha(opacity=80);
                    font-size: 18px;
                    font-weight: 600;
                }
            }
            .mt-author {
                float: right;
                position: relative;
                text-align: right;
                width: 40%;
                > {
                    .mt-avatar {
                        float: right;
                        margin-left: 15px;
                        width: 50px;
                        height: 50px;
                        border-radius: 50% !important;
                        overflow: hidden;
                    }
                    .mt-author-name {
                        margin-top: 5px;
                        a {
                            opacity: .9;
                            filter: alpha(opacity=90);
                            font-size: 15px;
                            font-weight: 600;
                            &:focus,
                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                    .mt-author-datetime {
                        font-size: 13px;
                    }
                    .mt-avatar>img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            .mt-content {
                margin-top: 20px;
                padding-top: 20px;
                border-top: 1px solid;
                clear: both;
                line-height: 1.7em;
                >p {
                    opacity: .7;
                    filter: alpha(opacity=70);
                }
                img {
                    &.pull-left {
                        margin: 0 15px 15px 0;
                    }
                    &.pull-right {
                        margin: 0 0 15px 15px;
                    }
                }
                .btn-group .dropdown-menu {
                    margin-right: 5px;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .mt-timeline-horizontal {
        .events-content ol li {
            .mt-title {
                width: 100%;
            }
            .mt-author {
                width: 100%;
                margin-top: 15px;
                text-align: left;
                > {
                    .mt-avatar {
                        float: left;
                        margin-right: 15px;
                        margin-left: 0;
                    }
                    .mt-author-name {
                        margin-top: 10px;
                    }
                }
            }
        }
        .btn.pull-right {
            float: none !important;
            margin: 0 !important;
        }
    }
}

.tasks-widget {
    &:after {
        clear: both;
    }
    .task-list {
        list-style: none;
        padding: 0;
        margin: 0;
        >li {
            position: relative;
            padding: 10px;
            border-bottom: 1px solid #F4F6F9;
            &:hover {
                background: #F4F6F9;
            }
            &.last-line {
                border-bottom: none;
            }
            &.task-done {
                background: #f6f6f6;
                &:hover {
                    background: #f4f4f4;
                }
                .task-title-sp {
                    text-decoration: line-through;
                }
            }
            > {
                .task-bell {
                    margin-left: 10px;
                }
                .task-checkbox {
                    float: left;
                    width: 30px;
                    input[type=checkbox] {
                        cursor: pointer;
                    }
                }
                .task-title {
                    color: #838FA1;
                    margin-right: 10px;
                    .task-title-sp {
                        margin-right: 5px;
                    }
                }
            }
            .task-config-btn {
                margin-top: -1px;
            }
            >.task-config {
                display: none;
                position: absolute;
                top: 7px;
                right: 10px;
            }
            &:hover>.task-config {
                display: block;
                margin-bottom: 0 !important;
            }
        }
    }
    .task-footer {
        margin-top: 5px;
        &:after,
        &:before {
            content: " ";
            display: table;
        }
        &:after {
            clear: both;
        }
    }
}

@media only screen and (max-width: 480px) {
    .tasks-widget {
        .task-config-btn {
            float: inherit;
            display: block;
        }
        .task-list-projects li>.label {
            margin-bottom: 5px;
        }
    }
}

.mt-comments .mt-comment {
    padding: 10px;
    margin: 0 0 10px;
    .mt-comment-img {
        width: 40px;
        float: left;
        >img {
            border-radius: 50% !important;
        }
    }
    .mt-comment-body {
        padding-left: 20px;
        position: relative;
        overflow: hidden;
        .mt-comment-info {
            &:after,
            &:before {
                content: " ";
                display: table;
            }
            &:after {
                clear: both;
            }
            .mt-comment-author {
                display: inline-block;
                float: left;
                margin: 0 0 5px;
                color: #060606;
                font-weight: 600;
            }
            .mt-comment-date {
                display: inline-block;
                float: right;
                margin: 0;
                color: #BABABA;
            }
        }
        .mt-comment-text {
            color: #999;
        }
        .mt-comment-details {
            margin: 10px 0 0;
            .mt-comment-status {
                text-transform: uppercase;
                float: left;
                &.mt-comment-status-pending {
                    color: #B8C0F5;
                }
                &.mt-comment-status-approved {
                    color: #6BD873;
                }
                &.mt-comment-status-rejected {
                    color: red;
                }
            }
            .mt-comment-actions {
                display: none;
                list-style: none;
                margin: 0;
                padding: 0;
                float: right;
                >li {
                    float: left;
                    padding: 0 5px;
                    margin: 0;
                    >a {
                        text-transform: uppercase;
                        color: #999;
                        &:hover {
                            color: #666;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
    &:hover {
        background: #eef1f5;
        .mt-comment-body .mt-comment-details .mt-comment-actions {
            display: inline-block;
        }
    }
}

.mt-actions .mt-action {
    margin: 0;
    padding: 15px 0;
    border-bottom: 1px solid #f7f8f9;
    &:last-child {
        border-bottom: 0;
    }
    .mt-action-img {
        width: 40px;
        float: left;
        >img {
            border-radius: 50% !important;
            margin-bottom: 2px;
        }
    }
    .mt-action-body {
        padding-left: 15px;
        position: relative;
        overflow: hidden;
        .mt-action-row {
            display: table;
            width: 100%;
            .mt-action-info {
                display: table-cell;
                vertical-align: top;
                .mt-action-icon {
                    display: table-cell;
                    padding: 6px 20px 6px 6px;
                    >i {
                        display: inline-block;
                        position: relative;
                        top: 10px;
                        font-size: 25px;
                        color: #78E0E8;
                    }
                }
                .mt-action-details {
                    display: table-cell;
                    vertical-align: top;
                    .mt-action-author {
                        color: #060606;
                        font-weight: 600;
                    }
                    .mt-action-desc {
                        margin-bottom: 0;
                        color: #999b9b;
                    }
                }
            }
            .mt-action-datetime {
                vertical-align: top;
                display: table-cell;
                text-align: center;
                width: 150px;
                white-space: nowrap;
                padding-top: 15px;
                color: #A6A8A8;
                .mt-action-dot {
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    background-color: red;
                    border-radius: 50% !important;
                    margin-left: 5px;
                    margin-right: 5px;
                }
            }
            .mt-action-buttons {
                vertical-align: top;
                display: table-cell;
                text-align: center;
                width: 160px;
                white-space: nowrap;
                padding-top: 10px;
            }
        }
    }
}

@media (max-width: 767px) {
    .mt-actions .mt-action .mt-action-body .mt-action-row {
        display: block;
        .mt-action-info {
            display: block;
        }
        .mt-action-datetime {
            display: inline-block;
            margin-left: 40px;
        }
        .mt-action-buttons {
            display: inline-block;
            float: right;
        }
    }
}

.mt-widget-1 {
    border: 1px solid #e7ecf1;
    text-align: center;
    position: relative;
    .mt-icon {
        position: absolute;
        right: 10px;
        top: 10px;
        margin: 7px;
        font-size: 20px;
        >a {
            >i {
                color: #a1e5e1;
            }
            &:hover>i {
                color: #79dad5;
            }
        }
    }
    .mt-img {
        display: inline-block;
        border-radius: 50% !important;
        border: 4px solid #e7ecf1;
        margin: 40px 0 30px;
        >img {
            border: 1px solid trnsparent;
            border-radius: 50% !important;
        }
    }
    .mt-body {
        .mt-username {
            text-align: center;
            margin: 5px 10px;
            font-weight: 600;
            font-size: 16px;
        }
        .mt-user-title {
            text-align: center;
            margin: 10px;
            color: #666;
            font-size: 13px;
        }
        .mt-stats {
            margin: 30px 0 0;
            .btn-group {
                border-top: 1px solid #e7ecf1;
                .btn {
                    padding: 10px;
                    font-size: 14px;
                    border-right: 1px solid #e7ecf1;
                    &:hover {
                        background-color: #e7ecf1;
                    }
                    &:last-child {
                        border: 0;
                        border-bottom-right-radius: 4px !important;
                    }
                    >i {
                        position: relative;
                        top: 3px;
                        right: 2px;
                        font-size: 16px;
                    }
                    &:first-child {
                        border-bottom-left-radius: 4px !important;
                    }
                }
            }
        }
    }
}

.mt-widget-2 {
    border: 1px solid #e7ecf1;
    position: relative;
    .mt-head {
        position: absolute;
        width: 100%;
        background-size: 100% 100%;
        .mt-head-label {
            position: absolute;
            top: 8px;
            right: 8px;
            cursor: pointer;
            >button {
                font-size: 13px;
                border-radius: 2px !important;
            }
        }
        .mt-head-user {
            .mt-head-user-img {
                position: relative;
                float: left;
                margin: 165px 32px 18px 35px;
                &::after {
                    content: "";
                    position: absolute;
                    top: 90%;
                    left: 50%;
                    margin-left: -15px;
                    border-width: 13px;
                    border-style: solid;
                    border-color: transparent transparent #fff;
                }
                >img {
                    width: 65px;
                    border-radius: 50% !important;
                }
            }
            .mt-head-user-info {
                margin: 175px 0 0 -9px;
                color: #fff;
                display: inline-block;
                .mt-user-name {
                    display: block;
                    font-size: 15px;
                }
                .mt-user-time {
                    font-size: 13px;
                    display: block;
                    >i {
                        position: relative;
                        top: 1px;
                        font-size: 13px;
                    }
                }
            }
        }
    }
    .mt-body {
        padding-top: 160px;
        text-align: center;
        .mt-body-title {
            margin-top: 130px;
            font-weight: 600;
            font-size: 16px;
        }
        .mt-body-description {
            margin-top: 10px;
            display: inline-block;
            color: #666;
            font-size: 13px;
            padding: 0 10px;
        }
        .mt-body-stats {
            padding: 0;
            &:after,
            &:before {
                content: " ";
                display: table;
            }
            &:after {
                clear: both;
            }
            >li {
                margin: 15px;
                list-style: none;
                display: inline-block;
            }
        }
        .mt-body-actions {
            border-top: 1px solid #e7ecf1;
            >i {
                font-size: 18px;
            }
            .btn {
                font-size: 14px;
                border-right: 1px solid #e7ecf1;
                padding: 12px 0;
                text-align: center;
                &:last-child {
                    border: 0;
                }
            }
        }
    }
}

.mt-widget-3 {
    border: 1px solid #e7ecf1;
    .mt-head {
        background-color: #5DC9E6;
        margin-bottom: 20px;
        color: #fff;
        padding: 15px 0;
    }
}

.mt-widget-4 {
    background-color: #26C0B8;
    min-height: 250px;
    .mt-container {
        background-color: #26C0B8;
        min-height: 250px;
    }
}

.mt-widget-3 {
    .mt-head {
        .mt-head-icon {
            font-size: 35px;
            text-align: center;
            padding-top: 20px;
            margin-bottom: 10px;
        }
        .mt-head-desc {
            margin-left: 10px;
            margin-right: 10px;
            text-align: center;
            color: #fff;
            opacity: .8;
            filter: alpha(opacity=80);
        }
        .mt-head-date {
            text-align: center;
            margin-top: 20px;
            display: block;
            color: #f2f2f2;
        }
        .mt-head-button {
            margin: 10px 0;
            text-align: center;
            padding: 20px;
            >button {
                width: 90px;
            }
        }
    }
    .mt-body-actions-icons {
        .btn-group {
            margin-bottom: 20px;
            .mt-icon {
                display: block;
                position: relative;
                padding: 5px;
                font-size: 15px;
            }
        }
        .btn {
            border-right: 1px solid #e7ecf1;
            font-size: 11px;
            text-align: center;
            padding: 0;
            &:last-child {
                border-right: 0;
            }
        }
    }
}

.mt-widget-4 {
    color: #fff;
    .mt-img-container {
        position: relative;
        >img {
            height: 250px;
            width: 100%;
        }
    }
    .mt-container {
        width: 150px;
        position: absolute;
        right: 15px;
        top: 0;
        .mt-head-title {
            text-align: center;
            margin-top: 20px;
            padding: 10px;
        }
        .mt-body-icons {
            margin-top: 30px;
            text-align: center;
            >a {
                color: #e6e6e6;
                display: inline-block;
                padding: 10px;
                font-size: 17px;
                &:hover {
                    color: #fff;
                }
            }
        }
        .mt-footer-button {
            margin-top: 30px;
            position: absolute;
            right: 0;
            >.btn {
                width: 90px;
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
                border: none !important;
            }
        }
    }
}

.mt-code {
    padding: 3px;
    color: #E43A45;
    border-radius: 4px !important;
    display: inline;
    word-wrap: normal;
}

.caption-desc {
    font-size: 13px;
    margin-top: .5em;
    line-height: 2.3em;
}

.mt-element-step {
    .row {
        margin: 0;
    }
    .step-default {
        .mt-step-col {
            padding-top: 30px;
            padding-bottom: 30px;
            text-align: center;
        }
        .mt-step-number {
            font-size: 26px;
            border-radius: 50% !important;
            display: inline-block;
            margin: auto auto 20px;
            padding: 3px 14px;
        }
        .mt-step-title {
            font-size: 30px;
            font-weight: 100;
        }
        .active {
            background-color: #32c5d2 !important;
            .mt-step-number {
                color: #32c5d2 !important;
            }
            .mt-step-content,
            .mt-step-title {
                color: #fff !important;
            }
        }
        .done {
            background-color: #26C281 !important;
            .mt-step-number {
                color: #26C281 !important;
            }
            .mt-step-content,
            .mt-step-title {
                color: #fff !important;
            }
        }
        .error {
            background-color: #E7505A !important;
            .mt-step-number {
                color: #E7505A !important;
            }
            .mt-step-content,
            .mt-step-title {
                color: #fff !important;
            }
        }
    }
    .step-thin {
        .mt-step-col {
            padding-top: 10px;
            padding-bottom: 10px;
        }
        .mt-step-number {
            font-size: 26px;
            border-radius: 50% !important;
            float: left;
            margin: auto;
            padding: 3px 14px;
        }
        .mt-step-title {
            font-size: 24px;
            font-weight: 100;
            padding-left: 60px;
            margin-top: -4px;
        }
        .mt-step-content {
            padding-left: 60px;
            margin-top: -5px;
        }
        .active {
            background-color: #32c5d2 !important;
            .mt-step-number {
                color: #32c5d2 !important;
            }
            .mt-step-content,
            .mt-step-title {
                color: #fff !important;
            }
        }
        .done {
            background-color: #26C281 !important;
            .mt-step-number {
                color: #26C281 !important;
            }
            .mt-step-content,
            .mt-step-title {
                color: #fff !important;
            }
        }
        .error {
            background-color: #E7505A !important;
            .mt-step-number {
                color: #E7505A !important;
            }
            .mt-step-content,
            .mt-step-title {
                color: #fff !important;
            }
        }
    }
    .step-background {
        .mt-step-col {
            padding-top: 30px;
            padding-bottom: 30px;
            text-align: center;
            height: 160px;
        }
        .mt-step-number {
            font-size: 200px;
            position: absolute;
            bottom: 0;
            right: 0;
            line-height: .79em;
            color: #dae1e4;
            z-index: 4;
        }
        .mt-step-content {
            text-align: right;
            z-index: 5;
            position: relative;
            padding-right: 25%;
        }
        .mt-step-title {
            text-align: right;
            z-index: 5;
            position: relative;
            padding-right: 25%;
            font-size: 30px;
            font-weight: 100;
        }
        .active {
            background-color: #32c5d2 !important;
            .mt-step-number {
                color: #2ab4c0 !important;
            }
            .mt-step-content,
            .mt-step-title {
                color: #fff !important;
            }
        }
        .done {
            background-color: #26C281 !important;
            .mt-step-number {
                color: #22ad73 !important;
            }
            .mt-step-content,
            .mt-step-title {
                color: #fff !important;
            }
        }
        .error {
            background-color: #E7505A !important;
            .mt-step-number {
                color: #e43a45 !important;
            }
            .mt-step-content,
            .mt-step-title {
                color: #fff !important;
            }
        }
    }
    .step-background-thin {
        .mt-step-col {
            padding-top: 15px;
            padding-bottom: 15px;
            text-align: center;
        }
        .mt-step-number {
            font-size: 120px;
            position: absolute;
            bottom: 0;
            right: 0;
            line-height: .79em;
            color: #dae1e4;
            z-index: 4;
        }
        .mt-step-title {
            font-size: 30px;
            font-weight: 100;
            text-align: right;
            padding-right: 25%;
            z-index: 5;
            position: relative;
        }
        .mt-step-content {
            text-align: right;
            position: relative;
            padding-right: 25%;
            z-index: 5;
        }
        .active {
            background-color: #32c5d2 !important;
            .mt-step-number {
                color: #2ab4c0 !important;
            }
            .mt-step-content,
            .mt-step-title {
                color: #fff !important;
            }
        }
        .done {
            background-color: #26C281 !important;
            .mt-step-number {
                color: #22ad73 !important;
            }
            .mt-step-content,
            .mt-step-title {
                color: #fff !important;
            }
        }
        .error {
            background-color: #E7505A !important;
            .mt-step-number {
                color: #e43a45 !important;
            }
            .mt-step-content,
            .mt-step-title {
                color: #fff !important;
            }
        }
    }
    .step-no-background {
        .mt-step-col {
            padding-top: 30px;
            padding-bottom: 30px;
            text-align: center;
        }
        .mt-step-number {
            font-size: 26px;
            border-radius: 50% !important;
            display: inline-block;
            margin: auto auto 20px;
            padding: 3px 14px;
            border: 1px solid #e5e5e5;
        }
        .mt-step-title {
            font-size: 30px;
            font-weight: 100;
        }
        .active {
            .mt-step-number {
                color: #32c5d2 !important;
                border-color: #32c5d2 !important;
                font-weight: 700;
            }
            .mt-step-content,
            .mt-step-title {
                color: #32c5d2 !important;
                font-weight: 700;
            }
        }
        .done {
            .mt-step-number {
                color: #26C281 !important;
                border-color: #26C281 !important;
                font-weight: 700;
            }
            .mt-step-content,
            .mt-step-title {
                color: #26C281 !important;
                font-weight: 700;
            }
        }
        .error {
            .mt-step-number {
                color: #E7505A !important;
                border-color: #E7505A !important;
                font-weight: 700;
            }
            .mt-step-content,
            .mt-step-title {
                color: #E7505A !important;
                font-weight: 700;
            }
        }
    }
    .step-no-background-thin {
        .mt-step-col {
            padding-top: 10px;
            padding-bottom: 10px;
        }
        .mt-step-number {
            font-size: 26px;
            border-radius: 50% !important;
            float: left;
            margin: auto;
            padding: 3px 14px;
            border: 1px solid #e5e5e5;
        }
        .mt-step-title {
            font-size: 24px;
            font-weight: 100;
            padding-left: 60px;
            margin-top: -4px;
        }
        .mt-step-content {
            padding-left: 60px;
            margin-top: -5px;
        }
        .active {
            .mt-step-number {
                color: #32c5d2 !important;
                border-color: #32c5d2 !important;
                font-weight: 700;
            }
            .mt-step-content,
            .mt-step-title {
                color: #32c5d2 !important;
                font-weight: 700;
            }
        }
        .done {
            .mt-step-number {
                color: #26C281 !important;
                border-color: #26C281 !important;
                font-weight: 700;
            }
            .mt-step-content,
            .mt-step-title {
                color: #26C281 !important;
                font-weight: 700;
            }
        }
        .error {
            .mt-step-number {
                color: #E7505A !important;
                border-color: #E7505A !important;
                font-weight: 700;
            }
            .mt-step-content,
            .mt-step-title {
                color: #E7505A !important;
                font-weight: 700;
            }
        }
    }
    .step-line {
        .mt-step-col {
            padding: 30px 0;
            text-align: center;
        }
        .mt-step-number {
            font-size: 26px;
            border-radius: 50% !important;
            display: inline-block;
            margin: auto auto 5px;
            padding: 9px;
            border: 3px solid #e5e5e5;
            position: relative;
            z-index: 5;
            height: 60px;
            width: 60px;
            text-align: center;
            >i {
                position: relative;
                top: 50%;
                transform: translateY(-120%);
            }
        }
        .mt-step-title {
            font-size: 20px;
            font-weight: 400;
            position: relative;
            &:after,
            &:before {
                content: '';
                height: 3px;
                width: 50%;
                position: absolute;
                background-color: #e5e5e5;
                top: -32px;
                z-index: 4;
                transform: translateY(-100%);
            }
            &:after {
                left: 50%;
            }
            &:before {
                right: 50%;
            }
        }
        .first .mt-step-title:before,
        .last .mt-step-title:after {
            content: none;
        }
        .active {
            .mt-step-number {
                color: #32c5d2 !important;
                border-color: #32c5d2 !important;
            }
            .mt-step-content {
                color: #32c5d2 !important;
            }
            .mt-step-title {
                color: #32c5d2 !important;
                &:after,
                &:before {
                    background-color: #32c5d2;
                }
            }
        }
        .done {
            .mt-step-number {
                color: #26C281 !important;
                border-color: #26C281 !important;
            }
            .mt-step-content {
                color: #26C281 !important;
            }
            .mt-step-title {
                color: #26C281 !important;
                &:after,
                &:before {
                    background-color: #26C281;
                }
            }
        }
        .error {
            .mt-step-number {
                color: #E7505A !important;
                border-color: #E7505A !important;
            }
            .mt-step-content {
                color: #E7505A !important;
            }
            .mt-step-title {
                color: #E7505A !important;
                &:after,
                &:before {
                    background-color: #E7505A;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .mt-element-step .step-line .mt-step-title {
        &:after,
        &:before {
            content: none;
        }
    }
    .note {
        &.note-info {
            &.note_style {
                margin: 10px 0px 0px 0px;
            }
        }
    }
}

.mt-element-list {
    .list-default {
        &.mt-list-head {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            padding: 15px;
            .list-title {
                margin: 0 0 .7em;
                font-size: 18px;
            }
            .list-date {
                font-size: 12px;
            }
            .list-pending {
                margin-bottom: 10px;
            }
            .list-count {
                display: inline-block;
                padding: 3px 7px;
                &.last {
                    margin-bottom: 0;
                }
            }
            .list-label {
                display: inline-block;
                font-size: 12px;
            }
        }
        &.mt-list-container {
            border-left: 1px solid;
            border-right: 1px solid;
            border-bottom: 1px solid;
            border-color: #e7ecf1;
            padding: 15px;
            .mt-list-title {
                padding-bottom: 15px;
                font-size: 14px;
                font-weight: 700;
            }
            ul {
                margin-bottom: 0;
                padding: 0;
                >.mt-list-item {
                    list-style: none;
                    border-bottom: 1px solid;
                    border-color: #e7ecf1;
                    padding: 25px 0;
                    min-height: 45px;
                    &:first-child {
                        padding-top: 0;
                    }
                    &:last-child {
                        padding-bottom: 0;
                        border: none;
                    }
                    > {
                        .list-icon-container {
                            border: 1px solid #e7ecf1;
                            border-radius: 50% !important;
                            padding: .9em;
                            float: left;
                            width: 45px;
                            height: 45px;
                            >a {
                                color: #34495e;
                                &:hover {
                                    color: #26C281;
                                    text-decoration: none;
                                }
                            }
                            &.done {
                                border-color: #26C281;
                                >a {
                                    color: #26C281;
                                    &:hover {
                                        color: #26C281;
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                        .list-item-content {
                            padding: 0 75px 0 60px;
                            > {
                                h3 {
                                    margin-top: 0;
                                    margin-bottom: 5px;
                                    font-size: 16px;
                                    >a {
                                        color: #34495e;
                                        &:hover {
                                            color: #26C281;
                                            text-decoration: none;
                                        }
                                    }
                                }
                                p {
                                    margin: 0;
                                }
                            }
                        }
                        .list-datetime {
                            text-align: right;
                            float: right;
                            width: 60px;
                        }
                    }
                }
            }
        }
        &.ext-1.mt-list-container {
            padding: 15px 0 0;
            .mt-list-title {
                padding: 0 15px 15px;
            }
            ul>.mt-list-item {
                padding: 15px;
                border-left: 3px solid;
                border-color: #2F353B #2F353B #e7ecf1;
                &:hover {
                    background-color: #e5e5e5;
                }
                &.done {
                    border-color: #26C281 #26C281 #e7ecf1;
                    &:hover {
                        background-color: #96ebc8;
                    }
                }
                >.list-icon-container {
                    border: none;
                }
            }
        }
        &.group .list-toggle-container {
            &:active,
            &:focus,
            &:hover {
                text-decoration: none;
            }
            .list-toggle {
                padding: 15px;
                background-color: #2F353B;
                font-weight: 700;
                color: #fff;
                text-decoration: none;
                &.done {
                    background-color: #26C281;
                }
            }
        }
    }
    .list-simple {
        &.mt-list-container {
            border-left: 1px solid;
            border-right: 1px solid;
            border-bottom: 1px solid;
            border-color: #e7ecf1;
            padding: 15px;
            ul {
                margin-bottom: 0;
                padding: 0;
                >.mt-list-item {
                    list-style: none;
                    border-bottom: 1px solid;
                    border-color: #e7ecf1;
                    padding: 15px 0;
                    &:first-child {
                        padding-top: 0;
                    }
                    &:last-child {
                        padding-bottom: 0;
                        border: none;
                    }
                    > {
                        .list-icon-container {
                            font-size: 14px;
                            float: left;
                            >a {
                                color: #34495e;
                                &:hover {
                                    color: #26C281;
                                    text-decoration: none;
                                }
                            }
                            &.done {
                                color: #26C281;
                                >a {
                                    color: #26C281;
                                    &:hover {
                                        color: #26C281;
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                        .list-item-content {
                            padding: 0 75px 0 60px;
                            >h3 {
                                margin: 0;
                                font-size: 18px;
                                >a {
                                    color: #34495e;
                                    &:hover {
                                        color: #26C281;
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                        .list-datetime {
                            text-align: right;
                            float: right;
                            width: 60px;
                        }
                    }
                }
            }
        }
        &.mt-list-head {
            padding: 15px;
            .list-title {
                margin: 0;
                padding-right: 85px;
            }
            .list-date {
                font-size: 12px;
                opacity: .8;
                float: right;
                width: 75px;
            }
        }
        &.ext-1.mt-list-container {
            padding: 0;
            ul>.mt-list-item {
                padding: 15px;
                border-left: 3px solid;
                border-color: #34495e #34495e #e7ecf1;
                &:hover {
                    background-color: #e5e5e5;
                }
                &.done {
                    border-color: #26C281 #26C281 #e7ecf1;
                    &:hover {
                        background-color: #96ebc8;
                    }
                }
            }
        }
        &.group .list-toggle-container {
            &:active,
            &:focus,
            &:hover {
                text-decoration: none;
            }
            .list-toggle {
                padding: 15px;
                background-color: #34495e;
                font-weight: 700;
                color: #fff;
                text-decoration: none;
                &.done {
                    background-color: #26C281;
                }
            }
        }
    }
    .list-news {
        &.ext-1.mt-list-container ul>.mt-list-item:hover,
        &.ext-2.mt-list-container ul>.mt-list-item:hover {
            background-color: #e5e5e5;
        }
        &.mt-list-head {
            padding: 15px;
            text-align: center;
            .list-title {
                margin: 0;
            }
            .badge {
                margin-top: 5px;
            }
        }
        &.mt-list-container {
            border-left: 1px solid;
            border-right: 1px solid;
            border-bottom: 1px solid;
            border-color: #e7ecf1;
            padding: 15px 0;
            ul {
                margin-bottom: 0;
                padding: 0;
                >.mt-list-item {
                    list-style: none;
                    border-bottom: 1px solid;
                    border-color: #e7ecf1;
                    padding: 15px;
                    position: relative;
                    &:first-child {
                        padding-top: 0;
                    }
                    &:last-child {
                        padding-bottom: 0;
                        border: none;
                    }
                    > {
                        .list-icon-container {
                            font-size: 20px;
                            position: absolute;
                            right: 5px;
                            top: 50%;
                            margin-top: -10px;
                            a {
                                color: #2f353b;
                                &:hover {
                                    color: #32c5d2;
                                }
                            }
                        }
                        .list-item-content {
                            padding: 0 25px 0 0;
                            >h3 {
                                margin: 0 0 10px;
                                font-size: 18px;
                                >a {
                                    color: #34495e;
                                    &:hover {
                                        color: #32c5d2;
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                        .list-datetime {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
        &.ext-1 {
            &.mt-list-head {
                position: relative;
                text-align: left;
                .list-count {
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 100%;
                    padding: 19px;
                }
            }
            .list-thumb {
                width: 80px;
                height: 80px;
                overflow: hidden;
                float: left;
                img {
                    width: 100%;
                }
            }
            &.mt-list-container {
                padding: 0;
                ul>.mt-list-item {
                    padding: 15px;
                    > {
                        .list-datetime,
                        .list-item-content {
                            padding-left: 90px;
                        }
                    }
                    &:last-child {
                        padding-bottom: 15px;
                    }
                }
            }
        }
        &.ext-2 {
            &.mt-list-head {
                position: relative;
                text-align: left;
                .list-count {
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 100%;
                    padding: 19px;
                }
            }
            .list-thumb {
                width: 80px;
                height: 80px;
                overflow: hidden;
                float: left;
                img {
                    width: 100%;
                }
            }
            &.mt-list-container {
                padding: 0;
                ul>.mt-list-item {
                    padding: 15px;
                    > {
                        .list-datetime {
                            padding-left: 90px;
                        }
                        .list-item-content {
                            padding-left: 90px;
                            >h3 {
                                font-size: 16px;
                                >a:hover {
                                    color: #F2784B;
                                }
                            }
                        }
                    }
                    &:last-child {
                        padding-bottom: 15px;
                    }
                }
            }
        }
    }
    .list-todo {
        &.mt-list-head {
            padding: 15px;
            position: relative;
            .list-title {
                margin: 0;
            }
            .list-head-count {
                margin-top: 5px;
                >.list-head-count-item {
                    display: inline-block;
                    margin-right: 15px;
                }
            }
            .list-count {
                position: absolute;
                top: 0;
                right: 0;
                padding: 29px;
                font-size: 16px;
            }
            a {
                color: #fff;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        &.mt-list-container {
            border-left: 1px solid;
            border-right: 1px solid;
            border-bottom: 1px solid;
            border-color: #e7ecf1;
            position: relative;
            .list-todo-line {
                position: absolute;
                z-index: 1;
                height: 100%;
                width: 1px;
                top: 0;
                left: 25px;
                border-left: 1px solid;
                border-color: #e7ecf1;
            }
            ul {
                margin-bottom: 0;
                padding: 0;
                position: relative;
                z-index: 5;
                >.mt-list-item {
                    list-style: none;
                    border-bottom: 1px solid;
                    border-bottom-style: dashed;
                    border-color: #e7ecf1;
                    padding: 15px;
                    position: relative;
                    &:last-child {
                        border: none;
                    }
                    > {
                        .list-todo-icon {
                            display: inline-block;
                            margin-top: .7em;
                            padding: .7em 0;
                            vertical-align: top;
                        }
                        .list-todo-item {
                            margin-left: 15px;
                            display: inline-block;
                            vertical-align: top;
                            width: 90%;
                            position: relative;
                            &:after {
                                right: 100%;
                                border: solid transparent;
                                content: " ";
                                height: 0;
                                width: 0;
                                position: absolute;
                                pointer-events: none;
                                border-color: rgba(47, 53, 59, 0);
                                border-right-color: #2f353b;
                                border-width: 8px;
                                top: 18px;
                            }
                            >.list-toggle-container {
                                &:active,
                                &:focus,
                                &:hover {
                                    text-decoration: none;
                                }
                                .list-toggle {
                                    padding: 15px;
                                    >.list-toggle-title {
                                        display: inline-block;
                                    }
                                }
                            }
                            .task-list {
                                border: 1px solid #e7ecf1;
                                padding: 0;
                                margin: 0;
                                position: relative;
                                border-top: none;
                                border-bottom: none;
                                .task-list-item {
                                    list-style: none;
                                    padding: 15px;
                                    border-bottom: 1px solid;
                                    border-color: #e7ecf1;
                                    a {
                                        color: #2f353b;
                                        &:hover {
                                            text-decoration: none;
                                            color: #e43a45;
                                        }
                                    }
                                    &:last-child {
                                        border-bottom: none;
                                    }
                                    > {
                                        .task-icon {
                                            float: left;
                                        }
                                        .task-content {
                                            padding: 0 45px 0 35px;
                                            > {
                                                h4 {
                                                    margin-top: 0;
                                                    font-size: 14px;
                                                }
                                                p {
                                                    font-size: 13px;
                                                    margin: 0;
                                                }
                                            }
                                        }
                                        .task-status {
                                            float: right;
                                            a {
                                                color: #e5e5e5;
                                            }
                                            .done:hover {
                                                color: #26C281;
                                            }
                                            .pending:hover {
                                                color: #e43a45;
                                            }
                                        }
                                    }
                                    &.done>.task-status .done {
                                        color: #26C281;
                                    }
                                }
                                .task-footer {
                                    padding: 15px;
                                    text-align: center;
                                    a {
                                        color: #2f353b;
                                        &:hover {
                                            text-decoration: none;
                                        }
                                        &.task-trash:hover {
                                            color: #e43a45;
                                        }
                                        &.task-add:hover {
                                            color: #26C281;
                                        }
                                    }
                                }
                            }
                        }
                        .list-icon-container {
                            font-size: 20px;
                            position: absolute;
                            right: 5px;
                            top: 50%;
                            margin-top: -10px;
                            a {
                                color: #2f353b;
                                &:hover {
                                    color: #32c5d2;
                                }
                            }
                        }
                        .list-item-content {
                            padding: 0 25px 0 0;
                            >h3 {
                                margin: 0 0 10px;
                                font-size: 18px;
                                >a {
                                    color: #34495e;
                                    &:hover {
                                        color: #32c5d2;
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                        .list-datetime {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
    .list-default.mt-list-head.white {
        background-color: #fff;
        color: #666;
        .badge {
            background-color: #fff;
            color: #666;
        }
        &.ext-1 .badge {
            background-color: #e6e6e6;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.white,
        &.mt-list-head.white {
            background-color: #fff;
            color: #666;
        }
        .list-count.white:hover {
            background-color: #f2f2f2;
        }
        .list-todo-line.white {
            border-color: #fff;
        }
        .list-todo-item.white {
            &:after {
                border-right-color: #fff !important;
            }
            .list-toggle {
                background: #fff;
                color: #666 !important;
                >.badge {
                    color: #fff;
                    background: #666;
                }
            }
        }
    }
    .list-default.mt-list-head.default {
        background-color: #e1e5ec;
        color: #666;
        .badge {
            background-color: #fff;
            color: #666;
        }
        &.ext-1 .badge {
            background-color: #c2cad8;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.default,
        &.mt-list-head.default {
            background-color: #e1e5ec;
            color: #666;
        }
        .list-count.default:hover {
            background-color: #d1d7e2;
        }
        .list-todo-line.default {
            border-color: #e1e5ec;
        }
        .list-todo-item.default {
            &:after {
                border-right-color: #e1e5ec !important;
            }
            .list-toggle {
                background: #e1e5ec;
                color: #666 !important;
                >.badge {
                    color: #e1e5ec;
                    background: #666;
                }
            }
        }
    }
    .list-default.mt-list-head.dark {
        background-color: #2f353b;
        color: #FFF;
        .badge {
            background-color: #464f57;
            color: #FFF;
        }
        &.ext-1 .badge {
            background-color: #181c1f;
        }
        .list-date,
        .list-label {
            color: #acb5bd;
        }
    }
    .list-todo {
        .list-count.dark,
        &.mt-list-head.dark {
            background-color: #2f353b;
            color: #FFF;
        }
        .list-count.dark:hover {
            background-color: #24282d;
        }
        .list-todo-line.dark {
            border-color: #2f353b;
        }
        .list-todo-item.dark {
            &:after {
                border-right-color: #2f353b !important;
            }
            .list-toggle {
                background: #2f353b;
                color: #FFF !important;
                >.badge {
                    color: #2f353b;
                    background: #FFF;
                }
            }
        }
    }
    .list-default.mt-list-head.blue {
        background-color: #3598dc;
        color: #FFF;
        .badge {
            background-color: #60aee4;
            color: #FFF;
        }
        &.ext-1 .badge {
            background-color: #217ebd;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.blue,
        &.mt-list-head.blue {
            background-color: #3598dc;
            color: #FFF;
        }
        .list-count.blue:hover {
            background-color: #258cd3;
        }
        .list-todo-line.blue {
            border-color: #3598dc;
        }
        .list-todo-item.blue {
            &:after {
                border-right-color: #3598dc !important;
            }
            .list-toggle {
                background: #3598dc;
                color: #FFF !important;
                >.badge {
                    color: #3598dc;
                    background: #FFF;
                }
            }
        }
    }
    .list-default.mt-list-head.blue-madison {
        background-color: #578ebe;
        color: #FFF;
        .badge {
            background-color: #7ca7cc;
            color: #FFF;
        }
        &.ext-1 .badge {
            background-color: #3f74a3;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.blue-madison,
        &.mt-list-head.blue-madison {
            background-color: #578ebe;
            color: #FFF;
        }
        .list-count.blue-madison:hover {
            background-color: #4682b5;
        }
        .list-todo-line.blue-madison {
            border-color: #578ebe;
        }
        .list-todo-item.blue-madison {
            &:after {
                border-right-color: #578ebe !important;
            }
            .list-toggle {
                background: #578ebe;
                color: #FFF !important;
                >.badge {
                    color: #578ebe;
                    background: #FFF;
                }
            }
        }
    }
    .list-default.mt-list-head.blue-chambray {
        background-color: #2C3E50;
        color: #FFF;
        .badge {
            background-color: #3e5871;
            color: #FFF;
        }
        &.ext-1 .badge {
            background-color: #1a252f;
        }
        .list-date,
        .list-label {
            color: #aabed1;
        }
    }
    .list-todo {
        .list-count.blue-chambray,
        &.mt-list-head.blue-chambray {
            background-color: #2C3E50;
            color: #FFF;
        }
        .list-count.blue-chambray:hover {
            background-color: #233140;
        }
        .list-todo-line.blue-chambray {
            border-color: #2C3E50;
        }
        .list-todo-item.blue-chambray {
            &:after {
                border-right-color: #2C3E50 !important;
            }
            .list-toggle {
                background: #2C3E50;
                color: #FFF !important;
                >.badge {
                    color: #2C3E50;
                    background: #FFF;
                }
            }
        }
    }
    .list-default.mt-list-head.blue-ebonyclay {
        background-color: #22313F;
        color: #FFF;
        .badge {
            background-color: #344b60;
            color: #FFF;
        }
        &.ext-1 .badge {
            background-color: #10171e;
        }
        .list-date,
        .list-label {
            color: #98b1c8;
        }
    }
    .list-todo {
        .list-count.blue-ebonyclay,
        &.mt-list-head.blue-ebonyclay {
            background-color: #22313F;
            color: #FFF;
        }
        .list-count.blue-ebonyclay:hover {
            background-color: #19242e;
        }
        .list-todo-line.blue-ebonyclay {
            border-color: #22313F;
        }
        .list-todo-item.blue-ebonyclay {
            &:after {
                border-right-color: #22313F !important;
            }
            .list-toggle {
                background: #22313F;
                color: #FFF !important;
                >.badge {
                    color: #22313F;
                    background: #FFF;
                }
            }
        }
    }
    .list-default.mt-list-head.blue-hoki {
        background-color: #67809F;
        color: #FFF;
        .badge {
            background-color: #869ab3;
            color: #FFF;
        }
        &.ext-1 .badge {
            background-color: #526781;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.blue-hoki,
        &.mt-list-head.blue-hoki {
            background-color: #67809F;
            color: #FFF;
        }
        .list-count.blue-hoki:hover {
            background-color: #5c7391;
        }
        .list-todo-line.blue-hoki {
            border-color: #67809F;
        }
        .list-todo-item.blue-hoki {
            &:after {
                border-right-color: #67809F !important;
            }
            .list-toggle {
                background: #67809F;
                color: #FFF !important;
                >.badge {
                    color: #67809F;
                    background: #FFF;
                }
            }
        }
    }
    .list-default.mt-list-head.blue-steel {
        background-color: #4B77BE;
        color: #FFF;
        .badge {
            background-color: #7093cc;
            color: #FFF;
        }
        &.ext-1 .badge {
            background-color: #395f9d;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.blue-steel,
        &.mt-list-head.blue-steel {
            background-color: #4B77BE;
            color: #FFF;
        }
        .list-count.blue-steel:hover {
            background-color: #406bb0;
        }
        .list-todo-line.blue-steel {
            border-color: #4B77BE;
        }
        .list-todo-item.blue-steel {
            &:after {
                border-right-color: #4B77BE !important;
            }
            .list-toggle {
                background: #4B77BE;
                color: #FFF !important;
                >.badge {
                    color: #4B77BE;
                    background: #FFF;
                }
            }
        }
    }
    .list-default.mt-list-head.blue-soft {
        background-color: #4c87b9;
        color: #FFF;
        .badge {
            background-color: #71a0c7;
            color: #FFF;
        }
        &.ext-1 .badge {
            background-color: #3b6d97;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.blue-soft,
        &.mt-list-head.blue-soft {
            background-color: #4c87b9;
            color: #FFF;
        }
        .list-count.blue-soft:hover {
            background-color: #427aa9;
        }
        .list-todo-line.blue-soft {
            border-color: #4c87b9;
        }
        .list-todo-item.blue-soft {
            &:after {
                border-right-color: #4c87b9 !important;
            }
            .list-toggle {
                background: #4c87b9;
                color: #FFF !important;
                >.badge {
                    color: #4c87b9;
                    background: #FFF;
                }
            }
        }
    }
    .list-default.mt-list-head.blue-dark {
        background-color: #5e738b;
        color: #FFF;
        .badge {
            background-color: #788da4;
            color: #FFF;
        }
        &.ext-1 .badge {
            background-color: #495a6d;
        }
        .list-date,
        .list-label {
            color: #f2f4f6;
        }
    }
    .list-todo {
        .list-count.blue-dark,
        &.mt-list-head.blue-dark {
            background-color: #5e738b;
            color: #FFF;
        }
        .list-count.blue-dark:hover {
            background-color: #54667c;
        }
        .list-todo-line.blue-dark {
            border-color: #5e738b;
        }
        .list-todo-item.blue-dark {
            &:after {
                border-right-color: #5e738b !important;
            }
            .list-toggle {
                background: #5e738b;
                color: #FFF !important;
                >.badge {
                    color: #5e738b;
                    background: #FFF;
                }
            }
        }
    }
    .list-default.mt-list-head.blue-sharp {
        background-color: #5C9BD1;
        color: #FFF;
        .badge {
            background-color: #84b3dc;
            color: #FFF;
        }
        &.ext-1 .badge {
            background-color: #3782c3;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.blue-sharp,
        &.mt-list-head.blue-sharp {
            background-color: #5C9BD1;
            color: #FFF;
        }
        .list-count.blue-sharp:hover {
            background-color: #488fcb;
        }
        .list-todo-line.blue-sharp {
            border-color: #5C9BD1;
        }
        .list-todo-item.blue-sharp {
            &:after {
                border-right-color: #5C9BD1 !important;
            }
            .list-toggle {
                background: #5C9BD1;
                color: #FFF !important;
                >.badge {
                    color: #5C9BD1;
                    background: #FFF;
                }
            }
        }
    }
    .list-default.mt-list-head.blue-oleo {
        background-color: #94A0B2;
        color: #FFF;
        .badge {
            background-color: #b2bac7;
            color: #FFF;
        }
        &.ext-1 .badge {
            background-color: #76869d;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.blue-oleo,
        &.mt-list-head.blue-oleo {
            background-color: #94A0B2;
            color: #FFF;
        }
        .list-count.blue-oleo:hover {
            background-color: #8593a7;
        }
        .list-todo-line.blue-oleo {
            border-color: #94A0B2;
        }
        .list-todo-item.blue-oleo {
            &:after {
                border-right-color: #94A0B2 !important;
            }
            .list-toggle {
                background: #94A0B2;
                color: #FFF !important;
                >.badge {
                    color: #94A0B2;
                    background: #FFF;
                }
            }
        }
    }
    .list-default.mt-list-head.green {
        background-color: #32c5d2;
        color: #FFF;
        .badge {
            background-color: #5cd1db;
            color: #FFF;
        }
        &.ext-1 .badge {
            background-color: #26a1ab;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.green,
        &.mt-list-head.green {
            background-color: #32c5d2;
            color: #FFF;
        }
        .list-count.green:hover {
            background-color: #2ab4c0;
        }
        .list-todo-line.green {
            border-color: #32c5d2;
        }
        .list-todo-item.green {
            &:after {
                border-right-color: #32c5d2 !important;
            }
            .list-toggle {
                background: #32c5d2;
                color: #FFF !important;
                >.badge {
                    color: #32c5d2;
                    background: #FFF;
                }
            }
        }
    }
    .list-default.mt-list-head.green-meadow {
        background-color: #1BBC9B;
        color: #FFF;
        .badge {
            background-color: #2ae0bb;
            color: #FFF;
        }
        &.ext-1 .badge {
            background-color: #158f76;
        }
        .list-date,
        .list-label {
            color: #dcfaf4;
        }
    }
    .list-todo {
        .list-count.green-meadow,
        &.mt-list-head.green-meadow {
            background-color: #1BBC9B;
            color: #FFF;
        }
        .list-count.green-meadow:hover {
            background-color: #18a689;
        }
        .list-todo-line.green-meadow {
            border-color: #1BBC9B;
        }
        .list-todo-item.green-meadow {
            &:after {
                border-right-color: #1BBC9B !important;
            }
            .list-toggle {
                background: #1BBC9B;
                color: #FFF !important;
                >.badge {
                    color: #1BBC9B;
                    background: #FFF;
                }
            }
        }
    }
    .list-default.mt-list-head.green-seagreen {
        background-color: #1BA39C;
        color: #FFF;
        .badge {
            background-color: #22cfc6;
            color: #FFF;
        }
        &.ext-1 .badge {
            background-color: #147772;
        }
        .list-date,
        .list-label {
            color: #c7f6f3;
        }
    }
    .list-todo {
        .list-count.green-seagreen,
        &.mt-list-head.green-seagreen {
            background-color: #1BA39C;
            color: #FFF;
        }
        .list-count.green-seagreen:hover {
            background-color: #178d87;
        }
        .list-todo-line.green-seagreen {
            border-color: #1BA39C;
        }
        .list-todo-item.green-seagreen {
            &:after {
                border-right-color: #1BA39C !important;
            }
            .list-toggle {
                background: #1BA39C;
                color: #FFF !important;
                >.badge {
                    color: #1BA39C;
                    background: #FFF;
                }
            }
        }
    }
    .list-default.mt-list-head.green-turquoise {
        background-color: #36D7B7;
        color: #FFF;
        .badge {
            background-color: #61dfc6;
            color: #FFF;
        }
        &.ext-1 .badge {
            background-color: #24b699;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.green-turquoise,
        &.mt-list-head.green-turquoise {
            background-color: #36D7B7;
            color: #FFF;
        }
        .list-count.green-turquoise:hover {
            background-color: #28cbab;
        }
        .list-todo-line.green-turquoise {
            border-color: #36D7B7;
        }
        .list-todo-item.green-turquoise {
            &:after {
                border-right-color: #36D7B7 !important;
            }
            .list-toggle {
                background: #36D7B7;
                color: #FFF !important;
                >.badge {
                    color: #36D7B7;
                    background: #FFF;
                }
            }
        }
    }
    .list-default.mt-list-head.green-haze {
        background-color: #44b6ae;
        color: #FFF;
        .badge {
            background-color: #67c6bf;
            color: #FFF;
        }
        &.ext-1 .badge {
            background-color: #36918b;
        }
        .list-date,
        .list-label {
            color: #fbfefd;
        }
    }
    .list-todo {
        .list-count.green-haze,
        &.mt-list-head.green-haze {
            background-color: #44b6ae;
            color: #FFF;
        }
        .list-count.green-haze:hover {
            background-color: #3da39c;
        }
        .list-todo-line.green-haze {
            border-color: #44b6ae;
        }
        .list-todo-item.green-haze {
            &:after {
                border-right-color: #44b6ae !important;
            }
            .list-toggle {
                background: #44b6ae;
                color: #FFF !important;
                >.badge {
                    color: #44b6ae;
                    background: #FFF;
                }
            }
        }
    }
    .list-default.mt-list-head.green-jungle {
        background-color: #26C281;
        color: #FFF;
        .badge {
            background-color: #41da9a;
            color: #FFF;
        }
        &.ext-1 .badge {
            background-color: #1e9765;
        }
        .list-date,
        .list-label {
            color: #ecfbf5;
        }
    }
    .list-todo {
        .list-count.green-jungle,
        &.mt-list-head.green-jungle {
            background-color: #26C281;
            color: #FFF;
        }
        .list-count.green-jungle:hover {
            background-color: #22ad73;
        }
        .list-todo-line.green-jungle {
            border-color: #26C281;
        }
        .list-todo-item.green-jungle {
            &:after {
                border-right-color: #26C281 !important;
            }
            .list-toggle {
                background: #26C281;
                color: #FFF !important;
                >.badge {
                    color: #26C281;
                    background: #FFF;
                }
            }
        }
    }
    .list-default.mt-list-head.green-soft {
        background-color: #3faba4;
        color: #FFF;
        .badge {
            background-color: #5bc2bc;
            color: #FFF;
        }
        &.ext-1 .badge {
            background-color: #318680;
        }
        .list-date,
        .list-label {
            color: #f0f9f9;
        }
    }
    .list-todo {
        .list-count.green-soft,
        &.mt-list-head.green-soft {
            background-color: #3faba4;
            color: #FFF;
        }
        .list-count.green-soft:hover {
            background-color: #389892;
        }
        .list-todo-line.green-soft {
            border-color: #3faba4;
        }
        .list-todo-item.green-soft {
            &:after {
                border-right-color: #3faba4 !important;
            }
            .list-toggle {
                background: #3faba4;
                color: #FFF !important;
                >.badge {
                    color: #3faba4;
                    background: #FFF;
                }
            }
        }
    }
    .list-default.mt-list-head.green-dark {
        background-color: #4DB3A2;
        color: #FFF;
        .badge {
            background-color: #71c2b5;
            color: #FFF;
        }
        &.ext-1 .badge {
            background-color: #3d9082;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.green-dark,
        &.mt-list-head.green-dark {
            background-color: #4DB3A2;
            color: #FFF;
        }
        .list-count.green-dark:hover {
            background-color: #45a292;
        }
        .list-todo-line.green-dark {
            border-color: #4DB3A2;
        }
        .list-todo-item.green-dark {
            &:after {
                border-right-color: #4DB3A2 !important;
            }
            .list-toggle {
                background: #4DB3A2;
                color: #FFF !important;
                >.badge {
                    color: #4DB3A2;
                    background: #FFF;
                }
            }
        }
    }
    .list-default.mt-list-head.green-sharp {
        background-color: #2ab4c0;
        color: #FFF;
        .badge {
            background-color: #46cbd7;
            color: #FFF;
        }
        &.ext-1 .badge {
            background-color: #218d96;
        }
        .list-date,
        .list-label {
            color: #eefafb;
        }
    }
    .list-todo {
        .list-count.green-sharp,
        &.mt-list-head.green-sharp {
            background-color: #2ab4c0;
            color: #FFF;
        }
        .list-count.green-sharp:hover {
            background-color: #25a0ab;
        }
        .list-todo-line.green-sharp {
            border-color: #2ab4c0;
        }
        .list-todo-item.green-sharp {
            &:after {
                border-right-color: #2ab4c0 !important;
            }
            .list-toggle {
                background: #2ab4c0;
                color: #FFF !important;
                >.badge {
                    color: #2ab4c0;
                    background: #FFF;
                }
            }
        }
    }
    .list-default.mt-list-head.green-steel {
        background-color: #29b4b6;
        color: #FFF;
        .badge {
            background-color: #3ed1d4;
            color: #FFF;
        }
        &.ext-1 .badge {
            background-color: #208b8c;
        }
        .list-date,
        .list-label {
            color: #e5f9f9;
        }
    }
    .list-todo {
        .list-count.green-steel,
        &.mt-list-head.green-steel {
            background-color: #29b4b6;
            color: #FFF;
        }
        .list-count.green-steel:hover {
            background-color: #249fa1;
        }
        .list-todo-line.green-steel {
            border-color: #29b4b6;
        }
        .list-todo-item.green-steel {
            &:after {
                border-right-color: #29b4b6 !important;
            }
            .list-toggle {
                background: #29b4b6;
                color: #FFF !important;
                >.badge {
                    color: #29b4b6;
                    background: #FFF;
                }
            }
        }
    }
    .list-default.mt-list-head.grey {
        background-color: #E5E5E5;
        color: #333;
        .badge {
            background-color: #fff;
            color: #333;
        }
        &.ext-1 .badge {
            background-color: #ccc;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.grey,
        &.mt-list-head.grey {
            background-color: #E5E5E5;
            color: #333;
        }
        .list-count.grey:hover {
            background-color: #d8d8d8;
        }
        .list-todo-line.grey {
            border-color: #E5E5E5;
        }
        .list-todo-item.grey {
            &:after {
                border-right-color: #E5E5E5 !important;
            }
            .list-toggle {
                background: #E5E5E5;
                color: #333 !important;
                >.badge {
                    color: #E5E5E5;
                    background: #333;
                }
            }
        }
    }
    .list-default.mt-list-head.grey-steel {
        background-color: #e9edef;
        color: #80898e;
        .badge {
            background-color: #fff;
            color: #80898e;
        }
        &.ext-1 .badge {
            background-color: #cbd5da;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.grey-steel,
        &.mt-list-head.grey-steel {
            background-color: #e9edef;
            color: #80898e;
        }
        .list-count.grey-steel:hover {
            background-color: #dae1e4;
        }
        .list-todo-line.grey-steel {
            border-color: #e9edef;
        }
        .list-todo-item.grey-steel {
            &:after {
                border-right-color: #e9edef !important;
            }
            .list-toggle {
                background: #e9edef;
                color: #80898e !important;
                >.badge {
                    color: #e9edef;
                    background: #80898e;
                }
            }
        }
    }
    .list-default.mt-list-head.grey-cararra {
        background-color: #fafafa;
        color: #333;
        .badge {
            background-color: #fff;
            color: #333;
        }
        &.ext-1 .badge {
            background-color: #e1e1e1;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.grey-cararra,
        &.mt-list-head.grey-cararra {
            background-color: #fafafa;
            color: #333;
        }
        .list-count.grey-cararra:hover {
            background-color: #ededed;
        }
        .list-todo-line.grey-cararra {
            border-color: #fafafa;
        }
        .list-todo-item.grey-cararra {
            &:after {
                border-right-color: #fafafa !important;
            }
            .list-toggle {
                background: #fafafa;
                color: #333 !important;
                >.badge {
                    color: #fafafa;
                    background: #333;
                }
            }
        }
    }
    .list-default.mt-list-head.grey-gallery {
        background-color: #555;
        color: #fff;
        .badge {
            background-color: #6f6f6f;
            color: #fff;
        }
        &.ext-1 .badge {
            background-color: #3c3c3c;
        }
        .list-date,
        .list-label {
            color: #d5d5d5;
        }
    }
    .list-todo {
        .list-count.grey-gallery,
        &.mt-list-head.grey-gallery {
            background-color: #555;
            color: #fff;
        }
        .list-count.grey-gallery:hover {
            background-color: #484848;
        }
        .list-todo-line.grey-gallery {
            border-color: #555;
        }
        .list-todo-item.grey-gallery {
            &:after {
                border-right-color: #555 !important;
            }
            .list-toggle {
                background: #555;
                color: #fff !important;
                >.badge {
                    color: #555;
                    background: #fff;
                }
            }
        }
    }
    .list-default.mt-list-head.grey-cascade {
        background-color: #95A5A6;
        color: #FFF;
        .badge {
            background-color: #b1bdbd;
            color: #FFF;
        }
        &.ext-1 .badge {
            background-color: #798d8f;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.grey-cascade,
        &.mt-list-head.grey-cascade {
            background-color: #95A5A6;
            color: #FFF;
        }
        .list-count.grey-cascade:hover {
            background-color: #87999a;
        }
        .list-todo-line.grey-cascade {
            border-color: #95A5A6;
        }
        .list-todo-item.grey-cascade {
            &:after {
                border-right-color: #95A5A6 !important;
            }
            .list-toggle {
                background: #95A5A6;
                color: #FFF !important;
                >.badge {
                    color: #95A5A6;
                    background: #FFF;
                }
            }
        }
    }
    .list-default.mt-list-head.grey-silver {
        background-color: #BFBFBF;
        color: #FAFCFB;
        .badge {
            background-color: #d9d9d9;
            color: #FAFCFB;
        }
        &.ext-1 .badge {
            background-color: #a6a6a6;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.grey-silver,
        &.mt-list-head.grey-silver {
            background-color: #BFBFBF;
            color: #FAFCFB;
        }
        .list-count.grey-silver:hover {
            background-color: #b2b2b2;
        }
        .list-todo-line.grey-silver {
            border-color: #BFBFBF;
        }
        .list-todo-item.grey-silver {
            &:after {
                border-right-color: #BFBFBF !important;
            }
            .list-toggle {
                background: #BFBFBF;
                color: #FAFCFB !important;
                >.badge {
                    color: #BFBFBF;
                    background: #FAFCFB;
                }
            }
        }
    }
    .list-default.mt-list-head.grey-salsa {
        background-color: #ACB5C3;
        color: #FAFCFB;
        .badge {
            background-color: #cacfd8;
            color: #FAFCFB;
        }
        &.ext-1 .badge {
            background-color: #8e9bae;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.grey-salsa,
        &.mt-list-head.grey-salsa {
            background-color: #ACB5C3;
            color: #FAFCFB;
        }
        .list-count.grey-salsa:hover {
            background-color: #9da8b8;
        }
        .list-todo-line.grey-salsa {
            border-color: #ACB5C3;
        }
        .list-todo-item.grey-salsa {
            &:after {
                border-right-color: #ACB5C3 !important;
            }
            .list-toggle {
                background: #ACB5C3;
                color: #FAFCFB !important;
                >.badge {
                    color: #ACB5C3;
                    background: #FAFCFB;
                }
            }
        }
    }
    .list-default.mt-list-head.grey-salt {
        background-color: #bfcad1;
        color: #FAFCFB;
        .badge {
            background-color: #dde3e6;
            color: #FAFCFB;
        }
        &.ext-1 .badge {
            background-color: #a1b1bc;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.grey-salt,
        &.mt-list-head.grey-salt {
            background-color: #bfcad1;
            color: #FAFCFB;
        }
        .list-count.grey-salt:hover {
            background-color: #b0bec6;
        }
        .list-todo-line.grey-salt {
            border-color: #bfcad1;
        }
        .list-todo-item.grey-salt {
            &:after {
                border-right-color: #bfcad1 !important;
            }
            .list-toggle {
                background: #bfcad1;
                color: #FAFCFB !important;
                >.badge {
                    color: #bfcad1;
                    background: #FAFCFB;
                }
            }
        }
    }
    .list-default.mt-list-head.grey-mint {
        background-color: #525e64;
        color: #FFF;
        .badge {
            background-color: #697880;
            color: #FFF;
        }
        &.ext-1 .badge {
            background-color: #3b4448;
        }
        .list-date,
        .list-label {
            color: #d7dcde;
        }
    }
    .list-todo {
        .list-count.grey-mint,
        &.mt-list-head.grey-mint {
            background-color: #525e64;
            color: #FFF;
        }
        .list-count.grey-mint:hover {
            background-color: #475156;
        }
        .list-todo-line.grey-mint {
            border-color: #525e64;
        }
        .list-todo-item.grey-mint {
            &:after {
                border-right-color: #525e64 !important;
            }
            .list-toggle {
                background: #525e64;
                color: #FFF !important;
                >.badge {
                    color: #525e64;
                    background: #FFF;
                }
            }
        }
    }
    .list-default.mt-list-head.red {
        background-color: #e7505a;
        color: #fff;
        .badge {
            background-color: #ed7d84;
            color: #fff;
        }
        &.ext-1 .badge {
            background-color: #e12330;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.red,
        &.mt-list-head.red {
            background-color: #e7505a;
            color: #fff;
        }
        .list-count.red:hover {
            background-color: #e43a45;
        }
        .list-todo-line.red {
            border-color: #e7505a;
        }
        .list-todo-item.red {
            &:after {
                border-right-color: #e7505a !important;
            }
            .list-toggle {
                background: #e7505a;
                color: #fff !important;
                >.badge {
                    color: #e7505a;
                    background: #fff;
                }
            }
        }
    }
    .list-default.mt-list-head.red-pink {
        background-color: #E08283;
        color: #fff;
        .badge {
            background-color: #eaabac;
            color: #fff;
        }
        &.ext-1 .badge {
            background-color: #d6595a;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.red-pink,
        &.mt-list-head.red-pink {
            background-color: #E08283;
            color: #fff;
        }
        .list-count.red-pink:hover {
            background-color: #db6e6f;
        }
        .list-todo-line.red-pink {
            border-color: #E08283;
        }
        .list-todo-item.red-pink {
            &:after {
                border-right-color: #E08283 !important;
            }
            .list-toggle {
                background: #E08283;
                color: #fff !important;
                >.badge {
                    color: #E08283;
                    background: #fff;
                }
            }
        }
    }
    .list-default.mt-list-head.red-sunglo {
        background-color: #E26A6A;
        color: #fff;
        .badge {
            background-color: #ea9595;
            color: #fff;
        }
        &.ext-1 .badge {
            background-color: #da3f3f;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.red-sunglo,
        &.mt-list-head.red-sunglo {
            background-color: #E26A6A;
            color: #fff;
        }
        .list-count.red-sunglo:hover {
            background-color: #de5555;
        }
        .list-todo-line.red-sunglo {
            border-color: #E26A6A;
        }
        .list-todo-item.red-sunglo {
            &:after {
                border-right-color: #E26A6A !important;
            }
            .list-toggle {
                background: #E26A6A;
                color: #fff !important;
                >.badge {
                    color: #E26A6A;
                    background: #fff;
                }
            }
        }
    }
    .list-default.mt-list-head.red-intense {
        background-color: #e35b5a;
        color: #fff;
        .badge {
            background-color: #ea8686;
            color: #fff;
        }
        &.ext-1 .badge {
            background-color: #dc302e;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.red-intense,
        &.mt-list-head.red-intense {
            background-color: #e35b5a;
            color: #fff;
        }
        .list-count.red-intense:hover {
            background-color: #df4544;
        }
        .list-todo-line.red-intense {
            border-color: #e35b5a;
        }
        .list-todo-item.red-intense {
            &:after {
                border-right-color: #e35b5a !important;
            }
            .list-toggle {
                background: #e35b5a;
                color: #fff !important;
                >.badge {
                    color: #e35b5a;
                    background: #fff;
                }
            }
        }
    }
    .list-default.mt-list-head.red-thunderbird {
        background-color: #D91E18;
        color: #fff;
        .badge {
            background-color: #e9403b;
            color: #fff;
        }
        &.ext-1 .badge {
            background-color: #ab1813;
        }
        .list-date,
        .list-label {
            color: #fef3f2;
        }
    }
    .list-todo {
        .list-count.red-thunderbird,
        &.mt-list-head.red-thunderbird {
            background-color: #D91E18;
            color: #fff;
        }
        .list-count.red-thunderbird:hover {
            background-color: #c21b15;
        }
        .list-todo-line.red-thunderbird {
            border-color: #D91E18;
        }
        .list-todo-item.red-thunderbird {
            &:after {
                border-right-color: #D91E18 !important;
            }
            .list-toggle {
                background: #D91E18;
                color: #fff !important;
                >.badge {
                    color: #D91E18;
                    background: #fff;
                }
            }
        }
    }
    .list-default.mt-list-head.red-flamingo {
        background-color: #EF4836;
        color: #fff;
        .badge {
            background-color: #f37365;
            color: #fff;
        }
        &.ext-1 .badge {
            background-color: #e02612;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.red-flamingo,
        &.mt-list-head.red-flamingo {
            background-color: #EF4836;
            color: #fff;
        }
        .list-count.red-flamingo:hover {
            background-color: #ed321e;
        }
        .list-todo-line.red-flamingo {
            border-color: #EF4836;
        }
        .list-todo-item.red-flamingo {
            &:after {
                border-right-color: #EF4836 !important;
            }
            .list-toggle {
                background: #EF4836;
                color: #fff !important;
                >.badge {
                    color: #EF4836;
                    background: #fff;
                }
            }
        }
    }
    .list-default.mt-list-head.red-soft {
        background-color: #d05454;
        color: #fff;
        .badge {
            background-color: #db7c7c;
            color: #fff;
        }
        &.ext-1 .badge {
            background-color: #bd3434;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.red-soft,
        &.mt-list-head.red-soft {
            background-color: #d05454;
            color: #fff;
        }
        .list-count.red-soft:hover {
            background-color: #cb4040;
        }
        .list-todo-line.red-soft {
            border-color: #d05454;
        }
        .list-todo-item.red-soft {
            &:after {
                border-right-color: #d05454 !important;
            }
            .list-toggle {
                background: #d05454;
                color: #fff !important;
                >.badge {
                    color: #d05454;
                    background: #fff;
                }
            }
        }
    }
    .list-default.mt-list-head.red-haze {
        background-color: #f36a5a;
        color: #fff;
        .badge {
            background-color: #f6958a;
            color: #fff;
        }
        &.ext-1 .badge {
            background-color: #f03f2a;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.red-haze,
        &.mt-list-head.red-haze {
            background-color: #f36a5a;
            color: #fff;
        }
        .list-count.red-haze:hover {
            background-color: #f15542;
        }
        .list-todo-line.red-haze {
            border-color: #f36a5a;
        }
        .list-todo-item.red-haze {
            &:after {
                border-right-color: #f36a5a !important;
            }
            .list-toggle {
                background: #f36a5a;
                color: #fff !important;
                >.badge {
                    color: #f36a5a;
                    background: #fff;
                }
            }
        }
    }
    .list-default.mt-list-head.red-mint {
        background-color: #e43a45;
        color: #fff;
        .badge {
            background-color: #ea676f;
            color: #fff;
        }
        &.ext-1 .badge {
            background-color: #cf1c28;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.red-mint,
        &.mt-list-head.red-mint {
            background-color: #e43a45;
            color: #fff;
        }
        .list-count.red-mint:hover {
            background-color: #e12430;
        }
        .list-todo-line.red-mint {
            border-color: #e43a45;
        }
        .list-todo-item.red-mint {
            &:after {
                border-right-color: #e43a45 !important;
            }
            .list-toggle {
                background: #e43a45;
                color: #fff !important;
                >.badge {
                    color: #e43a45;
                    background: #fff;
                }
            }
        }
    }
    .list-default.mt-list-head.yellow {
        background-color: #c49f47;
        color: #fff;
        .badge {
            background-color: #d0b36e;
            color: #fff;
        }
        &.ext-1 .badge {
            background-color: #a48334;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.yellow,
        &.mt-list-head.yellow {
            background-color: #c49f47;
            color: #fff;
        }
        .list-count.yellow:hover {
            background-color: #b7923b;
        }
        .list-todo-line.yellow {
            border-color: #c49f47;
        }
        .list-todo-item.yellow {
            &:after {
                border-right-color: #c49f47 !important;
            }
            .list-toggle {
                background: #c49f47;
                color: #fff !important;
                >.badge {
                    color: #c49f47;
                    background: #fff;
                }
            }
        }
    }
    .list-default.mt-list-head.yellow-gold {
        background-color: #E87E04;
        color: #fff;
        .badge {
            background-color: #fb9724;
            color: #fff;
        }
        &.ext-1 .badge {
            background-color: #b66303;
        }
        .list-date,
        .list-label {
            color: #fff6ec;
        }
    }
    .list-todo {
        .list-count.yellow-gold,
        &.mt-list-head.yellow-gold {
            background-color: #E87E04;
            color: #fff;
        }
        .list-count.yellow-gold:hover {
            background-color: #cf7004;
        }
        .list-todo-line.yellow-gold {
            border-color: #E87E04;
        }
        .list-todo-item.yellow-gold {
            &:after {
                border-right-color: #E87E04 !important;
            }
            .list-toggle {
                background: #E87E04;
                color: #fff !important;
                >.badge {
                    color: #E87E04;
                    background: #fff;
                }
            }
        }
    }
    .list-default.mt-list-head.yellow-casablanca {
        background-color: #f2784b;
        color: #fff;
        .badge {
            background-color: #f59c7b;
            color: #fff;
        }
        &.ext-1 .badge {
            background-color: #ef541b;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.yellow-casablanca,
        &.mt-list-head.yellow-casablanca {
            background-color: #f2784b;
            color: #fff;
        }
        .list-count.yellow-casablanca:hover {
            background-color: #f06633;
        }
        .list-todo-line.yellow-casablanca {
            border-color: #f2784b;
        }
        .list-todo-item.yellow-casablanca {
            &:after {
                border-right-color: #f2784b !important;
            }
            .list-toggle {
                background: #f2784b;
                color: #fff !important;
                >.badge {
                    color: #f2784b;
                    background: #fff;
                }
            }
        }
    }
    .list-default.mt-list-head.yellow-crusta {
        background-color: #f3c200;
        color: #fff;
        .badge {
            background-color: #ffd327;
            color: #fff;
        }
        &.ext-1 .badge {
            background-color: #c09900;
        }
        .list-date,
        .list-label {
            color: #fffdf3;
        }
    }
    .list-todo {
        .list-count.yellow-crusta,
        &.mt-list-head.yellow-crusta {
            background-color: #f3c200;
            color: #fff;
        }
        .list-count.yellow-crusta:hover {
            background-color: #daae00;
        }
        .list-todo-line.yellow-crusta {
            border-color: #f3c200;
        }
        .list-todo-item.yellow-crusta {
            &:after {
                border-right-color: #f3c200 !important;
            }
            .list-toggle {
                background: #f3c200;
                color: #fff !important;
                >.badge {
                    color: #f3c200;
                    background: #fff;
                }
            }
        }
    }
    .list-default.mt-list-head.yellow-lemon {
        background-color: #F7CA18;
        color: #fff;
        .badge {
            background-color: #f9d549;
            color: #fff;
        }
        &.ext-1 .badge {
            background-color: #d5ab07;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.yellow-lemon,
        &.mt-list-head.yellow-lemon {
            background-color: #F7CA18;
            color: #fff;
        }
        .list-count.yellow-lemon:hover {
            background-color: #edbf08;
        }
        .list-todo-line.yellow-lemon {
            border-color: #F7CA18;
        }
        .list-todo-item.yellow-lemon {
            &:after {
                border-right-color: #F7CA18 !important;
            }
            .list-toggle {
                background: #F7CA18;
                color: #fff !important;
                >.badge {
                    color: #F7CA18;
                    background: #fff;
                }
            }
        }
    }
    .list-default.mt-list-head.yellow-saffron {
        background-color: #F4D03F;
        color: #fff;
        .badge {
            background-color: #f7dc6f;
            color: #fff;
        }
        &.ext-1 .badge {
            background-color: #f1c40f;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.yellow-saffron,
        &.mt-list-head.yellow-saffron {
            background-color: #F4D03F;
            color: #fff;
        }
        .list-count.yellow-saffron:hover {
            background-color: #f3ca27;
        }
        .list-todo-line.yellow-saffron {
            border-color: #F4D03F;
        }
        .list-todo-item.yellow-saffron {
            &:after {
                border-right-color: #F4D03F !important;
            }
            .list-toggle {
                background: #F4D03F;
                color: #fff !important;
                >.badge {
                    color: #F4D03F;
                    background: #fff;
                }
            }
        }
    }
    .list-default.mt-list-head.yellow-soft {
        background-color: #c8d046;
        color: #fff;
        .badge {
            background-color: #d4da6f;
            color: #fff;
        }
        &.ext-1 .badge {
            background-color: #adb52e;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.yellow-soft,
        &.mt-list-head.yellow-soft {
            background-color: #c8d046;
            color: #fff;
        }
        .list-count.yellow-soft:hover {
            background-color: #c1c933;
        }
        .list-todo-line.yellow-soft {
            border-color: #c8d046;
        }
        .list-todo-item.yellow-soft {
            &:after {
                border-right-color: #c8d046 !important;
            }
            .list-toggle {
                background: #c8d046;
                color: #fff !important;
                >.badge {
                    color: #c8d046;
                    background: #fff;
                }
            }
        }
    }
    .list-default.mt-list-head.yellow-haze {
        background-color: #c5bf66;
        color: #fff;
        .badge {
            background-color: #d3ce8b;
            color: #fff;
        }
        &.ext-1 .badge {
            background-color: #b4ad44;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.yellow-haze,
        &.mt-list-head.yellow-haze {
            background-color: #c5bf66;
            color: #fff;
        }
        .list-count.yellow-haze:hover {
            background-color: #beb754;
        }
        .list-todo-line.yellow-haze {
            border-color: #c5bf66;
        }
        .list-todo-item.yellow-haze {
            &:after {
                border-right-color: #c5bf66 !important;
            }
            .list-toggle {
                background: #c5bf66;
                color: #fff !important;
                >.badge {
                    color: #c5bf66;
                    background: #fff;
                }
            }
        }
    }
    .list-default.mt-list-head.yellow-mint {
        background-color: #c5b96b;
        color: #fff;
        .badge {
            background-color: #d3ca90;
            color: #fff;
        }
        &.ext-1 .badge {
            background-color: #b6a747;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.yellow-mint,
        &.mt-list-head.yellow-mint {
            background-color: #c5b96b;
            color: #fff;
        }
        .list-count.yellow-mint:hover {
            background-color: #beb059;
        }
        .list-todo-line.yellow-mint {
            border-color: #c5b96b;
        }
        .list-todo-item.yellow-mint {
            &:after {
                border-right-color: #c5b96b !important;
            }
            .list-toggle {
                background: #c5b96b;
                color: #fff !important;
                >.badge {
                    color: #c5b96b;
                    background: #fff;
                }
            }
        }
    }
    .list-default.mt-list-head.purple {
        background-color: #8E44AD;
        color: #fff;
        .badge {
            background-color: #a563c1;
            color: #fff;
        }
        &.ext-1 .badge {
            background-color: #703688;
        }
        .list-date,
        .list-label {
            color: #f9f5fb;
        }
    }
    .list-todo {
        .list-count.purple,
        &.mt-list-head.purple {
            background-color: #8E44AD;
            color: #fff;
        }
        .list-count.purple:hover {
            background-color: #7f3d9b;
        }
        .list-todo-line.purple {
            border-color: #8E44AD;
        }
        .list-todo-item.purple {
            &:after {
                border-right-color: #8E44AD !important;
            }
            .list-toggle {
                background: #8E44AD;
                color: #fff !important;
                >.badge {
                    color: #8E44AD;
                    background: #fff;
                }
            }
        }
    }
    .list-default.mt-list-head.purple-plum {
        background-color: #8775a7;
        color: #fff;
        .badge {
            background-color: #a294bb;
            color: #fff;
        }
        &.ext-1 .badge {
            background-color: #6d5b8e;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.purple-plum,
        &.mt-list-head.purple-plum {
            background-color: #8775a7;
            color: #fff;
        }
        .list-count.purple-plum:hover {
            background-color: #79659d;
        }
        .list-todo-line.purple-plum {
            border-color: #8775a7;
        }
        .list-todo-item.purple-plum {
            &:after {
                border-right-color: #8775a7 !important;
            }
            .list-toggle {
                background: #8775a7;
                color: #fff !important;
                >.badge {
                    color: #8775a7;
                    background: #fff;
                }
            }
        }
    }
    .list-default.mt-list-head.purple-medium {
        background-color: #BF55EC;
        color: #fff;
        .badge {
            background-color: #d083f1;
            color: #fff;
        }
        &.ext-1 .badge {
            background-color: #ae27e7;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.purple-medium,
        &.mt-list-head.purple-medium {
            background-color: #BF55EC;
            color: #fff;
        }
        .list-count.purple-medium:hover {
            background-color: #b63ee9;
        }
        .list-todo-line.purple-medium {
            border-color: #BF55EC;
        }
        .list-todo-item.purple-medium {
            &:after {
                border-right-color: #BF55EC !important;
            }
            .list-toggle {
                background: #BF55EC;
                color: #fff !important;
                >.badge {
                    color: #BF55EC;
                    background: #fff;
                }
            }
        }
    }
    .list-default.mt-list-head.purple-studio {
        background-color: #8E44AD;
        color: #fff;
        .badge {
            background-color: #a563c1;
            color: #fff;
        }
        &.ext-1 .badge {
            background-color: #703688;
        }
        .list-date,
        .list-label {
            color: #f9f5fb;
        }
    }
    .list-todo {
        .list-count.purple-studio,
        &.mt-list-head.purple-studio {
            background-color: #8E44AD;
            color: #fff;
        }
        .list-count.purple-studio:hover {
            background-color: #7f3d9b;
        }
        .list-todo-line.purple-studio {
            border-color: #8E44AD;
        }
        .list-todo-item.purple-studio {
            &:after {
                border-right-color: #8E44AD !important;
            }
            .list-toggle {
                background: #8E44AD;
                color: #fff !important;
                >.badge {
                    color: #8E44AD;
                    background: #fff;
                }
            }
        }
    }
    .list-default.mt-list-head.purple-wisteria {
        background-color: #9B59B6;
        color: #fff;
        .badge {
            background-color: #b07cc6;
            color: #fff;
        }
        &.ext-1 .badge {
            background-color: #804399;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.purple-wisteria,
        &.mt-list-head.purple-wisteria {
            background-color: #9B59B6;
            color: #fff;
        }
        .list-count.purple-wisteria:hover {
            background-color: #8f4bab;
        }
        .list-todo-line.purple-wisteria {
            border-color: #9B59B6;
        }
        .list-todo-item.purple-wisteria {
            &:after {
                border-right-color: #9B59B6 !important;
            }
            .list-toggle {
                background: #9B59B6;
                color: #fff !important;
                >.badge {
                    color: #9B59B6;
                    background: #fff;
                }
            }
        }
    }
    .list-default.mt-list-head.purple-seance {
        background-color: #9A12B3;
        color: #fff;
        .badge {
            background-color: #c217e1;
            color: #fff;
        }
        &.ext-1 .badge {
            background-color: #720d85;
        }
        .list-date,
        .list-label {
            color: #f2cafa;
        }
    }
    .list-todo {
        .list-count.purple-seance,
        &.mt-list-head.purple-seance {
            background-color: #9A12B3;
            color: #fff;
        }
        .list-count.purple-seance:hover {
            background-color: #86109c;
        }
        .list-todo-line.purple-seance {
            border-color: #9A12B3;
        }
        .list-todo-item.purple-seance {
            &:after {
                border-right-color: #9A12B3 !important;
            }
            .list-toggle {
                background: #9A12B3;
                color: #fff !important;
                >.badge {
                    color: #9A12B3;
                    background: #fff;
                }
            }
        }
    }
    .list-default.mt-list-head.purple-intense {
        background-color: #8775a7;
        color: #fff;
        .badge {
            background-color: #a294bb;
            color: #fff;
        }
        &.ext-1 .badge {
            background-color: #6d5b8e;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.purple-intense,
        &.mt-list-head.purple-intense {
            background-color: #8775a7;
            color: #fff;
        }
        .list-count.purple-intense:hover {
            background-color: #79659d;
        }
        .list-todo-line.purple-intense {
            border-color: #8775a7;
        }
        .list-todo-item.purple-intense {
            &:after {
                border-right-color: #8775a7 !important;
            }
            .list-toggle {
                background: #8775a7;
                color: #fff !important;
                >.badge {
                    color: #8775a7;
                    background: #fff;
                }
            }
        }
    }
    .list-default.mt-list-head.purple-sharp {
        background-color: #796799;
        color: #fff;
        .badge {
            background-color: #9486ad;
            color: #fff;
        }
        &.ext-1 .badge {
            background-color: #61527b;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.purple-sharp,
        &.mt-list-head.purple-sharp {
            background-color: #796799;
            color: #fff;
        }
        .list-count.purple-sharp:hover {
            background-color: #6d5d8a;
        }
        .list-todo-line.purple-sharp {
            border-color: #796799;
        }
        .list-todo-item.purple-sharp {
            &:after {
                border-right-color: #796799 !important;
            }
            .list-toggle {
                background: #796799;
                color: #fff !important;
                >.badge {
                    color: #796799;
                    background: #fff;
                }
            }
        }
    }
    .list-default.mt-list-head.purple-soft {
        background-color: #8877a9;
        color: #fff;
        .badge {
            background-color: #a396bd;
            color: #fff;
        }
        &.ext-1 .badge {
            background-color: #6e5c91;
        }
        .list-date,
        .list-label {
            color: #fff;
        }
    }
    .list-todo {
        .list-count.purple-soft,
        &.mt-list-head.purple-soft {
            background-color: #8877a9;
            color: #fff;
        }
        .list-count.purple-soft:hover {
            background-color: #7a679f;
        }
        .list-todo-line.purple-soft {
            border-color: #8877a9;
        }
        .list-todo-item.purple-soft {
            &:after {
                border-right-color: #8877a9 !important;
            }
            .list-toggle {
                background: #8877a9;
                color: #fff !important;
                >.badge {
                    color: #8877a9;
                    background: #fff;
                }
            }
        }
    }
    .list-todo-item {
        &.white {
            &:after {
                border-right-color: #fff;
            }
            .list-toggle {
                background: #fff;
            }
        }
        &.default {
            &:after {
                border-right-color: #e1e5ec;
            }
            .list-toggle {
                background: #e1e5ec;
            }
        }
        &.dark {
            &:after {
                border-right-color: #2f353b;
            }
            .list-toggle {
                background: #2f353b;
            }
        }
        &.blue {
            &:after {
                border-right-color: #3598dc;
            }
            .list-toggle {
                background: #3598dc;
            }
        }
        &.blue-madison {
            &:after {
                border-right-color: #578ebe;
            }
            .list-toggle {
                background: #578ebe;
            }
        }
        &.blue-chambray {
            &:after {
                border-right-color: #2C3E50;
            }
            .list-toggle {
                background: #2C3E50;
            }
        }
        &.blue-ebonyclay {
            &:after {
                border-right-color: #22313F;
            }
            .list-toggle {
                background: #22313F;
            }
        }
        &.blue-hoki {
            &:after {
                border-right-color: #67809F;
            }
            .list-toggle {
                background: #67809F;
            }
        }
        &.blue-steel {
            &:after {
                border-right-color: #4B77BE;
            }
            .list-toggle {
                background: #4B77BE;
            }
        }
        &.blue-soft {
            &:after {
                border-right-color: #4c87b9;
            }
            .list-toggle {
                background: #4c87b9;
            }
        }
        &.blue-dark {
            &:after {
                border-right-color: #5e738b;
            }
            .list-toggle {
                background: #5e738b;
            }
        }
        &.blue-sharp {
            &:after {
                border-right-color: #5C9BD1;
            }
            .list-toggle {
                background: #5C9BD1;
            }
        }
        &.blue-oleo {
            &:after {
                border-right-color: #94A0B2;
            }
            .list-toggle {
                background: #94A0B2;
            }
        }
        &.green {
            &:after {
                border-right-color: #32c5d2;
            }
            .list-toggle {
                background: #32c5d2;
            }
        }
        &.green-meadow {
            &:after {
                border-right-color: #1BBC9B;
            }
            .list-toggle {
                background: #1BBC9B;
            }
        }
        &.green-seagreen {
            &:after {
                border-right-color: #1BA39C;
            }
            .list-toggle {
                background: #1BA39C;
            }
        }
        &.green-turquoise {
            &:after {
                border-right-color: #36D7B7;
            }
            .list-toggle {
                background: #36D7B7;
            }
        }
        &.green-haze {
            &:after {
                border-right-color: #44b6ae;
            }
            .list-toggle {
                background: #44b6ae;
            }
        }
        &.green-jungle {
            &:after {
                border-right-color: #26C281;
            }
            .list-toggle {
                background: #26C281;
            }
        }
        &.green-soft {
            &:after {
                border-right-color: #3faba4;
            }
            .list-toggle {
                background: #3faba4;
            }
        }
        &.green-dark {
            &:after {
                border-right-color: #4DB3A2;
            }
            .list-toggle {
                background: #4DB3A2;
            }
        }
        &.green-sharp {
            &:after {
                border-right-color: #2ab4c0;
            }
            .list-toggle {
                background: #2ab4c0;
            }
        }
        &.green-steel {
            &:after {
                border-right-color: #29b4b6;
            }
            .list-toggle {
                background: #29b4b6;
            }
        }
        &.grey {
            &:after {
                border-right-color: #E5E5E5;
            }
            .list-toggle {
                background: #E5E5E5;
            }
        }
        &.grey-steel {
            &:after {
                border-right-color: #e9edef;
            }
            .list-toggle {
                background: #e9edef;
            }
        }
        &.grey-cararra {
            &:after {
                border-right-color: #fafafa;
            }
            .list-toggle {
                background: #fafafa;
            }
        }
        &.grey-gallery {
            &:after {
                border-right-color: #555;
            }
            .list-toggle {
                background: #555;
            }
        }
        &.grey-cascade {
            &:after {
                border-right-color: #95A5A6;
            }
            .list-toggle {
                background: #95A5A6;
            }
        }
        &.grey-silver {
            &:after {
                border-right-color: #BFBFBF;
            }
            .list-toggle {
                background: #BFBFBF;
            }
        }
        &.grey-salsa {
            &:after {
                border-right-color: #ACB5C3;
            }
            .list-toggle {
                background: #ACB5C3;
            }
        }
        &.grey-salt {
            &:after {
                border-right-color: #bfcad1;
            }
            .list-toggle {
                background: #bfcad1;
            }
        }
        &.grey-mint {
            &:after {
                border-right-color: #525e64;
            }
            .list-toggle {
                background: #525e64;
            }
        }
        &.red {
            &:after {
                border-right-color: #e7505a;
            }
            .list-toggle {
                background: #e7505a;
            }
        }
        &.red-pink {
            &:after {
                border-right-color: #E08283;
            }
            .list-toggle {
                background: #E08283;
            }
        }
        &.red-sunglo {
            &:after {
                border-right-color: #E26A6A;
            }
            .list-toggle {
                background: #E26A6A;
            }
        }
        &.red-intense {
            &:after {
                border-right-color: #e35b5a;
            }
            .list-toggle {
                background: #e35b5a;
            }
        }
        &.red-thunderbird {
            &:after {
                border-right-color: #D91E18;
            }
            .list-toggle {
                background: #D91E18;
            }
        }
        &.red-flamingo {
            &:after {
                border-right-color: #EF4836;
            }
            .list-toggle {
                background: #EF4836;
            }
        }
        &.red-soft {
            &:after {
                border-right-color: #d05454;
            }
            .list-toggle {
                background: #d05454;
            }
        }
        &.red-haze {
            &:after {
                border-right-color: #f36a5a;
            }
            .list-toggle {
                background: #f36a5a;
            }
        }
        &.red-mint {
            &:after {
                border-right-color: #e43a45;
            }
            .list-toggle {
                background: #e43a45;
            }
        }
        &.yellow {
            &:after {
                border-right-color: #c49f47;
            }
            .list-toggle {
                background: #c49f47;
            }
        }
        &.yellow-gold {
            &:after {
                border-right-color: #E87E04;
            }
            .list-toggle {
                background: #E87E04;
            }
        }
        &.yellow-casablanca {
            &:after {
                border-right-color: #f2784b;
            }
            .list-toggle {
                background: #f2784b;
            }
        }
        &.yellow-crusta {
            &:after {
                border-right-color: #f3c200;
            }
            .list-toggle {
                background: #f3c200;
            }
        }
        &.yellow-lemon {
            &:after {
                border-right-color: #F7CA18;
            }
            .list-toggle {
                background: #F7CA18;
            }
        }
        &.yellow-saffron {
            &:after {
                border-right-color: #F4D03F;
            }
            .list-toggle {
                background: #F4D03F;
            }
        }
        &.yellow-soft {
            &:after {
                border-right-color: #c8d046;
            }
            .list-toggle {
                background: #c8d046;
            }
        }
        &.yellow-haze {
            &:after {
                border-right-color: #c5bf66;
            }
            .list-toggle {
                background: #c5bf66;
            }
        }
        &.yellow-mint {
            &:after {
                border-right-color: #c5b96b;
            }
            .list-toggle {
                background: #c5b96b;
            }
        }
        &.purple {
            &:after {
                border-right-color: #8E44AD;
            }
            .list-toggle {
                background: #8E44AD;
            }
        }
        &.purple-plum {
            &:after {
                border-right-color: #8775a7;
            }
            .list-toggle {
                background: #8775a7;
            }
        }
        &.purple-medium {
            &:after {
                border-right-color: #BF55EC;
            }
            .list-toggle {
                background: #BF55EC;
            }
        }
        &.purple-studio {
            &:after {
                border-right-color: #8E44AD;
            }
            .list-toggle {
                background: #8E44AD;
            }
        }
        &.purple-wisteria {
            &:after {
                border-right-color: #9B59B6;
            }
            .list-toggle {
                background: #9B59B6;
            }
        }
        &.purple-seance {
            &:after {
                border-right-color: #9A12B3;
            }
            .list-toggle {
                background: #9A12B3;
            }
        }
        &.purple-intense {
            &:after {
                border-right-color: #8775a7;
            }
            .list-toggle {
                background: #8775a7;
            }
        }
        &.purple-sharp {
            &:after {
                border-right-color: #796799;
            }
            .list-toggle {
                background: #796799;
            }
        }
        &.purple-soft {
            &:after {
                border-right-color: #8877a9;
            }
            .list-toggle {
                background: #8877a9;
            }
        }
    }
}

.mt-element-ribbon {
    .ribbon {
        background-color: #bac3d0;
        color: #384353;
        &.ribbon-color-default {
            background-color: #bac3d0;
            color: #384353;
            >.ribbon-sub {
                background-color: #bac3d0;
                color: #384353;
            }
        }
        >.ribbon-sub {
            background-color: #bac3d0;
            color: #384353;
        }
    }
    position: relative;
    margin-bottom: 30px;
    .ribbon-content {
        margin: 0;
        padding: 25px;
        clear: both;
        &.no-padding {
            padding-top: 0;
        }
    }
    .ribbon {
        padding: .5em 1em;
        z-index: 5;
        float: left;
        margin: 10px 0 0 -2px;
        clear: left;
        position: relative;
        &.ribbon-right {
            float: right;
            clear: right;
            margin: 10px -2px 0 0;
        }
        &.ribbon-vertical-left {
            clear: none;
            margin: -2px 0 0 10px;
            padding-top: 1em;
            padding-bottom: 1em;
            width: 41px;
            text-align: center;
        }
        &.ribbon-vertical-right {
            clear: none;
            float: right;
            margin: -2px 10px 0 0;
            padding-top: 1em;
            padding-bottom: 1em;
            width: 41px;
            text-align: center;
        }
        &.ribbon-shadow {
            box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.4);
            &.ribbon-right,
            &.ribbon-vertical-right {
                box-shadow: (-2px) 2px 7px rgba(0, 0, 0, 0.4);
            }
        }
        &.ribbon-round {
            border-top-right-radius: 5px !important;
            border-bottom-right-radius: 5px !important;
            &.ribbon-right {
                border-radius: 5px 0 0 5px !important;
            }
            &.ribbon-vertical-left,
            &.ribbon-vertical-right {
                border-radius: 0 0 5px 5px !important;
            }
        }
        &.ribbon-border:after {
            border: 1px solid;
            content: '';
            position: absolute;
            top: 5px;
            bottom: 5px;
            left: 5px;
            right: 5px;
        }
        &.ribbon-border-vert:after {
            border-top: none;
            border-bottom: none;
            border-left: 1px solid;
            border-right: 1px solid;
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 5px;
            right: 5px;
        }
        &.ribbon-border-hor:after {
            border-top: 1px solid;
            border-bottom: 1px solid;
            border-left: none;
            border-right: none;
            content: '';
            position: absolute;
            top: 5px;
            bottom: 5px;
            left: 0;
            right: 0;
        }
        &.ribbon-border-dash:after {
            border: 1px dashed;
            content: '';
            position: absolute;
            top: 5px;
            bottom: 5px;
            left: 5px;
            right: 5px;
        }
        &.ribbon-border-dash-vert:after {
            border-top: none;
            border-bottom: none;
            border-left: 1px solid;
            border-right: 1px solid;
            border-left-style: dashed;
            border-right-style: dashed;
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 5px;
            right: 5px;
        }
        &.ribbon-border-dash-hor:after {
            border-top: 1px solid;
            border-bottom: 1px solid;
            border-left: none;
            border-right: none;
            border-top-style: dashed;
            border-bottom-style: dashed;
            content: '';
            position: absolute;
            top: 5px;
            bottom: 5px;
            left: 0;
            right: 0;
        }
        &.ribbon-clip {
            left: -10px;
            margin-left: 0;
            &.ribbon-right {
                left: auto;
                right: -10px;
                margin-right: 0;
            }
        }
        >.ribbon-sub {
            z-index: -1;
            position: absolute;
            padding: 0;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            &.ribbon-clip {
                &:after {
                    content: '';
                    position: absolute;
                    border-style: solid;
                    border-color: transparent !important;
                    bottom: -10px;
                }
                &:before {
                    content: '';
                    position: absolute;
                    border-style: solid;
                    border-color: transparent !important;
                    bottom: -10px;
                    border-width: 0 10px 10px 0;
                    border-right-color: #222 !important;
                    left: 0;
                }
                &.ribbon-right {
                    &:after {
                        content: '';
                        position: absolute;
                        border-style: solid;
                        border-color: transparent;
                        bottom: -10px;
                    }
                    &:before {
                        content: '';
                        position: absolute;
                        border-style: solid;
                        border-color: transparent;
                        bottom: -10px;
                        border-right-color: transparent !important;
                    }
                    &:after {
                        border-width: 0 0 10px 10px;
                        border-left-color: #222 !important;
                        right: 0;
                    }
                }
            }
            &.ribbon-bookmark:after {
                border-left: 21px solid;
                border-right: 20px solid;
                border-bottom: 1em solid transparent !important;
                bottom: -1em;
                content: '';
                height: 0;
                left: 0;
                position: absolute;
                width: 0;
            }
        }
        &:after {
            border-color: #62748f;
        }
        >.ribbon-sub:after {
            border-color: #62748f #bac3d0;
        }
        &.ribbon-color-default {
            &:after {
                border-color: #9ca8bb;
            }
            >.ribbon-sub:after {
                border-color: #62748f #bac3d0;
            }
        }
        &.ribbon-color-primary {
            background-color: #337ab7;
            color: #fff;
            &:after {
                border-color: #286090;
            }
            >.ribbon-sub {
                background-color: #337ab7;
                color: #000;
                &:after {
                    border-color: #122b40 #337ab7;
                }
            }
        }
        &.ribbon-color-info {
            background-color: #659be0;
            color: #fff;
            &:after {
                border-color: #3a80d7;
            }
            >.ribbon-sub {
                background-color: #659be0;
                color: #0c203a;
                &:after {
                    border-color: #1d4f8e #659be0;
                }
            }
        }
        &.ribbon-color-success {
            background-color: #36c6d3;
            color: #fff;
            &:after {
                border-color: #27a4b0;
            }
            >.ribbon-sub {
                background-color: #36c6d3;
                color: #020808;
                &:after {
                    border-color: #14565c #36c6d3;
                }
            }
        }
        &.ribbon-color-danger {
            background-color: #ed6b75;
            color: #fff;
            &:after {
                border-color: #e73d4a;
            }
            >.ribbon-sub {
                background-color: #ed6b75;
                color: #4f0a0f;
            }
        }
        &.ribbon-color-warning {
            background-color: #F1C40F;
            color: #010100;
            >.ribbon-sub {
                background-color: #F1C40F;
                color: #010100;
            }
        }
        &.ribbon-color-danger>.ribbon-sub:after {
            border-color: #a91520 #ed6b75;
        }
        &.ribbon-color-warning {
            &:after {
                border-color: #c29d0b;
            }
            >.ribbon-sub:after {
                border-color: #614f06 #F1C40F;
            }
        }
    }
}

.mt-element-card {
    .mt-card-item {
        border: 1px solid #e7ecf1;
        position: relative;
        margin-bottom: 30px;
        .mt-card-avatar {
            margin-bottom: 15px;
        }
        .mt-card-content {
            text-align: center;
            .mt-card-name {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 10px;
            }
            .mt-card-desc {
                font-size: 14px;
                margin: 0 0 10px;
            }
            .mt-card-social>ul {
                padding: 0;
                margin-bottom: 10px;
                >li {
                    list-style: none;
                    display: inline-block;
                    margin: 0 3px;
                    >a {
                        color: #000;
                        font-size: 18px;
                        &.mt-card-btn {
                            color: #fff;
                            &:hover {
                                color: #36c6d3;
                            }
                        }
                        &:hover {
                            color: #F1C40F;
                        }
                    }
                }
            }
        }
    }
    &.mt-card-round .mt-card-item {
        padding: 40px 40px 10px;
        .mt-card-avatar {
            border-radius: 50% !important;
            -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
            mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
            .mt-overlay {
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
                border-radius: 50%;
            }
        }
    }
}

.mt-element-overlay {
    .mt-overlay-1 {
        width: 100%;
        height: 100%;
        float: left;
        overflow: hidden;
        position: relative;
        text-align: center;
        cursor: default;
        img {
            display: block;
            position: relative;
            -webkit-transition: all .4s linear;
            transition: all .4s linear;
            width: 100%;
            height: auto;
        }
        h2 {
            text-transform: uppercase;
            color: #fff;
            text-align: center;
            position: relative;
            font-size: 17px;
            background: rgba(0, 0, 0, 0.6);
            -webkit-transform: translatey(-100px) translateZ(0);
            -ms-transform: translatey(-100px) translateZ(0);
            transform: translatey(-100px) translateZ(0);
            -webkit-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;
            padding: 10px;
        }
        .mt-info {
            text-decoration: none;
            display: inline-block;
            text-transform: uppercase;
            color: #fff;
            background-color: transparent;
            opacity: 0;
            filter: alpha(opacity=0);
            -webkit-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;
            padding: 0;
            margin: auto;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            transform: translateY(-50%) translateZ(0);
            -webkit-transform: translateY(-50%) translateZ(0);
            -ms-transform: translateY(-50%) translateZ(0);
            >li {
                list-style: none;
                display: inline-block;
                margin: 0 3px;
                &:hover {
                    -webkit-transition: all .2s ease-in-out;
                    transition: all .2s ease-in-out;
                    cursor: pointer;
                }
            }
        }
        &:hover {
            .mt-overlay {
                opacity: 1;
                filter: alpha(opacity=100);
                -webkit-transform: translateZ(0);
                -ms-transform: translateZ(0);
                transform: translateZ(0);
            }
            img {
                -ms-transform: scale(1.2) translateZ(0);
                -webkit-transform: scale(1.2) translateZ(0);
                transform: scale(1.2) translateZ(0);
            }
            .mt-info {
                opacity: 1;
                filter: alpha(opacity=100);
                -webkit-transition-delay: .2s;
                transition-delay: .2s;
            }
        }
        .mt-overlay {
            width: 100%;
            height: 100%;
            position: absolute;
            overflow: hidden;
            top: 0;
            left: 0;
            opacity: 0;
            background-color: rgba(0, 0, 0, 0.7);
            -webkit-transition: all .4s ease-in-out;
            transition: all .4s ease-in-out;
        }
        &.mt-scroll-up {
            &:hover .mt-overlay {
                bottom: 0;
            }
            .mt-overlay {
                bottom: -100%;
                top: auto;
            }
        }
        &.mt-scroll-down {
            &:hover .mt-overlay {
                top: 0;
            }
            .mt-overlay {
                top: -100%;
            }
        }
        &.mt-scroll-left {
            &:hover .mt-overlay {
                right: 0;
            }
            .mt-overlay {
                right: -100%;
                left: auto;
            }
        }
        &.mt-scroll-right {
            &:hover .mt-overlay {
                left: 0;
            }
            .mt-overlay {
                left: -100%;
            }
        }
    }
    .mt-overlay-2 {
        width: 100%;
        height: 100%;
        float: left;
        overflow: hidden;
        position: relative;
        text-align: center;
        cursor: default;
        img {
            display: block;
            position: relative;
            -webkit-transition: all .4s ease-in;
            transition: all .4s ease-in;
            width: 100%;
            height: auto;
        }
        h2 {
            text-align: center;
            position: relative;
            font-size: 17px;
            padding: 10px;
            background: rgba(0, 0, 0, 0.6);
        }
        .mt-info,
        h2 {
            -webkit-transform: scale(0.7);
            -ms-transform: scale(0.7);
            transform: scale(0.7);
            -webkit-transition: all .4s ease-in;
            transition: all .4s ease-in;
            opacity: 0;
            filter: alpha(opacity=0);
            color: #fff;
            text-transform: uppercase;
        }
        .mt-info {
            display: inline-block;
            text-decoration: none;
            margin: auto;
            position: absolute;
            top: 50%;
            -webkit-transform: scale(0.7) translateY(-50%) translateX(-50%);
            -ms-transform: scale(0.7) translateY(-50%) translateX(-50%);
            transform: scale(0.7) translateY(-50%) translateX(-50%);
            &:hover {
                box-shadow: 0 0 5px #fff;
            }
        }
        &:hover {
            img {
                filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0" /><feGaussianBlur stdDeviation="3" /></filter></svg>#filter');
                filter: blur(3px);
                -webkit-filter: blur(3px);
                -webkit-transform: scale(1.2);
                -ms-transform: scale(1.2);
                transform: scale(1.2);
            }
            .mt-overlay {
                opacity: 1;
                filter: alpha(opacity=100);
                -webkit-transition-delay: 0s;
                transition-delay: 0s;
                -webkit-transform: translate(0, 0);
                -ms-transform: translate(0, 0);
                transform: translate(0, 0);
            }
            h2 {
                -webkit-transition-delay: .5s;
                transition-delay: .5s;
            }
            .mt-info,
            h2 {
                opacity: 1;
                filter: alpha(opacity=100);
                -webkit-transform: scale(1) translateY(-50%);
                -ms-transform: scale(1) translateY(-50%);
                transform: scale(1) translateY(-50%);
            }
            .mt-info {
                -webkit-transform: scale(1) translateY(-50%) translateX(-50%);
                -ms-transform: scale(1) translateY(-50%) translateX(-50%);
                transform: scale(1) translateY(-50%) translateX(-50%);
            }
        }
        .mt-overlay {
            width: 100%;
            height: 100%;
            position: absolute;
            overflow: hidden;
            top: 0;
            left: 0;
        }
        &.mt-overlay-2-grey:hover img {
            filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0" /><feGaussianBlur stdDeviation="3" /></filter></svg>#filter');
            filter: grayscale(1) blur(3px);
            -webkit-filter: grayscale(1) blur(3px);
        }
        &.mt-overlay-2-icons .mt-info {
            border: none;
            width: 100%;
            padding: 0;
            -webkit-transform: scale(0.7) translateY(-50%) translateX(-50%);
            -ms-transform: scale(0.7) translateY(-50%) translateX(-50%);
            transform: scale(0.7) translateY(-50%) translateX(-50%);
            &:hover {
                box-shadow: none;
            }
            >li {
                list-style: none;
                display: inline-block;
                margin: 0 3px;
                &:hover {
                    -webkit-transition: all .2s ease-in-out;
                    transition: all .2s ease-in-out;
                    cursor: pointer;
                }
            }
        }
    }
    .mt-overlay-3 {
        .mt-info,
        h2 {
            -webkit-transition: all 0.4s cubic-bezier(0.88, -0.99, 0, 1.81);
            transition: all 0.4s cubic-bezier(0.88, -0.99, 0, 1.81);
            color: #fff;
            text-transform: uppercase;
        }
    }
    .mt-overlay-2.mt-overlay-2-icons:hover .mt-info {
        -webkit-transform: scale(1) translateY(-50%) translateX(-50%);
        -ms-transform: scale(1) translateY(-50%) translateX(-50%);
        transform: scale(1) translateY(-50%) translateX(-50%);
    }
    .mt-overlay-3 {
        width: 100%;
        height: 100%;
        float: left;
        overflow: hidden;
        position: relative;
        text-align: center;
        cursor: default;
        img {
            display: block;
            position: relative;
            width: 100%;
            height: auto;
        }
        h2 {
            text-align: center;
            position: relative;
            font-size: 17px;
            padding: 10px;
            background: rgba(0, 0, 0, 0.6);
            -webkit-transform: translateY(100px);
            -ms-transform: translateY(100px);
            transform: translateY(100px);
            transition: all 0.4s cubic-bezier(0.88, -0.99, 0, 1.81);
        }
        .mt-info {
            display: inline-block;
            text-decoration: none;
            border: 1px solid #fff;
            background-color: transparent;
            opacity: 0;
            filter: alpha(opacity=0);
            -webkit-transform: scale(0);
            -ms-transform: scale(0);
            transform: scale(0);
            transition: all 0.4s cubic-bezier(0.88, -0.99, 0, 1.81);
            font-weight: 400;
            position: absolute;
            top: 15px;
            bottom: 15px;
            left: 15px;
            right: 15px;
            margin: auto;
            padding: 45% 0 0;
            &:hover {
                box-shadow: 0 0 5px #fff;
            }
        }
        &:hover {
            .mt-overlay {
                background-color: rgba(48, 152, 157, 0.7);
            }
            h2 {
                -webkit-transform: translateY(5px);
                -ms-transform: translateY(5px);
                transform: translateY(5px);
            }
            .mt-info {
                opacity: 1;
                filter: alpha(opacity=100);
                -webkit-transform: scale(1);
                -ms-transform: scale(1);
                transform: scale(1);
            }
        }
        .mt-overlay {
            width: 100%;
            height: 100%;
            position: absolute;
            overflow: hidden;
            top: 0;
            left: 0;
            background-color: rgba(75, 75, 75, 0.7);
            -webkit-transition: all 0.4s cubic-bezier(0.88, -0.99, 0, 1.81);
            transition: all 0.4s cubic-bezier(0.88, -0.99, 0, 1.81);
        }
        &.mt-overlay-3-icons .mt-info {
            padding: 40% 0 0;
            >li {
                list-style: none;
                display: inline-block;
                margin: 0 3px;
                &:hover {
                    -webkit-transition: all .2s ease-in-out;
                    transition: all .2s ease-in-out;
                    cursor: pointer;
                }
            }
        }
    }
    .mt-overlay-4 {
        width: 100%;
        height: 100%;
        float: left;
        overflow: hidden;
        position: relative;
        text-align: center;
        cursor: default;
        img {
            display: block;
            position: relative;
            -webkit-transition: all 0.4s cubic-bezier(0.88, -0.99, 0, 1.81);
            transition: all 0.4s cubic-bezier(0.88, -0.99, 0, 1.81);
            width: 100%;
            height: auto;
        }
        h2 {
            text-transform: uppercase;
            color: #fff;
            text-align: center;
            position: relative;
            font-size: 17px;
            background: rgba(0, 0, 0, 0.6);
            -webkit-transform: translatey(-100px);
            -ms-transform: translatey(-100px);
            transform: translatey(-100px);
            -webkit-transition: all 0.4s cubic-bezier(0.88, -0.99, 0, 1.81);
            transition: all 0.4s cubic-bezier(0.88, -0.99, 0, 1.81);
            padding: 10px;
        }
        .mt-info {
            display: inline-block;
            text-transform: uppercase;
            opacity: 0;
            filter: alpha(opacity=0);
            -webkit-transition: all .4s ease;
            transition: all .4s ease;
            margin: 50px 0 0;
        }
        &:hover {
            .mt-overlay {
                opacity: 1;
                filter: alpha(opacity=100);
            }
            .mt-info,
            h2 {
                opacity: 1;
                filter: alpha(opacity=100);
                -ms-transform: translatey(0);
                -webkit-transform: translatey(0);
                transform: translatey(0);
            }
            .mt-info {
                -webkit-transition-delay: .2s;
                transition-delay: .2s;
            }
        }
        .mt-overlay {
            width: 100%;
            height: 100%;
            position: absolute;
            overflow: hidden;
            top: 0;
            left: 0;
            opacity: 0;
            filter: alpha(opacity=0);
            background-color: rgba(0, 0, 0, 0.7);
            -webkit-transition: all 0.4s cubic-bezier(0.88, -0.99, 0, 1.81);
            transition: all 0.4s cubic-bezier(0.88, -0.99, 0, 1.81);
        }
        &.mt-overlay-4-icons .mt-info {
            border: none;
            position: absolute;
            padding: 0;
            top: 50%;
            left: 0;
            right: 0;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            margin: auto;
            &:hover {
                box-shadow: none;
            }
            >li {
                list-style: none;
                display: inline-block;
                margin: 0 3px;
                &:hover {
                    -webkit-transition: all .2s ease-in-out;
                    transition: all .2s ease-in-out;
                    cursor: pointer;
                }
            }
        }
    }
    .mt-overlay-5 {
        width: 100%;
        height: 100%;
        float: left;
        overflow: hidden;
        position: relative;
        text-align: center;
        cursor: default;
        background: -webkit-linear-gradient(45deg, #ff89e9 0, #05abe0 100%);
        background: linear-gradient(45deg, #ff89e9 0, #05abe0 100%);
        .mt-overlay {
            width: 100%;
            height: 100%;
            position: absolute;
            overflow: hidden;
            top: 0;
            left: 0;
            padding: 3em;
            text-align: left;
            &:before {
                position: absolute;
                top: 20px;
                right: 20px;
                bottom: 20px;
                left: 20px;
                border: 1px solid #fff;
                content: '';
                opacity: 0;
                filter: alpha(opacity=0);
                -webkit-transition: opacity .35s, -webkit-transform .45s;
                transition: opacity .35s, transform .45s;
                -webkit-transform: translate3d(-20px, 0, 0);
                transform: translate3d(-20px, 0, 0);
            }
        }
    }
}

.border-after-white:after,
.border-before-white:before,
.border-white {
    border-color: #fff !important;
}

.border-top-after-white:after,
.border-top-before-white:before,
.border-top-white {
    border-top-color: #fff !important;
}

.border-bottom-after-white:after,
.border-bottom-before-white:before,
.border-bottom-white {
    border-bottom-color: #fff !important;
}

.border-left-after-white:after,
.border-left-before-white:before,
.border-left-white {
    border-left-color: #fff !important;
}

.border-right-after-white:after,
.border-right-before-white:before,
.border-right-white {
    border-right-color: #fff !important;
}

.mt-element-overlay {
    .mt-overlay-5 {
        img {
            display: block;
            position: relative;
            max-width: none;
            width: calc(113% + 60px);
            -webkit-transition: opacity .35s, -webkit-transform .45s;
            transition: opacity .35s, transform .45s;
            -webkit-transform: translate3d(-40px, 0, 0);
            transform: translate3d(-40px, 0, 0);
        }
        h2 {
            text-transform: uppercase;
            color: #fff;
            position: relative;
            font-size: 17px;
            background-color: transparent;
            padding: 15% 0 10px;
            text-align: left;
        }
        a,
        p {
            color: #FFF;
            opacity: 0;
            filter: alpha(opacity=0);
            -webkit-transition: opacity .35s, -webkit-transform .45s;
            transition: opacity .35s, transform .45s;
            -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
        }
        a:hover {
            text-decoration: none;
            opacity: .6;
            filter: alpha(opacity=60);
        }
        &:hover {
            img {
                opacity: .6;
                filter: alpha(opacity=60);
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }
            .mt-overlay:before,
            a,
            p {
                opacity: 1;
                filter: alpha(opacity=100);
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }
        }
    }
    .mt-overlay-6 {
        width: 100%;
        height: 100%;
        float: left;
        overflow: hidden;
        position: relative;
        text-align: center;
        cursor: default;
        background: #42b078;
        .mt-overlay {
            width: 100%;
            height: 100%;
            position: absolute;
            overflow: hidden;
            top: 0;
            left: 0;
            padding: 50px 20px;
        }
        img {
            display: block;
            position: relative;
            max-width: none;
            width: calc(100% + 20px);
            -webkit-transition: opacity .35s, -webkit-transform .35s;
            transition: opacity .35s, transform .35s;
            -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
        }
        h2 {
            text-transform: uppercase;
            color: #fff;
            text-align: center;
            position: relative;
            font-size: 17px;
            overflow: hidden;
            padding: .5em 0;
            background-color: transparent;
            &:after {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background: #fff;
                content: '';
                -webkit-transition: -webkit-transform .35s;
                transition: transform .35s;
                -webkit-transform: translate3d(-100%, 0, 0);
                transform: translate3d(-100%, 0, 0);
            }
        }
        a {
            color: #FFF;
            opacity: 0;
            filter: alpha(opacity=0);
            -webkit-transition: opacity .35s, -webkit-transform .35s;
            transition: opacity .35s, transform .35s;
            -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
        }
        p {
            color: #FFF;
            opacity: 0;
            filter: alpha(opacity=0);
            -webkit-transition: opacity .35s, -webkit-transform .35s;
            transition: opacity .35s, transform .35s;
            -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
            margin-top: 20px;
        }
        .mt-info:hover {
            text-decoration: none;
            opacity: .6;
            filter: alpha(opacity=60);
            -webkit-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;
            cursor: pointer;
        }
        &:hover {
            img {
                opacity: .4;
                filter: alpha(opacity=40);
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }
            h2:after {
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }
            a,
            p {
                opacity: 1;
                filter: alpha(opacity=100);
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }
        }
    }
}

.bg-white {
    background: #fff !important;
}

.bg-white-opacity {
    background: rgba(255, 255, 255, 0.8) !important;
}

.bg-after-white:after,
.bg-before-white:before,
.bg-hover-white:hover {
    background: #fff !important;
}

.font-white {
    color: #fff !important;
}

.bg-font-white {
    color: #666 !important;
}

.border-after-default:after,
.border-before-default:before,
.border-default {
    border-color: #e1e5ec !important;
}

.border-top-after-default:after,
.border-top-before-default:before,
.border-top-default {
    border-top-color: #e1e5ec !important;
}

.border-bottom-after-default:after,
.border-bottom-before-default:before,
.border-bottom-default {
    border-bottom-color: #e1e5ec !important;
}

.border-left-after-default:after,
.border-left-before-default:before,
.border-left-default {
    border-left-color: #e1e5ec !important;
}

.border-right-after-default:after,
.border-right-before-default:before,
.border-right-default {
    border-right-color: #e1e5ec !important;
}

.bg-default {
    background: #e1e5ec !important;
}

.bg-default-opacity {
    background: rgba(225, 229, 236, 0.8) !important;
}

.bg-after-default:after,
.bg-before-default:before,
.bg-hover-default:hover {
    background: #e1e5ec !important;
}

.font-default {
    color: #e1e5ec !important;
}

.bg-font-default {
    color: #666 !important;
}

.border-after-dark:after,
.border-before-dark:before,
.border-dark {
    border-color: #2f353b !important;
}

.border-top-after-dark:after,
.border-top-before-dark:before,
.border-top-dark {
    border-top-color: #2f353b !important;
}

.border-bottom-after-dark:after,
.border-bottom-before-dark:before,
.border-bottom-dark {
    border-bottom-color: #2f353b !important;
}

.border-left-after-dark:after,
.border-left-before-dark:before,
.border-left-dark {
    border-left-color: #2f353b !important;
}

.border-right-after-dark:after,
.border-right-before-dark:before,
.border-right-dark {
    border-right-color: #2f353b !important;
}

.bg-dark {
    background: #2f353b !important;
}

.bg-dark-opacity {
    background: rgba(47, 53, 59, 0.8) !important;
}

.bg-after-dark:after,
.bg-before-dark:before,
.bg-hover-dark:hover {
    background: #2f353b !important;
}

.font-dark {
    color: #2f353b !important;
}

.bg-font-dark {
    color: #FFF !important;
}

.border-after-blue:after,
.border-before-blue:before,
.border-blue {
    border-color: #3598dc !important;
}

.border-top-after-blue:after,
.border-top-before-blue:before,
.border-top-blue {
    border-top-color: #3598dc !important;
}

.border-bottom-after-blue:after,
.border-bottom-before-blue:before,
.border-bottom-blue {
    border-bottom-color: #3598dc !important;
}

.border-left-after-blue:after,
.border-left-before-blue:before,
.border-left-blue {
    border-left-color: #3598dc !important;
}

.border-right-after-blue:after,
.border-right-before-blue:before,
.border-right-blue {
    border-right-color: #3598dc !important;
}

.bg-blue {
    background: #3598dc !important;
}

.bg-blue-opacity {
    background: rgba(53, 152, 220, 0.8) !important;
}

.bg-after-blue:after,
.bg-before-blue:before,
.bg-hover-blue:hover {
    background: #3598dc !important;
}

.font-blue {
    color: #3598dc !important;
}

.bg-font-blue {
    color: #FFF !important;
}

.border-after-blue-madison:after,
.border-before-blue-madison:before,
.border-blue-madison {
    border-color: #578ebe !important;
}

.border-top-after-blue-madison:after,
.border-top-before-blue-madison:before,
.border-top-blue-madison {
    border-top-color: #578ebe !important;
}

.border-bottom-after-blue-madison:after,
.border-bottom-before-blue-madison:before,
.border-bottom-blue-madison {
    border-bottom-color: #578ebe !important;
}

.border-left-after-blue-madison:after,
.border-left-before-blue-madison:before,
.border-left-blue-madison {
    border-left-color: #578ebe !important;
}

.border-right-after-blue-madison:after,
.border-right-before-blue-madison:before,
.border-right-blue-madison {
    border-right-color: #578ebe !important;
}

.bg-blue-madison {
    background: #578ebe !important;
}

.bg-blue-madison-opacity {
    background: rgba(87, 142, 190, 0.8) !important;
}

.bg-after-blue-madison:after,
.bg-before-blue-madison:before,
.bg-hover-blue-madison:hover {
    background: #578ebe !important;
}

.font-blue-madison {
    color: #578ebe !important;
}

.bg-font-blue-madison {
    color: #FFF !important;
}

.border-after-blue-chambray:after,
.border-before-blue-chambray:before,
.border-blue-chambray {
    border-color: #2C3E50 !important;
}

.border-top-after-blue-chambray:after,
.border-top-before-blue-chambray:before,
.border-top-blue-chambray {
    border-top-color: #2C3E50 !important;
}

.border-bottom-after-blue-chambray:after,
.border-bottom-before-blue-chambray:before,
.border-bottom-blue-chambray {
    border-bottom-color: #2C3E50 !important;
}

.border-left-after-blue-chambray:after,
.border-left-before-blue-chambray:before,
.border-left-blue-chambray {
    border-left-color: #2C3E50 !important;
}

.border-right-after-blue-chambray:after,
.border-right-before-blue-chambray:before,
.border-right-blue-chambray {
    border-right-color: #2C3E50 !important;
}

.bg-blue-chambray {
    background: #2C3E50 !important;
}

.bg-blue-chambray-opacity {
    background: rgba(44, 62, 80, 0.8) !important;
}

.bg-after-blue-chambray:after,
.bg-before-blue-chambray:before,
.bg-hover-blue-chambray:hover {
    background: #2C3E50 !important;
}

.font-blue-chambray {
    color: #2C3E50 !important;
}

.bg-font-blue-chambray {
    color: #FFF !important;
}

.border-after-blue-ebonyclay:after,
.border-before-blue-ebonyclay:before,
.border-blue-ebonyclay {
    border-color: #22313F !important;
}

.border-top-after-blue-ebonyclay:after,
.border-top-before-blue-ebonyclay:before,
.border-top-blue-ebonyclay {
    border-top-color: #22313F !important;
}

.border-bottom-after-blue-ebonyclay:after,
.border-bottom-before-blue-ebonyclay:before,
.border-bottom-blue-ebonyclay {
    border-bottom-color: #22313F !important;
}

.border-left-after-blue-ebonyclay:after,
.border-left-before-blue-ebonyclay:before,
.border-left-blue-ebonyclay {
    border-left-color: #22313F !important;
}

.border-right-after-blue-ebonyclay:after,
.border-right-before-blue-ebonyclay:before,
.border-right-blue-ebonyclay {
    border-right-color: #22313F !important;
}

.bg-blue-ebonyclay {
    background: #22313F !important;
}

.bg-blue-ebonyclay-opacity {
    background: rgba(34, 49, 63, 0.8) !important;
}

.bg-after-blue-ebonyclay:after,
.bg-before-blue-ebonyclay:before,
.bg-hover-blue-ebonyclay:hover {
    background: #22313F !important;
}

.font-blue-ebonyclay {
    color: #22313F !important;
}

.bg-font-blue-ebonyclay {
    color: #FFF !important;
}

.border-after-blue-hoki:after,
.border-before-blue-hoki:before,
.border-blue-hoki {
    border-color: #67809F !important;
}

.border-top-after-blue-hoki:after,
.border-top-before-blue-hoki:before,
.border-top-blue-hoki {
    border-top-color: #67809F !important;
}

.border-bottom-after-blue-hoki:after,
.border-bottom-before-blue-hoki:before,
.border-bottom-blue-hoki {
    border-bottom-color: #67809F !important;
}

.border-left-after-blue-hoki:after,
.border-left-before-blue-hoki:before,
.border-left-blue-hoki {
    border-left-color: #67809F !important;
}

.border-right-after-blue-hoki:after,
.border-right-before-blue-hoki:before,
.border-right-blue-hoki {
    border-right-color: #67809F !important;
}

.bg-blue-hoki {
    background: #67809F !important;
}

.bg-blue-hoki-opacity {
    background: rgba(103, 128, 159, 0.8) !important;
}

.bg-after-blue-hoki:after,
.bg-before-blue-hoki:before,
.bg-hover-blue-hoki:hover {
    background: #67809F !important;
}

.font-blue-hoki {
    color: #67809F !important;
}

.bg-font-blue-hoki {
    color: #FFF !important;
}

.border-after-blue-steel:after,
.border-before-blue-steel:before,
.border-blue-steel {
    border-color: #4B77BE !important;
}

.border-top-after-blue-steel:after,
.border-top-before-blue-steel:before,
.border-top-blue-steel {
    border-top-color: #4B77BE !important;
}

.border-bottom-after-blue-steel:after,
.border-bottom-before-blue-steel:before,
.border-bottom-blue-steel {
    border-bottom-color: #4B77BE !important;
}

.border-left-after-blue-steel:after,
.border-left-before-blue-steel:before,
.border-left-blue-steel {
    border-left-color: #4B77BE !important;
}

.border-right-after-blue-steel:after,
.border-right-before-blue-steel:before,
.border-right-blue-steel {
    border-right-color: #4B77BE !important;
}

.bg-blue-steel {
    background: #4B77BE !important;
}

.bg-blue-steel-opacity {
    background: rgba(75, 119, 190, 0.8) !important;
}

.bg-after-blue-steel:after,
.bg-before-blue-steel:before,
.bg-hover-blue-steel:hover {
    background: #4B77BE !important;
}

.font-blue-steel {
    color: #4B77BE !important;
}

.bg-font-blue-steel {
    color: #FFF !important;
}

.border-after-blue-soft:after,
.border-before-blue-soft:before,
.border-blue-soft {
    border-color: #4c87b9 !important;
}

.border-top-after-blue-soft:after,
.border-top-before-blue-soft:before,
.border-top-blue-soft {
    border-top-color: #4c87b9 !important;
}

.border-bottom-after-blue-soft:after,
.border-bottom-before-blue-soft:before,
.border-bottom-blue-soft {
    border-bottom-color: #4c87b9 !important;
}

.border-left-after-blue-soft:after,
.border-left-before-blue-soft:before,
.border-left-blue-soft {
    border-left-color: #4c87b9 !important;
}

.border-right-after-blue-soft:after,
.border-right-before-blue-soft:before,
.border-right-blue-soft {
    border-right-color: #4c87b9 !important;
}

.bg-blue-soft {
    background: #4c87b9 !important;
}

.bg-blue-soft-opacity {
    background: rgba(76, 135, 185, 0.8) !important;
}

.bg-after-blue-soft:after,
.bg-before-blue-soft:before,
.bg-hover-blue-soft:hover {
    background: #4c87b9 !important;
}

.font-blue-soft {
    color: #4c87b9 !important;
}

.bg-font-blue-soft {
    color: #FFF !important;
}

.border-after-blue-dark:after,
.border-before-blue-dark:before,
.border-blue-dark {
    border-color: #5e738b !important;
}

.border-top-after-blue-dark:after,
.border-top-before-blue-dark:before,
.border-top-blue-dark {
    border-top-color: #5e738b !important;
}

.border-bottom-after-blue-dark:after,
.border-bottom-before-blue-dark:before,
.border-bottom-blue-dark {
    border-bottom-color: #5e738b !important;
}

.border-left-after-blue-dark:after,
.border-left-before-blue-dark:before,
.border-left-blue-dark {
    border-left-color: #5e738b !important;
}

.border-right-after-blue-dark:after,
.border-right-before-blue-dark:before,
.border-right-blue-dark {
    border-right-color: #5e738b !important;
}

.bg-blue-dark {
    background: #5e738b !important;
}

.bg-blue-dark-opacity {
    background: rgba(94, 115, 139, 0.8) !important;
}

.bg-after-blue-dark:after,
.bg-before-blue-dark:before,
.bg-hover-blue-dark:hover {
    background: #5e738b !important;
}

.font-blue-dark {
    color: #5e738b !important;
}

.bg-font-blue-dark {
    color: #FFF !important;
}

.border-after-blue-sharp:after,
.border-before-blue-sharp:before,
.border-blue-sharp {
    border-color: #5C9BD1 !important;
}

.border-top-after-blue-sharp:after,
.border-top-before-blue-sharp:before,
.border-top-blue-sharp {
    border-top-color: #5C9BD1 !important;
}

.border-bottom-after-blue-sharp:after,
.border-bottom-before-blue-sharp:before,
.border-bottom-blue-sharp {
    border-bottom-color: #5C9BD1 !important;
}

.border-left-after-blue-sharp:after,
.border-left-before-blue-sharp:before,
.border-left-blue-sharp {
    border-left-color: #5C9BD1 !important;
}

.border-right-after-blue-sharp:after,
.border-right-before-blue-sharp:before,
.border-right-blue-sharp {
    border-right-color: #5C9BD1 !important;
}

.bg-blue-sharp {
    background: #5C9BD1 !important;
}

.bg-blue-sharp-opacity {
    background: rgba(92, 155, 209, 0.8) !important;
}

.bg-after-blue-sharp:after,
.bg-before-blue-sharp:before,
.bg-hover-blue-sharp:hover {
    background: #5C9BD1 !important;
}

.font-blue-sharp {
    color: #5C9BD1 !important;
}

.bg-font-blue-sharp {
    color: #FFF !important;
}

.border-after-blue-oleo:after,
.border-before-blue-oleo:before,
.border-blue-oleo {
    border-color: #94A0B2 !important;
}

.border-top-after-blue-oleo:after,
.border-top-before-blue-oleo:before,
.border-top-blue-oleo {
    border-top-color: #94A0B2 !important;
}

.border-bottom-after-blue-oleo:after,
.border-bottom-before-blue-oleo:before,
.border-bottom-blue-oleo {
    border-bottom-color: #94A0B2 !important;
}

.border-left-after-blue-oleo:after,
.border-left-before-blue-oleo:before,
.border-left-blue-oleo {
    border-left-color: #94A0B2 !important;
}

.border-right-after-blue-oleo:after,
.border-right-before-blue-oleo:before,
.border-right-blue-oleo {
    border-right-color: #94A0B2 !important;
}

.bg-blue-oleo {
    background: #94A0B2 !important;
}

.bg-blue-oleo-opacity {
    background: rgba(148, 160, 178, 0.8) !important;
}

.bg-after-blue-oleo:after,
.bg-before-blue-oleo:before,
.bg-hover-blue-oleo:hover {
    background: #94A0B2 !important;
}

.font-blue-oleo {
    color: #94A0B2 !important;
}

.bg-font-blue-oleo {
    color: #FFF !important;
}

.border-after-green:after,
.border-before-green:before,
.border-green {
    border-color: #32c5d2 !important;
}

.border-top-after-green:after,
.border-top-before-green:before,
.border-top-green {
    border-top-color: #32c5d2 !important;
}

.border-bottom-after-green:after,
.border-bottom-before-green:before,
.border-bottom-green {
    border-bottom-color: #32c5d2 !important;
}

.border-left-after-green:after,
.border-left-before-green:before,
.border-left-green {
    border-left-color: #32c5d2 !important;
}

.border-right-after-green:after,
.border-right-before-green:before,
.border-right-green {
    border-right-color: #32c5d2 !important;
}

.bg-green {
    background: #32c5d2 !important;
}

.bg-green-opacity {
    background: rgba(50, 197, 210, 0.8) !important;
}

.bg-after-green:after,
.bg-before-green:before,
.bg-hover-green:hover {
    background: #32c5d2 !important;
}

.font-green {
    color: #32c5d2 !important;
}

.bg-font-green {
    color: #FFF !important;
}

.border-after-green-meadow:after,
.border-before-green-meadow:before,
.border-green-meadow {
    border-color: #1BBC9B !important;
}

.border-top-after-green-meadow:after,
.border-top-before-green-meadow:before,
.border-top-green-meadow {
    border-top-color: #1BBC9B !important;
}

.border-bottom-after-green-meadow:after,
.border-bottom-before-green-meadow:before,
.border-bottom-green-meadow {
    border-bottom-color: #1BBC9B !important;
}

.border-left-after-green-meadow:after,
.border-left-before-green-meadow:before,
.border-left-green-meadow {
    border-left-color: #1BBC9B !important;
}

.border-right-after-green-meadow:after,
.border-right-before-green-meadow:before,
.border-right-green-meadow {
    border-right-color: #1BBC9B !important;
}

.bg-green-meadow {
    background: #1BBC9B !important;
}

.bg-green-meadow-opacity {
    background: rgba(27, 188, 155, 0.8) !important;
}

.bg-after-green-meadow:after,
.bg-before-green-meadow:before,
.bg-hover-green-meadow:hover {
    background: #1BBC9B !important;
}

.font-green-meadow {
    color: #1BBC9B !important;
}

.bg-font-green-meadow {
    color: #FFF !important;
}

.border-after-green-seagreen:after,
.border-before-green-seagreen:before,
.border-green-seagreen {
    border-color: #1BA39C !important;
}

.border-top-after-green-seagreen:after,
.border-top-before-green-seagreen:before,
.border-top-green-seagreen {
    border-top-color: #1BA39C !important;
}

.border-bottom-after-green-seagreen:after,
.border-bottom-before-green-seagreen:before,
.border-bottom-green-seagreen {
    border-bottom-color: #1BA39C !important;
}

.border-left-after-green-seagreen:after,
.border-left-before-green-seagreen:before,
.border-left-green-seagreen {
    border-left-color: #1BA39C !important;
}

.border-right-after-green-seagreen:after,
.border-right-before-green-seagreen:before,
.border-right-green-seagreen {
    border-right-color: #1BA39C !important;
}

.bg-green-seagreen {
    background: #1BA39C !important;
}

.bg-green-seagreen-opacity {
    background: rgba(27, 163, 156, 0.8) !important;
}

.bg-after-green-seagreen:after,
.bg-before-green-seagreen:before,
.bg-hover-green-seagreen:hover {
    background: #1BA39C !important;
}

.font-green-seagreen {
    color: #1BA39C !important;
}

.bg-font-green-seagreen {
    color: #FFF !important;
}

.border-after-green-turquoise:after,
.border-before-green-turquoise:before,
.border-green-turquoise {
    border-color: #36D7B7 !important;
}

.border-top-after-green-turquoise:after,
.border-top-before-green-turquoise:before,
.border-top-green-turquoise {
    border-top-color: #36D7B7 !important;
}

.border-bottom-after-green-turquoise:after,
.border-bottom-before-green-turquoise:before,
.border-bottom-green-turquoise {
    border-bottom-color: #36D7B7 !important;
}

.border-left-after-green-turquoise:after,
.border-left-before-green-turquoise:before,
.border-left-green-turquoise {
    border-left-color: #36D7B7 !important;
}

.border-right-after-green-turquoise:after,
.border-right-before-green-turquoise:before,
.border-right-green-turquoise {
    border-right-color: #36D7B7 !important;
}

.bg-green-turquoise {
    background: #36D7B7 !important;
}

.bg-green-turquoise-opacity {
    background: rgba(54, 215, 183, 0.8) !important;
}

.bg-after-green-turquoise:after,
.bg-before-green-turquoise:before,
.bg-hover-green-turquoise:hover {
    background: #36D7B7 !important;
}

.font-green-turquoise {
    color: #36D7B7 !important;
}

.bg-font-green-turquoise {
    color: #FFF !important;
}

.border-after-green-haze:after,
.border-before-green-haze:before,
.border-green-haze {
    border-color: #44b6ae !important;
}

.border-top-after-green-haze:after,
.border-top-before-green-haze:before,
.border-top-green-haze {
    border-top-color: #44b6ae !important;
}

.border-bottom-after-green-haze:after,
.border-bottom-before-green-haze:before,
.border-bottom-green-haze {
    border-bottom-color: #44b6ae !important;
}

.border-left-after-green-haze:after,
.border-left-before-green-haze:before,
.border-left-green-haze {
    border-left-color: #44b6ae !important;
}

.border-right-after-green-haze:after,
.border-right-before-green-haze:before,
.border-right-green-haze {
    border-right-color: #44b6ae !important;
}

.bg-green-haze {
    background: #44b6ae !important;
}

.bg-green-haze-opacity {
    background: rgba(68, 182, 174, 0.8) !important;
}

.bg-after-green-haze:after,
.bg-before-green-haze:before,
.bg-hover-green-haze:hover {
    background: #44b6ae !important;
}

.font-green-haze {
    color: #44b6ae !important;
}

.bg-font-green-haze {
    color: #FFF !important;
}

.border-after-green-jungle:after,
.border-before-green-jungle:before,
.border-green-jungle {
    border-color: #26C281 !important;
}

.border-top-after-green-jungle:after,
.border-top-before-green-jungle:before,
.border-top-green-jungle {
    border-top-color: #26C281 !important;
}

.border-bottom-after-green-jungle:after,
.border-bottom-before-green-jungle:before,
.border-bottom-green-jungle {
    border-bottom-color: #26C281 !important;
}

.border-left-after-green-jungle:after,
.border-left-before-green-jungle:before,
.border-left-green-jungle {
    border-left-color: #26C281 !important;
}

.border-right-after-green-jungle:after,
.border-right-before-green-jungle:before,
.border-right-green-jungle {
    border-right-color: #26C281 !important;
}

.bg-green-jungle {
    background: #26C281 !important;
}

.bg-green-jungle-opacity {
    background: rgba(38, 194, 129, 0.8) !important;
}

.bg-after-green-jungle:after,
.bg-before-green-jungle:before,
.bg-hover-green-jungle:hover {
    background: #26C281 !important;
}

.font-green-jungle {
    color: #26C281 !important;
}

.bg-font-green-jungle {
    color: #FFF !important;
}

.border-after-green-soft:after,
.border-before-green-soft:before,
.border-green-soft {
    border-color: #3faba4 !important;
}

.border-top-after-green-soft:after,
.border-top-before-green-soft:before,
.border-top-green-soft {
    border-top-color: #3faba4 !important;
}

.border-bottom-after-green-soft:after,
.border-bottom-before-green-soft:before,
.border-bottom-green-soft {
    border-bottom-color: #3faba4 !important;
}

.border-left-after-green-soft:after,
.border-left-before-green-soft:before,
.border-left-green-soft {
    border-left-color: #3faba4 !important;
}

.border-right-after-green-soft:after,
.border-right-before-green-soft:before,
.border-right-green-soft {
    border-right-color: #3faba4 !important;
}

.bg-green-soft {
    background: #3faba4 !important;
}

.bg-green-soft-opacity {
    background: rgba(63, 171, 164, 0.8) !important;
}

.bg-after-green-soft:after,
.bg-before-green-soft:before,
.bg-hover-green-soft:hover {
    background: #3faba4 !important;
}

.font-green-soft {
    color: #3faba4 !important;
}

.bg-font-green-soft {
    color: #FFF !important;
}

.border-after-green-dark:after,
.border-before-green-dark:before,
.border-green-dark {
    border-color: #4DB3A2 !important;
}

.border-top-after-green-dark:after,
.border-top-before-green-dark:before,
.border-top-green-dark {
    border-top-color: #4DB3A2 !important;
}

.border-bottom-after-green-dark:after,
.border-bottom-before-green-dark:before,
.border-bottom-green-dark {
    border-bottom-color: #4DB3A2 !important;
}

.border-left-after-green-dark:after,
.border-left-before-green-dark:before,
.border-left-green-dark {
    border-left-color: #4DB3A2 !important;
}

.border-right-after-green-dark:after,
.border-right-before-green-dark:before,
.border-right-green-dark {
    border-right-color: #4DB3A2 !important;
}

.bg-green-dark {
    background: #4DB3A2 !important;
}

.bg-green-dark-opacity {
    background: rgba(77, 179, 162, 0.8) !important;
}

.bg-after-green-dark:after,
.bg-before-green-dark:before,
.bg-hover-green-dark:hover {
    background: #4DB3A2 !important;
}

.font-green-dark {
    color: #4DB3A2 !important;
}

.bg-font-green-dark {
    color: #FFF !important;
}

.border-after-green-sharp:after,
.border-before-green-sharp:before,
.border-green-sharp {
    border-color: #2ab4c0 !important;
}

.border-top-after-green-sharp:after,
.border-top-before-green-sharp:before,
.border-top-green-sharp {
    border-top-color: #2ab4c0 !important;
}

.border-bottom-after-green-sharp:after,
.border-bottom-before-green-sharp:before,
.border-bottom-green-sharp {
    border-bottom-color: #2ab4c0 !important;
}

.border-left-after-green-sharp:after,
.border-left-before-green-sharp:before,
.border-left-green-sharp {
    border-left-color: #2ab4c0 !important;
}

.border-right-after-green-sharp:after,
.border-right-before-green-sharp:before,
.border-right-green-sharp {
    border-right-color: #2ab4c0 !important;
}

.bg-green-sharp {
    background: #2ab4c0 !important;
}

.bg-green-sharp-opacity {
    background: rgba(42, 180, 192, 0.8) !important;
}

.bg-after-green-sharp:after,
.bg-before-green-sharp:before,
.bg-hover-green-sharp:hover {
    background: #2ab4c0 !important;
}

.font-green-sharp {
    color: #2ab4c0 !important;
}

.bg-font-green-sharp {
    color: #FFF !important;
}

.border-after-green-steel:after,
.border-before-green-steel:before,
.border-green-steel {
    border-color: #29b4b6 !important;
}

.border-top-after-green-steel:after,
.border-top-before-green-steel:before,
.border-top-green-steel {
    border-top-color: #29b4b6 !important;
}

.border-bottom-after-green-steel:after,
.border-bottom-before-green-steel:before,
.border-bottom-green-steel {
    border-bottom-color: #29b4b6 !important;
}

.border-left-after-green-steel:after,
.border-left-before-green-steel:before,
.border-left-green-steel {
    border-left-color: #29b4b6 !important;
}

.border-right-after-green-steel:after,
.border-right-before-green-steel:before,
.border-right-green-steel {
    border-right-color: #29b4b6 !important;
}

.bg-green-steel {
    background: #29b4b6 !important;
}

.bg-green-steel-opacity {
    background: rgba(41, 180, 182, 0.8) !important;
}

.bg-after-green-steel:after,
.bg-before-green-steel:before,
.bg-hover-green-steel:hover {
    background: #29b4b6 !important;
}

.font-green-steel {
    color: #29b4b6 !important;
}

.bg-font-green-steel {
    color: #FFF !important;
}

.border-after-grey:after,
.border-before-grey:before,
.border-grey {
    border-color: #E5E5E5 !important;
}

.border-top-after-grey:after,
.border-top-before-grey:before,
.border-top-grey {
    border-top-color: #E5E5E5 !important;
}

.border-bottom-after-grey:after,
.border-bottom-before-grey:before,
.border-bottom-grey {
    border-bottom-color: #E5E5E5 !important;
}

.border-left-after-grey:after,
.border-left-before-grey:before,
.border-left-grey {
    border-left-color: #E5E5E5 !important;
}

.border-right-after-grey:after,
.border-right-before-grey:before,
.border-right-grey {
    border-right-color: #E5E5E5 !important;
}

.bg-grey {
    background: #E5E5E5 !important;
}

.bg-grey-opacity {
    background: rgba(229, 229, 229, 0.8) !important;
}

.bg-after-grey:after,
.bg-before-grey:before,
.bg-hover-grey:hover {
    background: #E5E5E5 !important;
}

.font-grey {
    color: #E5E5E5 !important;
}

.bg-font-grey {
    color: #333 !important;
}

.border-after-grey-steel:after,
.border-before-grey-steel:before,
.border-grey-steel {
    border-color: #e9edef !important;
}

.border-top-after-grey-steel:after,
.border-top-before-grey-steel:before,
.border-top-grey-steel {
    border-top-color: #e9edef !important;
}

.border-bottom-after-grey-steel:after,
.border-bottom-before-grey-steel:before,
.border-bottom-grey-steel {
    border-bottom-color: #e9edef !important;
}

.border-left-after-grey-steel:after,
.border-left-before-grey-steel:before,
.border-left-grey-steel {
    border-left-color: #e9edef !important;
}

.border-right-after-grey-steel:after,
.border-right-before-grey-steel:before,
.border-right-grey-steel {
    border-right-color: #e9edef !important;
}

.bg-grey-steel {
    background: #e9edef !important;
}

.bg-grey-steel-opacity {
    background: rgba(233, 237, 239, 0.8) !important;
}

.bg-after-grey-steel:after,
.bg-before-grey-steel:before,
.bg-hover-grey-steel:hover {
    background: #e9edef !important;
}

.font-grey-steel {
    color: #e9edef !important;
}

.bg-font-grey-steel {
    color: #80898e !important;
}

.border-after-grey-cararra:after,
.border-before-grey-cararra:before,
.border-grey-cararra {
    border-color: #fafafa !important;
}

.border-top-after-grey-cararra:after,
.border-top-before-grey-cararra:before,
.border-top-grey-cararra {
    border-top-color: #fafafa !important;
}

.border-bottom-after-grey-cararra:after,
.border-bottom-before-grey-cararra:before,
.border-bottom-grey-cararra {
    border-bottom-color: #fafafa !important;
}

.border-left-after-grey-cararra:after,
.border-left-before-grey-cararra:before,
.border-left-grey-cararra {
    border-left-color: #fafafa !important;
}

.border-right-after-grey-cararra:after,
.border-right-before-grey-cararra:before,
.border-right-grey-cararra {
    border-right-color: #fafafa !important;
}

.bg-grey-cararra {
    background: #fafafa !important;
}

.bg-grey-cararra-opacity {
    background: rgba(250, 250, 250, 0.8) !important;
}

.bg-after-grey-cararra:after,
.bg-before-grey-cararra:before,
.bg-hover-grey-cararra:hover {
    background: #fafafa !important;
}

.font-grey-cararra {
    color: #fafafa !important;
}

.bg-font-grey-cararra {
    color: #333 !important;
}

.border-after-grey-gallery:after,
.border-before-grey-gallery:before,
.border-grey-gallery {
    border-color: #555 !important;
}

.border-top-after-grey-gallery:after,
.border-top-before-grey-gallery:before,
.border-top-grey-gallery {
    border-top-color: #555 !important;
}

.border-bottom-after-grey-gallery:after,
.border-bottom-before-grey-gallery:before,
.border-bottom-grey-gallery {
    border-bottom-color: #555 !important;
}

.border-left-after-grey-gallery:after,
.border-left-before-grey-gallery:before,
.border-left-grey-gallery {
    border-left-color: #555 !important;
}

.border-right-after-grey-gallery:after,
.border-right-before-grey-gallery:before,
.border-right-grey-gallery {
    border-right-color: #555 !important;
}

.bg-grey-gallery {
    background: #555 !important;
}

.bg-grey-gallery-opacity {
    background: rgba(85, 85, 85, 0.8) !important;
}

.bg-after-grey-gallery:after,
.bg-before-grey-gallery:before,
.bg-hover-grey-gallery:hover {
    background: #555 !important;
}

.font-grey-gallery {
    color: #555 !important;
}

.bg-font-grey-gallery {
    color: #fff !important;
}

.border-after-grey-cascade:after,
.border-before-grey-cascade:before,
.border-grey-cascade {
    border-color: #95A5A6 !important;
}

.border-top-after-grey-cascade:after,
.border-top-before-grey-cascade:before,
.border-top-grey-cascade {
    border-top-color: #95A5A6 !important;
}

.border-bottom-after-grey-cascade:after,
.border-bottom-before-grey-cascade:before,
.border-bottom-grey-cascade {
    border-bottom-color: #95A5A6 !important;
}

.border-left-after-grey-cascade:after,
.border-left-before-grey-cascade:before,
.border-left-grey-cascade {
    border-left-color: #95A5A6 !important;
}

.border-right-after-grey-cascade:after,
.border-right-before-grey-cascade:before,
.border-right-grey-cascade {
    border-right-color: #95A5A6 !important;
}

.bg-grey-cascade {
    background: #95A5A6 !important;
}

.bg-grey-cascade-opacity {
    background: rgba(149, 165, 166, 0.8) !important;
}

.bg-after-grey-cascade:after,
.bg-before-grey-cascade:before,
.bg-hover-grey-cascade:hover {
    background: #95A5A6 !important;
}

.font-grey-cascade {
    color: #95A5A6 !important;
}

.bg-font-grey-cascade {
    color: #FFF !important;
}

.border-after-grey-silver:after,
.border-before-grey-silver:before,
.border-grey-silver {
    border-color: #BFBFBF !important;
}

.border-top-after-grey-silver:after,
.border-top-before-grey-silver:before,
.border-top-grey-silver {
    border-top-color: #BFBFBF !important;
}

.border-bottom-after-grey-silver:after,
.border-bottom-before-grey-silver:before,
.border-bottom-grey-silver {
    border-bottom-color: #BFBFBF !important;
}

.border-left-after-grey-silver:after,
.border-left-before-grey-silver:before,
.border-left-grey-silver {
    border-left-color: #BFBFBF !important;
}

.border-right-after-grey-silver:after,
.border-right-before-grey-silver:before,
.border-right-grey-silver {
    border-right-color: #BFBFBF !important;
}

.bg-grey-silver {
    background: #BFBFBF !important;
}

.bg-grey-silver-opacity {
    background: rgba(191, 191, 191, 0.8) !important;
}

.bg-after-grey-silver:after,
.bg-before-grey-silver:before,
.bg-hover-grey-silver:hover {
    background: #BFBFBF !important;
}

.font-grey-silver {
    color: #BFBFBF !important;
}

.bg-font-grey-silver {
    color: #FAFCFB !important;
}

.border-after-grey-salsa:after,
.border-before-grey-salsa:before,
.border-grey-salsa {
    border-color: #ACB5C3 !important;
}

.border-top-after-grey-salsa:after,
.border-top-before-grey-salsa:before,
.border-top-grey-salsa {
    border-top-color: #ACB5C3 !important;
}

.border-bottom-after-grey-salsa:after,
.border-bottom-before-grey-salsa:before,
.border-bottom-grey-salsa {
    border-bottom-color: #ACB5C3 !important;
}

.border-left-after-grey-salsa:after,
.border-left-before-grey-salsa:before,
.border-left-grey-salsa {
    border-left-color: #ACB5C3 !important;
}

.border-right-after-grey-salsa:after,
.border-right-before-grey-salsa:before,
.border-right-grey-salsa {
    border-right-color: #ACB5C3 !important;
}

.bg-grey-salsa {
    background: #ACB5C3 !important;
}

.bg-grey-salsa-opacity {
    background: rgba(172, 181, 195, 0.8) !important;
}

.bg-after-grey-salsa:after,
.bg-before-grey-salsa:before,
.bg-hover-grey-salsa:hover {
    background: #ACB5C3 !important;
}

.font-grey-salsa {
    color: #ACB5C3 !important;
}

.bg-font-grey-salsa {
    color: #FAFCFB !important;
}

.border-after-grey-salt:after,
.border-before-grey-salt:before,
.border-grey-salt {
    border-color: #bfcad1 !important;
}

.border-top-after-grey-salt:after,
.border-top-before-grey-salt:before,
.border-top-grey-salt {
    border-top-color: #bfcad1 !important;
}

.border-bottom-after-grey-salt:after,
.border-bottom-before-grey-salt:before,
.border-bottom-grey-salt {
    border-bottom-color: #bfcad1 !important;
}

.border-left-after-grey-salt:after,
.border-left-before-grey-salt:before,
.border-left-grey-salt {
    border-left-color: #bfcad1 !important;
}

.border-right-after-grey-salt:after,
.border-right-before-grey-salt:before,
.border-right-grey-salt {
    border-right-color: #bfcad1 !important;
}

.bg-grey-salt {
    background: #bfcad1 !important;
}

.bg-grey-salt-opacity {
    background: rgba(191, 202, 209, 0.8) !important;
}

.bg-after-grey-salt:after,
.bg-before-grey-salt:before,
.bg-hover-grey-salt:hover {
    background: #bfcad1 !important;
}

.font-grey-salt {
    color: #bfcad1 !important;
}

.bg-font-grey-salt {
    color: #FAFCFB !important;
}

.border-after-grey-mint:after,
.border-before-grey-mint:before,
.border-grey-mint {
    border-color: #525e64 !important;
}

.border-top-after-grey-mint:after,
.border-top-before-grey-mint:before,
.border-top-grey-mint {
    border-top-color: #525e64 !important;
}

.border-bottom-after-grey-mint:after,
.border-bottom-before-grey-mint:before,
.border-bottom-grey-mint {
    border-bottom-color: #525e64 !important;
}

.border-left-after-grey-mint:after,
.border-left-before-grey-mint:before,
.border-left-grey-mint {
    border-left-color: #525e64 !important;
}

.border-right-after-grey-mint:after,
.border-right-before-grey-mint:before,
.border-right-grey-mint {
    border-right-color: #525e64 !important;
}

.bg-grey-mint {
    background: #525e64 !important;
}

.bg-grey-mint-opacity {
    background: rgba(82, 94, 100, 0.8) !important;
}

.bg-after-grey-mint:after,
.bg-before-grey-mint:before,
.bg-hover-grey-mint:hover {
    background: #525e64 !important;
}

.font-grey-mint {
    color: #525e64 !important;
}

.bg-font-grey-mint {
    color: #FFF !important;
}

.border-after-red:after,
.border-before-red:before,
.border-red {
    border-color: #e7505a !important;
}

.border-top-after-red:after,
.border-top-before-red:before,
.border-top-red {
    border-top-color: #e7505a !important;
}

.border-bottom-after-red:after,
.border-bottom-before-red:before,
.border-bottom-red {
    border-bottom-color: #e7505a !important;
}

.border-left-after-red:after,
.border-left-before-red:before,
.border-left-red {
    border-left-color: #e7505a !important;
}

.border-right-after-red:after,
.border-right-before-red:before,
.border-right-red {
    border-right-color: #e7505a !important;
}

.bg-red {
    background: #e7505a !important;
}

.bg-red-opacity {
    background: rgba(231, 80, 90, 0.8) !important;
}

.bg-after-red:after,
.bg-before-red:before,
.bg-hover-red:hover {
    background: #e7505a !important;
}

.font-red {
    color: #e7505a !important;
}

.bg-font-red {
    color: #fff !important;
}

.border-after-red-pink:after,
.border-before-red-pink:before,
.border-red-pink {
    border-color: #E08283 !important;
}

.border-top-after-red-pink:after,
.border-top-before-red-pink:before,
.border-top-red-pink {
    border-top-color: #E08283 !important;
}

.border-bottom-after-red-pink:after,
.border-bottom-before-red-pink:before,
.border-bottom-red-pink {
    border-bottom-color: #E08283 !important;
}

.border-left-after-red-pink:after,
.border-left-before-red-pink:before,
.border-left-red-pink {
    border-left-color: #E08283 !important;
}

.border-right-after-red-pink:after,
.border-right-before-red-pink:before,
.border-right-red-pink {
    border-right-color: #E08283 !important;
}

.bg-red-pink {
    background: #E08283 !important;
}

.bg-red-pink-opacity {
    background: rgba(224, 130, 131, 0.8) !important;
}

.bg-after-red-pink:after,
.bg-before-red-pink:before,
.bg-hover-red-pink:hover {
    background: #E08283 !important;
}

.font-red-pink {
    color: #E08283 !important;
}

.bg-font-red-pink {
    color: #fff !important;
}

.border-after-red-sunglo:after,
.border-before-red-sunglo:before,
.border-red-sunglo {
    border-color: #E26A6A !important;
}

.border-top-after-red-sunglo:after,
.border-top-before-red-sunglo:before,
.border-top-red-sunglo {
    border-top-color: #E26A6A !important;
}

.border-bottom-after-red-sunglo:after,
.border-bottom-before-red-sunglo:before,
.border-bottom-red-sunglo {
    border-bottom-color: #E26A6A !important;
}

.border-left-after-red-sunglo:after,
.border-left-before-red-sunglo:before,
.border-left-red-sunglo {
    border-left-color: #E26A6A !important;
}

.border-right-after-red-sunglo:after,
.border-right-before-red-sunglo:before,
.border-right-red-sunglo {
    border-right-color: #E26A6A !important;
}

.bg-red-sunglo {
    background: #E26A6A !important;
}

.bg-red-sunglo-opacity {
    background: rgba(226, 106, 106, 0.8) !important;
}

.bg-after-red-sunglo:after,
.bg-before-red-sunglo:before,
.bg-hover-red-sunglo:hover {
    background: #E26A6A !important;
}

.font-red-sunglo {
    color: #E26A6A !important;
}

.bg-font-red-sunglo {
    color: #fff !important;
}

.border-after-red-intense:after,
.border-before-red-intense:before,
.border-red-intense {
    border-color: #e35b5a !important;
}

.border-top-after-red-intense:after,
.border-top-before-red-intense:before,
.border-top-red-intense {
    border-top-color: #e35b5a !important;
}

.border-bottom-after-red-intense:after,
.border-bottom-before-red-intense:before,
.border-bottom-red-intense {
    border-bottom-color: #e35b5a !important;
}

.border-left-after-red-intense:after,
.border-left-before-red-intense:before,
.border-left-red-intense {
    border-left-color: #e35b5a !important;
}

.border-right-after-red-intense:after,
.border-right-before-red-intense:before,
.border-right-red-intense {
    border-right-color: #e35b5a !important;
}

.bg-red-intense {
    background: #e35b5a !important;
}

.bg-red-intense-opacity {
    background: rgba(227, 91, 90, 0.8) !important;
}

.bg-after-red-intense:after,
.bg-before-red-intense:before,
.bg-hover-red-intense:hover {
    background: #e35b5a !important;
}

.font-red-intense {
    color: #e35b5a !important;
}

.bg-font-red-intense {
    color: #fff !important;
}

.border-after-red-thunderbird:after,
.border-before-red-thunderbird:before,
.border-red-thunderbird {
    border-color: #D91E18 !important;
}

.border-top-after-red-thunderbird:after,
.border-top-before-red-thunderbird:before,
.border-top-red-thunderbird {
    border-top-color: #D91E18 !important;
}

.border-bottom-after-red-thunderbird:after,
.border-bottom-before-red-thunderbird:before,
.border-bottom-red-thunderbird {
    border-bottom-color: #D91E18 !important;
}

.border-left-after-red-thunderbird:after,
.border-left-before-red-thunderbird:before,
.border-left-red-thunderbird {
    border-left-color: #D91E18 !important;
}

.border-right-after-red-thunderbird:after,
.border-right-before-red-thunderbird:before,
.border-right-red-thunderbird {
    border-right-color: #D91E18 !important;
}

.bg-red-thunderbird {
    background: #D91E18 !important;
}

.bg-red-thunderbird-opacity {
    background: rgba(217, 30, 24, 0.8) !important;
}

.bg-after-red-thunderbird:after,
.bg-before-red-thunderbird:before,
.bg-hover-red-thunderbird:hover {
    background: #D91E18 !important;
}

.font-red-thunderbird {
    color: #D91E18 !important;
}

.bg-font-red-thunderbird {
    color: #fff !important;
}

.border-after-red-flamingo:after,
.border-before-red-flamingo:before,
.border-red-flamingo {
    border-color: #EF4836 !important;
}

.border-top-after-red-flamingo:after,
.border-top-before-red-flamingo:before,
.border-top-red-flamingo {
    border-top-color: #EF4836 !important;
}

.border-bottom-after-red-flamingo:after,
.border-bottom-before-red-flamingo:before,
.border-bottom-red-flamingo {
    border-bottom-color: #EF4836 !important;
}

.border-left-after-red-flamingo:after,
.border-left-before-red-flamingo:before,
.border-left-red-flamingo {
    border-left-color: #EF4836 !important;
}

.border-right-after-red-flamingo:after,
.border-right-before-red-flamingo:before,
.border-right-red-flamingo {
    border-right-color: #EF4836 !important;
}

.bg-red-flamingo {
    background: #EF4836 !important;
}

.bg-red-flamingo-opacity {
    background: rgba(239, 72, 54, 0.8) !important;
}

.bg-after-red-flamingo:after,
.bg-before-red-flamingo:before,
.bg-hover-red-flamingo:hover {
    background: #EF4836 !important;
}

.font-red-flamingo {
    color: #EF4836 !important;
}

.bg-font-red-flamingo {
    color: #fff !important;
}

.border-after-red-soft:after,
.border-before-red-soft:before,
.border-red-soft {
    border-color: #d05454 !important;
}

.border-top-after-red-soft:after,
.border-top-before-red-soft:before,
.border-top-red-soft {
    border-top-color: #d05454 !important;
}

.border-bottom-after-red-soft:after,
.border-bottom-before-red-soft:before,
.border-bottom-red-soft {
    border-bottom-color: #d05454 !important;
}

.border-left-after-red-soft:after,
.border-left-before-red-soft:before,
.border-left-red-soft {
    border-left-color: #d05454 !important;
}

.border-right-after-red-soft:after,
.border-right-before-red-soft:before,
.border-right-red-soft {
    border-right-color: #d05454 !important;
}

.bg-red-soft {
    background: #d05454 !important;
}

.bg-red-soft-opacity {
    background: rgba(208, 84, 84, 0.8) !important;
}

.bg-after-red-soft:after,
.bg-before-red-soft:before,
.bg-hover-red-soft:hover {
    background: #d05454 !important;
}

.font-red-soft {
    color: #d05454 !important;
}

.bg-font-red-soft {
    color: #fff !important;
}

.border-after-red-haze:after,
.border-before-red-haze:before,
.border-red-haze {
    border-color: #f36a5a !important;
}

.border-top-after-red-haze:after,
.border-top-before-red-haze:before,
.border-top-red-haze {
    border-top-color: #f36a5a !important;
}

.border-bottom-after-red-haze:after,
.border-bottom-before-red-haze:before,
.border-bottom-red-haze {
    border-bottom-color: #f36a5a !important;
}

.border-left-after-red-haze:after,
.border-left-before-red-haze:before,
.border-left-red-haze {
    border-left-color: #f36a5a !important;
}

.border-right-after-red-haze:after,
.border-right-before-red-haze:before,
.border-right-red-haze {
    border-right-color: #f36a5a !important;
}

.bg-red-haze {
    background: #f36a5a !important;
}

.bg-red-haze-opacity {
    background: rgba(243, 106, 90, 0.8) !important;
}

.bg-after-red-haze:after,
.bg-before-red-haze:before,
.bg-hover-red-haze:hover {
    background: #f36a5a !important;
}

.font-red-haze {
    color: #f36a5a !important;
}

.bg-font-red-haze {
    color: #fff !important;
}

.border-after-red-mint:after,
.border-before-red-mint:before,
.border-red-mint {
    border-color: #e43a45 !important;
}

.border-top-after-red-mint:after,
.border-top-before-red-mint:before,
.border-top-red-mint {
    border-top-color: #e43a45 !important;
}

.border-bottom-after-red-mint:after,
.border-bottom-before-red-mint:before,
.border-bottom-red-mint {
    border-bottom-color: #e43a45 !important;
}

.border-left-after-red-mint:after,
.border-left-before-red-mint:before,
.border-left-red-mint {
    border-left-color: #e43a45 !important;
}

.border-right-after-red-mint:after,
.border-right-before-red-mint:before,
.border-right-red-mint {
    border-right-color: #e43a45 !important;
}

.bg-red-mint {
    background: #e43a45 !important;
}

.bg-red-mint-opacity {
    background: rgba(228, 58, 69, 0.8) !important;
}

.bg-after-red-mint:after,
.bg-before-red-mint:before,
.bg-hover-red-mint:hover {
    background: #e43a45 !important;
}

.font-red-mint {
    color: #e43a45 !important;
}

.bg-font-red-mint {
    color: #fff !important;
}

.border-after-yellow:after,
.border-before-yellow:before,
.border-yellow {
    border-color: #c49f47 !important;
}

.border-top-after-yellow:after,
.border-top-before-yellow:before,
.border-top-yellow {
    border-top-color: #c49f47 !important;
}

.border-bottom-after-yellow:after,
.border-bottom-before-yellow:before,
.border-bottom-yellow {
    border-bottom-color: #c49f47 !important;
}

.border-left-after-yellow:after,
.border-left-before-yellow:before,
.border-left-yellow {
    border-left-color: #c49f47 !important;
}

.border-right-after-yellow:after,
.border-right-before-yellow:before,
.border-right-yellow {
    border-right-color: #c49f47 !important;
}

.bg-yellow {
    background: #c49f47 !important;
}

.bg-yellow-opacity {
    background: rgba(196, 159, 71, 0.8) !important;
}

.bg-after-yellow:after,
.bg-before-yellow:before,
.bg-hover-yellow:hover {
    background: #c49f47 !important;
}

.font-yellow {
    color: #c49f47 !important;
}

.bg-font-yellow {
    color: #fff !important;
}

.border-after-yellow-gold:after,
.border-before-yellow-gold:before,
.border-yellow-gold {
    border-color: #E87E04 !important;
}

.border-top-after-yellow-gold:after,
.border-top-before-yellow-gold:before,
.border-top-yellow-gold {
    border-top-color: #E87E04 !important;
}

.border-bottom-after-yellow-gold:after,
.border-bottom-before-yellow-gold:before,
.border-bottom-yellow-gold {
    border-bottom-color: #E87E04 !important;
}

.border-left-after-yellow-gold:after,
.border-left-before-yellow-gold:before,
.border-left-yellow-gold {
    border-left-color: #E87E04 !important;
}

.border-right-after-yellow-gold:after,
.border-right-before-yellow-gold:before,
.border-right-yellow-gold {
    border-right-color: #E87E04 !important;
}

.bg-yellow-gold {
    background: #E87E04 !important;
}

.bg-yellow-gold-opacity {
    background: rgba(232, 126, 4, 0.8) !important;
}

.bg-after-yellow-gold:after,
.bg-before-yellow-gold:before,
.bg-hover-yellow-gold:hover {
    background: #E87E04 !important;
}

.font-yellow-gold {
    color: #E87E04 !important;
}

.bg-font-yellow-gold {
    color: #fff !important;
}

.border-after-yellow-casablanca:after,
.border-before-yellow-casablanca:before,
.border-yellow-casablanca {
    border-color: #f2784b !important;
}

.border-top-after-yellow-casablanca:after,
.border-top-before-yellow-casablanca:before,
.border-top-yellow-casablanca {
    border-top-color: #f2784b !important;
}

.border-bottom-after-yellow-casablanca:after,
.border-bottom-before-yellow-casablanca:before,
.border-bottom-yellow-casablanca {
    border-bottom-color: #f2784b !important;
}

.border-left-after-yellow-casablanca:after,
.border-left-before-yellow-casablanca:before,
.border-left-yellow-casablanca {
    border-left-color: #f2784b !important;
}

.border-right-after-yellow-casablanca:after,
.border-right-before-yellow-casablanca:before,
.border-right-yellow-casablanca {
    border-right-color: #f2784b !important;
}

.bg-yellow-casablanca {
    background: #f2784b !important;
}

.bg-yellow-casablanca-opacity {
    background: rgba(242, 120, 75, 0.8) !important;
}

.bg-after-yellow-casablanca:after,
.bg-before-yellow-casablanca:before,
.bg-hover-yellow-casablanca:hover {
    background: #f2784b !important;
}

.font-yellow-casablanca {
    color: #f2784b !important;
}

.bg-font-yellow-casablanca {
    color: #fff !important;
}

.border-after-yellow-crusta:after,
.border-before-yellow-crusta:before,
.border-yellow-crusta {
    border-color: #f3c200 !important;
}

.border-top-after-yellow-crusta:after,
.border-top-before-yellow-crusta:before,
.border-top-yellow-crusta {
    border-top-color: #f3c200 !important;
}

.border-bottom-after-yellow-crusta:after,
.border-bottom-before-yellow-crusta:before,
.border-bottom-yellow-crusta {
    border-bottom-color: #f3c200 !important;
}

.border-left-after-yellow-crusta:after,
.border-left-before-yellow-crusta:before,
.border-left-yellow-crusta {
    border-left-color: #f3c200 !important;
}

.border-right-after-yellow-crusta:after,
.border-right-before-yellow-crusta:before,
.border-right-yellow-crusta {
    border-right-color: #f3c200 !important;
}

.bg-yellow-crusta {
    background: #f3c200 !important;
}

.bg-yellow-crusta-opacity {
    background: rgba(243, 194, 0, 0.8) !important;
}

.bg-after-yellow-crusta:after,
.bg-before-yellow-crusta:before,
.bg-hover-yellow-crusta:hover {
    background: #f3c200 !important;
}

.font-yellow-crusta {
    color: #f3c200 !important;
}

.bg-font-yellow-crusta {
    color: #fff !important;
}

.border-after-yellow-lemon:after,
.border-before-yellow-lemon:before,
.border-yellow-lemon {
    border-color: #F7CA18 !important;
}

.border-top-after-yellow-lemon:after,
.border-top-before-yellow-lemon:before,
.border-top-yellow-lemon {
    border-top-color: #F7CA18 !important;
}

.border-bottom-after-yellow-lemon:after,
.border-bottom-before-yellow-lemon:before,
.border-bottom-yellow-lemon {
    border-bottom-color: #F7CA18 !important;
}

.border-left-after-yellow-lemon:after,
.border-left-before-yellow-lemon:before,
.border-left-yellow-lemon {
    border-left-color: #F7CA18 !important;
}

.border-right-after-yellow-lemon:after,
.border-right-before-yellow-lemon:before,
.border-right-yellow-lemon {
    border-right-color: #F7CA18 !important;
}

.bg-yellow-lemon {
    background: #F7CA18 !important;
}

.bg-yellow-lemon-opacity {
    background: rgba(247, 202, 24, 0.8) !important;
}

.bg-after-yellow-lemon:after,
.bg-before-yellow-lemon:before,
.bg-hover-yellow-lemon:hover {
    background: #F7CA18 !important;
}

.font-yellow-lemon {
    color: #F7CA18 !important;
}

.bg-font-yellow-lemon {
    color: #fff !important;
}

.border-after-yellow-saffron:after,
.border-before-yellow-saffron:before,
.border-yellow-saffron {
    border-color: #F4D03F !important;
}

.border-top-after-yellow-saffron:after,
.border-top-before-yellow-saffron:before,
.border-top-yellow-saffron {
    border-top-color: #F4D03F !important;
}

.border-bottom-after-yellow-saffron:after,
.border-bottom-before-yellow-saffron:before,
.border-bottom-yellow-saffron {
    border-bottom-color: #F4D03F !important;
}

.border-left-after-yellow-saffron:after,
.border-left-before-yellow-saffron:before,
.border-left-yellow-saffron {
    border-left-color: #F4D03F !important;
}

.border-right-after-yellow-saffron:after,
.border-right-before-yellow-saffron:before,
.border-right-yellow-saffron {
    border-right-color: #F4D03F !important;
}

.bg-yellow-saffron {
    background: #F4D03F !important;
}

.bg-yellow-saffron-opacity {
    background: rgba(244, 208, 63, 0.8) !important;
}

.bg-after-yellow-saffron:after,
.bg-before-yellow-saffron:before,
.bg-hover-yellow-saffron:hover {
    background: #F4D03F !important;
}

.font-yellow-saffron {
    color: #F4D03F !important;
}

.bg-font-yellow-saffron {
    color: #fff !important;
}

.border-after-yellow-soft:after,
.border-before-yellow-soft:before,
.border-yellow-soft {
    border-color: #c8d046 !important;
}

.border-top-after-yellow-soft:after,
.border-top-before-yellow-soft:before,
.border-top-yellow-soft {
    border-top-color: #c8d046 !important;
}

.border-bottom-after-yellow-soft:after,
.border-bottom-before-yellow-soft:before,
.border-bottom-yellow-soft {
    border-bottom-color: #c8d046 !important;
}

.border-left-after-yellow-soft:after,
.border-left-before-yellow-soft:before,
.border-left-yellow-soft {
    border-left-color: #c8d046 !important;
}

.border-right-after-yellow-soft:after,
.border-right-before-yellow-soft:before,
.border-right-yellow-soft {
    border-right-color: #c8d046 !important;
}

.bg-yellow-soft {
    background: #c8d046 !important;
}

.bg-yellow-soft-opacity {
    background: rgba(200, 208, 70, 0.8) !important;
}

.bg-after-yellow-soft:after,
.bg-before-yellow-soft:before,
.bg-hover-yellow-soft:hover {
    background: #c8d046 !important;
}

.font-yellow-soft {
    color: #c8d046 !important;
}

.bg-font-yellow-soft {
    color: #fff !important;
}

.border-after-yellow-haze:after,
.border-before-yellow-haze:before,
.border-yellow-haze {
    border-color: #c5bf66 !important;
}

.border-top-after-yellow-haze:after,
.border-top-before-yellow-haze:before,
.border-top-yellow-haze {
    border-top-color: #c5bf66 !important;
}

.border-bottom-after-yellow-haze:after,
.border-bottom-before-yellow-haze:before,
.border-bottom-yellow-haze {
    border-bottom-color: #c5bf66 !important;
}

.border-left-after-yellow-haze:after,
.border-left-before-yellow-haze:before,
.border-left-yellow-haze {
    border-left-color: #c5bf66 !important;
}

.border-right-after-yellow-haze:after,
.border-right-before-yellow-haze:before,
.border-right-yellow-haze {
    border-right-color: #c5bf66 !important;
}

.bg-yellow-haze {
    background: #c5bf66 !important;
}

.bg-yellow-haze-opacity {
    background: rgba(197, 191, 102, 0.8) !important;
}

.bg-after-yellow-haze:after,
.bg-before-yellow-haze:before,
.bg-hover-yellow-haze:hover {
    background: #c5bf66 !important;
}

.font-yellow-haze {
    color: #c5bf66 !important;
}

.bg-font-yellow-haze {
    color: #fff !important;
}

.border-after-yellow-mint:after,
.border-before-yellow-mint:before,
.border-yellow-mint {
    border-color: #c5b96b !important;
}

.border-top-after-yellow-mint:after,
.border-top-before-yellow-mint:before,
.border-top-yellow-mint {
    border-top-color: #c5b96b !important;
}

.border-bottom-after-yellow-mint:after,
.border-bottom-before-yellow-mint:before,
.border-bottom-yellow-mint {
    border-bottom-color: #c5b96b !important;
}

.border-left-after-yellow-mint:after,
.border-left-before-yellow-mint:before,
.border-left-yellow-mint {
    border-left-color: #c5b96b !important;
}

.border-right-after-yellow-mint:after,
.border-right-before-yellow-mint:before,
.border-right-yellow-mint {
    border-right-color: #c5b96b !important;
}

.bg-yellow-mint {
    background: #c5b96b !important;
}

.bg-yellow-mint-opacity {
    background: rgba(197, 185, 107, 0.8) !important;
}

.bg-after-yellow-mint:after,
.bg-before-yellow-mint:before,
.bg-hover-yellow-mint:hover {
    background: #c5b96b !important;
}

.font-yellow-mint {
    color: #c5b96b !important;
}

.bg-font-yellow-mint {
    color: #fff !important;
}

.border-after-purple:after,
.border-before-purple:before,
.border-purple {
    border-color: #8E44AD !important;
}

.border-top-after-purple:after,
.border-top-before-purple:before,
.border-top-purple {
    border-top-color: #8E44AD !important;
}

.border-bottom-after-purple:after,
.border-bottom-before-purple:before,
.border-bottom-purple {
    border-bottom-color: #8E44AD !important;
}

.border-left-after-purple:after,
.border-left-before-purple:before,
.border-left-purple {
    border-left-color: #8E44AD !important;
}

.border-right-after-purple:after,
.border-right-before-purple:before,
.border-right-purple {
    border-right-color: #8E44AD !important;
}

.bg-purple {
    background: #8E44AD !important;
}

.bg-purple-opacity {
    background: rgba(142, 68, 173, 0.8) !important;
}

.bg-after-purple:after,
.bg-before-purple:before,
.bg-hover-purple:hover {
    background: #8E44AD !important;
}

.font-purple {
    color: #8E44AD !important;
}

.bg-font-purple {
    color: #fff !important;
}

.border-after-purple-plum:after,
.border-before-purple-plum:before,
.border-purple-plum {
    border-color: #8775a7 !important;
}

.border-top-after-purple-plum:after,
.border-top-before-purple-plum:before,
.border-top-purple-plum {
    border-top-color: #8775a7 !important;
}

.border-bottom-after-purple-plum:after,
.border-bottom-before-purple-plum:before,
.border-bottom-purple-plum {
    border-bottom-color: #8775a7 !important;
}

.border-left-after-purple-plum:after,
.border-left-before-purple-plum:before,
.border-left-purple-plum {
    border-left-color: #8775a7 !important;
}

.border-right-after-purple-plum:after,
.border-right-before-purple-plum:before,
.border-right-purple-plum {
    border-right-color: #8775a7 !important;
}

.bg-purple-plum {
    background: #8775a7 !important;
}

.bg-purple-plum-opacity {
    background: rgba(135, 117, 167, 0.8) !important;
}

.bg-after-purple-plum:after,
.bg-before-purple-plum:before,
.bg-hover-purple-plum:hover {
    background: #8775a7 !important;
}

.font-purple-plum {
    color: #8775a7 !important;
}

.bg-font-purple-plum {
    color: #fff !important;
}

.border-after-purple-medium:after,
.border-before-purple-medium:before,
.border-purple-medium {
    border-color: #BF55EC !important;
}

.border-top-after-purple-medium:after,
.border-top-before-purple-medium:before,
.border-top-purple-medium {
    border-top-color: #BF55EC !important;
}

.border-bottom-after-purple-medium:after,
.border-bottom-before-purple-medium:before,
.border-bottom-purple-medium {
    border-bottom-color: #BF55EC !important;
}

.border-left-after-purple-medium:after,
.border-left-before-purple-medium:before,
.border-left-purple-medium {
    border-left-color: #BF55EC !important;
}

.border-right-after-purple-medium:after,
.border-right-before-purple-medium:before,
.border-right-purple-medium {
    border-right-color: #BF55EC !important;
}

.bg-purple-medium {
    background: #BF55EC !important;
}

.bg-purple-medium-opacity {
    background: rgba(191, 85, 236, 0.8) !important;
}

.bg-after-purple-medium:after,
.bg-before-purple-medium:before,
.bg-hover-purple-medium:hover {
    background: #BF55EC !important;
}

.font-purple-medium {
    color: #BF55EC !important;
}

.bg-font-purple-medium {
    color: #fff !important;
}

.border-after-purple-studio:after,
.border-before-purple-studio:before,
.border-purple-studio {
    border-color: #8E44AD !important;
}

.border-top-after-purple-studio:after,
.border-top-before-purple-studio:before,
.border-top-purple-studio {
    border-top-color: #8E44AD !important;
}

.border-bottom-after-purple-studio:after,
.border-bottom-before-purple-studio:before,
.border-bottom-purple-studio {
    border-bottom-color: #8E44AD !important;
}

.border-left-after-purple-studio:after,
.border-left-before-purple-studio:before,
.border-left-purple-studio {
    border-left-color: #8E44AD !important;
}

.border-right-after-purple-studio:after,
.border-right-before-purple-studio:before,
.border-right-purple-studio {
    border-right-color: #8E44AD !important;
}

.bg-purple-studio {
    background: #8E44AD !important;
}

.bg-purple-studio-opacity {
    background: rgba(142, 68, 173, 0.8) !important;
}

.bg-after-purple-studio:after,
.bg-before-purple-studio:before,
.bg-hover-purple-studio:hover {
    background: #8E44AD !important;
}

.font-purple-studio {
    color: #8E44AD !important;
}

.bg-font-purple-studio {
    color: #fff !important;
}

.border-after-purple-wisteria:after,
.border-before-purple-wisteria:before,
.border-purple-wisteria {
    border-color: #9B59B6 !important;
}

.border-top-after-purple-wisteria:after,
.border-top-before-purple-wisteria:before,
.border-top-purple-wisteria {
    border-top-color: #9B59B6 !important;
}

.border-bottom-after-purple-wisteria:after,
.border-bottom-before-purple-wisteria:before,
.border-bottom-purple-wisteria {
    border-bottom-color: #9B59B6 !important;
}

.border-left-after-purple-wisteria:after,
.border-left-before-purple-wisteria:before,
.border-left-purple-wisteria {
    border-left-color: #9B59B6 !important;
}

.border-right-after-purple-wisteria:after,
.border-right-before-purple-wisteria:before,
.border-right-purple-wisteria {
    border-right-color: #9B59B6 !important;
}

.bg-purple-wisteria {
    background: #9B59B6 !important;
}

.bg-purple-wisteria-opacity {
    background: rgba(155, 89, 182, 0.8) !important;
}

.bg-after-purple-wisteria:after,
.bg-before-purple-wisteria:before,
.bg-hover-purple-wisteria:hover {
    background: #9B59B6 !important;
}

.font-purple-wisteria {
    color: #9B59B6 !important;
}

.bg-font-purple-wisteria {
    color: #fff !important;
}

.border-after-purple-seance:after,
.border-before-purple-seance:before,
.border-purple-seance {
    border-color: #9A12B3 !important;
}

.border-top-after-purple-seance:after,
.border-top-before-purple-seance:before,
.border-top-purple-seance {
    border-top-color: #9A12B3 !important;
}

.border-bottom-after-purple-seance:after,
.border-bottom-before-purple-seance:before,
.border-bottom-purple-seance {
    border-bottom-color: #9A12B3 !important;
}

.border-left-after-purple-seance:after,
.border-left-before-purple-seance:before,
.border-left-purple-seance {
    border-left-color: #9A12B3 !important;
}

.border-right-after-purple-seance:after,
.border-right-before-purple-seance:before,
.border-right-purple-seance {
    border-right-color: #9A12B3 !important;
}

.bg-purple-seance {
    background: #9A12B3 !important;
}

.bg-purple-seance-opacity {
    background: rgba(154, 18, 179, 0.8) !important;
}

.bg-after-purple-seance:after,
.bg-before-purple-seance:before,
.bg-hover-purple-seance:hover {
    background: #9A12B3 !important;
}

.font-purple-seance {
    color: #9A12B3 !important;
}

.bg-font-purple-seance {
    color: #fff !important;
}

.border-after-purple-intense:after,
.border-before-purple-intense:before,
.border-purple-intense {
    border-color: #8775a7 !important;
}

.border-top-after-purple-intense:after,
.border-top-before-purple-intense:before,
.border-top-purple-intense {
    border-top-color: #8775a7 !important;
}

.border-bottom-after-purple-intense:after,
.border-bottom-before-purple-intense:before,
.border-bottom-purple-intense {
    border-bottom-color: #8775a7 !important;
}

.border-left-after-purple-intense:after,
.border-left-before-purple-intense:before,
.border-left-purple-intense {
    border-left-color: #8775a7 !important;
}

.border-right-after-purple-intense:after,
.border-right-before-purple-intense:before,
.border-right-purple-intense {
    border-right-color: #8775a7 !important;
}

.bg-purple-intense {
    background: #8775a7 !important;
}

.bg-purple-intense-opacity {
    background: rgba(135, 117, 167, 0.8) !important;
}

.bg-after-purple-intense:after,
.bg-before-purple-intense:before,
.bg-hover-purple-intense:hover {
    background: #8775a7 !important;
}

.font-purple-intense {
    color: #8775a7 !important;
}

.bg-font-purple-intense {
    color: #fff !important;
}

.border-after-purple-sharp:after,
.border-before-purple-sharp:before,
.border-purple-sharp {
    border-color: #796799 !important;
}

.border-top-after-purple-sharp:after,
.border-top-before-purple-sharp:before,
.border-top-purple-sharp {
    border-top-color: #796799 !important;
}

.border-bottom-after-purple-sharp:after,
.border-bottom-before-purple-sharp:before,
.border-bottom-purple-sharp {
    border-bottom-color: #796799 !important;
}

.border-left-after-purple-sharp:after,
.border-left-before-purple-sharp:before,
.border-left-purple-sharp {
    border-left-color: #796799 !important;
}

.border-right-after-purple-sharp:after,
.border-right-before-purple-sharp:before,
.border-right-purple-sharp {
    border-right-color: #796799 !important;
}

.bg-purple-sharp {
    background: #796799 !important;
}

.bg-purple-sharp-opacity {
    background: rgba(121, 103, 153, 0.8) !important;
}

.bg-after-purple-sharp:after,
.bg-before-purple-sharp:before,
.bg-hover-purple-sharp:hover {
    background: #796799 !important;
}

.font-purple-sharp {
    color: #796799 !important;
}

.bg-font-purple-sharp {
    color: #fff !important;
}

.border-after-purple-soft:after,
.border-before-purple-soft:before,
.border-purple-soft {
    border-color: #8877a9 !important;
}

.border-top-after-purple-soft:after,
.border-top-before-purple-soft:before,
.border-top-purple-soft {
    border-top-color: #8877a9 !important;
}

.border-bottom-after-purple-soft:after,
.border-bottom-before-purple-soft:before,
.border-bottom-purple-soft {
    border-bottom-color: #8877a9 !important;
}

.border-left-after-purple-soft:after,
.border-left-before-purple-soft:before,
.border-left-purple-soft {
    border-left-color: #8877a9 !important;
}

.border-right-after-purple-soft:after,
.border-right-before-purple-soft:before,
.border-right-purple-soft {
    border-right-color: #8877a9 !important;
}

.bg-purple-soft {
    background: #8877a9 !important;
}

.bg-purple-soft-opacity {
    background: rgba(136, 119, 169, 0.8) !important;
}

.bg-after-purple-soft:after,
.bg-before-purple-soft:before,
.bg-hover-purple-soft:hover {
    background: #8877a9 !important;
}

.font-purple-soft {
    color: #8877a9 !important;
}

.bg-font-purple-soft {
    color: #fff !important;
}

.color-demo {
    border: 1px solid #eee;
    margin: 0 0 20px;
    cursor: pointer;
    .color-view {
        padding: 35px 10px;
        text-align: center;
        font-size: 18px;
    }
    .color-info {
        border-top: 1px solid #eee;
        padding: 10px;
        text-align: center;
    }
}

.mt-cookie-consent-bar {
    position: fixed;
    bottom: 0;
    z-index: 10101;
    padding: 10px 25px;
    width: 100%;
    background: rgba(0, 0, 0, 0.9);
    color: #fff;
    left: 0;
    right: 0;
    .mt-cookie-consent-bar-holder {
        display: table;
        width: 100%;
        .mt-cookie-consent-bar-content {
            display: table-cell;
            text-align: left;
            vertical-align: middle;
        }
        .mt-cookie-consent-bar-action {
            display: table-cell;
            text-align: right;
            vertical-align: middle;
        }
    }
    &.mt-cookie-consent-bar-light {
        background: rgba(238, 238, 238, 0.9);
        color: #333;
    }
}

.mt-content-row {
    display: table;
    height: 100%;
    width: 100%;
    .mt-content-col-fluid {
        display: table-cell;
        .mt-content-body {
            margin: 0;
            height: 100%;
            width: 100%;
            display: table;
        }
    }
    .mt-content-col-fixed {
        display: table-cell;
        .mt-content-sidebar {
            height: 100%;
            width: 100%;
            display: table;
            margin-left: 20px;
            border: 1px solid red;
        }
    }
}

.select2-container--bootstrap .select2-selection {
    border: 0;
    padding: 8px 24px 6px 25px;
}

@media (max-width: 767px) {
    .mt-content-row {
        display: block;
        width: 100%;
        .mt-content-col-fixed {
            display: block;
            width: 100%;
        }
        .mt-content-col-fluid {
            display: block;
            width: 100%;
            margin-bottom: 20px;
            .mt-content-body {
                margin: 0;
            }
        }
        .mt-content-col-fixed .mt-content-sidebar {
            margin-left: 0;
        }
    }
}

#ratingList {
    .table-hover>tbody>tr.performance-group td,
    .table-hover>tbody>tr.performance-group:hover td {
        background-color: #00688F;
        color: white;
    }
    .project-list th {
        text-align: center;
    }
    .project-rating-header {
        text-align: center;
        border-bottom: 1px solid #00688F;
    }
    .table-hover>tbody>tr.group td,
    .table-hover>tbody>tr.group:hover td {
        background-color: #00688F;
        color: white
    }
    .review-projects-tag {
        padding: 0;
        list-style: none;
        margin: 0;
    }
    .review-projects-tag li {
        background-color: #00688f;
        display: inline-block;
        color: #fff;
        padding: 4px;
        margin-right: 5px;
        border-radius: 3px !important;
    }
}